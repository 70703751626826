import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  FormControl,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D5VALI } from "../../../Validation";
import { Box } from "react-bootstrap-icons";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArE5Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [Lov, setLov] = useState([]);
  // InstituteId,RptMonth,IAMRSId,Activities,ProgramTitle,RecordingDate,BroadCastingDate
  const [activities, setActivities] = useState("");
  const [programTitle, setProgramTitle] = useState("");
  const [recordingDate, setRecordingDate] = useState("");
  const [broadCastingDate, setBroadCastingDate] = useState("");

  const [activitiesErr, setActivitiesErr] = useState(false);
  const [programTitleErr, setProgramTitleErr] = useState(false);
  const [recordingDateErr, setRecordingDateErr] = useState(false);
  const [broadCastingDateErr, setBroadCastingDateErr] = useState(false);

  const [stateType, setStateType] = useState([]);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader,setLoader] =useState(true);
  const [inves, setInves] = useState([])
  const [InvestigatorName, setInvestigatorName] = useState("")
  const [InvestigatorNameErr, setInvestigatorNameErr] = useState(false)


  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D5VALI),
  });

  const backs = () => {
    navigate("/ArE5");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("items purchased");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    const tok = localStorage.getItem("token");
    setInsti(institute);
    handleInvestigators(tok, institute)
  }, []);

  const edit = (item) => {
    console.log(item);
    setActivities(item.activities);
    setProgramTitle(item.programTitle);
    setRecordingDate(dateFormat(item.recordingDate));
    setBroadCastingDate(dateFormat(item.broadCastingDate));
    setId(item.id);
    setInvestigatorName(item.investigatorId);
  };

  const handleLov = async (token, Activities) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"Activities"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLov(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      InvestigatorId: InvestigatorName,
      Activities: activities,
      ProgramTitle: programTitle,
      RecordingDate: recordingDate,
      BroadCastingDate: broadCastingDate,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Er5SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArE5");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Er5SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArE5");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const handleInvestigators = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);
    } catch (error) {
      console.log(error);
    }
  };
  const formValidateFun = () => {
    if(!InvestigatorName){
      setInvestigatorNameErr(true)
      document.getElementById("InvestigatorName").focus()}
  else  if (!activities) {
      setActivitiesErr(true);
      document.getElementById("activities").focus();
    } else if (!programTitle) {
      setProgramTitleErr(true);
      document.getElementById("programTitle").focus();
    } else if (!recordingDate) {
      setRecordingDateErr(true);
      document.getElementById("recordingDate").focus();
    } else if (!broadCastingDate) {
      setBroadCastingDateErr(true);
      document.getElementById("broadCastingDate").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
         <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span> E5. Other Extension Activities undertaken</span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control">
        <h5 style={{ margin: 20 }}>
          E5. Other Extension Activities undertaken
        </h5>

        
        <FormControl style={{ width: "48%", margin: 12 }} error={InvestigatorNameErr} id="InvestigatorName">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Faculty Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="investigatorName"
              value={InvestigatorName}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setInvestigatorName(e.target.value)
                setInvestigatorNameErr(false)
              }}
            >
              {inves?.map((row, index) => {
                return (
                  <MenuItem key={row.index} value={row.id}>
                    {row.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ width: "48%", margin: 12 }} error={activitiesErr}  id="activities">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Activities
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="activities"
              value={activities}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setActivities(e.target.value)
                setActivitiesErr(false)
              }}
            >
            {Lov?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.lovName}>
                  {row.lovName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>

        
          <TextField
            error={programTitleErr}
            id="programTitle"
            label="Title of the Programme (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={programTitle}
            onChange={(e) => {
                setProgramTitle(e.target.value)
                setProgramTitleErr(false)
              }}
          />

        <TextField
          error={recordingDateErr}
          id="recordingDate"
          label="Date of recording of the  programme"
          variant="outlined"
          type="date"
          style={{ margin: "10px", width: "48%" }}
          value={recordingDate}
          onChange={(e) => {
            setRecordingDate(e.target.value);
            setRecordingDateErr(false);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            placeholder: "",
          }}
        />

        <TextField
          error={broadCastingDateErr}
          id="broadCastingDate"
          label="Date of broadcasting of the  programme "
          variant="outlined"
          type="date"
          style={{ margin: "10px", width: "48%" }}
          value={broadCastingDate}
          onChange={(e) => {
            setBroadCastingDate(e.target.value);
            setBroadCastingDateErr(false);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            placeholder: "",
          }}
        />

        <div className="from-last">
          <button
            type="submit"
            style={{
              backgroundColor: "#008CBA",
              padding: "5px",
              borderRadius: "3px",
              width: "80px",
              color: "#fff",
            }}
            onClick={backs}
          >
            Back
          </button>
          <button
            type="submit"
            style={{ paddingTop: "5px" }}
            className="sbmit-buttons"
            onClick={formValidateFun}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArE5Inside;
