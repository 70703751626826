import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { dateFormat } from "../../utils/Date";
import ComponentLoader from "../../ComponentLoader";
const defaltDate="1900-01-01T00:00:00"
function AdminReportStatus() {
  const [rolee, setRolee] = useState("");
  const [isInvesIsTrue, setInvesIsTrue] = useState("");
  const [listDataInsti, setListDataInsti] = useState([]);
  const [listAll, SetListAll] = useState([]);
  const [llll, setIII] = useState("");
  const [ins, setIns] = useState();
  const [monthData, setMonthData] = useState([])
  const [assM, setAssMonth] = useState("");
  const [listDataDepat, setListDataDepat] = useState([]);
  const [masState, setMasState] = useState({
    inIv: 0,
    inTu: 0,
    diId: 0,
  });
  const [loader, setLoader] = useState(true);

  const isTrue = rolee === "Investigator";

  useEffect(() => {
    const investigator = localStorage.getItem("loginId");
    const inst = localStorage.getItem("loginId");
    setIns(inst);

    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      // setAssMonth(Month);
    }

    const tok = localStorage.getItem("token");
    setIII(tok);
    monthList(tok)

    const rolee = localStorage.getItem("role");
    setRolee(rolee);

    let invesIsTrue = localStorage.getItem("role");
    setInvesIsTrue(invesIsTrue);
    setMasState((prev) => ({
      ...prev,
      diId: investigator,
    }));

    if (rolee === "Director") {
      handleInstitute(tok, investigator);
    }

    if (rolee == "Principal") {
      handleDepat(tok, investigator);
    }
    if (rolee === "Director") {
      handleDepat(tok, investigator);
    }
  }, []);

  const handleInstitute = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      console.log(data);
      //  setHandleDepat(data)
      setListDataInsti(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleDepat = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEDEPARTMENT}`,
        {
          headers: h,
        }
      );
      console.log(data);
      //  setHandleDepat(data)
      setListDataDepat(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const monthList = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.MONTH_LIST}`, { headers: h, }
      );
      console.log(data);
      setMonthData(data);setLoader(false)

    } catch (error) {
      console.log(error);setLoader(false)
    }

  }

  const getInfo = async (value, opt, inds) => {
    if(assM === ""){
      alert("Please Select Month")
    }else{
    setLoader(true);
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    let d = {
      InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
      RptMonth: assM !== "" ? parseInt(assM) : "",
      DirectorateId: masState.diId > 0 ? parseInt(masState.diId) : 0,
      InstituteId: masState.inTu > 0 ? parseInt(masState.inTu) : 0,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ADMINREPORT}`,
        d,
        { headers: h }
      );
      if(data){
        setLoader(false)
      }
      SetListAll(data); 
     
      console.log(data); 
      if (data.monthlyReport?.length > 0) {
        const di = JSON.stringify(d);
        localStorage.setItem("getinfoDId", di);
        //setMonthlyReport(data.monthlyReport[0]);
        const objectString = JSON.stringify(data.monthlyReport[0]);
        localStorage.setItem("MReport", objectString);
        console.log(data);
      
      } else {
        //setMonthlyReport({});
        localStorage.removeItem("MReport");
      }
    } catch (error) {
      console.log(error);  setLoader(false)
    }}
  };

  return (
    <div style={{ marginTop: 70 }}>
      <ComponentLoader isLoading={loader} />
      <ToastContainer />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Month Report Submitted Status</span>
        </div>
      </div>
      <div className="table-container-grid table-gridss">
        {/* <div className="table-head"> */}
        <div>
          <div
             style={{ height: "60px" }}
            className="headerr d-flex py-2 align-items-center head-fix headsss"
          >
            <div className="d-flex" style={{ alignItems:"center"}} >
              <Link to={isTrue ? "/MasterDataFaculty" : "/MasterData"}>
                {/* <HomeIcon
                    style={{ marginTop: 12, marginLeft: 10, color: "white" }}
                  /> */}
              </Link>
              <h5 className="mt-3 mr-3">&nbsp;&nbsp;&nbsp;Filter:&nbsp;</h5>
              <div className="pr-2">
                <select
                  value={assM}
                  onChange={(e) => {
                    setAssMonth(e.target.value)
                   
                  }}
                  id="months"
                  name="months"
                >
                  <option value="">Select Month</option>
                  {monthData?.map((item) => (
                    <option value={item.monthCode}> {item.monthName} </option>
                  ))
                  }
                </select>
              </div>
            </div>

            {/* <div className="Admin-Select">
                {isInvesIsTrue === "Director" ? (
                  <>
                    <select
                      id="months"
                      name="months"
                      style={{ marginLeft: "10px" }}
                    >
                      <option value="Example">Institute</option>
                      {listDataDepat?.map((item) => (
                        <option value={item.id}>{item.instituteName}</option>
                      ))}
                    </select>
                  </>
                ) : null}
              </div> */}
            <div className="header-btn mt-2 mr-2">
              <button onClick={getInfo}>Get Info</button>
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* ************************* */}
        <div style={{ marginTop: "10px" }}>
          <TableContainer>
            <Table
              // sx={tableStyle}
              aria-labelledby="tableTitle"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="headtable">
                <TableCell align="left">S.No</TableCell>
                <TableCell align="left">Institute Name</TableCell>
                {/* <TableCell align="right">Faculty Name</TableCell> */}
                <TableCell align="left">Report Submit Date</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableHead>

              <TableBody>
                {listAll.map((val, index) => (
                  // let a =moment(val.submittedOn).format("DD/MMM/YYYY")
                  <TableRow key={index} className="statusCell">
                    <TableCell align="left" >{index + 1}</TableCell>
                    <TableCell align="left">{val.instituteName}</TableCell>
                    <TableCell align="left">{val.submittedOn == defaltDate ? "-" :dateFormat(val.submittedOn)}</TableCell>
                    <TableCell align="left">{val.activeStatus == 0 ? "-" : val.activeStatus}</TableCell>

                  </TableRow>
                ))}
{/* "1900-01-01T00:00:00" */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default AdminReportStatus;
