import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { B3VALIDATION } from "../../../Validation";
//  import { Targetproduct } from '../../../Pages/Validation';
import FormControl from "@mui/material/FormControl";
import { B1VALIDATION } from "../../../Validation";
import ComponentLoader from "../../../../ComponentLoader";
// import { Box } from "react-bootstrap-icons";

function B1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);
 
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [dropdown, setDropdown] = useState([]);
  const [PType, setPType] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader,setLoader] =useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B1VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
     
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Research Area");
    setLoader(false)
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setValue("ResearchAreaCropv", item.researchAreaCrop);
    setId(item.id);
  };

  // const SubmitBtn = () => {
  //   alert("SuccessFully Submited");
  //   // window.location.reload();
  // };

  const handleBack = () => {
    navigate("/B1");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // DeptId Dropdown //


  // Lov Type //


  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      ResearchAreaCrop:data.ResearchAreaCropv,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    console.log(d);
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B1SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B1")
         
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B1EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B1")
        
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="main-container-margin">
        <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(B1)Research Area</span>
        </div>
      </div>
      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        {/* <div className=" col-lg-6 col-md-6 col-sm-12 Activies-main"></div> */}
        <div className="form-control">
          <h5>B1.Research Area</h5>
         
          <TextField
            id="outlined-basic"
            label="Research Area Crop"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("ResearchAreaCropv")}
            error={errors.ResearchAreaCropv ? true : false}
            helperText={errors.ResearchAreaCropv?.message}
          />

          
          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              // onClick={handleLeavepage}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default B1Inside;
