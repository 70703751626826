import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect, useRef } from "react";
import logo from "../../Images/tnau.png";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { CgProfile } from "react-icons/cg";
import { BiLogOut } from "react-icons/bi";
import axiosInstance from "../../Service/AxiosInstance";
// import AddIcon from "@mui/icons-material/Add";
import "../../App.css";
import { RollerSkating } from "@mui/icons-material";
import { Icon } from "@mui/material";

const def = {
  position: "fixed",
  width: "100%",
  zIndex: "1000",
};
const Header = ({ name, callback, appData, goOpen }) => {
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [token, setToken] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [Name, setName] = useState("");
  const [role, setRole] = useState("");
  const sidebarRef = useRef(null);
const [instiId,setInstiId] = useState("")
  const showProfile = () => {
    setIsOpen(!isOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
const [designation,setDesignation] = useState('')
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    const token = localStorage.getItem("token");
    if (token != null && token != undefined) {
      setToken(token);
    }

    const Name = localStorage.getItem("Name");
    const username = localStorage.getItem("username");
    const role = localStorage.getItem("role");
    if(role === "Principal"){
      const id = localStorage.getItem("loginId");
      setInstiId(id)
    }
    if (
      (username && role && Name) != null &&
      (username && role && Name) != undefined
    ) {
      setName(Name);
      setUsername(username);
      setRole(role);
    }
const designation= localStorage.getItem('designation');
setDesignation(designation)
  }, [sidebarRef, token]);

  const logOut = () => {
    localStorage.removeItem("token");
    delete axiosInstance.defaults.headers.common["Authorization"];
    navigate("/");
    localStorage.removeItem("MReport");
    localStorage.removeItem("Month");
    localStorage.clear();
    callback("NoUpdate");
  };

  const previousPage = () => {
    navigate(appData.navigate);
  };
  const addDetails = () => {
    if (appData.dialogue === true) {
      let isTrue = appData.item !== null;
      {
        isTrue ? goOpen(true) : goOpen(false);
      }
    } else {
      let isTrue = appData.item !== null;
      {
        isTrue
          ? navigate(appData.navigateAdd, {
              state: appData.item,
              levelId: appData.levelId,
            })
          : navigate(appData.navigateAdd);
      }
    }
  };
  return (
    <>
      {/* {token && (
        <>
          <div className="Header">
            <div className="header-title">
              <img src={logo} />
              {appData.back && (
                <button onClick={previousPage}>
                </button>
              )}
              <span>{name}</span>
              <div className="top-heading">
                <h5>TamilNadu Agriculture University</h5>
              </div>
            </div>
            <div className="header-right-container">

              <div>
                <div>
                  <span className="my-profile">
                    <AccountCircleIcon onClick={() => showProfile()} />
                    <div className="open-profile">
                      <ul>
                        <li>
                          <div className="dot-profile"></div>
                          <box-icon
                            type="regular"
                            name="user-circle"
                            color="#000"
                            size="28px"
                          ></box-icon>
                          <CgProfile />
                          <span>Profile</span>
                        </li>

                        <li onClick={logOut}>
                          <div className="dot-profile"></div>
                          <box-icon
                            name="log-out"
                            color="#000"
                            size="28px"
                          ></box-icon>
                          <BiLogOut />
                          <span>Logout</span>
                        </li>
                        <li className="profile_user">User : Simeon</li>
                        <li className="profile_user">Role : Super Admin</li>
                        <div></div>
                      </ul>
                    </div>
                  </span>{" "}
                </div>
              </div>

              {appData.go && (
                <button onClick={addDetails}>
                  {appData.edit ? (
                    <i className="pi pi-file-edit"></i>
                  ) : (
                    <i className="pi pi-plus"></i>
                  )}
                </button>
              )}
            </div>
          </div>
        </>
      )} */}

      <div style={def}>
        <div className="header" style={{ background: "#fff", height: "60px" }}>
          <div className="js-main-header">
            <div className="common-header">
              <div style={{ marginLeft: 7, marginTop: 7 }}>
                <img src={logo} className="js-img-header" />
              </div>

              <div className="top-center-header">
                <span style={{ fontSize: "14px" ,marginLeft: 7}}>
                  TamilNadu Agricultural University <br></br>
                  <span className="top-head2">
                    Directorate of TamilNadu Rice Research Institute
                  </span>
                </span>
              </div>
            </div>
            <div></div>
            <div className="header-logo">
              <span className="my-profile">
                {/* <div className=""> */}
                <ul>
                  <li className="profile_user">
                    <Icon>
                      <PersonIcon />
                    </Icon>
                    {role === "VC" ? "Admin" : Name}
                  </li>
                  <li className="profile_user">
                    <Icon>
                      <ManageAccountsIcon />
                    </Icon>
             { designation === 'AssistantOfficer' ? designation :  role === "VC" ? "University Admin" : role === "Principal" ? instiId == 1023 ? "Director" : "Professor and Head" : role }
                   
                  </li>
                  <div></div>
                </ul>
                {/* </div> */}
              </span>{" "}
            </div>

            <div className="Logout-btn">
              {/* <BiLogOut /> */}
              <button onClick={logOut}>Logout</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
