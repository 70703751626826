import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArA9Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

//   InstituteId,RptMonth,IAMRSId,VechileModel,PurchaseYear,FuelConsumption,Expenses
  const [vehicleModel,setVehicleModel] = useState("")
  const [purchaseYear,setPurchaseYear] = useState("")
  const [fuelConsumption,setFuelConsumption] = useState("")
  const [expenses,setExpenses] = useState("")
console.log(purchaseYear);
  const [vehicleModelErr,setVehicleModelErr] = useState(false)
  const [purchaseYearErr,setPurchaseYearErr] = useState(false)
  const [fuelConsumptionErr,setFuelConsumptionErr] = useState(false)
  const [expensesErr,setExpensesErr] = useState(false)

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const [dropnature, setDropnature] = useState([]);
  const [loader,setLoader] =useState(true)

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A8VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location?.state);
      }
    }
    head("Student facilitation");
    setLoader(false)
  }, [location.state]);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
  }, []);
  
  const edit = (item) => {
    console.log(item);
    setVehicleModel(item.vechileModel);
    setPurchaseYear(dateFormat(item.purchaseYear));
    setFuelConsumption(item.fuelConsumption);
    setExpenses(item.expenses);
    setId(item.id)
    setLoader(false)
  };

  const backs = () => {
    navigate("/ArA9");
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      VechileModel: vehicleModel,
      PurchaseYear: purchaseYear,
      FuelConsumption: fuelConsumption,
      Expenses: expenses,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Ar9SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA9");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Ar9EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA9");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!vehicleModel) {
      setVehicleModelErr(true);
      document.getElementById("vehicleModel").focus();
    }  
    else if (!purchaseYear) {
      setPurchaseYearErr(true);
      document.getElementById("purchaseYear").focus();
    
    }
     else if (!fuelConsumption) {
      setFuelConsumptionErr(true);
      document.getElementById("fuelConsumption").focus();
    } else if (!expenses) {
      setExpensesErr(true);
      document.getElementById("expenses").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A9. Details of vehicles in the College
</span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
        <div className="form-control">
          <h5 style={{ width: 350, margin: 20 }}>
          A9. Details of vehicles in the College
          </h5>

          <TextField
            error={vehicleModelErr}
            id="vehicleModel"
            label="Name of the Vehicle (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={vehicleModel}
            onChange={(e) => {
              setVehicleModel(e.target.value);
              setVehicleModelErr(false);
            }}
          />
          <TextField
            error={purchaseYearErr}
            id="purchaseYear"
            label="Year of Purchase"
            variant="outlined"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={purchaseYear}
            onChange={(e) => {
              setPurchaseYear(e.target.value);
              setPurchaseYearErr(false);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />
          <TextField
            error={fuelConsumptionErr}
            id="fuelConsumption"
            label="Fuel consumption during this month (L)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={fuelConsumption}
            onChange={(e) => {
              setFuelConsumption(e.target.value);
              setFuelConsumptionErr(false);
            }}
          />
          <TextField
            error={expensesErr}
            id="expenses"
            label="Expenditure incurred during this month (Rs.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={expenses}
            onChange={(e) => {
              setExpenses(e.target.value);
              setExpensesErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              onClick={backs}
              style={{ backgroundColor: "#008CBA",padding:"5px",borderRadius:"3px",width:"80px",color:"#fff" }}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              style={{paddingTop:"5px"}}
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      {/* </form> */}
    </div>
  );
}

export default ArA9Inside;
