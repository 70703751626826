import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";

import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArA7Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);

  const [facultyName, setFacultyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [date, setDate] = useState("");
  const [reason, setReason] = useState("");

  const [facultyNameErr, setFacultyNameErr] = useState(false);
  const [designationErr, setDesignationErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [reasonErr, setReasonErr] = useState(false);
  const [loader,setLoader] =useState(true)

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti, setInsti] = useState();
  const [inves, setInves] = useState([]);
  const [duty, setDuty] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [dropnature, setDropnature] = useState([]);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A8VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      handleInvestigators(tok);

      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student facilitation");
  }, []);

  useEffect(() => {
    const tok = localStorage.getItem("token");
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
    handleInvestigators(tok,insti)
  }, []);
  
  const edit = (item) => {
    console.log(item);
    setFacultyName(item.investigatorId)
    setDesignation(item.designation)
    setDate(moment(item.date).format("YYYY-MM-DD"))
    setReason(item.reason)
    setId(item.id);
    setLoader(false)
  };

  const backs = () => {
    navigate("/ArA7");
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    
    const d = {
      id,
      InvestigatorId: facultyName,
      Designation: designation,
      Date: dateFormat(date),
      Reason: reason,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArA7SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA7");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArA7EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully updated");
          navigate("/ArA7");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!facultyName) {
      setFacultyNameErr(true);
      document.getElementById("facultyName").focus();
    } else if (!designation) {
      setDesignationErr(true);
      document.getElementById("designation").focus();
    } else if (!date) {
      setDateErr(true);
      document.getElementById("date").focus();
    } else if (!reason) {
      setReasonErr(true);
      document.getElementById("reason").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A7.Details on TSMS non-submission</span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={formValidateFun}> */}
        <div className="form-control">
          <h5 style={{ width: 350, margin: 20 }}>
            A7.Details on TSMS non-submission
          </h5>
           
         <FormControl
            style={{ width: "45%", margin: 12 }}
            error={facultyNameErr}
            id="facultyName"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Faculty Name 
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              style={{ height: "45px" }}
              value={facultyName}
              onChange={(e) => {
                setFacultyName(e.target.value);
                setFacultyNameErr(false);
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={designationErr}
            id="designation"
            label="Designation"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={designation}
            onChange={(e) => {
              setDesignation(e.target.value);
              setDesignationErr(false);
            }}
          />

          <TextField
            error={dateErr}
            id="date"
            label="Date"
            variant="outlined"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
              setDateErr(false);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={reasonErr}
            id="reason"
            label="Reason (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
              setReasonErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA",padding:"5px",borderRadius:"3px",width:"80px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              style={{paddingTop:"5px"}}
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      {/* </form> */}
    </div>
  );
}

export default ArA7Inside;
