import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  FormControl,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D5VALI } from "../../../Validation";
import { Box } from "react-bootstrap-icons";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArE7Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [inves, setInves] = useState([])

  const [investigatorName, setInvestigatorName] = useState("")
  const [documentDescription, setDocumentDescription] = useState("")
  const [releasedLevel, setReleasedLevel] = useState("")
  const [leadAuthor, setLeadAuthor] = useState("")

  const [investigatorNameErr, setInvestigatorNameErr] = useState(false)
  const [documentDescriptionErr, setDocumentDescriptionErr] = useState(false)
  const [releasedLevelErr, setReleasedLevelErr] = useState(false)
  const [leadAuthorErr, setLeadAuthorErr] = useState(false)
  const [realeaseType, setRealeaseType] = useState([]);
  const [stateType, setStateType] = useState([]);

  const [value, setValue] = useState({
    InvestigatorId: "",
    NameOfFPO: "",
    Address: "",
    MandateofFPO: "",
    NoofMemberFPO: "",
    MOUDate: "",
    FellowUp: ""
  });

  const [errValue, setErrValue] = useState({
    InvestigatorId: false,
    NameOfFPO: false,
    Address: false,
    MandateofFPO: false,
    NoofMemberFPO: false,
    MOUDate: false,
    FellowUp: false
  });




  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D5VALI),
  });

  const backs = () => {
    navigate("/ArE7");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
     
      handleReleaseType(tok);
      handleStateType(tok)
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("items purchased");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute)
  }, []);

  const edit = (item) => {
    console.log(item);
    
   setValue({
    InvestigatorId: item.investigatorId,
    NameOfFPO:  item.nameOfFPO,
    Address:  item.address,
    MandateofFPO: item. mandateofFPO,
    NoofMemberFPO: item. noofMemberFPO,
    MOUDate:  dateFormat(item.mouDate),
    FellowUp:  item.fellowUp
   })
    setId(item.id);
  };
  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
  })

  const handleReleaseType = async (token, TeamleaderRole) => {
    console.log(token);


    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"TeamleaderRole"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setRealeaseType(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleStateType = async (token, RealeaseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"RealeaseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setStateType(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };



  // Investigator 
  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      InvestigatorId:value.InvestigatorId,
      NameOfFPO:value.NameOfFPO,
      Address: value.Address,
      MandateofFPO: value.MandateofFPO,
      NoofMemberFPO: value.NoofMemberFPO,
      MOUDate: value.MOUDate,
      FellowUp: value.FellowUp,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARE7SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArE7");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARE7EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArE7");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {

     {/* InvestigatorId:"",
    NameOfFPO:"",
    Address:"",
    MandateofFPO:"",
    NoofMemberFPO:"",
    MOUDate:"",
    FellowUp:"" */}
    if (!value.InvestigatorId) {
      setErrValue((prev) => ({ ...prev, InvestigatorId: true }))
      document.getElementById("InvestigatorId").focus();
    } 
    else if (!value.NameOfFPO) {
      setErrValue((prev) => ({ ...prev, NameOfFPO: true }))
      document.getElementById("NameOfFPO").focus();
    } 
    else if (!value.Address) {
      setErrValue((prev) => ({ ...prev, Address: true }))
      document.getElementById("Address").focus();
    } 
    else if (!value.MandateofFPO) {
      setErrValue((prev) => ({ ...prev, MandateofFPO: true }))
      document.getElementById("MandateofFPO").focus();
    } 
    else if (!value.NoofMemberFPO) {
      setErrValue((prev) => ({ ...prev, NoofMemberFPO: true }))
      document.getElementById("NoofMemberFPO").focus();
    } 
    else if (!value.MOUDate) {
      setErrValue((prev) => ({ ...prev, MOUDate: true }))
      document.getElementById("MOUDate").focus();
    } 
    else if (!value.FellowUp) {
      setErrValue((prev) => ({ ...prev, FellowUp: true }))
      document.getElementById("FellowUp").focus();
    } 
    else {
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
         <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>E7. FPO linkages established

          </span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control">
        <h5 style={{ margin: 20 }}> E7. FPO linkages established

        </h5>

        {/* InvestigatorId:"",
    NameOfFPO:"",
    Address:"",
    MandateofFPO:"",
    NoofMemberFPO:"",
    MOUDate:"",
    FellowUp:"" */}

        <FormControl style={{ width: "48%", margin: 12 }} id="InvestigatorId">
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Name of the faculty
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="InvestigatorId"
            // value={investigatorName}
            label="Programme Type"
            size="small"
            sx={{ width: "100%" }}
            name="InvestigatorId"
            // style={{ margin: "10px", width: "48%" }}
            value={value.InvestigatorId}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
            }}
            error={errValue.InvestigatorId}
          >
            {inves?.map((row, index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          // error={documentDescriptionErr}
          id="NameOfFPO"
          label="Name of the FPO (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          name="NameOfFPO"
          value={value.NameOfFPO}
          onChange={(e) => {
            handleData(e);
            setErrValue((prev) => ({ ...prev, NameOfFPO: false }));
          }}
          error={errValue.NameOfFPO}
        />

        <TextField
          // error={documentDescriptionErr}
          id="Address"
          label="Address (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          name="Address"
          value={value.Address}
          onChange={(e) => {
            handleData(e);
            setErrValue((prev) => ({ ...prev, Address: false }));
          }}
          error={errValue.Address}
        />

        <TextField
          // error={releasedLevelErr}
          id="MandateofFPO"
          label="Mandate of FPO (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          name="MandateofFPO"
          value={value.MandateofFPO}
          onChange={(e) => {
            handleData(e);
            setErrValue((prev) => ({ ...prev, MandateofFPO: false }));
          }}
          error={errValue.MandateofFPO}
        />


        <TextField
          // error={leadAuthorErr}
          id="NoofMemberFPO"
          label="No. of members in the FPO (Max 250 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          name="NoofMemberFPO"
          type="number"
          value={value.NoofMemberFPO}
          onChange={(e) => {
            handleData(e);
            setErrValue((prev) => ({ ...prev, NoofMemberFPO: false }));
          }}
          error={errValue.NoofMemberFPO}
        />

        <TextField
          // error={leadAuthorErr}
          id="MOUDate"
          label="Date of signing of MoU"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          type="date"
          name="MOUDate"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            placeholder: "",
          }}
          value={value.MOUDate}
          onChange={(e) => {
            handleData(e);
            setErrValue((prev) => ({ ...prev, MOUDate: false }));
          }}
          error={errValue.MOUDate}
        />

        <TextField
          // error={leadAuthorErr}
          id="FellowUp"
          label="Fellow up activities (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          name="FellowUp"
          value={value.FellowUp}
          onChange={(e) => {
            handleData(e);
            setErrValue((prev) => ({ ...prev, FellowUp: false }));
          }}
          error={errValue.FellowUp}
        />

        {/* <FormControl
            style={{ width: "48%", margin: 12 }}
            error={leadAuthorErr}
            id="leadAuthor"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Lead author / Member
            </InputLabel>
            <Select
              variant="outlined"
              // label="Rating Type"
              style={{ height: "45px" }}
              name="leadAuthor"
            
              value={leadAuthor}
              onChange={(e) => {
                setLeadAuthor(e.target.value)
                setLeadAuthorErr(false)
              }}
            >
              {realeaseType?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

        <div className="from-last">
          <button
            type="submit"
            style={{ backgroundColor: "#008CBA", padding: "5px", borderRadius: "3px", width: "80px", color: "#fff" }}
            onClick={backs}
          >
            Back
          </button>
          <button type="submit" style={{ paddingTop: "5px" }} className="sbmit-buttons" onClick={formValidateFun}>
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArE7Inside;
