import React from 'react'
import { BounceLoader, ClipLoader, RingLoader } from 'react-spinners';
import loaderImg from "./Images/Crop copy.png"

const ComponentLoader = ({ isLoading }) => {
  const override = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "1000"
  }
  const sac = {
    width: '200px',
    top: '10px',
    color: "green"
  }
  // isLoading
  return (
    <div style={{ display: isLoading ? "block" : "none" }}>
      <div className='bg-shadow-loader'>

      </div>
      <div className='bg-loader'>
        <div><img src={loaderImg} alt='no image' style={sac} /></div>
      </div>
    </div>
  )
}

export default ComponentLoader