import * as React from "react";
import { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// login
import Login from "./Pages/Login/Login";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
// loading
import Loading from "./Loading";
// sidebar
import Sidebar from "./Components/Sidebar/Sidebar";
// header
import Header from "./Components/Header/Header";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import "primeicons/primeicons.css";


import "./App.css";
import Institute from "./Pages/Institute/Institute";
import Leave from "./Pages/Leave/Leave";
import MasterOnDuty from "./Pages/MasOnDuty/MasOnDuty";
import TransactionOnduty from "./Pages/Transaction/GeneralInfo/OnDuty/TransactionOnduty";
import Tsms from "./Pages/Transaction/GeneralInfo/Tsms/Tsms";
import Activities from "./Pages/Activities/Activities";
import Educational from "./Pages/Educational/Educational";
import MasterData from "./Pages/MasterDataMangement/MasterData";
import ManthyReport from "./Pages/MathlyReport/ManthyReport";
import Report from "./Pages/Report/Report";
import MasterCourse from "./Pages/MasterCourse/MasterCourse";
import Studentguides from "./Pages/StudentGuides/Studentguides";
import StudentDetalis from "./Pages/StudentGuides/StudentDetails/StudentDetalis";
import ReportTables from "./Pages/ReportTable/ReportTable";
import MonthlyReport from "./Pages/General/General";
import B3 from "./Pages/ResearchActivities/B3/B3";
import B3Inside from "./Pages/ResearchActivities/B3/B3Inside/B3Inside";
import B4Inside from "./Pages/ResearchActivities/B4/B4Inside/B4Inside";
import B4 from "./Pages/ResearchActivities/B4/B4";
import B5 from "./Pages/ResearchActivities/B5/B5";
import B5Inside from "./Pages/ResearchActivities/B5/B5Inside/B5Inside";
import B6Inside from "./Pages/ResearchActivities/B6/B6Inside/B6Inside";
import B6 from "./Pages/ResearchActivities/B6/B6";
import B7 from "./Pages/ResearchActivities/B7/B7";
import B7Inside from "./Pages/ResearchActivities/B7/B7Inside/B7Inside";
import B8 from "./Pages/ResearchActivities/B8/B8";
import B8Inside from "./Pages/ResearchActivities/B8/B8Inside/B8Inside";
import B9 from "./Pages/ResearchActivities/B9/B9";
import B9Inside from "./Pages/ResearchActivities/B9/B9Inside/B9Inside";
import B10 from "./Pages/ResearchActivities/B10/B10";
import B10Inside from "./Pages/ResearchActivities/B10/B10Inside/B10Inside";
import B11 from "./Pages/ResearchActivities/B11/B11";
import B11Inside from "./Pages/ResearchActivities/B11/B11Inside/B11Inside";
import B12 from "./Pages/ResearchActivities/B12/B12";
import B12Inside from "./Pages/ResearchActivities/B12/B12Inside/B12Inside";
import B13 from "./Pages/ResearchActivities/B13/B13";
import B13Inside from "./Pages/ResearchActivities/B13/B13Inside/B13Inside";
import B14 from "./Pages/ResearchActivities/B14/B14";
import B14Inside from "./Pages/ResearchActivities/B14/B14Inside/B14Inside";
import B15 from "./Pages/ResearchActivities/B15/B15";
import B15Inside from "./Pages/ResearchActivities/B15/B15Inside/B15Inside";
import B16 from "./Pages/ResearchActivities/B16/B16";
import B16Inside from "./Pages/ResearchActivities/B16/B16Inside/B16Inside";
import B17 from "./Pages/ResearchActivities/B17/B17";
import B17Inside from "./Pages/ResearchActivities/B17/B17Inside/B17Inside";
import B18 from "./Pages/ResearchActivities/B18/B18";
import B18Inside from "./Pages/ResearchActivities/B18/B18Inside/B18Inside";
import B19 from "./Pages/ResearchActivities/B19/B19";
import B19Inside from "./Pages/ResearchActivities/B19/B19Inside/B19Inside";
import B20 from "./Pages/ResearchActivities/B20/B20";
import B20Inside from "./Pages/ResearchActivities/B20/B20Inside/B20Inside";
import C1 from "./Pages/ExtensionActivities/C1/C1";
import C1Inside from "./Pages/ExtensionActivities/C1/C1Inside/C1Inside";
import C2 from "./Pages/ExtensionActivities/C2/C2";
import C2Inside from "./Pages/ExtensionActivities/C2/C2Inside/C2Inside";
import C3 from "./Pages/ExtensionActivities/C3/C3";
import C3Inside from "./Pages/ExtensionActivities/C3/C3Inside/C3Inside";
import C4 from "./Pages/ExtensionActivities/C4/C4";
import C4Inside from "./Pages/ExtensionActivities/C4/C4Inside/C4Inside";
import C5 from "./Pages/ExtensionActivities/C5/C5";
import C5Inside from "./Pages/ExtensionActivities/C5/C5Inside/C5Inside";
import C6 from "./Pages/ExtensionActivities/C6/C6";
import C6Inside from "./Pages/ExtensionActivities/C6/C6Inside/C6Inside";
import C7 from "./Pages/ExtensionActivities/C7/C7";
import C7Inside from "./Pages/ExtensionActivities/C7/C7Inside/C7Inside";
import C8 from "./Pages/ExtensionActivities/C8/C8";
import C8Inside from "./Pages/ExtensionActivities/C8/C8Inside/C8Inside";
import C9 from "./Pages/ExtensionActivities/C9/C9";
import C9Inside from "./Pages/ExtensionActivities/C9/C9Inside/C9Inside";
import D1 from "./Pages/InstitutionalDevelopment/D1/D1";
import D1Inside from "./Pages/InstitutionalDevelopment/D1/D1Inside/D1Inside";
import D2 from "./Pages/InstitutionalDevelopment/D2/D2";
import D2Inside from "./Pages/InstitutionalDevelopment/D2/D2Inside/D2Inside";
import D3 from "./Pages/InstitutionalDevelopment/D3/D3";
import D3Inside from "./Pages/InstitutionalDevelopment/D3/D3Inside/D3Inside";
import D4 from "./Pages/InstitutionalDevelopment/D4/D4";
import D4Inside from "./Pages/InstitutionalDevelopment/D4/D4Inside/D4Inside";
import D5 from "./Pages/InstitutionalDevelopment/D5/D5";
import D5Inside from "./Pages/InstitutionalDevelopment/D5/D5Inside/D5Inside";
import D6 from "./Pages/InstitutionalDevelopment/D6/D6";
import D6Inside from "./Pages/InstitutionalDevelopment/D6/D6Inside/D6Inside";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MasterDataFaculty from "./Pages/MasterDataMangement/MasterDataFaculty/MasterDataFaculty";
import A1 from "./Pages/EducationalActivities/A1/A1";
import A2 from "./Pages/EducationalActivities/A2/A2";
import A2Inside from "./Pages/EducationalActivities/A2/A2Inside/A2Inside";
import A3 from "./Pages/EducationalActivities/A3/A3";
import A3Inside from "./Pages/EducationalActivities/A3/A3Inside/A3Inside";
import A4 from "./Pages/EducationalActivities/A4/A4";
import A4Inside from "./Pages/EducationalActivities/A4/A4Inside/A4Inside";
import A5 from "./Pages/EducationalActivities/A5/A5";
import A5Inside from "./Pages/EducationalActivities/A5/A5Inside/A5Inside";
import A6 from "./Pages/EducationalActivities/A6/A6";
import A6Inside from "./Pages/EducationalActivities/A6/A6Inside/A6Inside";
import A7 from "./Pages/EducationalActivities/A7/A7";
import A7Inside from "./Pages/EducationalActivities/A7/A7Inside/A7Inside";
import A8 from "./Pages/EducationalActivities/A8/A8";
import A8Inside from "./Pages/EducationalActivities/A8/A8Inside/A8Inside";
import Department from "./Pages/Department/Department";
import StationType from "./Pages/StationType/StationType";
import Funding from "./Pages/Funding/Funding";
import MasCrop from "./Pages/Crop/MasCrop";
import ListValues from "./Pages/ListValues/ListValues";
import A1Inside from "./Pages/EducationalActivities/A1/A1Inside/A1Inside";
import B1 from "./Pages/ResearchActivities/B1/B1";
import B1Inside from "./Pages/ResearchActivities/B1/B1Inside/B1Inside";
import B2 from "./Pages/ResearchActivities/B2/B2";
import B2Inside from "./Pages/ResearchActivities/B2/B2Inside/B2Inside";
import ReportDownloadPage from "./Pages/ReportDownloadPage/ReportDownloadPage";
import LeaveInside from "./Pages/Transaction/GeneralInfo/Leave/LeaveInside";
import Reportingsystem from "./Pages/ReportingSystem/Reportingsystem";
import Contactus from "./Pages/ContactUs/Contactus";
import ReportTableLoop from "./Pages/ReportTable/ReportTableLoop";



// =========================================================================================
import ArA1ae from "./Pages/BasicInfo/A1(a-e)GeneralInfo/A1(a-e)GeneralInfo";
import ArA1f from "./Pages/BasicInfo/A1(f)cropsInTheFiled/A1(f)CropsInTheFIeld";
import ArA1fInside from "./Pages/BasicInfo/A1(f)cropsInTheFiled/A1(f)CropsInTheFIeldInside";
import ArA1g from "./Pages/BasicInfo/A1(g)LiveStockDetails/A1(g)LIveStockDetails";
import ArA1gInside from "./Pages/BasicInfo/A1(g)LiveStockDetails/A1(g)LIveStockDetailsInside";
import ArA1h from "./Pages/BasicInfo/A1(h)BuildingDetails/A1(h)BuildingDetails";
import ArA1hInside from "./Pages/BasicInfo/A1(h)BuildingDetails/A1(h)BuildingDetailsInside";
import ArA3Inside from "./Pages/BasicInfo/A3.LIstOfPermanentStaff/A3.LIstOfPermanentStaffInside"
import ArA2 from "./Pages/BasicInfo/A2.StaffStrength/A2.StaffStrength";
import ArA2Inside from "./Pages/BasicInfo/A2.StaffStrength/A2.StaffStrengthInside";
import ArA3 from "./Pages/BasicInfo/A3.LIstOfPermanentStaff/A3.LIstOfPermanentStaff";
import ArA4 from "./Pages/BasicInfo/A4.LeaveParticularOfTheStaff/A4.LeaveParticularOfTheStaff";
import ArA4Inside from "./Pages/BasicInfo/A4.LeaveParticularOfTheStaff/A4.LeaveParticularOfTheStaffInside";
import ArA5 from "./Pages/BasicInfo/A5.TourParticulars/A5.TourParticulars";
import ArA5Inside from "./Pages/BasicInfo/A5.TourParticulars/A5.TourParticularsInside";
import ArA6 from "./Pages/BasicInfo/A6Audit/A6Audit";
import ArA6Inside from "./Pages/BasicInfo/A6Audit/A6AuditInside";
import ARA7 from "./Pages/BasicInfo/A7TSMS/A7TSMS";
import ArA7Inside from "./Pages/BasicInfo/A7TSMS/A7TSMSinside";
import ArA8 from "./Pages/BasicInfo/A8Budget/A8Budget";
import ArA8Inside from "./Pages/BasicInfo/A8Budget/A8BudgetInside";
import ArA9 from "./Pages/BasicInfo/A9DetailsOfVehicle/A9DetailsOfVehicle";
import ArA9Inside from "./Pages/BasicInfo/A9DetailsOfVehicle/A9DetailsOfVehicleInside";
import ArA10 from "./Pages/BasicInfo/A10DetailsOnTheLEgal/A10DetailsOnTheLegal";
import ArA10Inside from "./Pages/BasicInfo/A10DetailsOnTheLEgal/A10DetailsOnTheLegalInside";
import ArA11 from "./Pages/BasicInfo/A11MonthlyMeetings/A11MonthlyMeetings";
import ArA11Insidee from "./Pages/BasicInfo/A11MonthlyMeetings/A11MonthlyMeetingsInside";

import ArB1 from "./Pages/B.EducationalActivities/B1DegreeProgram/B1DegreeProgram";
import ArB1Inside from "./Pages/B.EducationalActivities/B1DegreeProgram/B1DegreeProgramInside";
import ArB2 from "./Pages/B.EducationalActivities/B2DiplomacourseHandled/B2Diploma";
import ArB2Inside from "./Pages/B.EducationalActivities/B2DiplomacourseHandled/B2DiplomaInside";
import ArB3 from "./Pages/B.EducationalActivities/B3AdditionalEfforts/B3Additional";
import ArB3Inside from "./Pages/B.EducationalActivities/B3AdditionalEfforts/B3AdditionalInside";
import ArB4 from "./Pages/B.EducationalActivities/B4.Guest/B4Guest";
import ArB4Inside from "./Pages/B.EducationalActivities/B4.Guest/B4GuestInside";

import ArB9 from "./Pages/B.EducationalActivities/B9StudentFacilation/B9StudentFacilation";
import ArB9Inside from "./Pages/B.EducationalActivities/B9StudentFacilation/B9StudentFacilationInside"
import ArB10 from "./Pages/B.EducationalActivities/B10StudentPlacementDetails/B10StudentPlacementDetails";
import ArB10Inside from "./Pages/B.EducationalActivities/B10StudentPlacementDetails/B10StudentPlacementDetailsInside"
import ArB11 from "./Pages/B.EducationalActivities/B11StudentAchievementInAcademics/B11StudentAchievementInAcademics";
import ArB11Inside from "./Pages/B.EducationalActivities/B11StudentAchievementInAcademics/B11StudentAchievementInAcademicsInside";
import ArB12 from "./Pages/B.EducationalActivities/B12StudentsAcheivementActivity/B12StudentsAcheivementActivity";
import ArB12Inside from "./Pages/B.EducationalActivities/B12StudentsAcheivementActivity/B12StudentsAcheivementActivityInside";
import B13ExaminationActivities from "./Pages/B.EducationalActivities/B13 Examination Activities/B13Examinationactivities"
import B13ExaminationActivitiesInside from "./Pages/B.EducationalActivities/B13 Examination Activities/B13ExaminationActivitiesInside"


import ArC1 from "./Pages/Research/C1CropVarieties/C1CropVarieties";
import ArC1Inside from "./Pages/Research/C1CropVarieties/C1CropVarietiesInside";
import ArC2 from "./Pages/Research/C2Technologies/C2Technologies";
import ArC2Inside from "./Pages/Research/C2Technologies/C2TechnologiesInside";
import ArC3 from "./Pages/Research/C3FarmImplements/C3FarmImplements";
import ArC3Inside from "./Pages/Research/C3FarmImplements/C3FarmImplementsInside";
import ArC4 from "./Pages/Research/C4UniversityResearch/C4UniversityResearch";
import ArC4Inside from "./Pages/Research/C4UniversityResearch/C4UniversityResearchInside";
import ArC5 from "./Pages/Research/C5AIRCPProjects/C5AIRCPProjects";
import ArC5Inside from "./Pages/Research/C5AIRCPProjects/C5AIRCPProjectsInside";
import ArC6 from "./Pages/Research/C6VentureCapitalProjects/C6VentureCapitalProjects";
import ArC6Inside from "./Pages/Research/C6VentureCapitalProjects/C6VentureCapitalProjectsInside";
import ArC7 from "./Pages/Research/C7RevolvingFundProjects/C7RevolvingFundProjects";
import ArC7Inside from "./Pages/Research/C7RevolvingFundProjects/C7RevolvingFundProjectsInside";
import ArC8 from "./Pages/Research/C8ExternallyFundedProjects/C8ExternallyFundedProjects";
import ArC8Inside from "./Pages/Research/C8ExternallyFundedProjects/C8ExternallyFundedProjectsInside";
import ArC9 from "./Pages/Research/C9RevolvingFundProjects/C9RevolvingFundProjects";
import ArC9Inside from "./Pages/Research/C9RevolvingFundProjects/C9RevolvingFundProjectsInside";
import ArC10 from "./Pages/Research/C10RevolvingFundProjects/C10RevolvingFundProjects";
import ArC10Inside from "./Pages/Research/C10RevolvingFundProjects/C10RevolvingFundProjectsInside";
import ArC11 from "./Pages/Research/C11RevolvingFundProjects/C11RevolvingFundProjects";
import ArC11Inside from "./Pages/Research/C11RevolvingFundProjects/C11RevolvingFundProjectsInside";
import ArC12 from "./Pages/Research/C12RevolvingFundProjects/C12RevolvingFundProjects";
import ArC12Inside from "./Pages/Research/C12RevolvingFundProjects/C12RevolvingFundProjectsInside";
import ArC13 from "./Pages/Research/C13RevolvingFundProjects/C13RevolvingFundProjects";
import ArC13Inside from "./Pages/Research/C13RevolvingFundProjects/C13RevolvingFundProjectsInside";
import ArC14 from "./Pages/Research/C14RevolvingFundProjects/C14RevolvingFundProjects";
import ArC14Inside from "./Pages/Research/C14RevolvingFundProjects/C14RevolvingFundProjectsInside";
import ArC15 from "./Pages/Research/C15RevolvingFundProjects/C15RevolvingFundProjects";
import ArC15Inside from "./Pages/Research/C15RevolvingFundProjects/C15RevolvingFundProjectsInside";
import ArC16 from "./Pages/Research/C16RevolvingFundProjects/C16RevolvingFundProjects";
import ArC16Inside from "./Pages/Research/C16RevolvingFundProjects/C16RevolvingFundProjectsInside";

import ArD1 from "./Pages/Publications/D1PublicationJournal/D1PublicationJournal";
import ArD1Inside from "./Pages/Publications/D1PublicationJournal/D1PublicationJournalInside";
import ArD2 from "./Pages/Publications/D2BooksChapter/D2BooksChapter";
import ArD2Inside from "./Pages/Publications/D2BooksChapter/D2BooksChapterInside";

import ArE1 from "./Pages/Extension Activities-ADMINREPORT/E1TrainingOrganized/E1Training";
import ArE1Inside from "./Pages/Extension Activities-ADMINREPORT/E1TrainingOrganized/E1TrainingInside";
import ArE2 from "./Pages/Extension Activities-ADMINREPORT/E2ProgramsOrganized/E2Program";
import ArE2Inside from "./Pages/Extension Activities-ADMINREPORT/E2ProgramsOrganized/E2ProgramInside";
import ArE3 from "./Pages/Extension Activities-ADMINREPORT/E3TrainingAttented/E3TrainingAttent";
import ArE3Inside from "./Pages/Extension Activities-ADMINREPORT/E3TrainingAttented/E3TrainingAttentInside";
import ArE4 from "./Pages/Extension Activities-ADMINREPORT/E4Diagnostic/E4Diagnostic";
import ArE4Inside from "./Pages/Extension Activities-ADMINREPORT/E4Diagnostic/E4DiagnosticInside";
import ArE5 from "./Pages/Extension Activities-ADMINREPORT/E5ExtensionActivities/E5ExtensionActivities";
import ArE5Inside from "./Pages/Extension Activities-ADMINREPORT/E5ExtensionActivities/E5ExtensionActivitiesInside";
import ArE6 from "./Pages/Extension Activities-ADMINREPORT/E6KAVIADP/E6KAVIADP";
import ArE6Inside from "./Pages/Extension Activities-ADMINREPORT/E6KAVIADP/E6KAVIADPInside";
import ArE7 from "./Pages/Extension Activities-ADMINREPORT/E7FPO/E7FPO";
import ArE7Inside from "./Pages/Extension Activities-ADMINREPORT/E7FPO/E7FPOInside";
import ArE8 from "./Pages/Extension Activities-ADMINREPORT/E8SHG/E8SHG";
import ArE8Inside from "./Pages/Extension Activities-ADMINREPORT/E8SHG/E8SHGInside";
import ArE9 from "./Pages/Extension Activities-ADMINREPORT/E9PolicyDocuments/E9PolicyDocuments";
import ArE9Inside from "./Pages/Extension Activities-ADMINREPORT/E9PolicyDocuments/E9PolicyDocumentsInside";

import ArF1 from "./Pages/F_Data/Income generated/IncomeGenerated";
import ArF1Inside from "./Pages/F_Data/Income generated/IncomeGeneratedInside";
import ArF2 from "./Pages/F_Data/Production and distribution of Seeds/ProductionSeeds";
import ArF2Inside from "./Pages/F_Data/Production and distribution of Seeds/ProductionSeedsInside";
import GeneralAR from "./Pages/GeneralAR/GeneralAR";
import A1dLandAreaInside from "./Pages/BasicInfo/A1(d)LandArea/A1(d)LandAreaInside";
import A1dLandArea from "./Pages/BasicInfo/A1(d)LandArea/A1(d)LandArea";
import A1eWaterResources from "./Pages/BasicInfo/A1(e)WaterSources/A1(e)WaterResources";
import A1eWaterResourcesInside from "./Pages/BasicInfo/A1(e)WaterSources/A1(e)WaterSourcesInside";
import ArE from "./Pages/KAVIADP-ATMA-extension services/MobileAdvisoryProvided/MobileAdvisoryProvided";
import ArEInside from "./Pages/KAVIADP-ATMA-extension services/MobileAdvisoryProvided/MobileAdvisoryProvidedInside";
import ReportTableAr from "./Pages/ReportTableAR/ReportTableAr";
import MonthReportStatus from "./Pages/MonthReportStatus/MonthReportStatus";
import AdminReportStatus from "./Pages/Admin_Report_Status/AdminReportStatus";
import InvestigatorInsdie from "./Pages/Investigator/investigatorInside";
import InstitudeInside from "./Pages/Institute/institudeInside"
import ArB5 from "./Pages/B.EducationalActivities/B5Exposure/B5Exposure";
import ArB5Inside from "./Pages/B.EducationalActivities/B5Exposure/B5ExposureInside";
import ArB6Inside from "./Pages/B.EducationalActivities/B6StudentGuidance/B6StudentGuidanceInside";
import ArB6 from "./Pages/B.EducationalActivities/B6StudentGuidance/B6StudentGuidance";
import ArB7 from "./Pages/B.EducationalActivities/B7AdvisoryComittee/B7AdvisoryComittee";
import ArB7Inside from "./Pages/B.EducationalActivities/B7AdvisoryComittee/B7AdvisoryComitteeInside";
import ArB8 from "./Pages/B.EducationalActivities/B8Teaching/B8Teaching";
import ArB8Inside from "./Pages/B.EducationalActivities/B8Teaching/B8TeachingInside";
import ArD3 from "./Pages/Publications/D3Papers/D3Papers";
import ArD3Inside from "./Pages/Publications/D3Papers/D3PapersInside";
import ArD4 from "./Pages/Publications/D4Popular/D4Popular";
import ArD4Inside from "./Pages/Publications/D4Popular/D4PopularInside";
import ArD5 from "./Pages/Publications/D5ConferenceAttend/D5ConferenceAttend";
import ArD5Inside from "./Pages/Publications/D5ConferenceAttend/D5ConferenceAttendInside";
import ArD6 from "./Pages/Publications/D6ConferenceOrganized/D6ConferenceOrganized";
import ArD6Inside from "./Pages/Publications/D6ConferenceOrganized/D6ConferenceOrganizedInside";
import ArD7 from "./Pages/Publications/D7Invited/D7Invited";
import ArD7Inside from "./Pages/Publications/D7Invited/D7InvitedInside";
import ArF7Inside from "./Pages/F_Data/F7Award/F7AwardInside";
import ArF7 from "./Pages/F_Data/F7Award/F7Award";
import ArF6Inside from "./Pages/F_Data/F6Major/F6MajorInside";
import ArF6 from "./Pages/F_Data/F6Major/F6Major";
import ArF5Inside from "./Pages/F_Data/F5Details/F5DetailsInside";
import ArF5 from "./Pages/F_Data/F5Details/F5Details";
import ArF4Inside from "./Pages/F_Data/F4Additional/F4AdditionalInside";
import ArF4 from "./Pages/F_Data/F4Additional/F4Additional";
import ArF3Inside from "./Pages/F_Data/F3Academic/F3AcademicInside";
import ArF3 from "./Pages/F_Data/F3Academic/F3Academic";

import ExcutiveSummary from "./Pages/ExcutiveSummary/ExcutiveSummary"
import FilterData from "./Pages/FilterData/FilterData";

import Footer from "./Pages/Footer/Footer";
import FacultyProfile from "./Pages/MasterDataMangement/MasterDataFaculty/MyPRofile";
import Projectcodeinside from "./Pages/Project/Projectcodeinside";
import Dd from "./Pages/DashboardData/Dd";
import Contact from "./navitem/Contact";
import About from "./navitem/About";
import Stations from "./navitem/Stations";
import Utilities from "./navitem/Utilities";
import Techsupport from "./navitem/Projectteam";
import Sidebare from "./navitem/Sidebare";
import Methodology from "./navitem/Methodology";
import Projectteam from "./navitem/Projectteam";
import Utility from "./navitem/Header";


// =========================================================================================

const LovHome = lazy(() => import("./Pages/Lov/Home"));
const RouteHome = lazy(() => import("./Pages/Route/Home"));
const SchemesHome = lazy(() => import("./Pages/Schemes/Home"));
const ReasonHome = lazy(() => import("./Pages/Reason/Home"));

const Category = lazy(() => import("./Pages/Category/Home"));
const CategoryNew = lazy(() => import("./Pages/Category/New"));
const MargetSegmentHome = lazy(() => import("./Pages/Market_Segment/Home"));
const InvoiceView = lazy(() => import("./Pages/Invoice/View"));
const InvoiceCreation = lazy(() => import("./Pages/Invoice/Creation"));
const Directorate = lazy(() => import("./Pages/Directorate/Derictorate"));
const Investigator = lazy(() => import("./Pages/Investigator/Investigators"));
const Projectcode = lazy(() => import("./Pages/Project/Projectcode"));
const TransactionLeave = lazy(() =>
  import("./Pages/Transaction/GeneralInfo/Leave/TransactionLeave")
);
const General = lazy(() => import("./Pages/General/General"));
// level
const UserLevelList = lazy(() => import("./Pages/Level/Userlevel"));

const LocationLevelList = lazy(() => import("./Pages/Level/Locationlevel"));

const PositionLevelList = lazy(() => import("./Pages/Level/Positionlevel"));

const ProductLevelList = lazy(() => import("./Pages/Level/Productlevel"));

// logview

const LogViewHome = lazy(() => import("./Pages/LogView/Home"));

// position mapping
const PositionMappingHome = lazy(() => import("./Pages/Position_Mapping/Home"));
// menu
// const BusinessHierarchy = lazy(() =>
//   import("./Components/Sidebar/Sub-Menu/Business-Hierarchy")
// );

const MasterDataManagement = lazy(() =>
  import("./Components/Sidebar/Sub-Menu/Master-Data-Management")
);

const Configuration = lazy(() =>
  import("./Components/Sidebar/Sub-Menu/Configuration")
);

const GrnCreation = lazy(() => import("./Pages/Grn/Creation"));

function App() {
  const navigate = useNavigate();
  const [token, setToken] = useState(false);
  const [data, setData] = useState("");
  const [clone, setClone] = useState("Dashboard");
  const [update, setUpdate] = useState(true);
  const [add, setAdd] = useState(false);
  const [masterApp, setMasterApp] = useState({
    open: false,
    dialogue: false,
    go: false,
    back: false,
    navigate: "",
    navigateAdd: "",
    edit: false,
    item: null,
    dialougeItem: null,
    levelId: null,
    distributorHierarchyId: null,
  });
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    const tok = localStorage.getItem("token");
    let isTrue = tok != null && tok != undefined;
    // { isTrue ? setToken(true) : setToken(false); }
    if (tok != null && tok != undefined) {
      setToken(true);
    } else {
      setToken(false);
    }
  }, [
    token,
    update,
    masterApp.open,
    masterApp.go,
    masterApp.item,
    masterApp.edit,
    masterApp,
  ]);
  // console.log(masterApp.distributorHierarchyId);

  const headerData = (pram, other) => {
    setData(pram);
    setClone(other);
  };
  const callBack = (data) => {
    setUpdate(!update);
  };
  const changeHeaderFalse = () => {
    setMasterApp((prev) => {
      return {
        ...prev,
        open: false,
        go: false,
        back: false,
        edit: false,
        item: null,
        dialogue: false,
        dialougeItem: null,
        levelId: null,
        // distributorHierarchyId:null
      };
    });
  };

  const changeHeaderTrue = () => {
    setMasterApp((prev) => {
      return {
        ...prev,
        go: true,
      };
    });
  };

  const goBack = () => {
    setMasterApp((prev) => {
      return {
        ...prev,
        back: true,
      };
    });
  };
  const previousPage = (value) => {
    setMasterApp((prev) => {
      return {
        ...prev,
        navigate: value,
      };
    });
  };

  const goOpen = (value) => {
    if (value === true) {
      setMasterApp((prev) => {
        return {
          ...prev,
          open: true,
          dialougeItem: prev.item,
        };
      });
      setAdd(!add);
    } else {
      setMasterApp((prev) => {
        return {
          ...prev,
          open: true,
        };
      });
    }
  };
  const goCLose = () => {
    setMasterApp((prev) => {
      return {
        ...prev,
        open: false,
      };
    });
  };

  const dialogue = () => {
    setMasterApp((prev) => {
      return {
        ...prev,
        dialogue: true,
      };
    });
  };

  const goAdd = (value) => {
    setMasterApp((prev) => {
      return {
        ...prev,
        navigateAdd: value,
      };
    });
  };

  const editIconTrue = (item, levelId, distributorHierarchyId) => {
    setMasterApp((prev) => {
      return {
        ...prev,
        edit: true,
        item: item,
        levelId: levelId,
        distributorHierarchyId: distributorHierarchyId,
      };
    });
  };
  const editIconFalse = () => {
    setMasterApp((prev) => {
      return {
        ...prev,
        edit: false,
        item: null,
      };
    });
  };
  console.log(token);
  return (

    <>

      {token ? (
        <div className="app">


          <div className="main">
            {true && (
              <Header
                name={data}
                callback={callBack}
                appData={masterApp}
                goOpen={goOpen}
              />
            )}
            <Routes>
              <>

                <Route
                  exact
                  path="/"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Dashboard
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/Dashboard"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Dashboard
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/filtereport"
                  element={
                    <Suspense fallback={<Loading />}>
                      <FilterData
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                {/* ==========================================A Field======================================================= */}
                <Route
                  exact
                  path="/ArA1(a-e)"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA1ae
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(d)"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A1dLandArea
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(d)Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A1dLandAreaInside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(e)"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A1eWaterResources
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(e)Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A1eWaterResourcesInside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArA1(f)"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA1f
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(f)Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA1fInside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(g)"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA1g
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(g)Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA1gInside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(h)"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA1h
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA1(h)Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA1hInside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA2Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA3Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA4Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA5Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA6Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ARA7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA7Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArA8"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA8
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA8Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA8Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA9"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA9
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA9Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA9Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA10"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA10
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA10Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA10Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA11"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA11
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArA11Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArA11Insidee
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                {/* <Route
                  exact
                  path="/footer"
                  element={
                    <Suspense fallback={<Loading />}>
                      < Footer
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                /> */}

                {/* ================================================================================================= */}
                {/* ==========================================B Field======================================================= */}
                <Route
                  exact
                  path="/ArB1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArB7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB7inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArB8"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB8
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB8inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB8Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />



                <Route
                  exact
                  path="/ArB9"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB9
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB9inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB9Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB10"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB10
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB10inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB10Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB11"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB11
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB11inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB11Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB12"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB12
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB12inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArB12Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB13"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B13ExaminationActivities
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArB13Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B13ExaminationActivitiesInside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                {/* ================================================================================================= */}
                {/* ======================================C Field=========================================================== */}
                <Route
                  exact
                  path="/ArC1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC7inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/ArC8"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC8
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC8inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC8Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC9"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC9
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC9inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC9Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC10"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC10
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC10inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC10Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC11"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC11
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC11inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC11Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC12"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC12
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC12inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC12Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC13"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC13
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC13inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC13Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC14"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC14
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC14inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC14Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC15"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC15
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC15inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC15Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC16"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC16
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArC16inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArC16Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                {/* ======================================C ENd=========================================================== */}
                {/* ======================================D Start=========================================================== */}
                <Route
                  exact
                  path="/ArD1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArD7inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArD7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                {/* ======================================D ENd=========================================================== */}
                {/* ======================================E Start=========================================================== */}
                <Route
                  exact
                  path="/ArE1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArE2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArE3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/ArE4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArE5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArE6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArE7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE7inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArE8"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE8
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE8inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE8Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArE9"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE9
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/ArE9inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArE9Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                {/* ======================================E ENd=========================================================== */}
                {/* ======================================F Start=========================================================== */}



                <Route
                  exact
                  path="/exsummary"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ExcutiveSummary
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/ArF1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ArF7inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ArF7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                {/* ======================================F ENd=========================================================== */}

                <Route
                  exact
                  path="/directorate"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Directorate
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />



                <Route
                  exact
                  path="/institute"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Institute
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/Department"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Department
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/StationType"
                  element={
                    <Suspense fallback={<Loading />}>
                      <StationType
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/Funding"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Funding
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/MasCrop"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MasCrop
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/ListValues"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ListValues
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/investigator"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Investigator
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/projectcode"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Projectcode
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/leave"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Leave
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/contactus"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Contactus
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/monthreportstatus"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MonthReportStatus
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/adminReportStatus"
                  element={
                    <Suspense fallback={<Loading />}>
                      <AdminReportStatus
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/investigatorInside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <InvestigatorInsdie
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/projectcodeinside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Projectcodeinside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />



                <Route
                  exact
                  path="/institudeInside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <InstitudeInside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/reporttable"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ReportTables
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/reporttableAr"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ReportTableAr
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/reporttableloop"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ReportTableLoop
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/reportdownloadpage"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ReportDownloadPage
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/myprofile"
                  element={
                    <Suspense fallback={<Loading />}>
                      <FacultyProfile
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/MasterDataFaculty"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MasterDataFaculty
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />





                <Route
                  exact
                  path="/reportsystem"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Reportingsystem
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/Dd"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Dd
                      // head={headerData}
                      // changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A1Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A2Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A3Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A4Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A5Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A6Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A7Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A8"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A8
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A8Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A8Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A7Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/A4Inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <A4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B7inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b8"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B8
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B8inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B8Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b9"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B9
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B9inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B9Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b10"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B10
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B10inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B10Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b11"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B11
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B11inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B11Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b12"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B12
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B12inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B12Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b13"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B13
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B13inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B13Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b14"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B14
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B14inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B14Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b15"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B15
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B15inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B15Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b16"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B16
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B16inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B16Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b17"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B17
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B17inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B17Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b18"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B18
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B18inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B18Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b19"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B19
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B19inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B19Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/b20"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B20
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/B20inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <B20Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c7"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C7
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C7inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C7Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c8"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C8
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C8inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C8Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/c9"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C9
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/C9inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <C9Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/d1"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D1
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/D1inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D1Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/d2"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D2
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/D2inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D2Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/d3"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D3
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/D3inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D3Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/d4"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D4
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/D4inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D4Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/d5"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D5
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/D5inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D5Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/d6"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D6
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/D6inside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <D6Inside
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                      />
                    </Suspense>
                  }
                />

                {/* end Menu  */}

                <Route
                  exact
                  path="/masterOnDuty"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MasterOnDuty
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        goAdd={goAdd}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/transactionleave"
                  element={
                    <Suspense fallback={<Loading />}>
                      <TransactionLeave
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        goAdd={goAdd}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/leaveinside"
                  element={
                    <Suspense fallback={<Loading />}>
                      <LeaveInside />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="transactionOnduty"
                  element={
                    <Suspense fallback={<Loading />}>
                      <TransactionOnduty
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        goAdd={goAdd}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/TransactionTsms"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Tsms
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        goAdd={goAdd}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/monthyReport"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MonthlyReport
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                        goBack={goBack}
                        previousPage={previousPage}
                        appData={masterApp}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/GeneralAR"
                  element={
                    <Suspense fallback={<Loading />}>
                      <GeneralAR
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                        goBack={goBack}
                        previousPage={previousPage}
                        appData={masterApp}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/Activities"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Activities
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/Educational"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Educational
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        goAdd={goAdd}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/MasterData"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MasterData
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                        goBack={goBack}
                        previousPage={previousPage}
                        appData={masterApp}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/ManthyReport"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ManthyReport
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/Report"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Report
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/MasterCourse"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MasterCourse
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/Studentguides"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Studentguides
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/StudentDetalis"
                  element={
                    <Suspense fallback={<Loading />}>
                      <StudentDetalis
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />
                {/* <Route exact path="/user-level/home" element={

              <Suspense fallback={<Loading />}>
                <UserLevelHome head={headerData} />
              </Suspense>
            } /> */}

                <Route
                  exact
                  path="/level/userlevel"
                  element={
                    <Suspense fallback={<Loading />}>
                      <UserLevelList head={headerData} />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/level/locationlevel"
                  element={
                    <Suspense fallback={<></>}>
                      <LocationLevelList
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/level/positionlevel"
                  element={
                    <Suspense fallback={<Loading />}>
                      <PositionLevelList head={headerData} />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/level/productlevel"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ProductLevelList
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />





                <Route
                  exact
                  path="/market-segment/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <MargetSegmentHome
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/invoice/view"
                  element={
                    <Suspense fallback={<Loading />}>
                      <InvoiceView
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/invoice/creation"
                  element={
                    <Suspense fallback={<Loading />}>
                      <InvoiceCreation
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />





                <Route
                  exact
                  path="/grn-creation/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <GrnCreation
                        head={headerData}
                        changeHeaderFalse={changeHeaderFalse}
                        goBack={goBack}
                        previousPage={previousPage}
                        appData={masterApp}
                      />
                    </Suspense>
                  }
                />



                <Route
                  exact
                  path="/logview/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <LogViewHome head={headerData} />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/lov/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <LovHome
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/route/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <RouteHome
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/schemes/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <SchemesHome
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/reason/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ReasonHome
                        head={headerData}
                        changeHeaderTrue={changeHeaderTrue}
                        appData={masterApp}
                        goBack={goBack}
                        previousPage={previousPage}
                        editIconTrue={editIconTrue}
                        editIconFalse={editIconFalse}
                        dialogue={dialogue}
                        goCLose={goCLose}
                      />
                    </Suspense>
                  }
                />


                <Route
                  exact
                  path="/category/home"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Category head={headerData} />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="/category/new"
                  element={
                    <Suspense fallback={<Loading />}>
                      <CategoryNew head={headerData} />
                    </Suspense>
                  }
                />
              </>
            </Routes>
          </div>
        </div>
      ) : (

        // <Routes>
        //   <Route exact path="/" element={<Login callback={callBack} />} />
        // </Routes>
        <div class="main-2">
        <Sidebare
            />
         <Routes>
            <Route exact path="/" element={<Login callback={callBack} />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/aboutus" element={<About />} />
            <Route exact path="/stations" element={<Stations />} />
            <Route exact path="/projectteam" element={<Projectteam/>} />
            <Route exact path="/vision" element={<Utilities />} />
            <Route exact path="/methodology" element={<Methodology/>}/>
            <Route exact path="/utilities" element={<Utility/>}/>
          </Routes>

        </div>

      )}
    </>
  );
}

export default App;
