import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { B4VALIDATION } from "../../../Validation";

//  import { Translational } from '../../../Pages/Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../../ComponentLoader";

function B4Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [depatid, setDepatId] = useState(null);
  const [depatIdProductType, setDepatIdProductType] = useState("");
  const [loader,setLoader] =useState(true);
  const [Natureofcoaching, setNatureofcoaching] = useState("");
  const [Noofclassesoffered, setNoofclassesoffered] = useState("");
  const [FellowshipName, setFellowshipName] = useState("");
  const [NoOfStudent, setNoOfStudent] = useState("");

  const [productType, setProductType] = useState("");
  const [ProductName, setProductName] = useState("");
  const [Currentstatus, setCurrentstatus] = useState("");
  const [Tentativetimeline, setTentativetimeline] = useState(""); 
  const [Progress, setProgress] = useState(""); 

  const [depatIdProductTypeErr,setProductTypeErr] = useState(false)
  const [productNameErr,setProductNameErr] = useState(false)
  const [CurrentstatusErr,setCurrentstatusErr] = useState(false)
  const [TentativetimelineErr,setTentativetimelineErr] = useState(false)
  const [ProgressErr,setProgressErr] = useState(false)


    const [dropdown, setDropdown] = useState([]);
    const [PType, setPType] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [state,setState] = useState();
  const [assM,setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();


  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B4VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
      handleLovType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Target product");
  }, []);

  useEffect(()=>{
    const objectString = localStorage.getItem('MReport');
    const myObject = JSON.parse(objectString);
    if(myObject !==null){
      setState(myObject)
    }
    const Month = localStorage.getItem('Month');
    if(Month !==null){
      setAssMonth(Month)
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  },[])


  const handleBack = () => {
    navigate("/B4");
  };

  const edit = (item) => {
    console.log(item);
    setProductType(item.productType)
    setProductName(item.productName)
    setCurrentstatus(item.currentstatus)
    setTentativetimeline(item.tentativetimeline)
    setProgress(item.progress)
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov Type //
  const handleLovType = async (token,ProductType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    // console.log(productName);
    try {
      const { data } = await axios.get(`${window.BASE}${window.LOVTYPE}${"ProductType"}`, {
        headers: h,
      });
      console.log(data);
      if (data) {
        setPType(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      ProductType: productType,
      ProductName: ProductName,
      Currentstatus: Currentstatus,
      Tentativetimeline: Tentativetimeline,
      Progress : Progress,
      MRSId:state.id,
      investigatorId:parseInt(invis),
      rptMonth:parseInt(assM)
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B4SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B4")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B4EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B4")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.B4GETALL}`, {
        headers: h,
      });
      console.log(data);setLoader(false);
      // setStudentbtn(data);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const formValidateFun=()=>{
    if(!productType){
      setProductTypeErr(true)
      document.getElementById("productType").focus()
    }else if(!ProductName){
      setProductNameErr(true)
      document.getElementById("ProductName").focus()
    }else if(!Currentstatus){
      setCurrentstatusErr(true)
      document.getElementById("Currentstatus").focus()
    }else if(!Tentativetimeline){
      setTentativetimelineErr(true)
      document.getElementById("Tentativetimeline").focus()
    }else if(!Progress){
      setProgressErr(true)
      document.getElementById("Progress").focus()
    }else{
      handleLeavepage()
    }
  }


  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Translational Pathway (B4)</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            B4. Translational Pathway to the product
          </h5>

          
          <FormControl style={{ width: "48%", margin: 12 }} error={depatIdProductTypeErr} id="productType">
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Product Type
            </InputLabel>
            <Select
              variant="outlined"
              style={{ height: "45px" }}
              value={productType}
              onChange={(e) => {
                setProductType(e.target.value)
                setProductTypeErr(false)
              }}
            >
              {PType?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={productNameErr}
            id="ProductName"
            label="Product Name"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={ProductName}
            onChange={(e) => {
              setProductName(e.target.value)
              setProductNameErr(false)
            }}
            // {...register("ProductName")}
            // error={errors.ProductName ? true : false}
            // helperText={errors.ProductName?.message}
          />

          <TextField
            error={CurrentstatusErr}
            id="Currentstatus"
            label="Current status (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Currentstatus}
            onChange={(e) => {
              setCurrentstatus(e.target.value)
              setCurrentstatusErr(false)
            }}
          />


          <TextField
            error={TentativetimelineErr}
            id="Tentativetimeline"
            label="Tentative time line for release"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Tentativetimeline}
            onChange={(e) => {
              setTentativetimeline(e.target.value)
              setTentativetimelineErr(false)
            }}
          />

          <TextField
            error={ProgressErr}
            id="Progress"
            label="Progress during this month  (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Progress}
            onChange={(e) => {
              setProgress(e.target.value)
              setProgressErr(false)
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              style={{paddingTop:"5px"}}
              onClick={handleBack}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" style={{paddingTop:"5px"}} onClick={formValidateFun}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B4Inside;
