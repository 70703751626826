import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D6VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArF7Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Lov, setLov] = useState([]);

  const [nameoftheAward, setNameoftheAward] = useState("");
  const [awardedby, setAwardedby] = useState("");
  const [awardDate, setAwardDate] = useState("");
  const [Recongnition, setRecongnition] = useState("");

  const [nameoftheAwardErr, setNameoftheAwardErr] = useState(false);
  const [awardedbyErr, setAwardedbyErr] = useState(false);
  const [awardDateErr, setAwardDateErr] = useState(false);
  const [RecongnitionErr, setRecongnitionErr] = useState(false);

  const [faculty, setfaculty] = useState("")
  const [facultyErr, setFacultyErr] = useState(false);
  const [inves, setInves] = useState([]);
  const [loader,setLoader] =useState(true);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D6VALI),
  });

  const backs = () => {
    navigate("/ArF7");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
    
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Fellowship");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    handleInvestigators(tok,investigator)
  }, []);
  const edit = (item) => {
    console.log(item);
    setfaculty(item.investigatorId)
    setNameoftheAward(item.nameoftheAward);
    setAwardedby(item.awardedby);
    setAwardDate(dateFormat(item.awardDate));
    // setRecongnition(item.recognitionType);
    // setProjectstarttime(moment(item.ProjectStartTime).format("YYYY-MM-DD"))
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov
  const handleLov = async (token, RecognitionType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"RecognitionType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLov(data);   setLoader(false);
    } catch (error) {
      console.log(error);   setLoader(false);
    }
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);   setLoader(false);
    } catch (error) {
      console.log(error);   setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      InvestigatorId:faculty,
      NameoftheAward: nameoftheAward,
      Awardedby: awardedby,
      AwardDate: awardDate,
      // RecognitionType: Recongnition,
      IAMRSId: state.id,
      InstituteId: parseInt(invis),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF7SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF7");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF7EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF7");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if(!faculty){
      setFacultyErr(true);
      document.getElementById("faculty").focus()
    }
    else if (!nameoftheAward) {
      setNameoftheAwardErr(true)
      document.getElementById("nameoftheAward").focus()
    } else if (!awardedby) {
      setAwardedbyErr(true)
      document.getElementById("awardedby").focus()
    } else if (!awardDate) {
      setAwardDateErr(true)
      document.getElementById("awardDate").focus()
    }
    else {
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>F7. Award / Recognition / Fellowships</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}> F7. Award / Recognition / Fellowships</h5>

        

          <FormControl
            style={{ width: "48%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name of the faculty            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={facultyErr}
              style={{ height: "45px" }}
              value={faculty}
              onChange={(e) => {
                setfaculty(e.target.value);
                setFacultyErr(false);
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={nameoftheAwardErr}
            id="nameoftheAward"
            label=" Name of the Award /Recognition / Fellowship (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={nameoftheAward}
            onChange={(e) => {
              setNameoftheAward(e.target.value)
              setNameoftheAwardErr(false)
            }}
          />

          <TextField
            error={awardedbyErr}
            id="awardedby"
            label="Awarded By (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={awardedby}
            onChange={(e) => {
              setAwardedby(e.target.value)
              setAwardedbyErr(false)
            }}
          />

          {/* <TextField
            id="outlined-basic"
            label="Award Date"
            type="date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("AwardDatev")}
            error={errors.AwardDatev ? true : false}
            helperText={errors.AwardDatev?.message}
          /> */}

          <TextField
            error={awardDateErr}
            id="awardDate"
            label="Award Date / dd-mm-yyyy"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={awardDate}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // InputProps={{
            //   placeholder: "",
            // }}
            onChange={(e) => {
              setAwardDate(e.target.value)
              setAwardDateErr(false)
            }}
          />

          {/* <FormControl style={{ width: "48%", margin: 12 }} error={RecongnitionErr} id="Recongnition">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Recongnition Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={Recongnition}
              label="Programme Type"
              size="small"
              // sx={{ width: "100%" }}
              // style={{ margin: "10px", width: "48%" }}
              onChange={(e) => {
                setRecongnition(e.target.value)
                setRecongnitionErr(false)
              }}
            >
              {Lov?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "18px", padding: "5px 30px 28px 26px", borderRadius: "3px", color: "#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={() => formValidateFun()} style={{ paddingTop: "5px" }}>
              Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </div>
    </div>
  );
}

export default ArF7Inside;
