import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import LoopIcon from "@mui/icons-material/Loop";
import { ToastContainer, toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArF1({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/monthyReport");
  };

  const nextButton = () => {
    Navigate("/ArF2");
  };

  const Previousbutton = () => {
    Navigate("/ArE9");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [Studentguidess, setStudentguidess] = useState([]);
  const [listAll, setListAll] = useState([]);
  const [loader,setLoader] =useState(false);
  const [role, setRole] = useState("");
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [Mname, setName] = useState("");

  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");
  const [disables, setDisables] = useState(false);
  const [ii, setI] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    navigate("/ArF1inside");
  };
  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    // head(" Production and distribution of seeds / seedlings / Bio-inputs")
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);

    if (tok != null && tok != undefined) {
      if (myObject !== null) {
        handleDirectorate(tok, myObject.id);
        setI(myObject.id);
      }
    }
    const role = localStorage.getItem("role");
    setRole(role);
    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true);
    } else {
      setDisables(false);
    }
    let isTrue = localStorage.getItem("Mname");
    if (isTrue !== null) {
      setName(isTrue);
    }
  }, []);

  const edit = (item) => {
    navigate("/ArF1inside", { state: item });
    console.log(item);
    setOpen(true);
    setId(item.id);
  };

  const onclickDelete = async (id) => {
    const result = window.confirm("Are you sure you want to delete?");
    if (result) {
      // Perform the delete operation
      deleteTransaction(id);
      console.log("Deleted successfully");
      // window.location.reload();
    } else {
      // Cancelled delete operation
      console.log("Delete cancelled");
    }
  };

  const deleteTransaction = async (id) => {
    const d = {
      Id: id,
    };
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ARF1DELETE}`,
        d,
        { headers: h }
      );
      console.log(data);
      handleDirectorate(token, ii);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectorate = async (token, id) => {
    console.log(token); setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.ARF1GETALL}${"/" + id}`,
        { headers: h }
      );
      console.log(data);
      setListAll(data);   setLoader(false);
    } catch (error) {
      console.log(error);   setLoader(false);
    }
  };

  //student table
  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>
            F1. Production and distribution of Seeds / Seedlings / Bio-inputs{" "}
          </span>
        </div>
      </div>
      <div className="tables-main">
        <div style={{ marginLeft: 20 }}>
          <button className="tables-btn" onClick={Previousbutton}>
            <div className="sidebar-icon" />
            Previous
          </button>
        </div>
        <div style={{ marginRight: 20 }}>
          <button className="tables-btn" onClick={nextButton}>
            Next
            <div className="sidebar-icon" />
          </button>
        </div>
      </div>
      <div className="table-container-grid">
        <div className="tables-mains">
          <div className="table-head">
            {/* <div> */}

            {/* <button className='top-butto' onClick={backButton} >

              <ArrowBackIcon style={{ marginBottom: -5 }} className="sidebar-icon" />
              Back</button> */}

            {/* </div> */}

            <div>
              <button className="top-button" onClick={handleClickOpen} disabled={role === "Director" ? true : !disables ? false : true}>
                + Add
              </button>
            </div>
            <div className="Center-Month">
              <h4 className="h-6">{Mname}</h4>
            </div>
          </div>
        </div>

        <Dialog
          className="dialog"
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="form-control"></div>
        </Dialog>

        {/* ************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell >
                Station/KVK/ Department/ College
              </TableCell>
              <TableCell >Seeds/ Seedlings/Bio- inputs</TableCell>
              <TableCell >Crop </TableCell>
              <TableCell >Variety</TableCell>
              <TableCell >BS/FS/ CS/TL</TableCell>
              <TableCell >Quantity produced</TableCell>
              <TableCell >Qty distributed</TableCell>
              <TableCell >
                Quantity distributed in this month
              </TableCell>
              <TableCell >Balance</TableCell>
              <TableCell >Quantity produced</TableCell>
              <TableCell >Quantity distributed</TableCell>
              <TableCell >Balance</TableCell>
              <TableCell >Option</TableCell>
            </TableHead>

            <TableBody>
              {listAll?.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell >{row.instituteName}</TableCell>
                  <TableCell >{row.productType}</TableCell>
                  <TableCell >{row.cropName}</TableCell>
                  <TableCell >{row.variety}</TableCell>
                  <TableCell > {row.varietyType}</TableCell>
                  <TableCell > {row.seedQtyProduced}</TableCell>
                  <TableCell > {row.seedQtyDistributed}</TableCell>
                  <TableCell >

                    {row.seedQtyDistributedMonth}
                  </TableCell>
                  <TableCell > {row.seedQtyBalance}</TableCell>
                  <TableCell > {row.bioInputProduced}</TableCell>
                  <TableCell >{row.bioInputDistributed}</TableCell>
                  <TableCell >{row.bioInputBalance}</TableCell>
                 
                    <TableCell >
                      <div className="edit-delete">
                        <button className="tablebutton" onClick={() => edit(row)} disabled={role === "Director" ? true : !disables ? false : true}>
                          {<Edit className="sidebars-icon" />}
                        </button>

                        <button
                          className="deleted"
                          style={{ marginLeft: 3 }}
                          onClick={() => onclickDelete(row.id)}
                          disabled={role === "Director" ? true : !disables ? false : true}
                        >
                          {<DeleteIcon className="sidebars-icon" />}
                        </button>
                      </div>
                    </TableCell>
                
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={Studentguidess.length}
         rowsPerPage={masterManufacturer.rowsPerPage}
         page={masterManufacturer.page}
         onPageChange={handleChangePage}
         onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      </div>
    </div>
  );
}

export default ArF1;
