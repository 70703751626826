import { faAddressBook, faAddressCard, faBars, faBarsProgress, faBullseye, faDashboard, faDiagramProject, faEnvelope, faListCheck, faMicrochip, faScrewdriver, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import logo from "../Images/tnau.png";
import PicLogo from "../Images/pikturelogo.png";
import { useNavigate } from 'react-router-dom';
import Login from '../Pages/Login/Login';


function Sidebare() {
    const [open, setOpen] = useState(false);
    const [menu, setMenu] = useState(false);

    const handleClick = () => {
        navigate("/");
        setOpen(true);
    }

    const handleMenu = () => {
        setMenu(!menu);
    }
    const handleMenuItemClick = () => {
        setMenu(false)
    }
    const navigate = useNavigate();
    return (
        <>
            <div class="response-title">
                <div className="top-center-header2 ">
                    <h5 style={{ textAlign: "center", fontSize: "25px" }}>Digital Reporting System For Region || Research Stations</h5>
                </div>
                <div className='header-fixed'>
                    <div className="top-center-header">
                        <span style={{ fontSize: '21px', color: "white",textAlign:"center" }} className='response-header'>
                            TamilNadu Agricultural University <br></br>
                            <span className="top-head" >
                                <strong style={{ color: "white" }}>Directorate of TamilNadu Rice Research Institute</strong>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className='sidebar response-websidebar'>
                <div className="login-logo ">
                    <img src={logo} className="js-img-header top-responsive-header" onClick={() => navigate("/")} />


                </div>
                <div className='list-ul'>

                    <ul>
                        {/* <div className="li-box"> <li><FontAwesomeIcon className="li-icon" icon={faDashboard} /><br></br> Dashboard</li></div> */}
                        <div className="li-box" onClick={() => navigate("/aboutus")}> <li><FontAwesomeIcon className="li-icon" icon={faAddressCard} /><br></br>About</li></div>
                        <div className="li-box" onClick={() => navigate("/stations")}> <li><FontAwesomeIcon className="li-icon" icon={faListCheck} /><br></br> Why this Project</li></div>
                        <div className="li-box" onClick={() => navigate("/vision")}> <li><FontAwesomeIcon className="li-icon" icon={faBullseye} /><br></br>Vision</li></div>
                        <div className="li-box" onClick={() => navigate("/projectteam")}> <li><FontAwesomeIcon className="li-icon" icon={faDiagramProject} /><br></br> Project Team</li></div>
                        <div className="li-box" onClick={() => navigate("/methodology")}> <li><FontAwesomeIcon className="li-icon" icon={faBarsProgress} /><br></br> Methodology</li></div>
                        <div className="li-box" onClick={() => navigate("/utilities")}> <li><FontAwesomeIcon className="li-icon" icon={faScrewdriver} /><br></br> Farm Application</li></div>
                        {/* <div className="li-box" ><a href='https://trritnau.com:8088/' target='blank'> <li><FontAwesomeIcon className="li-icon" icon={faScrewdriver} /><br></br> Utilities</li> </a></div> */}
                        {/* <div className="li-box" onClick={() => navigate("/contact")}> <li><FontAwesomeIcon className="li-icon" icon={faEnvelope} /><br></br> Future Thrusts</li></div> */}
                        {/* https://trritnau.com:8088/ */}

                        {/* <div className="li-box"><li><button  data-toggle="modal" data-target="#exampleModalCenter">Log In</button></li></div> */}
                        <div className="li-box"> <li><a onClick={handleClick} data-toggle="modal" data-target="#exampleModalCenter" ><FontAwesomeIcon className="li-icon" icon={faUser} /><br></br> Login</a></li></div>

                    </ul>

                </div>
                <div className="footer-position">
                    <div className="footer-position">
                        <div className="banner-footer1"> Poweredby <a href="https://thepikture.com/" target="blank">
                            <img src={PicLogo} className='footer-logo' /> </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="responsive-mobilesidebar">
                <div class="response-title-respone">
                    <div className=" ">
                        <h5 style={{ textAlign: "center",  alignItems: "center", color: "yellow", fontSize: "13px" }}>Digital Reporting System For Region || Research Stations</h5>
                   
                    <div className='span-title-response'>
                        <div className="">
                            <span style={{ fontSize: '12px', textAlign: "center", color: "white" }} className=''>
                                TamilNadu Agricultural University <br></br>
                                <span className="" >
                                    <strong style={{ color: "white", fontSize:"13px", textAlign:"center" }}>Directorate of TamilNadu Rice Research Institute</strong>
                                </span>
                            </span>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='menu-response'>
                    <div className="menu-sidebar">
                        <FontAwesomeIcon className="li-icon" onClick={handleMenu} style={{ color: "white", fontSize: "20px" }} icon={faBars} />
                    </div>
                    <div>
                        <button className="response-button" onClick={handleClick} data-toggle="modal" data-target="#exampleModalCenter" >Login</button>
                    </div>
                </div>
                {menu &&
                    <div className='response-menuitem'>
                        <ul className="ul-sidebar">
                            <div className="li-box-menu" onClick={() => { navigate("/aboutus"); handleMenuItemClick(); }}> <li>About</li></div>
                            <div className="li-box-menu" onClick={() => { navigate("/stations"); handleMenuItemClick(); }}> <li> Why this Project</li></div>
                            <div className="li-box-menu" onClick={() => { navigate("/vision"); handleMenuItemClick(); }}> <li>Vision</li></div>
                            <div className="li-box-menu" onClick={() => { navigate("/projectteam"); handleMenuItemClick(); }}> <li> Project Team</li></div>
                            <div className="li-box-menu" onClick={() => { navigate("/methodology"); handleMenuItemClick(); }}> <li> Methodology</li></div>
                            <div className="li-box-menu" onClick={() => { navigate("/utilities"); handleMenuItemClick(); }}> <li> Farm Application</li></div>
                        </ul>
                    </div>
                }
            </div>
        </>
    )
}

export default Sidebare;