import React from "react";

import {
    TextField,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArA8Inside({ head }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = React.useState(false);

    const [Studentbtn, setStudentbtn] = useState([]);

    const [Natureofcoaching, setNatureofcoaching] = useState("");
    const [Noofclassesoffered, setNoofclassesoffered] = useState("");
    const [FellowshipName, setFellowshipName] = useState("");
    const [NoOfStudent, setNoOfStudent] = useState("");

    const [token, setToken] = useState("");
    const [id, setId] = useState(0);
    const [loader,setLoader] =useState(true)

    const [state, setState] = useState();
    const [assM, setAssMonth] = useState("");
    const [invis, setInvestigator] = useState();
    const [insti,setInsti] =useState([]);

    const[value,setValue]=useState({
        SchemeName:"",
        ACHead:"",
        SanctionedBudget:"",
        BudgetReceived:"",
        AvailAmount:"",
        ExpAmount:"",
        Balance:"",
       });

       const[errValue,setErrValue]=useState({
        SchemeName:false,
        ACHead:false,
        SanctionedBudget:false,
        BudgetReceived:false,
        AvailAmount:false,
        ExpAmount:false,
        Balance:false,
       });

    const navigate = useNavigate();
    const location = useLocation();

    const [dropnature, setDropnature] = useState([]);

    const toastSuccess = (data) => {
        toast.success(data, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        // setValue,
        reset,
        watch,
    } = useForm({
        // resolver: yupResolver(A8VALI),
    });

    useEffect(() => {
        const tok = localStorage.getItem("token");
        setToken(tok);
        console.log(tok);
        if (tok != null && tok != undefined) {
            // handleDirectorate(tok);
            console.log(location.state);
            if (location.state) {
                edit(location.state);
            }
        }
        head(" Student facilitation");
        setLoader(false)
    }, []);

    useEffect(() => {
        const objectString = localStorage.getItem("MReport");
        const myObject = JSON.parse(objectString);
        if (myObject !== null) {
            setState(myObject);
        }
        const Month = localStorage.getItem("Month");
        if (Month !== null) {
            setAssMonth(Month);
        }
        const insti = localStorage.getItem("loginId");
        setInsti(insti);
    }, []);

    const edit = (item) => {
        console.log(item);
       
        setValue({
            SchemeName:item.schemeName,
            ACHead:item.acHead,
            SanctionedBudget:item.sanctionedBudget,
            BudgetReceived:item.budgetReceived,
            AvailAmount:item.availAmount,
            ExpAmount:item.expAmount,
            Balance:item.balance,
        })
        setId(item.id)
        setLoader(false)
    };


    // const handleData = ((val,name)=>{
    //     // const {name,value}=event.target
    //     setValue(prev =>({
    //       ...prev,
    //       [name] : val
    //     }))
        // if(name === "AvailAmount"){
          
        //    if(value.ExpAmount.length > 0){
        //       let balance = value - value.ExpAmount;
     
        //       setValue(prev =>({
        //         ...prev,
        //         Balance : balance
        //       }))
        //    }else{
        //     setValue(prev =>({
        //         ...prev,
        //         Balance : val
        //       }))
        //    }
        // }

    //     if(name === "ExpAmount"){
    //         if(value.AvailAmount.length > 0){
    //             let balance = value.AvailAmount - val;
    //             console.log(val,val > value.AvailAmount);
    //             if(parseInt(val) > parseInt(value.AvailAmount)){
    //               alert("Please Put Correct Value")
    //             }else{
    //                 setValue(prev =>({
    //                     ...prev,
    //                     Balance : balance
    //                   }))
    //             }
               
             
    //          }else{
    //           setValue(prev =>({
    //               ...prev,
    //               Balance : val
    //             }))
    //          }
    //     }
    //   })
    //   console.log("value",value);


    const handleData = ((event)=>{
        const {name,value}=event.target
        setValue(prev =>({
          ...prev,
          [name] : value
        }))
      })
      console.log("value",value);
    const backs = () => {
        navigate("/ArA8");
    };

    const handleClickOpen = () => {
        setOpen(true);
        //  navigate("/Activities")
    };

    const handleClose = () => {
        setOpen(false);
        // setId(0)
    };

    const handleLeavepage = async () => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        console.log(token);

        const d = {

            id,
            SchemeName: value.SchemeName,
            ACHead: value.ACHead,
            SanctionedBudget:parseFloat(value.SanctionedBudget),
            BudgetReceived: parseFloat(value.BudgetReceived),
            AvailAmount: parseFloat(value.AvailAmount),
            ExpAmount: parseFloat(value.ExpAmount),
            Balance: parseFloat(value.AvailAmount-value.ExpAmount),
            IAMRSId: state.id,
            InstituteId: parseInt(insti),
            RptMonth: parseInt(assM),
        };


        if (id === 0) {
            try {
                const { data } = await axios.post(`${window.BASE}${window.ARA8SAVE}`, d, {
                    headers: h,
                });
                console.log(data);
                setStudentbtn(data);

                if (data.result === "Success") {
                    alert("Successfully Submitted");
                    navigate("/ArA8")
                    toastSuccess("Successfully Submitted");
                    setId(0);
                    setOpen(false);
                } else {
                    alert(data.errorMsgs[0])
                }

                //   setDirect(data);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const { data } = await axios.post(`${window.BASE}${window.ARA8EDIT}`, d, {
                    headers: h,
                });
                console.log(data);
                if (data.result === "Success") {
                    alert("Successfully Submitted");
                    navigate("/ArA8")
                    toastSuccess("Successfully updated");
                    setId(0);
                    setOpen(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const formValidateFun = (e) => {
        e.preventDefault()
        
         if (!value.SchemeName) {
          setErrValue((prev) => ({ ...prev, SchemeName: true }))
          document.getElementById("SchemeName").focus();
        } 
        else if (!value.ACHead) {
          setErrValue((prev) => ({ ...prev, ACHead: true }))
          document.getElementById("ACHead").focus();
        } 
        else if (!value.SanctionedBudget) {
          setErrValue((prev) => ({ ...prev, SanctionedBudget: true }))
          document.getElementById("SanctionedBudget").focus();
        } 
        else if (!value.BudgetReceived) {
          setErrValue((prev) => ({ ...prev, BudgetReceived: true }))
          document.getElementById("BudgetReceived").focus();
        } 
        else if (!value.AvailAmount) {
            setErrValue((prev) => ({ ...prev, AvailAmount: true }))
            document.getElementById("AvailAmount").focus();
          } 
          else if (!value.ExpAmount) {
            setErrValue((prev) => ({ ...prev, ExpAmount: true }))
            document.getElementById("ExpAmount").focus();
          } 
         
        
        else {
          handleLeavepage();
        }
      };
    

    return (
        <div className="main-container-margin">
              <ComponentLoader isLoading={loader} />
            <div className="master-main d-flex">
                <div className="head-icon">
                    <Link to="/GeneralAR">
                        <HomeIcon style={{ color: "white", marginLeft: 70 }} />
                    </Link>
                </div>
                <div className="master-main-span">
                    <span>
                    A8. Budget & Expenditure Details (in Lakhs)

                    </span>
                </div>
            </div>

            <form className="from-mains" onSubmit={formValidateFun}>
                <div className="form-control">
                    <h5 style={{ width: 350, margin: 20 }}>
                    A8. Budget & Expenditure Details (in Lakhs)
                    </h5>



                    {/* <TextField
                        id="SchemeName"
                        label="SchemeName"
                        name="SchemeName (Max 500 Character)"
                        variant="outlined"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.SchemeName}
                        onChange={(e) => {
                            handleData(e.target.value,e.target.name);
                            setErrValue((prev) => ({ ...prev, SchemeName: false }));
                          }}
                        error={errValue.SchemeName}
                        helperText={errors.Noofclassesoffered?.message}
                    /> */}



                     <TextField
                        id="SchemeName"
                        label="SchemeName (Max 500 Character)"
                        variant="outlined"
                        name="SchemeName"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.SchemeName}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, SchemeName: false }));
                          }}
                        error={errValue.SchemeName}
                        helperText={errors.Noofclassesoffered?.message}
                    />
                    <TextField
                        id="ACHead"
                        label="Head (Max 500 Character)"
                        variant="outlined"
                        name="ACHead"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.ACHead}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, ACHead: false }));
                          }}
                        error={errValue.ACHead}
                        helperText={errors.Noofclassesoffered?.message}
                    />

                    <TextField
                        id="SanctionedBudget"
                        label=" Sanctioned Budget (Rs) "
                        variant="outlined"
                        name="SanctionedBudget"
                        type="number"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.SanctionedBudget}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, SanctionedBudget: false }));
                          }}
                        error={errValue.SanctionedBudget}
                        helperText={errors.FellowshipName?.message}
                    />

                    <TextField
                        id="BudgetReceived"
                        label=" Budget received (Rs)"
                        variant="outlined"
                        name="BudgetReceived"
                        type="number"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.BudgetReceived}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, BudgetReceived: false }));
                          }}
                        error={errValue.BudgetReceived}
                        helperText={errors.NoOfStudent?.message}
                    />


                    <TextField
                        id="AvailAmount"
                        label=" Budget available at the beginning of the month "
                        variant="outlined"
                        name="AvailAmount"
                        type="number"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.AvailAmount}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, AvailAmount: false }));
                          }}
                        error={errValue.AvailAmount}
                        helperText={errors.NoOfStudent?.message}
                    />
                    <TextField
                        id="ExpAmount"
                        label=" Expenditure during this month "
                        variant="outlined"
                        name="ExpAmount"
                        type="number"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.ExpAmount}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, ExpAmount: false }));
                          }}
                        error={errValue.ExpAmount}
                        helperText={errors.NoOfStudent?.message}
                    />
                    <TextField
                        id="Balance"
                        label=" Balance(Rs.)"
                        name="Balance"
                        variant="outlined"
                       disabled
                       onChange={(e) => {
                       
                        setErrValue((prev) => ({ ...prev, Balance: false }));
                      }}
                        style={{ margin: "10px", width: "48%" }}
                        value={parseInt(value.AvailAmount)-parseInt(value.ExpAmount)-0}
                        // value={value.Balance}
                       
                        helperText={errors.NoOfStudent?.message}
                    />

                    <div className="from-last">
                        <button
                            type="submit"
                            style={{ backgroundColor: "#008CBA", height: "20px" }}
                            onClick={backs}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className="sbmit-buttons"
                        // onClick={handleLeavepage}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ArA8Inside;