import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArA6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Natureofcoaching, setNatureofcoaching] = useState("");
  const [Noofclassesoffered, setNoofclassesoffered] = useState("");
  const [FellowshipName, setFellowshipName] = useState("");
  const [NoOfStudent, setNoOfStudent] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti,setInsti]=useState();
  const [loader,setLoader] =useState(true)

  const[value,setValue]=useState({
    NoOfAudit:"",
    ReplyDetails:"",
    AuditDropped:"",
    PendingAudit:"",
   });

   const[errValue,setErrValue]=useState({
    NoOfAudit:false,
    ReplyDetails:false,
    AuditDropped:false,
    PendingAudit:false,
   });

  const navigate = useNavigate();
  const location = useLocation();

  const [dropnature, setDropnature] = useState([]);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A8VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student facilitation");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
    setLoader(false)
  }, []);

  const edit = (item) => {
    console.log(item);
    setLoader(false)

setValue({
  NoOfAudit:item.noOfAudit,
  ReplyDetails:item.replyDetails,
  AuditDropped:item.auditDropped,
  PendingAudit:item.pendingAudit,
})
setId(item.id)
  };

  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
  })
  console.log("value",value);

  const backs = () => {
    navigate("/ArA6");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      NoOfAudit: value.NoOfAudit,
      ReplyDetails: value.ReplyDetails,
      AuditDropped: value.AuditDropped,
      PendingAudit: value.PendingAudit,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };


    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA6")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA6EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully updated");
          navigate("/ArA6")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = (e) => {
    e.preventDefault()
    
     if (!value.NoOfAudit) {
      setErrValue((prev) => ({ ...prev, NoOfAudit: true }))
      document.getElementById("NoOfAudit").focus();
    } 
    else if (!value.ReplyDetails) {
      setErrValue((prev) => ({ ...prev, ReplyDetails: true }))
      document.getElementById("ReplyDetails").focus();
    } 
    else if (!value.AuditDropped) {
      setErrValue((prev) => ({ ...prev, AuditDropped: true }))
      document.getElementById("AuditDropped").focus();
    } 
    else if (!value.PendingAudit) {
      setErrValue((prev) => ({ ...prev, PendingAudit: true }))
      document.getElementById("PendingAudit").focus();
    } 
    
    else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A6.  Audit
</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ width: 350, margin: 20 }}>A6.  Audit
</h5>

          <TextField
            id="NoOfAudit"
            name="NoOfAudit"
            type="number"
            label="No. of audit objections pending at the beginning of the month (A)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={value.NoOfAudit}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, NoOfAudit: false }));
            }}
            error={errValue.NoOfAudit}
            helperText={errors.Natureofcoaching?.message}
          />

          <TextField
            id="ReplyDetails"
            label="Replies sent during this month (Max 500 Character)"
            name="ReplyDetails"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={value.ReplyDetails}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ReplyDetails: false }));
            }}
            error={errValue.ReplyDetails}
            helperText={errors.Noofclassesoffered?.message}
          />

          <TextField
            id="AuditDropped"
            label=" No. of audit objections dropped during the month (B)"
            variant="outlined"
            name="AuditDropped"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            value={value.AuditDropped}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, AuditDropped: false }));
            }}
            error={errValue.AuditDropped}
            helperText={errors.FellowshipName?.message}
          />

          <TextField
            id="PendingAudit"
            label="Pending audit objections (A-B)"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="PendingAudit"      
             value={value.PendingAudit}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, PendingAudit: false }));
            }}
            error={errValue.PendingAudit}
            helperText={errors.NoOfStudent?.message}
          />


         

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
            // onClick={handleLeavepage}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArA6Inside;