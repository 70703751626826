import React from 'react'
import '../Studentguides.css';
import { TextField, Grid, InputLabel, Select, MenuItem, DialogActions } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

// import { Activetiesvalidation } from '../Validation';
import { FormControl } from 'react-bootstrap';
import { Box } from 'react-bootstrap-icons';

function StudentDetalis({ head }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = React.useState(false);

    const [Studentbtn, setStudentbtn] = useState([])

    const [studentId, setStudentId,] = useState("")
    const [studentName, setStudentName] = useState("")
    const [courseType, setCourseType] = useState("")
    const [thesistitle, setThesistitle] = useState("")
    const [fllowshipdetails, setFellowshipdetails] = useState("")
    const [thesissubmission, setThesissubmission] = useState("")




    const [noofmeetings, setNoofmeetings] = useState("")
    const [instituteId, setInstituteId] = useState("")
    const [internshipPlan, setInternshipPlan] = useState("")
    const [progress, setProgress] = useState("")






    const [token, setToken] = useState("");
    const [id, setId] = useState(0);


    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        reset,
        watch,
    } = useForm({
        // resolver: yupResolver(Activetiesvalidation),
    });

    useEffect(() => {
        const tok = localStorage.getItem("token")
        setToken(tok);
        console.log(tok);
        if (tok != null && tok != undefined) {
            handleDirectorate(tok);
            

        }
        head("Student")
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
        //  navigate("/Activities")
    };


    const handleClose = () => {
        setOpen(false);
        // setId(0)
    };


    const handleLeavepage = async () => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        console.log(token);

        const d = {

            id,
            StudentId: studentId,
            StudentName: studentName,
            CourseType: courseType,
            Thesistitle: thesistitle,
            Fellowshipdetails: fllowshipdetails,
            Thesissubmission: thesissubmission,

            Noofmeetings: noofmeetings,
            InstituteId: parseInt(instituteId),
            InternshipPlan: internshipPlan,
            Progress: progress,


        }

        try {
            const { data } = await axios.post(`${window.BASE}${window.STUDENTSAVE}`, d, { headers: h });
            console.log(data);
            setStudentbtn(data)
            //   setDirect(data);

        } catch (error) {
            console.log(error);
        }
    };

    const handleDirectorate = async (token) => {
        console.log(token);
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        try {
            const { data } = await axios.get(`${window.BASE}${window.STUDENTALL}`, { headers: h });
            console.log(data);
            // setStudentbtn(data);
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div>

            <form className='from-mains' onSubmit={handleSubmit(handleLeavepage)}>
                <div className=' col-lg-6 col-md-6 col-sm-12 Activies-main'>


                    <TextField id="outlined-basic"
                        label="student Name"
                        variant="outlined"
                        className='studentnames'
                        style={{ width: 600 }}

                        // onChange={dateFormat}

                        // onChange={(e) => setRptDate(e.target.value)}
                        {...register("dateActi")}
                        error={errors.dateActi ? true : false}
                        helperText={errors.dateActi?.message}
                        onChange={(e) => setStudentName(e.target.value)}
                    />

                    <button className='top-buttons' onClick={handleClickOpen}>
                        <AccountBalanceIcon style={{ marginBottom: -5 }} className="sidebar-icon" />

                    </button>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"


                >
                    <DialogTitle id="scroll-dialog-title">

                        <div className="dialogueTittle">
                            <div>
                                <h5>Student</h5>
                            </div>
                            <div onClick={handleClose}>
                                <CloseIcon

                                    className="dialogue_close_icon"
                                />

                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent className='Dialog-box' style={{ padding: 0 }}>

                        <div className="manu-form ">
                            <div className='box width-50'>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Student Name"
                                    // value={courseNo}
                                    variant="outlined"
                                    size='small'
                                // onChange={(e) => setCourseNo(e.target.value)}

                                />

                            </div>

                            <div className='box width-50'>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Student Id"

                                    variant="outlined"
                                    size='small'
                                    value={courseType}
                                // onChange={(e) => setCourseType(e.target.value)}

                                />


                            </div>

                            <div className='box width-50'>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="button">

                        <button ></button>
                        <button variant="outlined" onClick={handleLeavepage} className='btn1'>Save</button>

                    </DialogActions>
                </Dialog>
                <div className='form-control'>

                    <h5 style={{ width: 150, margin: 20 }}>A5. Student Guidance</h5>
                    {/* <TextField id="outlined-basic" 
              label="Date" variant="outlined" 
              // onChange={(e) => setRptDate(e.target.value)}
               />
              <TextField id="outlined-basic" 
              label="Course Name" 
              variant="outlined" 
              // onChange={(e) => setCourseId(e.target.value)} 
              />  */}
                    {/* <TextField id="outlined-basic"
          label="Pg/ph.D"
          variant="outlined"
          style={{ margin: '10px',width:"40%" }}


          {...register("HourseActi")}
          error={errors.HourseActi ? true : false}
          helperText={errors.HourseActi?.message}
          onChange={(e) => setHours(e.target.value)}
        /> */}

                    <select
                        id="institute-select"
                        // value={selectedInstituteId}
                        onChange={(e) => setCourseType(e.target.value)}
                        style={{ margin: '10px', width: "40%" }}
                    >
                        <option value=""> Course</option>
                        <option value="1">PG</option>
                        <option value="2">Ph.D</option>

                        {/* Add more options as needed */}
                    </select>

                    <TextField id="outlined-basic"
                        label="Thesis title"
                        variant="outlined"
                        style={{ margin: '10px', width: "40%" }}

                        {...register("CoursetypeidActi")}
                        error={errors.CoursetypeidActi ? true : false}
                        helperText={errors.CoursetypeidActi?.message}

                        onChange={(e) => setThesistitle(e.target.value)}
                    />

                    <TextField id="outlined-basic"
                        label="Fellowship details"
                        variant="outlined"
                        style={{ margin: '10px', width: "40%" }}

                        {...register("CourseTeacherActiveties")}
                        error={errors.CourseTeacherActiveties ? true : false}
                        helperText={errors.CourseTeacherActiveties?.message}

                        onChange={(e) => setFellowshipdetails(e.target.value)}
                    />
                    <TextField id="outlined-basic"
                        label="Thesis submission"
                        variant="outlined"
                        style={{ margin: '10px', width: "40%" }}

                        {...register("classeActi")}
                        error={errors.classeActi ? true : false}
                        helperText={errors.classeActi?.message}

                        onChange={(e) => setThesissubmission(e.target.value)}
                    />

                    <h5 style={{ width: 250, margin: 20 }}>A6.Advisory Committee Chairman:</h5>
                    <TextField id="outlined-basic"
                        label="No. of meetings"
                        variant="outlined"
                        style={{ margin: '10px', width: "40%" }}

                        {...register("SlowlearnActi")}
                        error={errors.SlowlearnActi ? true : false}
                        helperText={errors.SlowlearnActi?.message}

                        onChange={(e) => setNoofmeetings(e.target.value)}
                    />

                    <TextField id="outlined-basic"
                        label="International Institute"
                        variant="outlined"
                        style={{ margin: '10px', width: "40%" }}

                        {...register("weeknesActi")}
                        error={errors.weeknesActi ? true : false}
                        helperText={errors.weeknesActi?.message}

                        onChange={(e) => setInstituteId(e.target.value)}
                    />

                    <TextField id="outlined-basic"
                        label="Plan for
          the
          internship"
                        variant="outlined"
                        style={{ margin: '10px', width: "40%" }}

                        {...register("AddtionActiveties")}
                        error={errors.AddtionActiveties ? true : false}
                        helperText={errors.AddtionActiveties?.message}

                        onChange={(e) => setInternshipPlan(e.target.value)}
                    />

                    <TextField id="outlined-basic"
                        label="Progress"
                        variant="outlined"
                        style={{ margin: '10px', width: "40%" }}

                        {...register("PassActi")}
                        error={errors.PassActi ? true : false}
                        helperText={errors.PassActi?.message}

                        onChange={(e) => setProgress(e.target.value)}
                    />




                    {/* {dirr?.map((row) => {
            return (

              <MenuItem value={row.id}>{row.instituteName}</MenuItem>

            )
          })} */}





                    <div className='from-last'>
                        <button type="submit" className='deactivate-buttons' >Delete</button>
                        <button type="submit" className='sbmit-buttons' onClick={handleLeavepage}>Submit</button>
                    </div>

                    {/* <TableBody>
                        {Studentbtn?.map((row) => (
                            <TableRow
                                key={row.studentName}

                            >
                                <TableCell align="right">{row.studentName}</TableCell>
                                <TableCell align="right">{row.courseType}</TableCell>
                                <TableCell align="right"> {row.thesistitle}</TableCell>
                                <TableCell align="right">{row.fellowshipdetails}</TableCell>
                                <TableCell align="right"> {row.thesissubmission}</TableCell>
                                <TableCell align="right">{row.noofmeetings}</TableCell>

                                <TableCell align="right"> {row.instituteId}</TableCell>
                                <TableCell align="right">{row.internshipPlan}</TableCell>
                                <TableCell align="right"> {row.progress}</TableCell>


                            </TableRow>
                        ))}
                    </TableBody> */}
                    <TableBody>
  {Array.isArray(Studentbtn) &&
    Studentbtn.map((row) => (
      <TableRow key={row.studentName}>
        <TableCell align="right">{row.studentName}</TableCell>
        <TableCell align="right">{row.courseType}</TableCell>
        <TableCell align="right">{row.thesistitle}</TableCell>
        <TableCell align="right">{row.fellowshipdetails}</TableCell>
        <TableCell align="right">{row.thesissubmission}</TableCell>
        <TableCell align="right">{row.noofmeetings}</TableCell>
        <TableCell align="right">{row.instituteId}</TableCell>
        <TableCell align="right">{row.internshipPlan}</TableCell>
        <TableCell align="right">{row.progress}</TableCell>
      </TableRow>
    ))}
</TableBody>

                </div>
            </form>
        </div>
    )
}

export default StudentDetalis
