import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D1VALI } from "../../../Validation";

// import { Activetiesvalidation } from '../Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";
// import IncomeGenerated from "./IncomeGenerated";

function ArF1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Nameofitem, setNameofitem] = useState("");
  const [Nameofcrop, setNameofcrop] = useState("");
  const [Specifycity, setSpecifycity] = useState("");
  // --------------------------------------------------------
  const [Previosebalance, setPreviosebalance] = useState("");
  const [QProduced, setQProduced] = useState("");
  const [Quantitysold, setQuantitysold] = useState("");
  const [Balance, setBalance] = useState("");

  const [total, setTotal] = useState(0)
  // --------------------------------------------------------
  const [IncomeGenerated, setIncomeGenerated] = useState("");
  const [loader, setLoader] = useState(true);
  const [NameofitemErr, setNameofitemErr] = useState(false);
  const [NameofcropErr, setNameofcropErr] = useState(false);
  const [SpecifycityErr, setSpecifycityErr] = useState(false);
  const [PreviosebalanceErr, setPreviosebalanceErr] = useState(false);
  const [QProducedErr, setQProducedErr] = useState(false);
  const [QuantitysoldErr, setQuantitysoldErr] = useState(false);
  const [BalanceErr, setBalanceErr] = useState(false);
  const [IncomeGeneratedErr, setIncomeGeneratedErr] = useState(false);


  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti, setInsti] = useState();
  const [dropcourse, setDropcourse] = useState([]);
  const [dropnatcoching, setDropnatcoching] = useState([]);
  const [Lovspecifycity, setLovspecifycity] = useState([]);
  const [product, setProduct] = useState([]);
  const [listAll, setListAll] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [cropN, setCropN] = useState([])

  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState({
    ProductType: "",
    CropId: "",
    Variety: "",
    VarietyType: "",
    SeedQtyProduced: "",
    SeedQtyDistributed: "",
    SeedQtyDistributedMonth: "",
    SeedQtyBalance: "",
    BioInputProduced: "",
    BioInputDistributed: "",
    BioInputBalance: ""
  });

  const [errValue, setErrValue] = useState({
    ProductType: false,
    CropId: false,
    Variety: false,
    VarietyType: false,
    SeedQtyProduced: false,
    SeedQtyDistributed: false,
    SeedQtyDistributedMonth: false,
    SeedQtyBalance: false,
    BioInputProduced: false,
    BioInputDistributed: false,
    BioInputBalance: false
  });



  const handleFirstChange = (e) => {
    setPreviosebalance();
  };

  const handleSecondChange = (e) => {
    setQProduced();
  };

  const handleThirdChange = (e) => {
    setQuantitysold();
  };

  const calculateResult = () => {
    const additionResult = parseFloat(Previosebalance) + parseFloat(QProduced);
    const subtractionResult = additionResult - parseFloat(Quantitysold);
    setBalance(subtractionResult);
  };


  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D1VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleCrop(tok)
      handleProductyType(tok)
      handleLov(tok);
      handleCoursetypes(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("  Production and distribution of seeds / seedlings / Bio-inputs");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
  }, []);

  const edit = (item) => {
    console.log(item);
    //     instituteName
    // productType
    // cropName
    // variety
    // varietyType
    // seedQtyProduced
    // seedQtyDistributed
    // seedQtyDistributedMonth
    // seedQtyBalance
    // bioInputProduced
    // bioInputDistributed
    // bioInputBalance
    setValue({
      ProductType: item.productType,
      CropId: item.cropId,
      Variety: item.variety,
      VarietyType: item.varietyType,
      SeedQtyProduced: item.seedQtyProduced,
      SeedQtyDistributed: item.seedQtyDistributed,
      SeedQtyDistributedMonth: item.seedQtyDistributedMonth,
      SeedQtyBalance: item.seedQtyBalance,
      BioInputProduced: item.bioInputProduced,
      BioInputDistributed: item.bioInputDistributed,
      BioInputBalance: item.bioInputBalance
    })

    setId(item.id);
  };

  const handleCrop = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.CROPGETALL}`, {
        headers: h,
      });
      console.log(data);
      setCropN(data);
    } catch (error) {
      console.log(error);
    }
  };


  const handleData = ((val, name) => {
    // const {name,value}=event.target
    setValue(prev => ({
      ...prev,
      [name]: val
    }))
    let amt = '';
    let disMonth = value.SeedQtyDistributedMonth.length > 0;
    let distribute = value.SeedQtyDistributed.length > 0;
    let produced = value.SeedQtyProduced.length > 0;
    let add = (disMonth ? parseInt(value.SeedQtyDistributedMonth) : 0) + (distribute ? parseInt(value.SeedQtyDistributed) : 0)
    let pro = produced ? parseInt(value.SeedQtyProduced) : 0;
    let vv = val.length > 0 ? parseInt(val) : 0;
    if (name === 'SeedQtyProduced') {
      console.log(val, value.SeedQtyDistributedMonth, value.SeedQtyDistributed, add);
      amt = vv - add
      setValue(prev => ({
        ...prev,
        SeedQtyBalance: amt
      }))
    }
    if (name === "SeedQtyDistributed") {
      let dis = disMonth ? parseInt(value.SeedQtyDistributedMonth) : 0
      amt = (pro) - (parseInt(vv) + dis);
      console.log(amt);
      setValue(prev => ({
        ...prev,
        SeedQtyBalance: amt
      }))
    }
    if (name === "SeedQtyDistributedMonth"
    ) {
      let dis = distribute ? parseInt(value.SeedQtyDistributed) : 0
      amt = (pro) - (parseInt(vv) + dis);
      console.log(amt);
      setValue(prev => ({
        ...prev,
        SeedQtyBalance: amt
      }))
    }






  })

  // Lov Specifycity
  const handleProductyType = async (token, NameOfItems) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"NameOfItems"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setProduct(data);
    } catch (error) {
      console.log(error);
    }
  };


  // Lov
  const handleLov = async (token, NameOfItems) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"NameOfItems"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnatcoching(data);
    } catch (error) {
      console.log(error);
    }
  };

  // dropdown
  const handleCoursetypes = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.CROPGETALL}`, {
        headers: h,
      });
      console.log(data);

      setDropcourse(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const backs = () => {
    navigate("/ArF1");
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    
    const d = {
      id,
      ProductType: value.ProductType,
      CropId: parseInt(value.CropId),
      Variety: value.Variety,
      VarietyType: value.VarietyType ==="" ? "0":  value.VarietyType,
      SeedQtyProduced: value.SeedQtyProduced==="" ? "0":  value.SeedQtyProduced,
      SeedQtyDistributed: value.SeedQtyDistributed==="" ? "0":  value.SeedQtyDistributed,
      SeedQtyDistributedMonth: value.SeedQtyDistributedMonth==="" ? "0":  value.SeedQtyDistributedMonth,
      SeedQtyBalance: value.SeedQtyBalance==="" ? "0":  value.SeedQtyBalance,
      BioInputProduced: value.BioInputProduced==="" ? "0":  value.BioInputProduced,
      BioInputDistributed: value.BioInputDistributed==="" ? "0":  value.BioInputDistributed,
      BioInputBalance: value.BioInputProduced - value.BioInputDistributed,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF1SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setListAll(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF1");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF1EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF1");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = (e) => {
    e.preventDefault()
    if (!value.ProductType) {
      setErrValue((prev) => ({ ...prev, ProductType: true }))
      document.getElementById("ProductType").focus()
    } else if (!value.CropId) {
      setErrValue((prev) => ({ ...prev, CropId: true }))
      document.getElementById("CropId").focus()
    } else if (!value.Variety) {
      setErrValue((prev) => ({ ...prev, Variety: true }))
      document.getElementById("Variety").focus()
    }

    else {
      handleLeavepage()
    }

  };

  //calculation All Onchange

  // useEffect(() => {
  //   console.log("Previosebalance ======= ",Previosebalance)
  //   let prebal = Previosebalance ? parseInt(Previosebalance) : 0;
  //   let proceed = QProduced ? parseInt(QProduced) : 0;
  //   let minus = Quantitysold ? parseInt(Quantitysold) : 0;
  //   let total = ( prebal + proceed) - minus

  //   console.log("QProduced =====",QProduced);
  //   setBalance(total)
  // }, [Previosebalance,QProduced,Quantitysold]);

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>F1. Production and distribution of Seeds / Seedlings / Bio-inputs</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            F1. Production and distribution of Seeds / Seedlings / Bio-inputs
          </h5>

          {/* <TextField
            
            id="ProductType"
            label="Seeds/ Seedlings/Bio- inputs"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="ProductType"
            error={errValue.ProductType}
            value={value.ProductType}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ProductType: false }));
            }}
          /> */}

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={errValue.ProductType}
            id="ProductType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Seeds/ Seedlings/Bio- inputs
            </InputLabel>
            <Select
              variant="outlined"
              // label="Rating Type"
              style={{ height: "45px" }}
              name="ProductType"
              sx={{ width: "100%" }}
              value={value.ProductType}
              onChange={(e) => {
                handleData(e.target.value, e.target.name);
                setErrValue((prev) => ({ ...prev, ProductType: false }));
              }}
            >
              {product?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
            
            id="CropId"
            label="Crop Id"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="CropId"
            error={errValue.CropId}
            value={value.CropId}
            onChange={(e) => {
              handleData(e.target.value,e.target.name);
              setErrValue((prev) => ({ ...prev, CropId: false }));
            }}
          /> */}
          <FormControl
            style={{ width: "48%", margin: 12 }}
            id="CropId"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Crop
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="CropId"
              label=" Position Scheme "
              size="small"
              sx={{ width: "100%" }}
              error={errValue.CropId}
              value={value.CropId}
              onChange={(e) => {
                handleData(e.target.value, e.target.name);
                setErrValue((prev) => ({ ...prev, CropId: false }));
              }}
            >
              {cropN?.map((row, index) => {
                return (
                  <MenuItem key={row.index} value={row.id}>
                    {row.cropName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField


            label="Variety"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Variety"
            id="Variety"
            error={errValue.Variety}
            value={value.Variety}
            onChange={(e) => {
              handleData(e.target.value, e.target.name);
              setErrValue((prev) => ({ ...prev, Variety: false }));
            }}
          />

          <TextField

            id="VarietyType"
            label="BS/FS/ CS/TL"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="VarietyType"
            error={errValue.VarietyType}
            value={value.VarietyType}
            onChange={(e) => {
              handleData(e.target.value, e.target.name);
              setErrValue((prev) => ({ ...prev, VarietyType: false }));
            }}
          />

          <TextField

            id="SeedQtyProduced"
            label="Quantity produced"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="SeedQtyProduced"
            error={errValue.SeedQtyProduced}
            value={value.SeedQtyProduced}
            onChange={(e) => {
              handleData(e.target.value, e.target.name);
              setErrValue((prev) => ({ ...prev, SeedQtyProduced: false }));
            }}
          />

          <TextField

            id="SeedQtyDistributed"
            label="Qty distributed"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="SeedQtyDistributed"
            error={errValue.SeedQtyDistributed}
            value={value.SeedQtyDistributed}
            onChange={(e) => {
              handleData(e.target.value, e.target.name);
              setErrValue((prev) => ({ ...prev, SeedQtyDistributed: false }));
            }}
          />

          <TextField

            id="SeedQtyDistributedMonth"
            label="Quantity distributed in this month"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="SeedQtyDistributedMonth"
            error={errValue.SeedQtyDistributedMonth}
            value={value.SeedQtyDistributedMonth}
            onChange={(e) => {
              handleData(e.target.value, e.target.name);
              setErrValue((prev) => ({ ...prev, SeedQtyDistributedMonth: false }));
            }}
          />

          <TextField

            id="SeedQtyBalance"
            label="Balance"
            variant="outlined"
            disabled
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="SeedQtyBalance"
            error={errValue.SeedQtyBalance}
            value={value.SeedQtyBalance}
            onChange={(e) => {

              setErrValue((prev) => ({ ...prev, SeedQtyBalance: false }));
            }}
          />

          <TextField

            id="BioInputProduced"
            label="Quantity produced"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="BioInputProduced"
            error={errValue.BioInputProduced}
            value={value.BioInputProduced}
            onChange={(e) => {
              handleData(e.target.value, e.target.name);
              setErrValue((prev) => ({ ...prev, BioInputProduced: false }));
            }}
          />

          <TextField

            id="BioInputDistributed"
            label="Quantity distributed"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            name="BioInputDistributed"
            error={errValue.BioInputDistributed}
            value={value.BioInputDistributed}
            onChange={(e) => {
              handleData(e.target.value, e.target.name);
              setErrValue((prev) => ({ ...prev, BioInputDistributed: false }));
            }}
          />

          <TextField

            id="BioInputBalance"
            label="Balance"
            variant="outlined"
            type="number"
            disabled
            style={{ margin: "10px", width: "48%" }}
            name="BioInputBalance"
            error={errValue.BioInputBalance}
            value={value.BioInputProduced - value.BioInputDistributed}
            onChange={(e) => {

              setErrValue((prev) => ({ ...prev, BioInputBalance: false }));
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" >
              Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </form>
    </div>
  );
}

export default ArF1Inside;