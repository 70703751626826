import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A5VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArD5Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [facultyName, setFacultyName] = useState("");
  const [eventName, setEventName] = useState("");
  const [noOfdays, setNoOfdays] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventType, setEventType] = useState("");
  const [organizedby, setOrganizedby] = useState("");
  const [sponsor, setSponsor] = useState("");

  const [facultyNameErr, setFacultyNameErr] = useState(false);
  const [eventNameErr, setEventNameErr] = useState(false);
  const [noOfdaysErr, setNoOfdaysErr] = useState(false);
  const [eventDateErr, setEventDateErr] = useState(false);
  const [eventTypeErr, setEventTypeErr] = useState(false);
  const [organizedbyErr, setOrganizedbyErr] = useState(false);
  const [sponsorErr, setSponsorErr] = useState(false);
  const [loader,setLoader] =useState(true);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();
  const [inves, setInves] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [check, setCheck] = useState(0);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A5VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
    
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student Guidance");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute);
  }, []);

  const backs = () => {
    navigate("/ArD5");
  };
  
  const edit = (item) => {
    console.log(item);
    setFacultyName(item.investigatorId);
    setEventName(item.eventName);
    setNoOfdays(item.noOfdays);
    setEventDate(item.eventdate);
    setEventType(item.eventType);
    setOrganizedby(item.organizedby);
    setSponsor(item.sponsor);
    setId(item.id);
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: facultyName,
      EventName: eventName,
      NoOfdays: noOfdays,
      Eventdate: eventDate,
      EventType: eventType,
      Organizedby: organizedby,
      Sponsor: sponsor,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD5SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArD5");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD5EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArD5");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!facultyName) {
      setFacultyNameErr(true);
      document.getElementById("facultyName").focus();
    } else if (!eventName) {
      setEventNameErr(true);
      document.getElementById("eventName").focus();
    } else if (!noOfdays) {
      setNoOfdaysErr(true);
      document.getElementById("noOfdays").focus();
    } else if (!eventDate) {
      setEventDateErr(true);
      document.getElementById("eventDate").focus();
    } else if (!eventType) {
      setEventTypeErr(true);
      document.getElementById("eventType").focus();
    } else if (!organizedby) {
      setOrganizedbyErr(true);
      document.getElementById("organizedby").focus();
    } else if (!sponsor) {
      setSponsorErr(true);
      document.getElementById("sponsor").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>
            D5. Conference / Seminar / Symposium / Training / Workshop attended{" "}
          </span>
        </div>
      </div>
      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control" style={{ marginTop: 50 }}>
        <h5 style={{ margin: 20 }}>
          {" "}
          D5. Conference / Seminar / Symposium / Training / Workshop attended
        </h5>

        <FormControl
          style={{ width: "45%", margin: 12 }}
          error={facultyNameErr}
          id="facultyName"
          size="small"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Faculty Name
          </InputLabel>
          <Select
            variant="outlined"
            size="small"
            style={{ height: "45px" }}
            value={facultyName}
            onChange={(e) => {
              setFacultyName(e.target.value);
              setFacultyNameErr(false);
            }}
          >
            {inves?.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {option.investigatorName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          error={eventNameErr}
          id="eventName"
          label="Event Name (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={eventName}
          onChange={(e) => {
            setEventName(e.target.value);
            setEventNameErr(false);
          }}
        />

        <TextField
          error={noOfdaysErr}
          id="noOfdays"
          label="No. of days (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={noOfdays}
          onChange={(e) => {
            setNoOfdays(e.target.value);
            setNoOfdaysErr(false);
          }}
        />

        <TextField
          error={eventDateErr}
          id="eventDate"
          label="Event Date(s)"
          variant="outlined"
          type="date"
          style={{ margin: "10px", width: "48%" }}
          value={eventDate}
          onChange={(e) => {
            setEventDate(e.target.value);
            setEventDateErr(false);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            placeholder: "",
          }}
        />

        <FormControl
          style={{ width: "45%", margin: 12 }}
          error={eventTypeErr}
          id="eventType"
          size="small"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Event Type
          </InputLabel>
          <Select
            variant="outlined"
            size="small"
            style={{ height: "45px" }}
            value={eventType}
            onChange={(e) => {
              setEventType(e.target.value);
              setEventTypeErr(false);
            }}
          >
            <MenuItem value="Online">Online</MenuItem>
            <MenuItem value="Offline">Offline</MenuItem>
          </Select>
        </FormControl>

        <TextField
          error={organizedbyErr}
          id="organizedby"
          label="Organized by (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={organizedby}
          onChange={(e) => {
            setOrganizedby(e.target.value);
            setOrganizedbyErr(false);
          }}
        />

        <TextField
          error={sponsorErr}
          id="sponsor"
          label="Sponsor (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={sponsor}
          onChange={(e) => {
            setSponsor(e.target.value);
            setSponsorErr(false);
          }}
        />

        <div className="from-last">
          <button
            type="submit"
            style={{
              backgroundColor: "#008CBA",
              padding: "5px",
              borderRadius: "3px",
              width: "80px",
              color: "#fff",
            }}
            onClick={backs}
          >
            Back
          </button>
          <button
            type="submit"
            className="sbmit-buttons"
            onClick={formValidateFun}
            style={{ paddingTop: "5px" }}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArD5Inside;
