import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MasterDataFaculty.css";
import { FaHandshake } from "react-icons/fa";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import WorkIcon from "@mui/icons-material/Work";
import Directorate from "../../../Images/directorate.png";
import Department from "../../../Images/Department.png";
import StationCategory from "../../../Images/Category.png";
import Faculty from "../../../Images/Faculty.jpeg";
import Institution from "../../../Images/institution.png";
import FundingAgency from "../../../Images/FundingAgency.png";
import Course from "../../../Images/Course.png";
import Crop from "../../../Images/Crop.png";
import LOV from "../../../Images/LOV.png";
import Leave from "../../../Images/Leave.png";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

const intitute = {
  width: "90px",
};

const department = {
  width: "79px",
  top: "3px",
};

const fac = {
  width: "60px",
  top: "10px",
};

function MasterDataFaculty({ head }) {
  const navigate = useNavigate();

  const monthlyR = () => {
    // navigate("/monthyReport");
    navigate("/reportsystem");

  };

  const myprofile = () => {
    navigate("/myprofile")
  }



  useEffect(() => {
    // head("Master Data Management")
    setLoader(false)
  }, []);
  const [loader, setLoader] = useState(true)
  return (
    <>
      <div className="master-container" style={{ marginTop: 60 }}>
        <ComponentLoader isLoading={loader} />
        <div className="master-main d-flex">
          <div className="head-icon">
            <Link to="/monthyReport">
              <HomeIcon style={{ color: "white", marginLeft: 70 }} />
            </Link>
          </div>
          <div className="master-main-span">
            <span>Digital Reporting System</span>
          </div>
        </div>

        <div className="master-grid">
          {/* <div className='master-grid-header'><span>Tracks</span></div> */}
          <div className="inner-master-grid">
            { }
            <div  style={{ background: "none",border:"none",boxShadow:"none" }}>

            </div>

            <div className="faculty-new-page"  style={{marginTop:100}}>
              <div className="inner-head">
                <div
                  className="inner-head-bottom-grid"
                  style={{ background: "rgb(166,140,213)" }}
                >
                  <h6>Report Online</h6>
                </div>
              </div>
              <div className="inner-middle">

                <div className="inner-midle-grid">
                  <div onClick={monthlyR}>
                    <img src={Leave} alt="Leave" className="img" style={fac} />
                    <span style={{ textAlign: "center" }}>Online Report</span>
                  </div>
                </div>
                <div className="inner-midle-grid">
                  <div onClick={myprofile}>
                    <img
                      src={Faculty}
                      alt="Faculty"
                      className="img"
                      style={fac}
                    />
                    <span>My Profile</span>
                  </div>
                </div>

                <div className="inner-midle-grid">
                  <div
                    style={{
                      background: "none",
                      border: "none",
                      boxShadow: "none",
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div style={{ background: "none",border:"none",boxShadow:"none" } }>

            </div>
          </div>
        </div>
        <div className="dashboard-footer2">
        <div style={{ paddingLeft: 40 }}>@ 2024 Directorate Of Rice Research Institude</div>
        <div style={{ paddingRight: 40 }} className="banner-footer1">Powered by <a href="https://thepikture.com/" target="blank">Pikture Associates</a></div>
      </div>
      </div>
    </>
  );
}

export default MasterDataFaculty;
