import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A5VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArD4Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true);
  // Authors,ArticleDate,ArticleTitle,MagazineNewspaper
  const [authors, setAuthors] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleDate, setArticleDate] = useState("");
  const [magazineNewspaper, setMagazineNewspaper] = useState("");

  const [authorsErr, setAuthorsErr] = useState(false);
  const [articleTitleErr, setArticleTitleErr] = useState(false);
  const [articleDateErr, setArticleDateErr] = useState(false);
  const [magazineNewspaperErr, setMagazineNewspaperErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [Lovrole, setLovrole] = useState([]);
  const [LovroleParam, setLovroleParam] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [check, setCheck] = useState(0);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A5VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student Guidance");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);setLoader(false);

  }, []);

  const backs = () => {
    navigate("/ArD4");
  };

  const edit = (item) => {
    console.log(item);
    setAuthors(item.authors);
    setArticleTitle(item.articleTitle);
    setArticleDate(moment(item.articleDate).format("YYYY-MM-DD"));
    setMagazineNewspaper(item.magazineNewspaper);
    setId(item.id);
  };
 
  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      Authors: authors,
      ArticleDate: articleDate,
      ArticleTitle: articleTitle,
      MagazineNewspaper: magazineNewspaper,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD4SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArD4");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD4EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArD4");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  
  const formValidateFun = () => {
    if (!authors) {
      setAuthorsErr(true);
      document.getElementById("authors").focus();
    } else if (!articleDate) {
      setArticleDateErr(true);
      document.getElementById("articleDate").focus();
    } else if (!articleTitle) {
      setArticleTitleErr(true);
      document.getElementById("articleTitle").focus();
    } else if (!magazineNewspaper) {
      setMagazineNewspaperErr(true);
      document.getElementById("magazineNewspaper").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>D4. Popular articles</span>
        </div>
      </div>
      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control" style={{ marginTop: 50 }}>
        <h5 style={{ margin: 20 }}>
          {" "}
          D4. Popular articles
        </h5>

        <TextField
          error={authorsErr}
          id="authors"
          label="Authors (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={authors}
          onChange={(e) => {
            setAuthors(e.target.value);
            setAuthorsErr(false);
          }}
        />

        <TextField
          error={articleDateErr}
          id="Article Date"
          label="Date"
          type="date"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={articleDate}
          onChange={(e) => {
            setArticleDate(e.target.value);
            setArticleDateErr(false);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            placeholder: "",
          }}
        />

        <TextField
          error={articleTitleErr}
          id="articleTitle"
          label="Title of the article (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={articleTitle}
          onChange={(e) => {
            setArticleTitle(e.target.value);
            setArticleTitleErr(false);
          }}
        />

        <TextField
          error={magazineNewspaperErr}
          id="magazineNewspaper"
          label="Magazine / Newspaper (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={magazineNewspaper}
          onChange={(e) => {
            setMagazineNewspaper(e.target.value);
            setMagazineNewspaperErr(false);
          }}
        />

       

        <div className="from-last">
          <button
            type="submit"
            style={{
              backgroundColor: "#008CBA",
              padding: "5px",
              borderRadius: "3px",
              width: "80px",
              color: "#fff",
            }}
            onClick={backs}
          >
            Back
          </button>
          <button
            type="submit"
            className="sbmit-buttons"
            onClick={formValidateFun}
            style={{ paddingTop: "5px" }}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArD4Inside;