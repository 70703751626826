import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { A6VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../../ComponentLoader";

function A6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [studentName, setStudentName] = useState("");
  const [noofmeetings, setNoofmeetings] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [internshipPlan, setInternshipPlan] = useState("");
  const [progress, setProgress] = useState("");
  const [loader,setLoader] =useState(true);
  const [dirr, setDepartment] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [state,setState] = useState();
  const [assM,setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(A6VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
      handleInstituteName(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Advisory Committee Chairman");
  }, []);
  useEffect(()=>{
    const objectString = localStorage.getItem('MReport');
    const myObject = JSON.parse(objectString);
    if(myObject !==null){
      setState(myObject)
    }
    const Month = localStorage.getItem('Month');
    if(Month !==null){
      setAssMonth(Month)
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  },[])

  const backs = () => {
    navigate("/A6")
}

  const edit = (item) => {
    console.log(item);
    setStudentName(item.studentName);
    setId(item.id);
    setValue("StudentNamev", item.studentName)
    setValue("Noofmeetingsv", item.noofmeetings)
    setValue("InstituteIdv", item.institute)
    setValue("InternshipPlanv", item.internshipPlan)
    setValue("Progressv", item.progress)
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleInstituteName = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INSTITUTES}`, {
        headers: h,
      });
      console.log(data);
      setDepartment(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleLeavepage = async (data) => {
    console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      StudentName: data.StudentNamev,
      Noofmeetings: data.Noofmeetingsv,
      Institute: data.InstituteIdv,
      InternshipPlan: data.InternshipPlanv,
      Progress: data.Progressv,
      MRSId:state.id,
      investigatorId:parseInt(invis),
      rptMonth:parseInt(assM)
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted")
          navigate("/A6")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A6EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted")
          navigate("/A6")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.A6GETALL}`, {
        headers: h,
      });
      console.log(data); setLoader(false);
      // setStudentbtn(data);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Advisory commitee  Chairman (A6)</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>

        <div className="form-control">
          <h5 style={{ width: 400, margin: 20 }}>
            A6.Details Of Advisory commitee meetings 
          </h5>

          <TextField
            id="outlined-basic"
            label="Student Name"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={studentName}
            // onChange={(e) => setStudentName(e.target.value)}

            {...register("StudentNamev")}
            error={errors.StudentNamev ? true : false}
            helperText={errors.StudentNamev?.message}
          />

          <TextField
            id="outlined-basic"
            label="No Of Meetings conducted"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Natureofcoaching}

            // onChange={(e) => setNoofmeetings(e.target.value)}

            {...register("Noofmeetingsv")}
            error={errors.Noofmeetingsv ? true : false}
            helperText={errors.Noofmeetingsv?.message}
          />

          {/* <FormControl style={{ width: "48%", margin: 12 }}>
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Course Id
            </InputLabel>

            <Select
          
              labelId="demo-simple-select-label"
              id="demo-simple-select"

              // value={age}
              label="Institute Id"
              size="small"
              sx={{ width: "100%" }}
              // onChange={handleChange}
              // value={instituteId}

              // onChange={(e) => setInstituteId(e.target.value)}

              {...register("InstituteIdv")}
              error={errors.InstituteIdv ? true : false}
              helperText={errors.InstituteIdv?.message}
            >
              {dirr?.map((row) => {
                return (
                  <MenuItem value={row.id}>
                    {row.instituteName}
                  </MenuItem>
                );
              })}
            </Select>
            </FormControl> */}

            <TextField
              id="outlined-basic"
              label="institute identified for thesis work (Max 500 Character)"
              variant="outlined"
              style={{ margin: "10px", width: "48%" }}
              // value={Noofclassesoffered}

              // onChange={(e) => setInternshipPlan(e.target.value)}
              {...register("InstituteIdv")}
              error={errors.InstituteIdv ? true : false}
              helperText={errors.InstituteIdv?.message}
            />

            <TextField
              id="outlined-basic"
              label="Internship planned  (Max 500 Character)"
              variant="outlined"
              style={{ margin: "10px", width: "48%" }}
              // value={Noofclassesoffered}

              // onChange={(e) => setInternshipPlan(e.target.value)}

              {...register("InternshipPlanv")}
              error={errors.InternshipPlanv ? true : false}
              helperText={errors.InternshipPlanv?.message}
            />

            <TextField
              id="outlined-basic"
              label="Progress (Max 500 Character)"
              variant="outlined"
              style={{ margin: "10px", width: "48%" }}
              // value={Noofclassesoffered}

              // onChange={(e) => setProgress(e.target.value)}

              {...register("Progressv")}
              error={errors.Progressv ? true : false}
              helperText={errors.Progressv?.message}
            />

            <div className="from-last">
            <button type="submit" style={{ backgroundColor: "#008CBA", height: "20px" }} onClick={backs}>Back</button>
              <button
                type="submit"
                className="sbmit-buttons"

              >
                Save
              </button>
            </div>
        </div>
      </form>
    </div>
  );
}

export default A6Inside;
