import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { B14VALIDATION } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArC1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true)
  const [investigatorName,setInvestigatorName] = useState("")
  const [cropName,setCropName] = useState("")
  const [variety,setVariety] = useState("")
  const [specialFuture,setSpecialFuture] = useState("")
  const [status,setStatus] = useState("")
  const [tentativeTimeLine,setTentativeTimeLine] = useState("")

  const [investigatorNameErr,setInvestigatorNameErr] = useState(false)
  const [cropNameErr,setCropNameErr] = useState(false)
  const [varietyErr,setVarietyErr] = useState(false)
  const [specialFutureErr,setSpecialFutureErr] = useState(false)
  const [statusErr,setStatusErr] = useState(false)
  const [tentativeTimeLineErr,setTentativeTimeLineErr] = useState(false)
 
  const [inves,setInves] = useState([])
  const [cropN,setCropN] = useState([])
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B14VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
    
      handleCrop(tok)
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Publications");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute)
  }, []);

  const handleBack = () => {
    navigate("/ArC1");
  };
  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId);
    setCropName(item.cropId);
    setVariety(item.variety);
    setSpecialFuture(item.specialFuture);
    setStatus(item.status);
    setTentativeTimeLine(item.tentativeTimeLine);
    setId(item.id);
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleCrop = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.CROPGETALL}`, {
        headers: h,
      });
      console.log(data);
      setCropN(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };


  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
 
    const d = {
      id,
      InvestigatorId: investigatorName,
      CropId: cropName,
      Variety: variety,
      SpecialFuture: specialFuture,
      Status: status,
      TentativeTimeLine: tentativeTimeLine,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Cr1SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArC1");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Cr1EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully updated");
          navigate("/ArC1");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!investigatorName) {
      setInvestigatorNameErr(true);
      document.getElementById("investigatorName").focus();
    } else if (!cropName) {
      setCropNameErr(true);
      document.getElementById("cropName").focus();
    } else if (!variety) {
      setVarietyErr(true);
      document.getElementById("variety").focus();
    } else if (!specialFuture) {
      setSpecialFutureErr(true);
      document.getElementById("specialFuture").focus();
    } else if (!status) {
      setStatusErr(true);
      document.getElementById("status").focus();
    } else if (!tentativeTimeLine) {
      setTentativeTimeLineErr(true);
      document.getElementById("tentativeTimeLine").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C1. Crop Varieties
 </span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>  C1. Crop Varieties
 </h5>

        <FormControl
          style={{ width: "48%", margin: 12 }}
          error={investigatorNameErr}
          id="investigatorName"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
          Scientists involved
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label=" Position Scheme "
            size="small"
            sx={{ width: "100%" }}
            value={investigatorName}
            onChange={(e) => {
              setInvestigatorName(e.target.value);
              setInvestigatorNameErr(false);
            }}
          >
            {inves?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl
          style={{ width: "48%", margin: 12 }}
          error={cropNameErr}
          id="cropName"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Crop
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label=" Position Scheme "
            size="small"
            sx={{ width: "100%" }}
            value={cropName}
            onChange={(e) => {
              setCropName(e.target.value);
              setCropNameErr(false);
            }}
          >
            {cropN?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.cropName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

          <TextField
            error={varietyErr}
            id="variety"
            label="Variety (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={variety}
            onChange={(e) => {
              setVariety(e.target.value);
              setVarietyErr(false);
            }}
          />
          <TextField
            error={specialFutureErr}
            id="specialFuture"
            label="Special Future (Max 500 Character)" 
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={specialFuture}
            onChange={(e) => {
              setSpecialFuture(e.target.value);
              setSpecialFutureErr(false);
            }}
          />
          <TextField
            error={statusErr}
            id="status"
            label="Status (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setStatusErr(false);
            }}
          />
          <TextField
            error={tentativeTimeLineErr}
            id="tentativeTimeLine"
            label="TentativeTimeLine (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={tentativeTimeLine}
            onChange={(e) => {
              setTentativeTimeLine(e.target.value);
              setTentativeTimeLineErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
              style={{ backgroundColor: "#008CBA",padding:"5px",borderRadius:"3px",width:"80px",color:"#fff" }}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              style={{paddingTop:"5px"}}
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      {/* </form> */}
    </div>
  );
}

export default ArC1Inside;
