import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { C2VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArC9Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [funding,setfunding] = useState([])
   
  const [plsName,setPlsName] = useState("")
  const [projectName,setProjectName] = useState("")
  const [fundingAgency,setFundingAgency] = useState("")
  const [budjet,setBudjet] = useState("")
  const [startDate,setStartDate] = useState("")
  const [endDate,setEndDate] = useState("")
  const [progress,setProgress] = useState("")
  const [loader,setLoader] =useState(true)
  const [plsNameErr,setPlsNameErr] = useState(false)
  const [projectNameErr,setProjectNameErr] = useState(false)
  const [fundingAgencyErr,setFundingAgencyErr] = useState(false)
  const [budjetErr,setBudjetErr] = useState(false)
  const [startDateErr,setStartDateErr] = useState(false)
  const [endDateErr,setEndDateErr] = useState(false)
  const [progressErr,setProgressErr] = useState(false)

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C2VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleFundingAgency(tok)
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Field days attended");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
  }, []);

  const backs = () => {
    navigate("/ArC9");
  };

  const edit = (item) => {
    console.log(item);
    setPlsName(item.pIsName);
    setProjectName(item.projectName);
    setFundingAgency(item.fundingAgencyId);
    setBudjet(item.budget);
    setStartDate(moment(item.startDate).format("YYYY-MM-DD"));;
    setEndDate(moment(item.endDate).format("YYYY-MM-DD"));;
    setProgress(item.progress);
    setId(item.id);
  };

  
  //Funding Agency //
  const handleFundingAgency = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.FUNDINGGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setfunding(data);setLoader(false)
      }
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      ProjectName: projectName,
      FundingAgencyId: fundingAgency,
      PIsName: plsName,
      Budget: budjet,
      StartDate: startDate,
      EndDate: endDate,
      Progress: progress,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr9SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC9");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr9EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArC9");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!projectName){
      setProjectNameErr(true)
      document.getElementById("projectName").focus()
    }else if(!fundingAgency){
      setFundingAgencyErr(true)
      document.getElementById("fundingAgency").focus()
    }else if(!plsName){
      setPlsNameErr(true)
      document.getElementById("plsName").focus()
    }else if(!budjet){
      setBudjetErr(true)
      document.getElementById("budjet").focus()
    }else if(!startDate){
      setStartDateErr(true)
      document.getElementById("startDate").focus()
    } else if(!endDate){
      setEndDateErr(true)
      document.getElementById("endDate").focus()
    } else if(!progress){
      setStartDateErr(true)
      document.getElementById("progress").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C9. Bio-Efficacy Project
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
          C9. Bio-Efficacy Project

          </h5>


          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={fundingAgencyErr}
            id="fundingAgency"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Funding Agency
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={fundingAgency}
              label="Programme Type"
              size="small"
              // sx={{ width: "100%" }}
              // style={{ width: "48%", margin: 12 }}
              onChange={(e) => {
                setFundingAgency(e.target.value);
                setFundingAgencyErr(false);
              }}
            >
              {funding?.map((row,index) => {
                return (
                  <MenuItem key={row.index} value={row.id}>
                    {row.fundingAgencyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={projectNameErr}
            id="projectName"
            label="Name of the Project (Max 500 Character)"
            variant="outlined"
            value={projectName}
            // type="date"
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setProjectName(e.target.value);
              setProjectNameErr(false);
            }}
          />

          <TextField
            error={plsNameErr}
            id="plsName"
            label=" Name of the PIs (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={plsName}
            onChange={(e) => {
              setPlsName(e.target.value);
              setPlsNameErr(false);
            }}
          />

          <TextField
            error={budjetErr}
            id="budjet"
            label="Budget (Rs.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={budjet}
            onChange={(e) => {
              setBudjet(e.target.value);
              setBudjetErr(false);
            }}
          />

          <TextField
            error={startDateErr}
            id="startDate"
            label="Start date"
            type="date"
            variant="outlined"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
              setStartDateErr(false)
            }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={endDateErr}
            id="endDate"
            label="End date"
            type="date"
            variant="outlined"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
              setEndDateErr(false)
            }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={progressErr}
            id="progress"
            label="Progress during this Month (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={progress}
            onChange={(e) => {
              setProgress(e.target.value)
              setProgressErr(false)
            }}
          />        

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={()=>formValidateFun()} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArC9Inside;
