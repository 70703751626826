import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { B12VALIDATION } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import ComponentLoader from "../../../../ComponentLoader";

function B12Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [LovInvestype, setLovInvestype] = useState([]);

  const [fundingAgency, setfundingAgency] = useState([]);
  const [options, setOptions] = useState([]);
  // set
  const [Fundid, setFundid] = useState("");

  const [Fundingagencyid, setFundingagencyid] = useState(null);
  const [Titleoftheproject, setTitleoftheproject] = useState("");
  const [PICOInvestigatortype, setPICOInvestigatortype] = useState("");
  const [Budget, setBudget] = useState("");
  const [Date, setDate] = useState("");
  const [Status, setStatus] = useState("");

  const [projectNo, setProjectNo] = useState("")
  const [projectNoErr, setProjectNoErr] = useState(false)

  const [FundingagencyidErr, setFundingagencyidErr] = useState(false);
  const [TitleoftheprojectErr, setTitleoftheprojectErr] = useState(false);
  const [PICOInvestigatortypeErr, setPICOInvestigatortypeErr] = useState(false);
  const [BudgetErr, setBudgetErr] = useState(false);
  const [DateErr, setDateErr] = useState(false);
  const [StatusErr, setStatusErr] = useState(false);
  const [loader,setLoader] =useState(true);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [Code, setCode] = useState("");
  const [Fundingagencyname, setFundingagencyname] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B12VALIDATION),
  });

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      handleFundingAgency(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("New Project");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const handleBack = () => {
    navigate("/B12");
  };

  // const BtnSubmit = () => {
  //   alert("Successfully Submitted")
  // }

  const edit = (item) => {
    console.log(item);
    setProjectNo(item.projectNo)
    setFundid(item.fundingAgencyId);
    setTitleoftheproject(item.titleoftheProject);
    setPICOInvestigatortype(item.investigatorType);
    setBudget(item.budget);
    setDate(moment(item.dateofSubmission).format("YYYY-MM-DD"));
    setStatus(item.status);
    setId(item.id);
  };

  useEffect(() => {
    const getValue = options.find((data) => data.id === Fundid);
    if (getValue) {
      setFundingagencyid(getValue);
    }
    console.log("getId=============", getValue);
  }, [fundingAgency]);

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov Investigator type
  const handleLov = async (token, InvestigatorType) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"InvestigatorType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovInvestype(data);
    } catch (error) {
      console.log(error);
    }
  };

  //Funding Agency //
  const handleFundingAgency = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.FUNDINGGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setfundingAgency(data);
        setOptions(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      projectNo: projectNo,
      FundingAgencyId: Fundingagencyid.id,
      TitleoftheProject: Titleoftheproject,
      InvestigatorType: PICOInvestigatortype,
      Budget: Budget,
      DateofSubmission: Date,
      Status: Status,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B12SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B12");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B12EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B12");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePopupInstitute = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      code: Code,
      FundingAgencyName: Fundingagencyname,
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.FUNDINGSAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          reload();
          setId(0);
          setOpen(false);
          // handleInstitute(token);
        } else {
          alert(data.errorMsgs);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {

    if (!projectNo) {
      setProjectNoErr(true);
      document.getElementById("projectNo").focus();
    }
    else if (!Fundingagencyid) {
      setFundingagencyidErr(true);
      document.getElementById("Fundingagencyid").focus();

    }

    else if (!Titleoftheproject) {
      setTitleoftheprojectErr(true);
      document.getElementById("Titleoftheproject").focus();
    } else if (!PICOInvestigatortype) {
      setPICOInvestigatortypeErr(true);
      document.getElementById("PICOInvestigatortype").focus();
    } else if (!Budget) {
      setBudgetErr(true);
      document.getElementById("Budget").focus();
    } else if (!Date) {
      setDateErr(true);
      document.getElementById("Date").focus();
    } else if (!Status) {
      setStatusErr(true);
      document.getElementById("Status").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>New Project (B12)</span>
        </div>
      </div>

      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div style={{ marginBottom: 4 }}>
                <h4>Funding Agency</h4>
              </div>
              <div onClick={handleClose}>
                <CloseIcon className="dialogue_close_icon" />
              </div>
            </div>
          </DialogTitle>

          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form manu-form2">
              <div className="box width-40">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Code"
                  variant="outlined"
                  size="small"
                  value={Code}
                  onChange={(e) => setCode(e.target.value)}
                // {...register("Codev")}
                // error={errors.Codev ? true : false}
                // helperText={errors.Codev?.message}
                />
              </div>

              <div className="box width-40">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Funding Agency"
                  variant="outlined"
                  size="small"
                  value={Fundingagencyname}
                  onChange={(e) => setFundingagencyname(e.target.value)}
                // {...register("FundingAgencyNamev")}
                // error={errors.FundingAgencyNamev ? true : false}
                // helperText={errors.FundingAgencyNamev?.message}
                />
              </div>

              <div className="box width-40"></div>
            </div>
            <div className="dialogue_action_button"></div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handlePopupInstitute}>
              Submit
            </button>
          </DialogActions>
        </Dialog>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ width: "48%", margin: 20 }}>
            B12. New Project(s) Proposed
          </h5>

          <div className="d-flex">
            <TextField
              error={projectNoErr}
              id="projectNo"
              label="Project No"
              variant="outlined"
              style={{ margin: "10px", width: "48%" }}
              value={projectNo}
              onChange={(e) => {
                setProjectNo(e.target.value);
                setProjectNoErr(false);
              }}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              style={{ margin: "10px", width: "48%" }}
              onChange={(e, val) => {
                setFundingagencyid(val);
                setFundingagencyidErr(false);
              }}
              getOptionLabel={(option) => `${option.fundingAgencyName}`}
              value={Fundingagencyid}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Funding Agency"
                  error={FundingagencyidErr}
                />
              )}
            />
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>


          </div>




          <TextField
            error={TitleoftheprojectErr}
            id="Titleoftheproject"
            value={Titleoftheproject}
            label="Title of the Project (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setTitleoftheproject(e.target.value);
              setTitleoftheprojectErr(false);
            }}
          />

          {/* <TextField
            error={PICOInvestigatortypeErr}
            id="PICOInvestigatortype"
            label="PI/Co-PI"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={PICOInvestigatortype}
            onChange={(e) => {
              setPICOInvestigatortype(e.target.value);
              setPICOInvestigatortypeErr(false);
            }}
          /> */}
          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={PICOInvestigatortypeErr}
            id="PICOInvestigatortype"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={PICOInvestigatortype}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setPICOInvestigatortype(e.target.value);
                setPICOInvestigatortypeErr(false);
              }}
            >
              {LovInvestype?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={BudgetErr}
            id="Budget"
            label="Budget in Lakhs"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Budget}
            onChange={(e) => {
              setBudget(e.target.value);
              setBudgetErr(false);
            }}
          />

          <TextField
            error={DateErr}
            id="Date"
            label="Date of submission"
            type="date"
            variant="outlined"
            value={Date}
            onChange={(e) => {
              setDate(e.target.value);
              setDateErr(false);
            }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={StatusErr}
            id="Status"
            label="Status (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Status}
            onChange={(e) => {
              setStatus(e.target.value);
              setStatusErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              onClick={handleBack}
              className="deactivate-buttons" style={{ paddingTop: "5px" }}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{ paddingTop: "5px" }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B12Inside;
