import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D5VALI } from "../../../Validation";
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArF6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true);

  const [departName, setDepartName] = useState("");
  const [equipmentName, setEquipmentName] = useState("");
  const [sourceofFunding, setSourceofFunding] = useState("");
  const [budget, setBudget] = useState("");

  const [equipmentNameErr, setEquipmentNameErr] = useState(false);
  const [sourceofFundingErr, setSourceofFundingErr] = useState(false);
  const [budgetErr, setBudgetErr] = useState(false);

  const [activity, setActivity] = useState("");
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D5VALI),
  });

  const backs = () => {
    navigate("/ArF6");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("items purchased");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    setLoader(false);
  }, []);

  const edit = (item) => {
    console.log(item);
    setBudget(item.budget);
    setSourceofFunding(item.sourceofFunding)
    setEquipmentName(item.equipmentName)
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async () => {
    // console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    // console.log(data);

    const d = {
      id,

      EquipmentName: equipmentName,
      SourceofFunding: sourceofFunding,
      Budget: budget,

      IAMRSId: state.id,
      InstituteId: parseInt(invis),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF6");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF6EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = (e) => {
    e.preventDefault()
    if (!equipmentName) {
      setEquipmentNameErr(true)
      document.getElementById("equipmentName").focus()
    } else if (!budget) {
      setBudgetErr(true)
      document.getElementById("budget").focus()
    } else if (!sourceofFunding) {
      setSourceofFundingErr(true)
      document.getElementById("sourceofFunding").focus()
    } else {
      handleLeavepage()
    }
  }


  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>F6. Major equipment purchased /infrastructure created  </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ margin: 20 }}> F6. Major equipment purchased /infrastructure created </h5>

          {/* <TextField
            id="outlined-basic"
            label="Name of the Department"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Natureofcoaching}

            // onChange={(e) => setNatureofcoaching(e.target.value)}

            {...register("DepartNameV")}
            error={errors.DepartNameV ? true : false}
            helperText={errors.DepartNameV?.message}
          /> */}

          <TextField
            id="outlined-basic"
            label=" Name of the Equipment/ Items (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={equipmentName}

            onChange={(e) => {
              setEquipmentName(e.target.value)
              setEquipmentNameErr(false)
            }}

            // {...register("EquipmentNameV")}
            error={equipmentNameErr}
            helperText={errors.EquipmentNameV?.message}
          />

          <TextField
            id="outlined-basic"
            label="Budget in Lakh"
            variant="outlined"
            // type="number"
            style={{ margin: "10px", width: "48%" }}
            value={budget}

            onChange={(e) => {
              setBudget(e.target.value)
              setBudgetErr(false)
            }}

            // {...register("BudgetV")}
            error={budgetErr}
            helperText={errors.BudgetV?.message}
          />

          <TextField
            id="outlined-basic"
            label="Source of Funding (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={sourceofFunding}

            onChange={(e) => {
              setSourceofFunding(e.target.value)
              setSourceofFundingErr(false)
            }}

            // {...register("SourceofFundingV")}
            error={sourceofFundingErr}
            helperText={errors.SourceofFundingV?.message}
          />

          {/* <TextField
            id="outlined-basic"
            label="Activity during this month (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={FellowshipName}

            // onChange={(e) => setFellowshipName(e.target.value)}

            {...register("ActivityV")}
            error={errors.ActivityV ? true : false}
            helperText={errors.ActivityV?.message}
          /> */}

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons">
              Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </form>
    </div>
  );
}

export default ArF6Inside;
