import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
// import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { B14VALIDATION } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArD1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Authors, setAuthors] = useState("");
  const [Publicationsyear, setPublicationsyear] = useState("");
  const [Titleofpublications, setTitleofpublications] = useState("");
  const [Journal, setJournal] = useState("");
  const [Volume, setVolume] = useState("");
  const [Issue, setIssue] = useState("");
  const [Pageno, setPageno] = useState("");
  const [ratingType, setratingType] = useState("");
  const [ISSNNAASID, setISSNNAASID] = useState("");
  const [NAASRating, setNAASRating] = useState("");
  const [SCIIndex, setSCIIndex] = useState("");
  const [loader,setLoader] =useState(true);
  const [ratingTypeDrop, setratingTypeDrop] = useState([]);

  const [AuthorsErr, setAuthorsErr] = useState(false);
  const [PublicationsyearErr, setPublicationsyearErr] = useState(false);
  const [TitleofpublicationsErr, setTitleofpublicationsErr] = useState(false);
  const [JournalErr, setJournalErr] = useState(false);
  const [VolumeErr, setVolumeErr] = useState(false);
  const [IssueErr, setIssueErr] = useState(false);
  const [PagenoErr, setPagenoErr] = useState(false);
  const [ratingTypeErr, setratingTypeErr] = useState(false);
  const [ISSNNAASIDErr, setISSNNAASIDErr] = useState(false);
  const [NAASRatingErr, setNAASRatingErr] = useState(false);
  const [SCIIndexErr, setSCIIndexErr] = useState(false);

  const [JournalIssueType, setJournalIssueType] = useState([]);
  const [Journalissuedate, setJournalissuedate] = useState("");
  const [ratingUpdate, setratingUpdate] = useState("");

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti, setInsti] = useState();

  const[value,setValue]=useState({
    Authors:"",
    PublicationsYear:"",
    TitleOfPublications:"",
    Journal:"",
    Volume:"",
    Issue:"",
    RatingType:"",
    PageNo:"",
    ISSNNAASID:"",
    NAASRating:"",
    SCIIndex:""
   });

   const[errValue,setErrValue]=useState({
    Authors:false,
    PublicationsYear:false,
    TitleOfPublications:false,
    Journal:false,
    Volume:false,
    Issue:false,
    RatingType:false,
    PageNo:false,
    ISSNNAASID:false,
    NAASRating:false,
    SCIIndex:false
   });

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B14VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleJournalIssue(tok);
      handleRatingType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Publications");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
  }, []);

  const handleBack = () => {
    navigate("/ArD1");
  };

  // const BtnSubmit = () => {
  //   alert("Successfully Submitted")
  // }

  const edit = (item) => {
    console.log(item);
//     authors
// publicationsYear
// titleOfPublications
// journal
// volume
// issue
// pageNo
// issnnaasid
// ratingType
// naasRating
// sciIndex
setValue({
  Authors:item.authors,
  PublicationsYear:item.publicationsYear,
  TitleOfPublications:item.titleOfPublications,
  Journal:item.journal,
  Volume:item.volume,
  Issue:item.issue,
  RatingType:item.ratingType,
  PageNo:item.pageNo,
  ISSNNAASID:item.issnnaasid,
  NAASRating:item.naasRating,
  SCIIndex:item.sciIndex
})
    setId(item.id);
  };

  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
 })

  console.log("value",value);


  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Journal Issue Lov Type //
  const handleJournalIssue = async (token, JournalIssue) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"JournalIssue"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setJournalIssueType(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  // rating type Lov Type //
  const handleRatingType = async (token, RatingType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"RatingType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setratingTypeDrop(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      Authors:value.Authors,
    PublicationsYear:value.PublicationsYear,
    TitleOfPublications:value.TitleOfPublications,
    Journal:value.Journal,
    Volume:value.Volume,
    Issue:value.Issue,
    RatingType:value.RatingType,
    PageNo:value.PageNo,
    ISSNNAASID:value.ISSNNAASID,
    NAASRating:value.NAASRating,
    SCIIndex:value.SCIIndex,
    IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARD1SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArD1");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARD1EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArD1");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }; 
  const formValidateFun = (e) => {
    e.preventDefault()
    if (!value.Authors) {
      setErrValue((prev) => ({ ...prev, Authors: true }))
      document.getElementById("Authors").focus()
    } else if (!value.PublicationsYear) {
      setErrValue((prev) => ({ ...prev, PublicationsYear: true }))
      document.getElementById("PublicationsYear").focus()
    } else if (!value.TitleOfPublications) {
      setErrValue((prev) => ({ ...prev, TitleOfPublications: true }))
      document.getElementById("TitleOfPublications").focus()
    }
  else if (!value.Journal) {
    setErrValue((prev) => ({ ...prev, Journal: true }))
    document.getElementById("Journal").focus()
  }
  else if (!value.Volume) {
    setErrValue((prev) => ({ ...prev, Volume: true }))
    document.getElementById("Volume").focus()
  }
  else if (!value.Issue) {
    setErrValue((prev) => ({ ...prev, Issue: true }))
    document.getElementById("Issue").focus()
  }
  else if (!value.RatingType) {
    setErrValue((prev) => ({ ...prev, RatingType: true }))
    document.getElementById("RatingType").focus()
  }
  else if (!value.PageNo) {
    setErrValue((prev) => ({ ...prev, PageNo: true }))
    document.getElementById("PageNo").focus()
  }
  else if (!value.ISSNNAASID) {
    setErrValue((prev) => ({ ...prev, ISSNNAASID: true }))
    document.getElementById("ISSNNAASID").focus()
  }
  else if (!value.NAASRating) {
    setErrValue((prev) => ({ ...prev, NAASRating: true }))
    document.getElementById("NAASRating").focus()
  }
  else if (!value.SCIIndex) {
    setErrValue((prev) => ({ ...prev, SCIIndex: true }))
    document.getElementById("SCIIndex").focus()
  }
  else{
    handleLeavepage()
  }
      
    };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>D1. Publications in the peer reviewed journals </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>D1. Publications in the peer reviewed journals</h5>
          
      
          
          <TextField
            id="Authors"
            label="Authors"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Authors"
            error={errValue.Authors}
            value={value.Authors}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Authors: false }));
            }}
          />

          <TextField
           
            id="PublicationsYear"
            label="Year"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="PublicationsYear"
            error={errValue.PublicationsYear}
            value={value.PublicationsYear}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, PublicationsYear: false }));
            }}
          />

          <TextField
           
            id="TitleOfPublications"
            label="Title (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="TitleOfPublications"
            error={errValue.TitleOfPublications}
            value={value.TitleOfPublications}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, TitleOfPublications: false }));
            }}
          />

          <TextField
          
            id="Journal"
            label="Journal (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Journal"
            error={errValue.Journal}
            value={value.Journal}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Journal: false }));
            }}
          />

          <TextField
            
            id="Volume"
            label="Volume (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Volume"
            error={errValue.Volume}
            value={value.Volume}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Volume: false }));
            }}
          />

          <TextField
          
            id="Issue"
            label="Issue (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Issue"
            error={errValue.Issue}
            value={value.Issue}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Issue: false }));
            }}
          />

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={errValue.RatingType}
            id="RatingType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Rating Type
            </InputLabel>
            <Select
              variant="outlined"
              // label="Rating Type"
              style={{ height: "45px" }}
              name="RatingType"
            
              value={value.RatingType}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, RatingType: false }));
              }}
            >
              {ratingTypeDrop?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
          
            id="PageNo"
            label="Page No"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="PageNo"
            error={errValue.PageNo}
            value={value.PageNo}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, PageNo: false }));
            }}
          />

          <TextField
          
            id="ISSNNAASID"
            label="ISSN NAAS ID"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="ISSNNAASID"
            error={errValue.ISSNNAASID}
            value={value.ISSNNAASID}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ISSNNAASID: false }));
            }}
          />

          <TextField
           
            id="NAASRating"
            label="NAAS rating/ Impact factor"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="NAASRating"
            error={errValue.NAASRating}
            value={value.NAASRating}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, NAASRating: false }));
            }}
          />

          <TextField
           
            id="SCIIndex"
            label="SCI Index Yes/No"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="SCIIndex"
            error={errValue.SCIIndex}
            value={value.SCIIndex}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, SCIIndex: false }));
            }}
            select // Add select prop to indicate it's a dropdown
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArD1Inside;
// export default ArD1Inside;
