import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { B4VALIDATION } from "../../../Validation";

//  import { Translational } from '../../../Pages/Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArB11Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true)
  const [departmentName, setDepartmentName] = useState("");
  const [degreeName, setDegreeName] = useState("");
  const [studentId, setStudentId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [year, setYear] = useState("");
  const [particulars, setParticulars] = useState("");

  const [departmentNameErr, setDepartmentNameErr] = useState(false);
  const [degreeNameErr, setDegreeNameErr] = useState(false);
  const [studentIdErr, setStudentIdErr] = useState(false);
  const [studentNameErr, setStudentNameErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const [particularsErr, setParticularsErr] = useState(false);

  const [dropdown, setDropdown] = useState([]);
  const [PType, setPType] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();
  const [inves, setInves] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B4VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Target product");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);

    const investigator = localStorage.getItem("loginId");
    setInves(investigator);
    setLoader(false)
  }, []);

  const handleBack = () => {
    navigate("/ArB11");
  };

  const edit = (item) => {
    console.log(item);
    setDepartmentName(item.departmentName);
    setDegreeName(item.degreeProgramme);
    setStudentId(item.studentId);
    setStudentName(item.studentName);
    setYear(item.year);
    setParticulars(item.particulars);
    setId(item.id);
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      InvestigatorId: inves,
      DepartmentName: departmentName,
      DegreeProgramme: degreeName,
      StudentId: studentId,
      StudentName: studentName,
      Year: year,
      Particulars: particulars,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB11SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArB11");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB11EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArB11");
          toastSuccess("Successfully Updated");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!departmentName) {
      setDepartmentNameErr(true);
      document.getElementById("departmentName").focus();
    } else if (!degreeName) {
      setDegreeNameErr(true);
      document.getElementById("degreeName").focus();
    } else if (!studentId) {
      setStudentIdErr(true);
      document.getElementById("studentId").focus();
    } else if (!studentName) {
      setStudentNameErr(true);
      document.getElementById("studentName").focus();
    } else if (!year) {
      setYearErr(true);
      document.getElementById("year").focus();
    } else if (!particulars) {
      setParticularsErr(true);
      document.getElementById("particulars").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>
          B11. Student achievements

          </span>
        </div>
      </div>
      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
          B11. Student achievements

          </h5>

          <TextField
            error={departmentNameErr}
            id="departmentName"
            label="Department Name (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={departmentName}
            onChange={(e) => {
              setDepartmentName(e.target.value);
              setDepartmentNameErr(false);
            }}
          />

          <TextField
            error={degreeNameErr}
            id="degreeName"
            label="Degree Programme (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={degreeName}
            onChange={(e) => {
              setDegreeName(e.target.value);
              setDegreeNameErr(false);
            }}
          />

          <TextField
            error={studentIdErr}
            id="studentId"
            label="Student Id"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={studentId}
            onChange={(e) => {
              setStudentId(e.target.value);
              setStudentIdErr(false);
            }}
          />

          <TextField
            error={studentNameErr}
            id="studentName"
            label="Name of the Student (Max 200 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={studentName}
            onChange={(e) => {
              setStudentName(e.target.value);
              setStudentNameErr(false);
            }}
          />

          <TextField
            error={yearErr}
            id="year"
            label="Year / Batch"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              setYearErr(false);
            }}
          />

          <TextField
            error={particularsErr}
            id="particulars"
            label="Particulars (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={particulars}
            onChange={(e) => {
              setParticulars(e.target.value);
              setParticularsErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              style={{ paddingTop: "5px" }}
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              style={{ paddingTop: "5px" }}
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArB11Inside;
