import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MasterData.css";
import { FaHandshake } from "react-icons/fa";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import WorkIcon from "@mui/icons-material/Work";
import Directorate from "../../Images/directorate.png";
import Department from "../../Images/Department.png";
import StationCategory from "../../Images/Category.png";
import Faculty from "../../Images/Faculty.jpeg";
import Institution from "../../Images/institution.png";
import FundingAgency from "../../Images/FundingAgency.png";
import Course from "../../Images/Course.png";
import Crop from "../../Images/Crop.png";
import LOV from "../../Images/LOV.png";
import Leave from "../../Images/Leave.png";
import ADMINREPORTIMG from "../../Images/AdminReport.png";
import FACULTYREPRTIMG from "../../Images/FacultyReport.png";
import FACULTYSTATUEIMG from "../../Images/submitStatusReport.png";
import ANALYTICALIMG from "../../Images/AnaliticalReport.png";
import TECH from "../../Images/tech.png";
import leave from "../../Images/leave1.png";
import { useState } from "react";
import ComponentLoader from "../../ComponentLoader";
// import HomeIcon from '@mui/icons-material/Home';

const intitute = {
  width: "90px",
};

const department = {
  width: "79px",
  top: "3px",
};

const fac = {
  width: "60px",
  top: "10px",
};
const sac = {
  width: "38px",
  top: "10px",
};
const sac2 = {
  width: "50px",
  top: "10px",
};
const sac1 = {
  width: "50px",
  top: "10px",
};

const sac3 = {
  width: "39px",
  top: "10px",
};

function MasterData({ head }) {
  const [rolee, setRolee] = useState("");
  const navigate = useNavigate();

  const monthlyR = () => {
    navigate("/monthyReport");
  };

  const Directorat = () => {
    navigate("/directorate");
  };

  const institute = () => {
    navigate("/institute");
  };

  const departmentt = () => {
    navigate("/Department");
  };

  const StationTypes = () => {
    navigate("/StationType");
  };

  const Fundings = () => {
    navigate("/Funding");
  };

  const investigator = () => {
    navigate("/investigator");
  };

  const projectcode = () => {
    navigate("/projectcode");
  };

  const MasterCourse = () => {
    navigate("/MasterCourse");
  };

  const MasterCrop = () => {
    navigate("/MasCrop");
  };

  const Listvaluess = () => {
    navigate("/ListValues");
  };

  const MasterLeave = () => {
    navigate("/Leave");
  };

  const Adminreport = () => {
    navigate("/GeneralAR");
  };

  const MonthReportStatus = () => {
    navigate("/monthreportstatus");
  };
  const FilterReport = () => {
    navigate("/filtereport");
  };

  const MasterContact = () => {
    navigate("/contactus");
  };
  const adminReportStatus = () => {
    navigate("/adminReportStatus");
  };

  useEffect(() => {
    const rolee = localStorage.getItem("role");
    setRolee(rolee);
    setLoader(false);
    // head("Master Data Management")
  }, []);
  console.log(rolee);
  const [loader, setLoader] = useState(true);

  return (
    <div className="master-container" style={{ marginTop: 60 }}>
      <ComponentLoader isLoading={loader} />
      <div className="master-main">
        <div className="master-content">
          Digital Reporting System for Region II Research Stations
        </div>
      </div>

      {/* {rolee==="Principal" &&
      <div className="master-grid">
        <div className='master-grid-header'><span>Tracks</span></div>
        <div className="inner-master-grid">
          {

          }
          <div>
            <div className="inner-head">
              <div>
                <h6>Bussiness Master</h6>
              </div>
            </div>

            
            <div className="inner-middle">
              {
                rolee === "Admin" && <> <div className="inner-midle-grid">
                <div onClick={Directorat}>
                  <img src={Directorate} alt="directorate" className="img" style={{department}}/>
                  <span>Directorate</span>
                </div>
              </div>
              <div className="inner-midle-grid">
                <div onClick={institute}>
                  <img
                    src={Institution}
                    alt="Institution"
                    className="img"
                    style={fac}
                  />
                  <span>Institution</span>
                </div>
              </div>
              </>
              }
             

              <div className="inner-midle-grid">
                <div onClick={departmentt}>
                  <img src={Department} alt="Department" className="img" style={fac}/>
                  <span>Department</span>
                </div>
              </div>
              <div className="inner-midle-grid">
                <div onClick={StationTypes}>
                  <img src={StationCategory} alt="StationCategory" className="img" style={fac}/>
                  <span>Station Type</span>
                </div>
              </div>

              <div className="inner-midle-grid">
                <div onClick={investigator}>
                  <img src={Faculty} alt="Faculty" className="img" style={fac}/>
                  <span>Faculty</span>
                </div>
              </div>
              <div className="inner-midle-grid" >
                <div style={{border:"none",background:"none",boxShadow:"none"}}></div>
              </div>
            </div>
          </div>



          <div>
            <div className="inner-head" >
              <div className="inner-head-middle-grid" style={{background:"rgb(253,91,20)"}}>
                <h6>Other Master</h6>
              </div>
            </div>
            <div className="inner-middle">
              <div className="inner-midle-grid">
                <div onClick={Fundings}>
                  <img src={FundingAgency} alt="FundingAgency" className="img" style={intitute}/>
                  <span>Funder</span>
                </div>
              </div>
              <div className="inner-midle-grid">
                <div onClick={MasterCourse}>
                  <img
                    src={Course}
                    alt="Course"
                    className="img"
                    style={fac}
                  />
                  <span>Course</span>
                </div>
              </div>
              <div className="inner-midle-grid">
                <div onClick={MasterCrop}>
                  <img src={Crop} alt="Crop" className="img" />
                  <span>Crop</span>
                </div>
              </div>

              <div className="inner-midle-grid">
                <div onClick={Listvaluess}>
                  <img src={Crop} alt="Crop" className="img" />
                  <span>Crop</span>
                </div>
              </div>


              <div className="inner-midle-grid">
                <div  onClick={Listvaluess}>
                  <img src={LOV} alt="LOV" className="img" />
                  <span>List of Values</span>
                </div>
              </div>
              <div className="inner-midle-grid">
                <div onClick={MasterLeave}>
                  <img
                    src={Leave}
                    alt="Leave"
                    className="img"
                    style={fac}
                  />
                  <span>Leave Types</span>
                </div>
              </div>

              <div className="inner-midle-grid">
                <div onClick={MasterContact}>
                  <img src={FundingAgency} alt="FundingAgency" className="img" style={intitute}/>
                  <span>Tech Support</span>
                </div>
              </div>
              
              <div className="inner-midle-grid">
                <div>
                  <img
                    src={Leave}
                    alt="Leave"
                    className="img"
                    style={fac}
                  />
                  <span>Leave Types</span>
                </div>
              </div>
             
             
             
            </div>
          </div>


          <div>
            <div className="inner-head">
              <div className="inner-head-bottom-grid" style={{background:"rgb(166,140,213)"}}>
                <h6>Monthly Report</h6>
              </div>
            </div>
            
            <div className="inner-middle">
              {  rolee === "Director" && <>
              <div className="inner-midle-grid">
                <div onClick={monthlyR}>
                  <img
                    src={FACULTYREPRTIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span>Faculty Monthly  <br></br>Report</span>
                </div>
              </div>
              
              <div className="inner-midle-grid">
                <div onClick={Adminreport}>
                  <img
                    src={ADMINREPORTIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span>Admin Monthly <br></br> Report</span>
                </div>
              </div>

              <div className="inner-midle-grid" >
                <div onClick={MonthReportStatus}>
                  <img
                    src={FACULTYSTATUEIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span>Faculty Report  <br></br>Status</span>
                </div>
              </div>


              <div className="inner-midle-grid" style={{marginTop:"10px"}}>
                <div onClick={adminReportStatus}>
                  <img
                    src={Leave}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span>Admin Report <br></br> Status</span>
                </div>
              </div>
              <div className="inner-midle-grid" style={{marginTop:"10px"}}>
                <div onClick={FilterReport}>
                  <img
                    src={ANALYTICALIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span >Analytical Report <br></br>View </span>
                 
                </div>
              </div>
              <div className="inner-midle-grid" >
                <div style={{background:"none",border:"none",boxShadow:"none"}}></div>
              </div>
             
              <div className="inner-midle-grid" >
                <div style={{background:"none",border:"none",boxShadow:"none"}}></div>
              </div>
              </> }

              {  rolee === "Principal" && <>
              <div className="inner-midle-grid">
                <div onClick={Adminreport}>
                  <img
                    src={ADMINREPORTIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span>Admin Report <br></br> Entry</span>
                </div>
              </div>
              
              <div className="inner-midle-grid">
                <div onClick={monthlyR}>
                  <img
                    src={FACULTYREPRTIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span>Faculty Monthly  <br></br>Report View </span>
                </div>
              </div>

              <div className="inner-midle-grid" >
                <div onClick={MonthReportStatus}>
                  <img
                    src={FACULTYSTATUEIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span>Faculty Report <br></br>Status</span>
                </div>
              </div>

              <div className="inner-midle-grid" >
                <div onClick={FilterReport}>
                  <img
                    src={ANALYTICALIMG}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <span >Analytical Report <br></br>View </span>
                 
                </div>
              </div>
              <div className="inner-midle-grid"></div>


             
              <div className="inner-midle-grid" >
                <div style={{background:"none",border:"none",boxShadow:"none"}}></div>
              </div>
              </> }
            </div>
          </div>
        </div>
      </div>} */}
      {/* {rolee === "Director" &&<> */}

      {rolee === "Admin" ? (
        <>
          <div className="inner-master-grid ">
            <div className="inner-middle extra-padding">
              <div className="inner-midle-grid2">
                <div onClick={Directorat}>
                  <img
                    src={Directorate}
                    alt="directorate"
                    className="img"
                    style={{ department }}
                  />
                  <span>Directorate</span>
                </div>
              </div>
              <div className="inner-midle-grid2">
                <div onClick={institute}>
                  <img
                    src={Institution}
                    alt="Institution"
                    className="img"
                    style={fac}
                  />
                  <span>Institution</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : rolee === "VC" ? (
        <>
          <div className="director-section">
            <div className="director-section1">
              <div className="diector-heading"> Review Panel</div>
              <div className="director-content">
                <div className="director-card" onClick={FilterReport}>
                  <img
                    src={ANALYTICALIMG}
                    alt="Leave"
                    className="img"
                    style={sac1}
                  />
                  <span>Data Analytics and Report generation</span>
                </div>
              </div>
            </div>
            <div className="director-section2">
              <div className="diector-heading2"> Other Services</div>
              <div className="director-content2">
                <div className="director-view" onClick={MasterContact}>
                  <img src={TECH} alt="Leave" className="img" style={sac} />
                  <p>Tech Support</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="director-section">
            <div className="director-section1">
              <div className="diector-heading"> Review Panel</div>
              <div className="director-content">
                <div className="director-card" onClick={monthlyR}>
                  <img
                    src={FACULTYREPRTIMG}
                    alt="Leave"
                    className="img"
                    style={sac1}
                  />
                  <span>Faculty Report View</span>
                </div>
                <div className="director-card" onClick={Adminreport}>
                  <img
                    src={ADMINREPORTIMG}
                    alt="Leave"
                    className="img"
                    style={sac1}
                  />
                  {rolee === "Director" ? (
                    <span>Institute Report View</span>
                  ) : null}
                  {rolee === "Principal" ? (
                    <span>Institute Report Entry</span>
                  ) : null}
                </div>
                <div className="director-card" onClick={MonthReportStatus}>
                  <img
                    src={FACULTYSTATUEIMG}
                    alt="Leave"
                    className="img"
                    style={sac1}
                  />
                  <span>Status of Faculty report submission</span>
                </div>
                {rolee === "Director" && (
                  <>
                    <div className="director-card" onClick={adminReportStatus}>
                      <img
                        src={Leave}
                        alt="Leave"
                        className="img"
                        style={sac1}
                      />
                      <span>Status of Admin report submission</span>
                    </div>
                  </>
                )}
                <div className="director-card" onClick={FilterReport}>
                  <img
                    src={ANALYTICALIMG}
                    alt="Leave"
                    className="img"
                    style={sac1}
                  />
                  <span>Data Analytics and Report generation</span>
                </div>
              </div>
            </div>
            <div className="director-section2">
              <div className="diector-heading2"> Data Management Tools</div>
              <div className="director-content2">
                <div className="director-view" onClick={investigator}>
                  <img src={Faculty} alt="Leave" className="img" style={sac} />
                  <p>Faculty</p>
                </div>
                <div className="director-view" onClick={Fundings}>
                  <img
                    src={FundingAgency}
                    alt="Leave"
                    className="img"
                    style={sac2}
                  />
                  <p>Funding Agency</p>
                </div>
                <div className="director-view" onClick={MasterCrop}>
                  <img src={Crop} alt="Leave" className="img" style={sac2} />
                  <p>Crop</p>
                </div>
                <div className="director-view" onClick={MasterLeave}>
                  <img src={leave} alt="Leave" className="img" style={sac3} />
                  <p>Leave Types</p>
                </div>
                <div className="director-view" onClick={Listvaluess}>
                  <img src={LOV} alt="Leave" className="img" style={sac2} />
                  <p>List Of Values</p>
                </div>
                <div className="director-view" onClick={MasterCourse}>
                  <img src={Course} alt="Leave" className="img" style={sac} />
                  <p>Course</p>
                </div>
                <div className="director-view" onClick={departmentt}>
                  <img
                    src={Department}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <p>Department</p>
                </div>
                {/* <div className="director-view" onClick={projectcode}>
                  <img
                    src={Faculty}
                    alt="Leave"
                    className="img"
                    style={sac}
                  />
                  <p>Project</p>
                </div> */}
                <div className="director-view" onClick={MasterContact}>
                  <img src={TECH} alt="Leave" className="img" style={sac} />
                  <p>Tech Support</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* </>} */}
      {rolee === "Admin" ? null : (
        <>
          {" "}
          <div className="dashboard-footer">
            <div style={{ paddingLeft: 40 }}>
              @ 2024 Directorate Of Rice Research Institute
            </div>
            <div style={{ paddingRight: 40 }} className="banner-footer1">
              Powered by{" "}
              <a href="https://thepikture.com/" target="blank">
                Pikture Associates
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MasterData;
