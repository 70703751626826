import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";

// import { A3VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import ComponentLoader from "../../../ComponentLoader";

function ArA1gInside({ head }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true)
  const [liveStockType,setLiveStockType] = useState("")
  const [liveStockValue,setLiveStockValue] = useState("")

  const [liveStockTypeErr,setLiveStockTypeErr] = useState(false)
  const [liveStockValueErr,setLiveStockValueErr] = useState(false)

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [lovList, setLovList] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A3VALI),
  });

  const backs = () => {
    navigate("/ArA1(g)");
  };
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      // console.log(location.state.trnMonthlyReport);
      // if (location.state.TrnMonthlyReport) {
      //   console.log(location.state.TrnMonthlyReport);
      //   setTrnMonthlyReport(location.state.TrnMonthlyReport);
      // } else {
      //   edit(location.state);
      // }
      if (location.state) {
        edit(location.state);
      }
      
    }
    head("Guest Lectures");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
  }, []);

  const edit = (item) => {
    console.log(item);
    setLiveStockType(item.livestockType);
    setLiveStockValue(item.livestockValue);
    setId(item.id);
  };


// Lov
const handleLov = async (token, LivestockType) => {
  console.log(token);

  const h = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const { data } = await axios.get(
      `${window.BASE}${window.LOVTYPE}${"LivestockType"}`,
      {
        headers: h,
      }
    );
    console.log(data);
    setLovList(data);setLoader(false)
  } catch (error) {
    console.log(error);setLoader(false)
  }
};

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      LivestockType:liveStockType,
      LivestockValue:liveStockValue,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.AGSAVE}`, d, {
          headers: h,
        });
        console.log(data);
        // setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArA1(g)");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.AGEDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Updated");
          navigate("/ArA1(g)");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const formValidateFun = () => {
    if (!liveStockType) {
      setLiveStockTypeErr(true);
      document.getElementById("liveStockType").focus();
    } else if (!liveStockValue) {
      setLiveStockValueErr(true);
      document.getElementById("liveStockValue").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
        <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A1e. Live Stock
</span>
        </div>
      </div>

      {/* <form className="from-mains"> */}
        <div className="form-control">
          <h5 style={{ margin: 20 }}>A1e. Live Stock
</h5>

          <FormControl style={{ width: "48%", margin: 12 }} error={liveStockTypeErr} id="programtype">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Livestock Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="liveStockType"
              value={liveStockType}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setLiveStockType(e.target.value)
                setLiveStockTypeErr(false)
              }}
            >
              {lovList?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={liveStockValueErr}
            id="liveStockValue"
            label="Livestock Number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={liveStockValue}
            onChange={(e) => {
              setLiveStockValue(e.target.value);
              setLiveStockValueErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA",padding:"5px",borderRadius:"3px",width:"80px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" style={{paddingTop:"5px"}} className="sbmit-buttons" onClick={formValidateFun}>
            Save
            </button>
          </div>
        </div>
      {/* </form> */}
    </div>
  );
}

export default ArA1gInside;
