import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { B19VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import ComponentLoader from "../../../../ComponentLoader";

function B19Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [fundingAgency, setfundingAgency] = useState([]);
  const [options, setOptions] = useState([]);
  // set
  const [Fundid, setFundid] = useState("");

  const [Fundingagencyid, setFundingagencyid] = useState(null);
  const [eventName, setEventName] = useState("");
  const [numberofparticipants, setNumberofparticipants] = useState("");
  const [eventdate, setEventdate] = useState("");
  const [targetGroup, setTargetGroup] = useState("");
  const [budget, setBudget] = useState("");
  const [loader,setLoader] =useState(true);
  const [FundingagencyidErr, setFundingagencyidErr] = useState(false);
  const [eventNameErr, setEventNameErr] = useState(false);
  const [numberofparticipantsErr, setNumberofparticipantsErr] = useState(false);
  const [eventdateErr, setEventdateErr] = useState(false);
  const [targetGroupErr, setTargetGroupErr] = useState(false);
  const [budgetErr, setBudgetErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [dropcourse, setDropcourse] = useState([]);
  const [Code, setCode] = useState("");
  const [Fundingagencyname, setFundingagencyname] = useState("");
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const reload = () => {
    window.location.reload();
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B19VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleFundingAgency(tok);
      handleCoursetypes(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Workshop organized");
  }, []);
  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const backs = () => {
    navigate("/B19");
  };

  const edit = (item) => {
    console.log(item);
    setFundid(item.fundingAgencyId);
    setEventName(item.eventName);
    setNumberofparticipants(item.numberofparticipants);
    setEventdate(moment(item.eventdate).format("YYYY-MM-DD"));
    setTargetGroup(item.targetGroup);
    setBudget(item.budget);

    setId(item.id);
  };

  useEffect(() => {
    const getValue = options.find((data) => data.id === Fundid);
    if (getValue) {
      setFundingagencyid(getValue);
    }
    console.log("getId=============", getValue);
  }, [fundingAgency]);

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  //Funding Agency //
  const handleFundingAgency = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.FUNDINGGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setfundingAgency(data);
        setOptions(data)
      }
    } catch (error) {
      console.log(error);
    }
  };

  // dropdown

  const handleCoursetypes = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);

      setDropcourse(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      FundingAgencyId: Fundingagencyid.id,
      EventName: eventName,
      Numberofparticipants: numberofparticipants,
      Eventdate: eventdate,
      TargetGroup: targetGroup,
      Budget: budget,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B19SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B19");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        };
        } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B19EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B19");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePopupInstitute = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      code: Code,
      FundingAgencyName: Fundingagencyname,
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.FUNDINGSAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          reload();
          setId(0);
          setOpen(false);
          // handleInstitute(token);
        } else {
          alert(data.errorMsgs);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!Fundingagencyid){
      setFundingagencyidErr(true)
      document.getElementById("fundingAgencyId").focus()
    }else if(!eventName){
      setEventNameErr(true)
      document.getElementById("eventName").focus()
    }else if(!eventdate){
      setEventdateErr(true)
      document.getElementById("eventdate").focus()
    }else if(!numberofparticipants){
      setNumberofparticipantsErr(true)
      document.getElementById("numberofparticipants").focus()
    }else if(!targetGroup){
      setTargetGroupErr(true)
      document.getElementById("targetGroup").focus()
    }else if(!budget){
      setBudgetErr(true)
      document.getElementById("budget").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin"> <ComponentLoader isLoading={loader} />

      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Workshop organized (B19)</span>
        </div>
      </div>

      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div style={{ marginBottom: 4 }}>
                <h4>Funding Agency</h4>
              </div>
              <div onClick={handleClose}>
                <CloseIcon
                  className="dialogue_close_icon"
                  style={{ backgroundColor: "black" }}
                />
              </div>
            </div>
          </DialogTitle>

          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form manu-form2">
              <div className="box width-40">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Code"
                  variant="outlined"
                  size="small"
                  value={Code}
                  onChange={(e) => setCode(e.target.value)}
                  // {...register("Codev")}
                  // error={errors.Codev ? true : false}
                  // helperText={errors.Codev?.message}
                />
              </div>

              <div className="box width-40">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Funding Agency"
                  variant="outlined"
                  size="small"
                  value={Fundingagencyname}
                  onChange={(e) => setFundingagencyname(e.target.value)}
                  // {...register("FundingAgencyNamev")}
                  // error={errors.FundingAgencyNamev ? true : false}
                  // helperText={errors.FundingAgencyNamev?.message}
                />
              </div>

              <div className="box width-40"></div>
            </div>
            <div className="dialogue_action_button"></div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handlePopupInstitute}>
              Submit
            </button>
          </DialogActions>
        </Dialog>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>
            B19. Conference / Seminar / Symposium / Training / Workshop
            organized
          </h5>

          <div className="d-flex">
          <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              onChange={(e, val) => {
                setFundingagencyid(val);
                setFundingagencyidErr(false);
              }}
              getOptionLabel={(option) => `${option.fundingAgencyName}`}
              value={Fundingagencyid}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Funding Agency"
                  error={FundingagencyidErr}
                />
              )}
            />
            {/* <FormControl
              style={{ width: "97%", margin: 12 }}
              error={fundingAgencyIdErr}
              id="fundingAgencyId"
            >
              <Autocomplete
                id="demo-simple-select"
                options={fundingAgency || []}
                getOptionLabel={(option) => option.fundingAgencyName}
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.toLowerCase();
                  return options.filter((option) =>
                    option.fundingAgencyName.toLowerCase().includes(inputValue)
                  );
                }}
                onChange={(e) => {
                  setFundingAgencyId(e.target.value);
                  setFundingAgencyIdErr(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Funding Agency"
                    size="small"
                    sx={{ width: "100%" }}
                    value={fundingAgencyId}
                    error={errors.FundingAgencyIdv ? true : false}
                    helperText={errors.FundingAgencyIdv?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.id} value={option}>
                    {option.fundingAgencyName}
                  </MenuItem>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <MenuItem key={option.id} {...getTagProps({ index })}>
                      {option.fundingAgencyName}
                    </MenuItem>
                  ))
                }
              />
            </FormControl> */}
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          <TextField
            error={eventNameErr}
            id="eventName"
            label="Name of the Event"
            variant="outlined"
            value={eventName}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setEventName(e.target.value);
              setEventNameErr(false);
            }}
          />

          <TextField
            error={eventdateErr}
            id="DOM"
            label="DOM"
            // type="date"
            variant="outlined"
            value={eventdate}
            onChange={(e) => {
              setEventdate(e.target.value);
              setEventdateErr(false);
            }}
            style={{ margin: "10px", width: "48%" }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // InputProps={{
            //   placeholder: "",
            // }}
          />

          <TextField
            error={numberofparticipantsErr}
            id="numberofparticipants"
            label=" Number of Participants"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={numberofparticipants}
            onChange={(e) => {
              setNumberofparticipants(e.target.value);
              setNumberofparticipantsErr(false);
            }}
          />

          <TextField
            error={targetGroupErr}
            id="targetGroup"
            label="Target Group (Max 500 Character)"
            variant="outlined"
            value={targetGroup}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setTargetGroup(e.target.value);
              setTargetGroupErr(false);
            }}
          />

          <TextField
            error={budgetErr}
            id="budget"
            label="Budget Rs. In Lakhs"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={budget}
            onChange={(e) => {
              setBudget(e.target.value);
              setBudgetErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px", padding:"5px 30px 28px 26px",borderRadius:"3px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" style={{paddingTop:"5px"}} onClick={formValidateFun}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B19Inside;
