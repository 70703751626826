import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A6VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArB7Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [faculty, setfaculty] = useState("")
  const [studentName, setStudentName] = useState("");
  const [noofmeetings, setNoofmeetings] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [internshipPlan, setInternshipPlan] = useState("");
  const [progress, setProgress] = useState("");

  const [facultyErr, setFacultyErr] = useState(false)
  const [studentNameErr, setStudentNameErr] = useState(false);
  const [noofmeetingsErr, setNoofmeetingsErr] = useState(false);
  const [instituteIdErr, setInstituteIdErr] = useState(false);
  const [internshipPlanErr, setInternshipPlanErr] = useState(false);
  const [progressErr, setProgressErr] = useState(false);
  const [loader,setLoader] =useState(true)

  const [dirr, setDepartment] = useState([]);
  const [inves, setInves] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A6VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
      handleInstituteName(tok);
   
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Advisory Committee Chairman");
  }, []);
  useEffect(() => {
    const objectString = localStorage.getItem('MReport');
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject)
    }
    const Month = localStorage.getItem('Month');
    if (Month !== null) {
      setAssMonth(Month)
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    handleInvestigators(tok,investigator)
  }, [])

  const backs = () => {
    navigate("/ArB7")
  }

  const edit = (item) => {
    console.log(item);
    setfaculty(item.investigatorId);
    setStudentName(item.studentName);
    setId(item.id);
    setNoofmeetings(item.noofmeetings)
    setInstituteId(item.institute)
    setInternshipPlan(item.internshipPlan)
    setProgress(item.progress)
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleInstituteName = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INSTITUTES}`, {
        headers: h,
      });
      console.log(data);
      setDepartment(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    // console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: faculty,
      StudentName: studentName,
      Noofmeetings: noofmeetings,
      Institute: instituteId,
      InternshipPlan: internshipPlan,
      Progress: progress,
      IAMRSId: state.id,
      InstituteId: parseInt(invis),
      RptMonth: parseInt(assM)
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB7SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted")
          navigate("/ArB7")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB7EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted")
          navigate("/ArB7")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data); setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.A6GETALL}`, {
        headers: h,
      });
      console.log(data); setLoader(false)
      // setStudentbtn(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const formValidateFun = (e) => {
    e.preventDefault()


    if (!faculty) {
      setFacultyErr(true);
      document.getElementById("faculty").focus();
    }

    else if (!studentName) {
      setStudentNameErr(true);
      document.getElementById("studentName").focus();
    } else if (!noofmeetings) {
      setNoofmeetingsErr(true);
      document.getElementById("noofmeetings").focus();
    } else if (!instituteId) {
      setInstituteIdErr(true);
      document.getElementById("instituteId").focus();
    } else if (!internshipPlan) {
      setInternshipPlanErr(true);
      document.getElementById("internshipPlan").focus();
    } else if (!progress) {
      setProgressErr(true);
      document.getElementById("progress").focus();
    }

    else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">

        </div>
        <div className="master-main-span">
          <span>B7. Details of Advisory Committee Meetings</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>

        <div className="form-control">
          <h5 style={{ width: 400, margin: 20 }}>
            B7. Details of Advisory Committee Meetings
          </h5>


          <FormControl
            style={{ width: "48%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name of the faculty            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={facultyErr}
              style={{ height: "45px" }}
              value={faculty}
              onChange={(e) => {
                setfaculty(e.target.value);
                setFacultyErr(false);
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Student Name(Max 250 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={studentName}
            onChange={(e) => {
              setStudentName(e.target.value)
              setStudentNameErr(false)
            }}

            // {...register("StudentNamev")}
            error={studentNameErr}
            helperText={studentNameErr}
          />

          <TextField
            id="outlined-basic"
            label="No Of Meetings Conducted"
            variant="outlined"
            type="number"
    
            style={{ margin: "10px", width: "48%" }}
            value={noofmeetings}

            onChange={(e) => {
              setNoofmeetings(e.target.value)
              setNoofmeetingsErr(false)
            }}

            // {...register("Noofmeetingsv")}
            error={noofmeetingsErr}
            helperText={errors.Noofmeetingsv?.message}
          />

          {/* <FormControl style={{ width: "48%", margin: 12 }}>
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Course Id
            </InputLabel>

            <Select
          
              labelId="demo-simple-select-label"
              id="demo-simple-select"

              // value={age}
              label="Institute Id"
              size="small"
              sx={{ width: "100%" }}
              // onChange={handleChange}
              // value={instituteId}

              // onChange={(e) => setInstituteId(e.target.value)}

              {...register("InstituteIdv")}
              error={errors.InstituteIdv ? true : false}
              helperText={errors.InstituteIdv?.message}
            >
              {dirr?.map((row) => {
                return (
                  <MenuItem value={row.id}>
                    {row.instituteName}
                  </MenuItem>
                );
              })}
            </Select>
            </FormControl> */}

          <TextField
            id="outlined-basic"
            label="institute Identified for thesis work (Max 250 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={instituteId}

            onChange={(e) => {
              setInstituteId(e.target.value)
              setInstituteIdErr(false)
            }}
            // {...register("InstituteIdv")}
            error={instituteIdErr}
            helperText={errors.InstituteIdv?.message}
          />

          <TextField
            id="outlined-basic"
            label="Internship Planned (Max 250 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={internshipPlan}

            onChange={(e) => {
              setInternshipPlan(e.target.value)
              setInternshipPlanErr(false)
            }}

            // {...register("InternshipPlanv")}
            error={internshipPlanErr}
            helperText={errors.InternshipPlanv?.message}
          />

          <TextField
            id="outlined-basic"
            label="Progress (Max 250 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={progress}

            onChange={(e) => {
              setProgress(e.target.value)
              setProgressErr(false)
            }}

            // {...register("Progressv")}
            error={progressErr}
            helperText={errors.Progressv?.message}
          />

          <div className="from-last">
            <button type="submit" style={{ backgroundColor: "#008CBA", height: "20px" }} onClick={backs}>Back</button>
            <button
              type="submit"
              className="sbmit-buttons"

            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArB7Inside;
