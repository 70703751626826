import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { Code } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { target } from "glamor";
import { Instituteex, Instituteexss } from "../Validation";
import { useForm } from "react-hook-form";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";

import { Link, Navigate, useNavigate } from "react-router-dom";

// yupResolver
import { yupResolver } from "@hookform/resolvers/yup";
import ComponentLoader from "../../ComponentLoader";

// import './App.css'

function Institute({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/MasterData");
  };
  const theme = useTheme();
  const useStyles = makeStyles({
    dialogue: {
      maxHeight: "none",
      height: "350px",
      [theme.breakpoints.down("md")]: {
        width: "100vw",
        height: "70vh",
        margin: 0,
        maxHeight: "none",
        maxWidth: "none",
        borderRadius: 0,
      },
    },
  });
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [instituteupdate, setInstituteUpdate] = useState("");

  const [loader,setLoader] =useState(true)

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [resetPassword, setResetPassword] = useState("");

  // const [userId, setUserId] = useState("");
  const [code, setCode] = useState(null);
  const [PrincipalName, setPrincipalName] = useState(null);
  const [principalemail, setPrincipalEmail] = useState(null);
  const [directordepartmentname, setDirectordeepartmentname] = useState(null);
  const [principalmobileno, setPrincipalMobileNo] = useState(null);
  const [password, setPassword] = useState(null);
  const [instituteName, setInstituteName] = useState(null);
  // const [Address, setAddress] = useState("");
  // const [City, setCity] = useState("");
  // const [District, setDistrict] = useState("");
  // const [Pincode, setPincode] = useState("");
  // const [PrincipalMobileNo, setPrincipalMobileNO] = useState("")
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [email, setEmail] = useState("");

  // const [masterInstitute,setMasterInsititute] = useState({
  //     Address:""
  // })

  //const [code, setCode] = useState("");
  const [instituteNameerror, setInstituteNameError] = useState("");
  const [PrincipalNameerror, setPrincipalNameError] = useState("");
  const [principalemailerror, setPrincipalEmailError] = useState("");
  const [principalmobilenoerror, setPrincipalMobileNoError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [valids, setValids] = useState(Instituteex);

  const [openReset, setOpenReset] = useState(false);
 const [showSubmitButton, setShowSubmitButton] = useState(false);

 const [depatdirector,setDepatdirector] = useState([])
 const [depadtmentname, setDepartmetName] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(Instituteex),
  });

  const minLength = 6;

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleInstitute(tok);
      handleDepartmentName(tok);
    }
    head("Institute");
  }, []);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const navigate = useNavigate();

  const handleResetOpen = () => {
    setOpenReset(true);
  };

  const handleResetClose = () => {
    setOpenReset(false);
  };

  const handleClickOpen = () => {
    // setShowSubmitButton(false);
    // setOpen(true);
    navigate("/institudeInside")
  };

  const handleClose = () => {
    setOpen(false);
    setShowSubmitButton(true);
    setId(0);
    reset({ errors: {} });
    setValue("Icode", "");
    setValue("Insname", "");
    setValue("Prinname", "");
    setValue("Prinemail", "");
    setValue("Prinemobileno", "");
    setValue("Prineepassword", "");
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


  const reload = () => {
    window.location.reload();
  };
  // const onChangePassword = (e) => {
  //     const password = e.target.value;
  //     setPassword(password)
  //     const regexPassword = /^(?=.?[A-Z])(?=.?[a-z])(?=.?[0-9])(?=.?[#?!@$%^&*-]).{8,}$/
  //     if (!password.trim()) {
  //         setPasswordError("Password is requird")
  //     }
  //     else if (password.length < 8) {
  //         setPasswordError("Password mininum length 8")
  //     }
  //     else if (!regexPassword.test(password)) {
  //         setPasswordError("Password should have 1 uppercase 1 lowercase 1 capital 1 spl character min 8 ")
  //     } else {
  //         setPasswordError("")
  //     }
  //   }

  //edit button function value pass
  const edit = (item) => {
    console.log(item);
    
    navigate("/institudeInside", { state: item });
  console.log(item);
  
  
    
  };
  console.log(errors);

  

  //institutes Table
  const handleInstitute = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INSTITUTES}`, {
        headers: h,
      });
      console.log(data);
      setDirect(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  //+new button click function date value and
  // Directorregister api
  const handlePopupInstitute = async (data) => {
    console.log(data);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      InstituteName: data.Insname,
      PrincipalName: data.Prinname,
      PrincipalEmail: data.Prinemail,
      PrincipalMobileNo: data.Prinemobileno,
      Password: data.Prineepassword,
      Code: data.Icode,
      DirectorateId: parseInt(data.Directordepartname)
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INSTITUTEADD}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
          reload()
          handleInstitute(token);
        } else {
          toastError("Please Contact to Technology Partner");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INSTITUTEUPDATE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
          handleInstitute(token);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setValids(Instituteex);
  };

  const handleResetPassword = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      username: principalemail,
      password: resetPassword
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.RESTPASSWORD}`,
        d,
        { headers: h }
      );
      console.log(data);
      if(data.result == "Password Reset Successfully."){
          toastSuccess("Successfully Reset");
          setTimeout(function() {
            reload()
          }, 6000);
      } else {
        toastError(data.errorMsgs[0])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) =>
    setDirect((prev) => {
      return { ...prev, page: newPage };
    });

  const handleChangeRowsPerPage = (event) => {
    let value = parseInt(event.target.value);
    setDirect((prev) => {
      return { ...prev, rowsPerPage: value, page: 5 };
    });
  };

  const handleDepartmentName = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.DIRECTORATE}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setDepatdirector(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  return (
    <div style={{ marginTop: 70 }}>
      <ToastContainer /> <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Institute</span>
        </div>
      </div>
      <div>
        <ToastContainer />
        <div className="table-container-grid directorate-padding">
          <div className="table-head">
            {/* <div>
              <button className="top-butto" onClick={backButton}>
                <ArrowBackIcon
                  style={{ marginBottom: 3 }}
                  className="sidebar-icon"
                />
                Back
              </button>
            </div> */}
            <div>
              <button className="top-button" onClick={handleClickOpen}>
                + Add
              </button>
            </div>
          </div>

          <Dialog
            open={openReset}
            onClose={handleResetClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h5 style={{ color: "#fff", fontSize: "15px" }}>
                Reset Password
              </h5>
            </DialogTitle>
            <DialogContent style={{ padding: "30px" }}>
              {/* {id > 0 ? null : ( */}
              <div className="box width-60">
                <TextField
                  // error={resetPasswordErr}
                  fullWidth
                  id="resetPassword"
                  label="Reset Password"
                  InputProps={{
                    inputProps: {
                      maxLength: minLength,
                    },
                  }}
                  variant="outlined"
                  size="small"
                  value={resetPassword}
                  onChange={(e) => {
                    setResetPassword(e.target.value);
                    // setResetPasswordErr(false);
                  }}
                />
              </div>
              {/* )} */}
            </DialogContent>
            <div
              className="dialogue_action_button"
              style={{ justifyContent: "center" }}
            >
              <button type="submit" onClick={handleResetPassword}>
                Submit
              </button>
            </div>
          </Dialog>

          <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              component: "form",
              onSubmit: handleSubmit(handlePopupInstitute),
            }}
            classes={{ paper: classes.dialogue }}
          >
            <DialogTitle id="scroll-dialog-title">
              <div className="dialogueTittle">
                <div style={{ marginBottom: 4 }}>
                  <h4>Institute</h4>
                </div>
                <div onClick={handleClose}>
                  <CloseIcon className="dialogue_close_icon" />
                </div>
              </div>
            </DialogTitle>

            <DialogContent className="Dialog-box" style={{ padding: 0 }}>
              <div className="manu-form manu-form2">
                <div className="box width-40">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Code"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setCode(e.target.value)}
                    {...register("Icode")}
                    error={errors.Icode ? true : false}
                    // helperText={errors.Icode?.message}
                  />
                </div>

                <div className="box width-40">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=" Institute"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setInstituteName(e.target.value)}
                    {...register("Insname")}
                    error={errors.Insname ? true : false}
                    helperText={errors.Insname?.message}
                  />
                  <span style={{ color: "red", fontSize: 10 }}>
                    {instituteNameerror}
                  </span>
                </div>

                <div className="box width-40">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Institute Head Name"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setPrincipalName(e.target.value)}
                    {...register("Prinname")}
                    error={errors.Prinname ? true : false}
                    helperText={errors.Prinname?.message}
                  />
                  <span style={{ color: "red", fontSize: 10 }}>
                    {PrincipalNameerror}
                  </span>
                </div>

                <div className="box width-40">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Email Id"
                    variant="outlined"
                    size="small"
                    disabled={id > 0 ? true : false}
                    onChange={(e) => setPrincipalEmail(e.target.value)}
                    {...register("Prinemail")}
                    error={errors.Prinemail ? true : false}
                    helperText={errors.Prinemail?.message}
                  />
                  <span style={{ color: "red", fontSize: 10 }}>
                    {principalemailerror}
                  </span>
                </div>

                <div className="box width-40">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Mobile No"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setPrincipalMobileNo(e.target.value)}
                    {...register("Prinemobileno")}
                    error={errors.Prinemobileno ? true : false}
                    helperText={errors.Prinemobileno?.message}
                  />
                  <span style={{ color: "red", fontSize: 10 }}>
                    {principalmobilenoerror}
                  </span>
                </div>

                {/* <div className="box width-40"> */}
                <FormControl
                style={{ width: "45%", margin: 12 }}
                id="depadtmentname"
                size="small"
              >
                <InputLabel id="demo-simple-select-helper-label" size="small">
                  Director Department Name
                </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  style={{ height: "45px" }}
                  // value={depadtmentname}
                  onChange={(e) => setDirectordeepartmentname(e.target.value)}
                    {...register("Directordepartname")}
                    error={errors.Directordepartname ? true : false}
                    helperText={errors.Directordepartname?.message}
                >
                  {depatdirector?.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.id}>
                        {option.departmentName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
                {/* </div> */}

                

                {id > 0 ? null : (
                  <div className="box width-40">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="password"
                      type="Password"
                      variant="outlined"
                      size="small"
                      onChange={(e) => setPassword(e.target.value)}
                      {...register("Prineepassword")}
                      error={errors.Prineepassword ? true : false}
                      helperText={errors.Prineepassword?.message}
                    />
                    <span style={{ color: "red", fontSize: 10 }}>
                      {passworderror}
                    </span>
                  </div>
                )}

                <div className="box width-40"></div>
              </div>
              <div className="dialogue_action_button"></div>
            </DialogContent>
            <DialogActions className="dialogue_action_button">
              
              {showSubmitButton && <button
                type="submit"
                onClick={handleResetOpen}
                style={{ width: "auto" }}
              >
                 Reset Password
              </button>}
              <button type="submit">Submit</button>
            </DialogActions>
          </Dialog>
          <TableContainer sx={tableContainerStyle}>
            <Table
              sx={tableStyle}
              aria-labelledby="tableTitle"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="headtable">
                {/* <TableCell align="right">code</TableCell> */}
                <TableCell align="right">Code</TableCell>
                <TableCell align="right">Institute Name</TableCell>
                <TableCell align="right">Institute Head Name</TableCell>
                <TableCell align="right">Email Id</TableCell>
                <TableCell align="right">Mobile No</TableCell>
                <TableCell align="right">Director Department Name</TableCell>
                {/* <TableCell align="right">Password</TableCell> */}
                <TableCell align="right">Option</TableCell>
              </TableHead>
              <TableBody>
                {direct.map((row) => (
                  <TableRow
                    key={row.PrincipalName}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row" id='tablehead' sx={{ background: '#fff', color: 'black' }}>
                                    {row.directorName}
                                </TableCell> */}

                    <TableCell align="right">{row.code}</TableCell>
                    <TableCell align="right">{row.instituteName}</TableCell>
                    <TableCell align="right">{row.principalName}</TableCell>
                    <TableCell align="right">{row.principalEmail}</TableCell>
                    <TableCell align="right">{row.principalMobileNo}</TableCell>
                    <TableCell align="right">{row.directorDepartmentName}</TableCell>
                    {/* <TableCell align="right">{row.Password}</TableCell> */}
                    <TableCell align="right">
                      <button className="tablebutton" onClick={() => edit(row)}>
                        {<Edit className="sidebar-icon" />}
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default Institute;
