import * as React from 'react';
// import './Leave.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import { Box, Container, Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';
import './Educational.css'
import LoopIcon from '@mui/icons-material/Loop';
import {useNavigate}  from 'react-router-dom';

import Edit from "@mui/icons-material/Edit";
import ComponentLoader from '../../ComponentLoader';

// import { Leaveess } from '../Validation';
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useForm } from 'react-hook-form';


// import './App.css'

function Educational({ head }) {


  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: 'none',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([])
  const [Info, setInfo] = useState([])
  const [open, setOpen] = React.useState(false);

  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("")


  //education 
  const [rptDate,setRptDate] = useState("")
  const [courseId,setCourseId] = useState("")
  const [Hours,setHours] = useState("")
  const [courseType,setCourseType] = useState("")
  const [courseTeacher,setCourseTeacher] = useState("")
  const [noOfClassTP,setNoOfClassTP] = useState("")

  const [noofslowlearners,setNoofslowlearners] = useState("")
  const [noofweakness,setNoofweakness] = useState("")
  const [ndditionalefforts,setAdditionalefforts] = useState("")
  const [pass,setPass] = useState("")


  const [guestSpeaker,setGuestSpeaker] = useState("")
  const [affiliation,setAffiliation] = useState("")
  const [titleofthelecture,setTitleofthelecture] = useState("")
  

  const [instituteId,setInstituteId] = useState("")
  const [noOfStudents,setNoOfStudents] = useState("")

  const [loader, setLoader] = useState(true);
  


  const navigate =useNavigate()
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  // const {
  //     register,
  //     handleSubmit,
  //     formState: { errors, isValid },
  //     setValue,
  //     reset,
  //     watch,
  // } = useForm({
  //     resolver: yupResolver(Leaveess),
  // });


  // const handleClickSubmit =()=>{


  // }
  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setId(item.id)
    setCode(item.levCode)
    setLeaveName(item.levName)


  }


  useEffect(() => {
    const tok = localStorage.getItem("token")
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
    }
    head("Educational")
  }, [])

  //    ***************************************************************


  function createData(code, Lname, option) {
    return { code, Lname, option };
  }

  const rows = [
    //     createData('CL', 'Casual Leave', 'Edit'),
    //     createData('SL', 'Sick Leave', 'Edit'),
  ];

  //  const edit = (item) => {
  //     console.log(item);
  //     setOpen(true);
  //     setValue("Leavecode", item.code)
  //     setValue("LeaveNamess", item.instituteName)
  //  }


  const handleLeavepage = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      // LevCode: code,
      // LevName: leavename,
      RptDate:rptDate,
      CourseId:courseId,
      Hours:Hours,
      CourseType:courseType,
      CourseTeacher:courseTeacher,
      NoOfClassTP:noOfClassTP,

      Noofslowlearners:noofslowlearners,
      Noofweakness:noofweakness,
      Additionalefforts:ndditionalefforts,
      Pass:pass,

      GuestSpeaker:guestSpeaker,
      Affiliation:affiliation,
      Titleofthelecture:titleofthelecture,

      InstituteId: parseInt(instituteId),
      NoOfStudents:noOfStudents,

      
    }

    try {
      const { data } = await axios.post(`${window.BASE}${window.EDUCATIONSAVE}`, d, { headers: h });
      console.log(data);setLoader(false)
      setDirect(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };




  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.EDUCATIONALL}`, { headers: h });
      console.log(data);setLoader(false)
      setDirect(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleClickSubmit = () => {
    setOpen(true);
  }
  const handleClickOpen = () => {
    setOpen(true);
    navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    setId(0)
  };

  return (
    <div className='table-container-grid'>
       <ComponentLoader isLoading={loader} />
      <div className="tables-mains">
        <div className='table-head'>
          <button className='top-button' onClick={handleClickOpen}>+ New</button>

          <div className='top-button-site'>
            <input type='search' />
            <button type='button' className='button-1'>{
               <LoopIcon className="sidebar-icon" />
               }</button>
            {/* <button type='button' className='button-1'></button> */}
          </div>
        </div>
      </div>

      <Dialog
      className="dialog"
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      
      >
          <div className='form-control'>
          </div>
       
      
      </Dialog>

      {/* ************************************************************************* */}

      <TableContainer sx={tableContainerStyle}>

        <Table
          sx={tableStyle}
          aria-labelledby="tableTitle"
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead className='headtable'>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Designation</TableCell>
            <TableCell align="right">Course Number</TableCell>
            <TableCell align="right">Tittle and creadit house</TableCell>
            <TableCell align="right">Course Type</TableCell>
            <TableCell align="right">Course Teacher/Associate</TableCell>

            <TableCell align="right">No.of class conducted(T+P)</TableCell>
            <TableCell align="right">Pass</TableCell>
            <TableCell align="right">Guast Speaker</TableCell>
            <TableCell align="right">Tittle</TableCell>
            <TableCell align="right">Ins Name</TableCell>
            <TableCell align="right">No(Stu)</TableCell>
            <TableCell align="right">title of the lecture</TableCell>
            <TableCell align="right">title of the lecture</TableCell>
            <TableCell align="right">  no of Students</TableCell>
          </TableHead>

          <TableBody>
          {direct?.map((row) => (
                                <TableRow
                                    key={row.RptDate}

                                >
            <TableCell align="right">{row.rptDate}</TableCell>
            <TableCell align="right">{row.courseId}</TableCell>
            <TableCell align="right"> {row.courseName}</TableCell>
            <TableCell align="right">{row.courseType}</TableCell>
            <TableCell align="right"> {row.courseTeacher}</TableCell>
            <TableCell align="right">{row.noOfClassTP}</TableCell>

            <TableCell align="right"> {row.noofslowlearners}</TableCell>
            <TableCell align="right">{row.noofweakness}</TableCell>
            <TableCell align="right"> {row.additionalefforts}</TableCell>
            <TableCell align="right">{row.pass}</TableCell>

            <TableCell align="right"> {row.GuestSpeaker}</TableCell>
            <TableCell align="right">{row.affiliation}</TableCell>
            <TableCell align="right">{row.titleofthelecture}</TableCell>

            <TableCell align="right">{row.instituteId}</TableCell>
            <TableCell align="right">{row.noOfStudents}</TableCell>
            
          
            </TableRow>
          ))}
          </TableBody>


        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={direct.length}
      //   rowsPerPage={masterManufacturer.rowsPerPage}
      //   page={masterManufacturer.page}
      //   onPageChange={handleChangePage}
      //   onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </div>
  )
}
export default Educational;

