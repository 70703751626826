import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";

import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArA5Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Natureofcoaching, setNatureofcoaching] = useState("");
  const [Noofclassesoffered, setNoofclassesoffered] = useState("");
  const [FellowshipName, setFellowshipName] = useState("");
  const [NoOfStudent, setNoOfStudent] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti,setInsti] =useState()
  const [inves,setInves] =useState([]);
  const [duty,setDuty] =useState([]);
  const [loader,setLoader] =useState(true)
  const[value,setValue]=useState({
    InvestigatorId:"",
    OnDytyId:"",
    FromDate:"",
    ToDate:"",
    PlaceOfVisit:"",
    Purpose:"",
   });

   const[errvalue,setErrValue]=useState({
    InvestigatorId:false,
    OnDytyId:false,
    FromDate:false,
    ToDate:false,
    PlaceOfVisit:false,
    Purpose:false,
   });


  const navigate = useNavigate();
  const location = useLocation();

  const [dropnature, setDropnature] = useState([]);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A8VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      
      onDuty(tok)

      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student facilitation");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
    handleInvestigators(tok,insti);
  }, []);

  const edit = (item) => {
    console.log(item.investigatorName,item.tourOrDutyName);
    // investigatorName
// tourOrDutyName
// fromDate
// toDate
// placeOfVisit
// purpose
    setValue({
      InvestigatorId:item.investigatorId,
      OnDytyId:item.onDytyId,
      FromDate:(moment(item.fromDate).format("YYYY-MM-DD")),
      ToDate:(moment(item.toDate).format("YYYY-MM-DD")),
      PlaceOfVisit:item.placeOfVisit,
      Purpose:item.purpose,
    })
    setId(item.id)
    // setProgrammeDateTime(moment(item.programmeDateTime).format("YYYY-MM-DD"));
   
  };

  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
  })
  console.log("value",value);

  const backs = () => {
    navigate("/ArA5");
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  
  const onDuty = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.ONDUTY}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setDuty(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };
  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: value.InvestigatorId,
      OnDytyId:  value.OnDytyId,
      FromDate: dateFormat( value.FromDate),
      ToDate:  dateFormat(value.ToDate),
      PlaceOfVisit:  value.PlaceOfVisit,
      Purpose:  value.Purpose,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };


    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA5SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA5")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA5EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA5")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const formValidateFun = (e) => {
    e.preventDefault()
    if (!value.InvestigatorId) {
      setErrValue((prev) => ({ ...prev, InvestigatorId: true }))
      document.getElementById("InvestigatorId").focus();
    }
     else if (!value.OnDytyId) {
      setErrValue((prev) => ({ ...prev, OnDytyId: true }))
      document.getElementById("OnDytyId").focus();
    } 
    else if (!value.FromDate) {
      setErrValue((prev) => ({ ...prev, FromDate: true }))
      document.getElementById("FromDate").focus();
    } 
    else if (!value.ToDate) {
      setErrValue((prev) => ({ ...prev, ToDate: true }))
      document.getElementById("ToDate").focus();
    } 
    else if (!value.PlaceOfVisit) {
      setErrValue((prev) => ({ ...prev, PlaceOfVisit: true }))
      document.getElementById("PlaceOfVisit").focus();
    } 
    else if (!value.Purpose) {
      setErrValue((prev) => ({ ...prev, PlacePurposeOfVisit: true }))
      document.getElementById("Purpose").focus();
    } 
    else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A5.  Tour/On Duty
</span>
        </div>
      </div>
      

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ width: 350, margin: 20 }}>A5.  Tour/On Duty
</h5>

          
 
         <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Name of the Staff
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={errvalue.InvestigatorId}
              style={{ height: "45px" }}
              value={value.InvestigatorId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

      

        <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="OnDytyId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Tour / on-duty
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="OnDytyId"
              error={errvalue.OnDytyId}
              style={{ height: "45px" }}
              value={value.OnDytyId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, OnDytyId: false }));
              }}
            >
              {duty?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.tourOrDutyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>


          <TextField
            id="FromDate"
            label="From Date "
            variant="outlined"
            name="FromDate"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={value.FromDate}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, FromDate: false }));
            }}
            error={errvalue.FromDate}
            helperText={errors.FellowshipName?.message}
          />

         <TextField
            id="ToDate"
            label="To Date "
            variant="outlined"
            name="ToDate"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={value.ToDate}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ToDate: false }));
            }}
            error={errvalue.ToDate}
            helperText={errors.FellowshipName?.message}
          />

          <TextField
            id="PlaceOfVisit"
            label=" Place visited (Max 500 Character)"
            variant="outlined"
            name="PlaceOfVisit"
            style={{ margin: "10px", width: "48%" }}
            value={value.PlaceOfVisit}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, PlaceOfVisit: false }));
            }}
            error={errvalue.PlaceOfVisit}
            helperText={errors.NoOfStudent?.message}
          />


          <TextField
            id="Purpose"
            label=" Purpose (Max 500 Character)"
            variant="outlined"
            name="Purpose"
            style={{ margin: "10px", width: "48%" }}
            value={value.Purpose}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Purpose: false }));
            }}
            error={errvalue.Purpose}
            helperText={errors.NoOfStudent?.message}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
            // onClick={handleLeavepage}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArA5Inside;