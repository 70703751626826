import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { C6VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";

function C6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Lovboardreviewer, setLovboardreviewer] = useState([]);

  const [organization, setOrganization] = useState("");
  const [editorialBoardReviewer, setEditorialBoardReviewer] = useState("");
  const [papersprocessedreviewed, setpapersprocessedreviewed] = useState("");

  const [organizationErr, setOrganizationErr] = useState(false);
  const [editorialBoardReviewerErr, setEditorialBoardReviewerErr] = useState(false);
  const [papersprocessedreviewedErr, setpapersprocessedreviewedErr] = useState(false);
  const [loader,setLoader] =useState(true);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(C6VALI),
  });

  const backs = () => {
    navigate("/C6");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Extension Activities");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setOrganization(item.organization);
    setEditorialBoardReviewer(item.editorialBoardReviewer);
    setpapersprocessedreviewed(item.nofpapersprocessedreviewed);

    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov Board Reviewer
  const handleLov = async (token, EditorialBoardReviewer) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"EditorialBoardReviewer"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovboardreviewer(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      Organization: organization,
      EditorialBoardReviewer: editorialBoardReviewer,
      Nofpapersprocessedreviewed: papersprocessedreviewed,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C6");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C6EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!organization){
      setOrganizationErr(true)
      document.getElementById("organization").focus()
    }else if(!editorialBoardReviewer){
      setEditorialBoardReviewerErr(true)
      document.getElementById("editorialBoardReviewer").focus()
    }else if(!papersprocessedreviewed){
      setpapersprocessedreviewedErr(true)
      document.getElementById("papersprocessedreviewed").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(C6).Editorial & Review services</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>C6. Editorial / Review Services</h5>

          <TextField
            error={organizationErr}
            id="organization"
            label="Organization / Journal"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={organization}
            onChange={(e) => {
                setOrganization(e.target.value);
                setOrganizationErr(false);
            }}
          />

          {/* <TextField
            error={editorialBoardReviewerErr}
            id="editorialBoardReviewer"
            label="Editorial Board / Reviewer"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={editorialBoardReviewer}
            onChange={(e) => {
                setEditorialBoardReviewer(e.target.value);
                setEditorialBoardReviewerErr(false);
            }}
          /> */}

          <FormControl style={{ width: "48%", margin: 12 }} error={editorialBoardReviewerErr} id="editorialBoardReviewer">
            <InputLabel id="demo-simple-select-helper-label" size="small">
                Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="editorialBoardReviewer"
              value={editorialBoardReviewer}
              label="Editorial Board / Reviewer"
              size="small"
              // sx={{ width: "100%" }}
              // style={{ margin: "10px", width: "48%" }}
              onChange={(e) => {
                setEditorialBoardReviewer(e.target.value)
                setEditorialBoardReviewerErr(false)
              }}
            >
              {Lovboardreviewer?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={papersprocessedreviewedErr}
            id="papersprocessedreviewed"
            label=" Papers Processed / Reviewed (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={papersprocessedreviewed}
            onChange={(e) => {
                setpapersprocessedreviewed(e.target.value);
                setpapersprocessedreviewedErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={()=>formValidateFun()} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default C6Inside;
