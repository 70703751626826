import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D1VALI } from "../../../Validation";

// import { Activetiesvalidation } from '../Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";
// import IncomeGenerated from "./IncomeGenerated";

function ArF2Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [revenueSource, setRevenueSource] = useState("");
  const [revenueLastYear, setRevenueLastYear] = useState("");
  const [revenueLastMonth, setRevenueLastMonth] = useState("");
  const [revenueCurMonth, setRevenueCurMonth] = useState("");
  const [revenueTotal, setRevenueTotal] = useState("");
  const [loader,setLoader] =useState(true);
  const [revenueSourceErr, setRevenueSourceErr] = useState(false);
  const [revenueLastYearErr, setRevenueLastYearErr] = useState(false);
  const [revenueLastMonthErr, setRevenueLastMonthErr] = useState(false);
  const [revenueCurMonthErr, setRevenueCurMonthErr] = useState(false);
  const [revenueTotalErr, setRevenueTotalErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const handleThird = (e) => {
    setRevenueLastMonth();
  };

  const handleFourth = (e) => {
    setRevenueCurMonth();
  };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D1VALI),
  });

  const backs = () => {
    navigate("/ArF2");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("  Production and distribution of seeds / seedlings / Bio-inputs");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    setLoader(false);
  }, []);

  const edit = (item) => {
    console.log(item);
    setRevenueSource(item.revenueSource);
    setRevenueLastYear(item.revenueLastYear);
    setRevenueLastMonth(item.revenueLastMonth);
    setRevenueCurMonth(item.revenueCurMonth);
    setRevenueTotal(item.revenueTotal);
    setId(item.id);
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      RevenueSource: revenueSource,
      RevenueLastYear: revenueLastYear,
      RevenueLastMonth: revenueLastMonth,
      RevenueCurMonth: revenueCurMonth,
      RevenueTotal: revenueTotal,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Fr2SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF2");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Fr2EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF2");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!revenueSource) {
      setRevenueSourceErr(true);
      document.getElementById("revenueSource").focus();
    } else if (!revenueLastYear) {
      setRevenueLastYearErr(true);
      document.getElementById("revenueLastYear").focus();
    } else if (!revenueLastMonth) {
      setRevenueLastMonthErr(true);
      document.getElementById("revenueLastMonth").focus();
    } else if (!revenueCurMonth) {
      setRevenueCurMonthErr(true);
      document.getElementById("revenueCurMonth").focus();
    } else if (!revenueTotal) {
      setRevenueTotalErr(true);
      document.getElementById("revenueTotal").focus();
    } else {
      handleLeavepage();
    }
  };

  //calculation All Onchange
  useEffect(() => {
    let third = revenueLastMonth ? parseInt(revenueLastMonth) : 0;
    let fourth = revenueCurMonth ? parseInt(revenueCurMonth) : 0;
    let total = third + fourth;
    setRevenueTotal(total);
  }, [revenueLastMonth, revenueCurMonth]);

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>F2. Income generated</span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control">
        <h5 style={{ margin: 20 }}>F2. Income generated</h5>

        <TextField
          error={revenueSourceErr}
          id="revenueSource"
          label="Source of revenue"
          variant="outlined"
        
          style={{ margin: "10px", width: "48%" }}
          value={revenueSource}
          onChange={(e) => {
            setRevenueSource(e.target.value);
            setRevenueSourceErr(false);
          }}
        />

        <TextField
          error={revenueLastYearErr}
          id="revenueLastYear"
          label="Revenue generated up to  the last year (Rs.)(Only Number)"
          variant="outlined"
          type="number"
          style={{ margin: "10px", width: "48%" }}
          value={revenueLastYear}
          onChange={(e) => {
            setRevenueLastYear(e.target.value);
            setRevenueLastYearErr(false);
          }}
        />

        <TextField
          error={revenueLastMonthErr}
          id="revenueLastMonth"
          label="Revenue generated last month (Rs.) Upto July 2023 (Only Number)"
          variant="outlined"
          type="number"
          style={{ margin: "10px", width: "48%" }}
          value={revenueLastMonth}
          onChange={(e) => {
            handleThird();
            setRevenueLastMonth(e.target.value);
            setRevenueLastMonthErr(false);
          }}
        />

        <TextField
          error={revenueCurMonthErr}
          id="revenueCurMonth"
          label="Revenue generated during this month (Rs.)- August 2023 (Only Number)"
          variant="outlined"
          type="number"
          style={{ margin: "10px", width: "48%" }}
          value={revenueCurMonth}
          onChange={(e) => {
            handleFourth();
            setRevenueCurMonth(e.target.value);
            setRevenueCurMonthErr(false);
          }}
        />

        <TextField
          error={revenueTotalErr}
          id="revenueTotal"
          label="Total revenue(Rs) (Only Number)"
          variant="outlined"
          type="number"
          style={{ margin: "10px", width: "48%" }}
          value={revenueTotal}
          onChange={(e) => {
            setRevenueTotal();
            // setRevenueTotalErr(false);
          }}
          disabled
        />

        <div className="from-last">
          <button
            type="submit"
            style={{
              backgroundColor: "#008CBA",
              padding: "5px",
              borderRadius: "3px",
              width: "80px",
              color: "#fff",
            }}
            onClick={backs}
          >
            Back
          </button>
          <button
            type="submit"
            style={{ paddingTop: "5px" }}
            className="sbmit-buttons"
            onClick={() => {
              formValidateFun();
            }}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArF2Inside;
