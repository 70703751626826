import React from 'react';
import Chart from 'react-apexcharts';
import { Row, Col, Card, Table, ListGroup } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import customerChart from './chart/analytics-cuatomer-chart';
import customerChart1 from './chart/analytics-cuatomer-chart-1';
import OrderCard from './chart/OrderCard';
import BarSimpleChart from './chart/BarSimpleChart';
import { Height } from '@mui/icons-material';



const DashAnalytics = () => {


    return (
        <React.Fragment>
            <Row class="dash-bord">
                {/* order cards */}
                <Col xl={2}>

                </Col>
                <Col md={6} xl={3}>
                    <OrderCard
                        params={{
                            title: 'No of Live Project',
                            class: 'bg-c-blue',
                            icon: 'feather icon-book',
                            primaryText: '08',
                            // secondaryText: 'Completed Orders',
                            // extraText: '351'
                        }}
                    />
                </Col>
                <Col md={6} xl={3}>
                    <OrderCard
                        params={{
                            title: 'Completed Project',
                            class: 'bg-c-green',
                            icon: 'feather icon-book',
                            primaryText: '03',
                            // secondaryText: 'This Month',
                            // extraText: '213'
                        }}
                    />
                </Col>
                <Col md={6} xl={3}>
                    <OrderCard
                        params={{
                            title: 'New Project',
                            class: 'bg-c-yellow',
                            icon: 'feather icon-book',
                            primaryText: '02',
                            // secondaryText: 'This Month',
                            // extraText: '$5,032'
                        }}
                    />
                </Col>
                <Col xl={1}>
                    
                </Col>

            </Row>

            <Row class="dash-bord">
                <Col xl={6} sm={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Funding Agency Wise Project</Card.Title>
                        </Card.Header>
                        <Card.Body className="text-center">
                            <BarSimpleChart />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6} sm={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Science Wise Project</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive hover >
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Project Name</th>
                                        <th>Project No</th>
                                        <th>Budget</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Science-1</td>
                                        <td>3</td>
                                        <td>4,50000</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Science-2</td>
                                        <td>2</td>
                                        <td>3,50000</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>science-3</td>
                                        <td>6</td>
                                        <td>7,50000</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row class="dash-bord">
                
                <Col sm={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h5">Project Details</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Sciencist</th>
                                        <th>Project Code</th>
                                        <th>Project Name</th>
                                        <th>Budget</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Manimaran</td>
                                        <td>P-21</td>
                                        <td>Science-1</td>
                                        <td>4,50000</td>
                                    </tr>
                                    <tr>
                                        <td>Manimaran</td>
                                        <td>P-21</td>
                                        <td>Science-1</td>
                                        <td>4,50000</td>
                                    </tr>
                                    <tr>

                                        <td>Manimaran</td>
                                        <td>P-21</td>
                                        <td>Science-1</td>
                                        <td>4,50000</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
               
            </Row>
        </React.Fragment >
    );
};

export default DashAnalytics;
