import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A5VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArD3Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true);
  const [authors, setAuthors] = useState("");
  const [year, setYear] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [conferenceTitle, setConferenceTitle] = useState("");
  const [conferenceDate, setConferenceDate] = useState("");
  const [place, setPlace] = useState("");
  const [organizer, setOrganizer] = useState("");

  const [authorsErr, setAuthorsErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const [articleTitleErr, setArticleTitleErr] = useState(false);
  const [conferenceTitleErr, setConferenceTitleErr] = useState(false);
  const [conferenceDateErr, setConferenceDateErr] = useState(false);
  const [placeErr, setPlaceErr] = useState(false);
  const [organizerErr, setOrganizerErr] = useState(false);


  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [Lovrole, setLovrole] = useState([]);
  const [LovroleParam, setLovroleParam] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [check, setCheck] = useState(0);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A5VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student Guidance");
  }, []);



  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    setLoader(false);
  }, []);

  const backs = () => {
    navigate("/ArD3");
  };
  
  const edit = (item) => {
    console.log(item);
    setAuthors(item.authors);
    setYear(item.year);
    setArticleTitle(item.articleTitle);
    setConferenceTitle(item.conferenceTitle);
    setConferenceDate(item.conferenceDate);
    setPlace(item.place);
    setOrganizer(item.organizer);
    setId(item.id);
  };


  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      Authors: authors,
      Year: year,
      ArticleTitle: articleTitle,
      ConferenceTitle: conferenceTitle,
      ConferenceDate: conferenceDate,
      Place: place,
      Organizer: organizer,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD3SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArD3");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD3EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArD3");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }; 
  
  const formValidateFun = () => {
    if (!authors) {
      setAuthorsErr(true);
      document.getElementById("authors").focus();
    } else if (!year) {
      setYearErr(true);
      document.getElementById("year").focus();
    } else if (!articleTitle) {
      setArticleTitleErr(true);
      document.getElementById("articleTitle").focus();
    } else if (!conferenceTitle) {
      setConferenceTitleErr(true);
      document.getElementById("conferenceTitle").focus();
    } else if (!conferenceDate) {
      setConferenceDateErr(true);
      document.getElementById("conferenceDate").focus();
    } else if (!place) {
      setPlaceErr(true);
      document.getElementById("place").focus();
    } else if (!organizer) {
      setOrganizerErr(true);
      document.getElementById("organizer").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>D3. Papers published in Conference / Seminars / Symposium</span>
        </div>
      </div>
      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control" style={{ marginTop: 50 }}>
        <h5 style={{ margin: 20 }}>
          {" "}
          D3. Papers published in Conference / Seminars / Symposium
        </h5>

        <TextField
          error={authorsErr}
          id="authors"
          label="Authors (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={authors}
          onChange={(e) => {
            setAuthors(e.target.value);
            setAuthorsErr(false);
          }}
        />

        <TextField
          error={yearErr}
          id="year"
          label="Year"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            setYearErr(false);
          }}
        />

        <TextField
          error={articleTitleErr}
          id="articleTitle"
          label="Article Title (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={articleTitle}
          onChange={(e) => {
            setArticleTitle(e.target.value);
            setArticleTitleErr(false);
          }}
        />

        <TextField
          error={conferenceTitleErr}
          id="conferenceTitle"
          label="Conference Title (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={conferenceTitle}
          onChange={(e) => {
            setConferenceTitle(e.target.value);
            setConferenceTitleErr(false);
          }}
        />

        <TextField
          error={conferenceDateErr}
          id="conferenceDate"
          label="Conference Date"
          variant="outlined"
          type="date"
          value={conferenceDate}
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            setConferenceDate(e.target.value);
            setConferenceDateErr(false);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            placeholder: "",
          }}
        />

        <TextField
          error={placeErr}
          id="place"
          label="Place (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={place}
          onChange={(e) => {
            setPlace(e.target.value);
            setPlaceErr(false);
          }}
        />

        <TextField
          error={organizerErr}
          id="organizer"
          label="Organizer (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={organizer}
          onChange={(e) => {
            setOrganizer(e.target.value);
            setOrganizerErr(false);
          }}
        />

        <div className="from-last">
          <button
            type="submit"
            style={{
              backgroundColor: "#008CBA",
              padding: "5px",
              borderRadius: "3px",
              width: "80px",
              color: "#fff",
            }}
            onClick={backs}
          >
            Back
          </button>
          <button
            type="submit"
            className="sbmit-buttons"
            onClick={formValidateFun}
            style={{ paddingTop: "5px" }}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArD3Inside;
