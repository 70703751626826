import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect, useState } from "react";
import axios from "axios";
import { dateFormat } from '../../utils/Date';
import { TextField } from '@mui/material';
import ComponentLoader from '../../ComponentLoader';


const ExcutiveSummary = () => {
    const [token, setToken] = useState("");
    const [state, setState] = useState();
    const [assM, setAssMonth] = useState("");
    const [invis, setInvestigator] = useState();
    const [insti, setInsti] = useState();
    const [initialData, setInitialData] = useState();

    const [role, setRole] = useState("");
    const [id, setId] = useState(0)
    console.log(initialData)
    const [loader,setLoader] =useState(true)


    const [value, setValue] = useState({
        AcademicActivities: "",
        ResearchActivities: "",
        ExtensionActivities: "",
        NoOfTME: "",
        TVROProgram: "",
        NoOfProject: "",
        ResearchArticle: "",
        BookChapters: "",
        Books: "",
        NoOfAward: "",
        TotalIncome: "",
        TotalAudit: "",
        DroppedAudit: "",
        PendingAudit: "",
        Visitors: ""
    })
    const [errValue, setErrValue] = useState({
        AcademicActivities: false,
        ResearchActivities: false,
        ExtensionActivities: false,
        NoOfTME: false,
        TVROProgram: false,
        NoOfProject: false,
        ResearchArticle: false,
        BookChapters: false,
        Books: false,
        NoOfAward: false,
        TotalIncome: false,
        TotalAudit: false,
        DroppedAudit: false,
        PendingAudit: false,
        Visitors: false
    });

    useEffect(() => {
        const tok = localStorage.getItem("token");
        setToken(tok);
        console.log(tok);
        if (tok != null && tok != undefined) {

        }

        var rolee= localStorage.getItem("role");
    setRole(rolee)
    }, []);


    useEffect(() => {
        const objectString = localStorage.getItem("MReport");
        const tok = localStorage.getItem("token");
        const myObject = JSON.parse(objectString);
        if (myObject !== null) {
            setState(myObject);
            handleGet(tok, myObject.id)
        }
        const Month = localStorage.getItem("Month");
        if (Month !== null) {
            setAssMonth(Month);
        }
        const insti = localStorage.getItem("loginId");
        setInsti(insti);
        setLoader(false)
    }, []);

    const navigate = useNavigate();

    const nextButton = () => {
        navigate("/ArA1(a-e)")
    }
    
    const handleData = (event) => {
        const { name, value } = event.target
        setValue(prev => ({
            ...prev,
            [name]: value
        }))
        // setId(globalID)
    }
    console.log("value", value);

    const todayDate = new Date();

    const handleGet = async (tok, id) => {
        console.log(tok, id)
        const h = {
            Authorization: `Bearer ${tok}`,
            "Content-Type": "application/json",
        };
        try {
            const { data } = await axios.get(
                `${window.BASE}${window.EXCUTIVE_GET}${"/" + id}`,
                { headers: h }
            );
            if (data.length > 0) {
                let i = data.length - 1
                const showData = data[i];
                setId(showData.id)
                setValue({
                    AcademicActivities: showData.academicActivities,
                    ResearchActivities: showData.researchActivities,
                    ExtensionActivities: showData.extensionActivities,
                    NoOfTME: showData.noOfTME,
                    TVROProgram: showData.tvroProgram,
                    NoOfProject: showData.noOfProject,
                    ResearchArticle: showData.researchArticle,
                    BookChapters: showData.bookChapters,
                    Books: showData.books,
                    NoOfAward: showData.noOfAward,
                    TotalIncome: showData.totalIncome,
                    TotalAudit: showData.totalAudit,
                    DroppedAudit: showData.droppedAudit,
                    PendingAudit: showData.pendingAudit,
                    Visitors: showData.visitors
                })
            }
            console.log(data);



            // setGlobalID(showData.id)

        } catch (error) {
            console.log(error);
        }
    }
    console.log(id, "check d");
    const handleLeavepage = async () => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        console.log(token);
        console.log(id, "id")
        const d = {
            id,
            AcademicActivities: value.AcademicActivities,
            ResearchActivities: value.ResearchActivities,
            ExtensionActivities: value.ExtensionActivities,
            NoOfTME: value.NoOfTME,
            TVROProgram: value.TVROProgram,
            NoOfProject: value.NoOfProject,
            ResearchArticle: value.ResearchArticle,
            BookChapters: value.BookChapters,
            Books: value.Books,
            NoOfAward: value.NoOfAward,
            TotalIncome: value.TotalIncome,
            TotalAudit: value.TotalAudit,
            DroppedAudit: value.DroppedAudit,
            PendingAudit: value.PendingAudit,
            Visitors: value.Visitors,
            RptDate: dateFormat(new Date()),
            IAMRSId: state.id,
            InstituteId: parseInt(insti),
            RptMonth: parseInt(assM),
        };

        if (id == 0) {
            try {
                const { data } = await axios.post(`${window.BASE}${window.EXCUTIVE}`, d, {
                    headers: h,
                });
                console.log(data);
                if (data.result === "Success") {
                    alert("Successfully Submitted");
                    setId(0);


                } else {
                    alert(data.errorMsgs[0])
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            try {
                const { data } = await axios.post(`${window.BASE}${window.EXCUTIVE}`, d, {
                    headers: h,
                });
                console.log(data);
                if (data.result === "Success") {
                    alert("Successfully Submitted");
                    setId(0);

                } else {
                    alert(data.errorMsgs[0])
                }
            } catch (error) {
                console.log(error);
            }

        }

    };

    const formValidateFun = () => {
        if (!value.AcademicActivities) {
            setErrValue((prev) => ({ ...prev, AcademicActivities: true }))
            document.getElementById("AcademicActivities").focus();
        }
        else if (!value.ResearchActivities) {
            setErrValue((prev) => ({ ...prev, ResearchActivities: true }))
            document.getElementById("ResearchActivities").focus();
        }
        else if (!value.NoOfTME) {
            setErrValue((prev) => ({ ...prev, NoOfTME: true }))
            document.getElementById("NoOfTME").focus();
        }
        else if (!value.ExtensionActivities) {
            setErrValue((prev) => ({ ...prev, ExtensionActivities: true }))
            document.getElementById("ExtensionActivities").focus();
        }
        else if (!value.TVROProgram) {
            setErrValue((prev) => ({ ...prev, TVROProgram: true }))
            document.getElementById("TVROProgram").focus();
        }
        else if (!value.NoOfProject) {
            setErrValue((prev) => ({ ...prev, NoOfProject: true }))
            document.getElementById("NoOfProject").focus();
        }
        else if (!value.ResearchArticle) {
            setErrValue((prev) => ({ ...prev, ResearchArticle: true }))
            document.getElementById("ResearchArticle").focus();
        }
        else if (!value.BookChapters) {
            setErrValue((prev) => ({ ...prev, BookChapters: true }))
            document.getElementById("BookChapters").focus();
        }
        else if (!value.Books) {
            setErrValue((prev) => ({ ...prev, Books: true }))
            document.getElementById("Books").focus();
        }
        else if (!value.NoOfAward) {
            setErrValue((prev) => ({ ...prev, NoOfAward: true }))
            document.getElementById("NoOfAward").focus();
        }
        else if (!value.TotalIncome) {
            setErrValue((prev) => ({ ...prev, TotalIncome: true }))
            document.getElementById("TotalIncome").focus();
        }
        else if (!value.TotalAudit) {
            setErrValue((prev) => ({ ...prev, TotalAudit: true }))
            document.getElementById("TotalAudit").focus();
        }
        else if (!value.DroppedAudit) {
            setErrValue((prev) => ({ ...prev, DroppedAudit: true }))
            document.getElementById("DroppedAudit").focus();
        }
        else if (!value.PendingAudit) {
            setErrValue((prev) => ({ ...prev, PendingAudit: true }))
            document.getElementById("PendingAudit").focus();
        }
        else if (!value.Visitors) {
            setErrValue((prev) => ({ ...prev, Visitors: true }))
            document.getElementById("Visitors").focus();
        }

        else {
            handleLeavepage();
        }

    };
    const backs = () => {
        navigate("/GeneralAR")
    }

    return (
        <div className="main-container-margin">
            <ComponentLoader isLoading={loader} />
            <div className="master-main d-flex">
                <div className="head-icon">
                    <Link to="/GeneralAR">
                        <HomeIcon style={{ color: "white", marginLeft: 38 }} />
                    </Link>
                </div>
                <div className="master-main-span">
                    <span>
                        Executive Summary:
                    </span>
                </div>
            </div>
            <div className="tables-main">
                <div style={{ marginLeft: 20 }}>

                </div>
                <div style={{ marginRight: 20 }}>
                    <button className="tables-btn" onClick={nextButton}>
                        Next
                        <div className="sidebar-icon" />
                    </button>
                </div>
            </div>



            <div className="excutive ">
                <div className="tables-mains">
                    <div className="table-head">
                        <div>

                        </div>

                    </div>
                </div>
                <table style={{ width: "100%", border: "1px solid black" }}>
                    <tbody className='tbody-summary'>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>I. </td>
                            <td style={{ width: "36%", border: "1px solid black" }}>Highlights on Academic Activities</td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">
                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 2000 Characters Only'
                                    name="AcademicActivities"
                                    id="AcademicActivities"
                                    error={errValue.AcademicActivities}
                                    value={value.AcademicActivities}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, AcademicActivities: false }));
                                    }}
                                ></TextField>
                            </td>

                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>II. </td>
                            <td style={{ width: "36%", border: "1px solid black" }}>Highlights on Research Activities</td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">
                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 2000 Characters Only'
                                    name="ResearchActivities"
                                    id="ResearchActivities"
                                    error={errValue.ResearchActivities}
                                    value={value.ResearchActivities}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, ResearchActivities: false }));
                                    }}
                                ></TextField>
                            </td>

                        </tr>

                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>III. </td>
                            <td style={{ width: "36%", border: "1px solid black" }} colspan="5"> Highlights on Extension Activities</td>

                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }} rowspan="3"> </td>
                            <td style={{ width: "36%", border: "1px solid black" }}>No. of Training/Meeting/Exhibition
                                organized (with No. of participants)
                            </td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">
                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 500 Characters Only'
                                    name="NoOfTME"
                                    id="NoOfTME"
                                    error={errValue.NoOfTME}
                                    value={value.NoOfTME}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, NoOfTME: false }));
                                    }}></TextField>
                            </td>

                        </tr>
                        <tr>
                            <td style={{ width: "36%", border: "1px solid black" }}>No. of diagnostic field visits made and
                                solutions provided
                            </td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">
                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 500 Characters Only'
                                    name="ExtensionActivities"
                                    id="ExtensionActivities"
                                    disabled={role === "Director" ? true : false}
                                    error={errValue.ExtensionActivities}
                                    value={value.ExtensionActivities}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, ExtensionActivities: false }));
                                    }}></TextField>
                            </td>

                        </tr>
                        <tr>
                            <td style={{ width: "36%", border: "1px solid black" }}>Radio Talk
                                TV Programme
                                Any other
                            </td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">
                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 500 Characters Only'
                                    name="TVROProgram"
                                    id="TVROProgram"
                                    error={errValue.TVROProgram}
                                    value={value.TVROProgram}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, TVROProgram: false }));
                                    }}></TextField> </td>

                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>IV. </td>
                            <td style={{ width: "36%", border: "1px solid black" }}>No. of Externally funded projects obtained (with budget)</td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">
                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 500 Characters Only'
                                    name="NoOfProject"
                                    id="NoOfProject"
                                    error={errValue.NoOfProject}
                                    value={value.NoOfProject}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, NoOfProject: false }));
                                    }}
                                ></TextField>
                            </td>

                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }} rowspan="3">V.  </td>
                            <td style={{ width: "36%", border: "1px solid black" }} rowspan="3">No. of Publications </td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "20%", border: "1px solid black" }} colspan="2">No. of Research Articles (&gt;6 NAAS Rating): </td>
                            <td style={{ width: "40%", border: "1px solid black" }} >
                                <TextField
                                    type='text'
                                    placeholder='Maximum 500 Characters Only'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    name="ResearchArticle"
                                    id="ResearchArticle"
                                    error={errValue.ResearchArticle}
                                    value={value.ResearchArticle}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, ResearchArticle: false }));
                                    }}></TextField></td>

                        </tr>
                        <tr>

                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "20%", border: "1px solid black" }} colspan="2">No. of Book Chapters (with ISBN): </td>
                            <td style={{ width: "40%", border: "1px solid black" }} >
                                <TextField
                                    type='text'
                                    placeholder='Maximum 500 Characters Only'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    name="BookChapters"
                                    id="BookChapters"
                                    error={errValue.BookChapters}
                                    value={value.BookChapters}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, BookChapters: false }));
                                    }}></TextField></td>
                        </tr>
                        <tr>

                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "20%", border: "1px solid black" }} colspan="2">No. of Books (with ISBN):</td>
                            <td style={{ width: "40%", border: "1px solid black" }} >
                                <TextField
                                    type='text'
                                    placeholder='Maximum 500 Characters Only'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    name="Books"
                                    id="Books"
                                    error={errValue.Books}
                                    disabled={role === "Director" ? true : false}
                                    value={value.Books}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, Books: false }));
                                    }}></TextField></td>
                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>VI.</td>
                            <td style={{ width: "36%", border: "1px solid black" }}> No. of Awards/Fellowships/ Honours obtained by the faculty/centre</td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">
                                <TextField
                                    type='text'
                                    className='summarytext'
                                    placeholder='Maximum 500 Characters Only'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    name="NoOfAward"
                                    id="NoOfAward"
                                    error={errValue.NoOfAward}
                                    value={value.NoOfAward}  
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, NoOfAward: false }));
                                    }}></TextField>
                            </td>

                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>VII. </td>
                            <td style={{ width: "36%", border: "1px solid black" }}>Total income generated (Rs.) </td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">

                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 50 Characters Only'
                                    name="TotalIncome"
                                    id="TotalIncome"
                                    error={errValue.TotalIncome}
                                    value={value.TotalIncome}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, TotalIncome: false }));
                                    }}></TextField></td>

                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>VIII. </td>
                            <td style={{ width: "36%", border: "1px solid black" }}> Status of Audit Paras </td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "20%", border: "1px solid black" }}>Total:<span>
                                <TextField
                                    type='text'
                                    placeholder='Maximum 10 Characters Only'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    name="TotalAudit"
                                    id="TotalAudit"
                                    error={errValue.TotalAudit}
                                    value={value.TotalAudit}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, TotalAudit: false }));
                                    }}></TextField></span></td>
                            <td style={{ width: "20%", border: "1px solid black" }}>Dropped: <span>
                                <TextField
                                    type='text'
                                    placeholder='Maximum 10 Characters Only'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    name="DroppedAudit"
                                    id="DroppedAudit"
                                    error={errValue.DroppedAudit}
                                    value={value.DroppedAudit}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, DroppedAudit: false }));
                                    }}></TextField></span>  </td>
                            <td style={{ width: "20%", border: "1px solid black" }}>Pending: <span>
                                <TextField
                                    type='text'
                                    placeholder='Maximum 10 Characters Only'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    name="PendingAudit"
                                    id="PendingAudit"
                                    error={errValue.PendingAudit}
                                    value={value.PendingAudit}
                                    disabled={role === "Director" ? true : false}
                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, PendingAudit: false }));
                                    }}></TextField></span></td>
                        </tr>
                        <tr>
                            <td style={{ width: "3%", border: "1px solid black" }}>IX.</td>
                            <td style={{ width: "36%", border: "1px solid black" }}> Important Visitors </td>
                            <td style={{ width: "3%", border: "1px solid black" }}>:</td>
                            <td style={{ width: "60%", border: "1px solid black" }} colspan="3">

                                <TextField
                                    type='text'
                                    className='summarytext'
                                    style={{ width: "95%", marginBottom: "5px" }}
                                    placeholder='Maximum 500 Characters Only'
                                    name="Visitors"
                                    id="Visitors"
                                    error={errValue.Visitors}
                                    value={value.Visitors} 
                                    disabled={role === "Director" ? true : false}

                                    onChange={(e) => {
                                        handleData(e);
                                        setErrValue((prev) => ({ ...prev, Visitors: false }));
                                    }}></TextField> </td>

                        </tr>

                    </tbody>
                </table>
                <div className="from-last">
                    <button
                        type="submit"
                        style={{ backgroundColor: "#008CBA", height: "20px", padding: "5px 30px 28px 26px", borderRadius: "3px", color: "#fff" }}
                        onClick={backs}
                    >
                        Back
                    </button>
                    <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{ paddingTop: "5px" }}>
                        Save
                    </button>
                </div>
            </div>

        </div>
    )
}


export default ExcutiveSummary;
