import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { blocklevel } from '../../../Pages/Validation';

// import { Activetiesvalidation } from '../Validation';
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";

function C7Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [nameoftheprogram, setNameoftheprogram] = useState("");
  const [activities, setActivities] = useState("");
  const [villageName, setVillageName] = useState("");
  const [numberofBeneficiary, setNumberofBeneficiary] = useState("");
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm();

  const backs = () => {
    navigate("/C7");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Block Level Scientist");
    setLoader(false);
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setValue("Nameoftheprogramv", item.nameoftheprogram);
    setValue("Activitiesv", item.activities);
    setValue("VillageNamev", item.villageName);
    setValue("NumberofBeneficiaryv", item.numberofBeneficiary);
    setValue("datev", item.date);

    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async (data) => {
    console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,

      Nameoftheprogram: data.Nameoftheprogramv,
      Activities: data.Activitiesv,
      VillageName: data.VillageNamev,
      NumberofBeneficiary: data.NumberofBeneficiaryv,
      Date: data.datev,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C7SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C7");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C7EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C7");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(C7). Extension Services Block Level </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            C7. KAVIADP / ATMA / other extension services performed as block
            level scientist
          </h5>
          {/* 
          <TextField
            id="outlined-basic"
            label="Name of the program (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Noofclassesoffered}

            // onChange={(e) => setNameoftheprogram(e.target.value)}

            {...register("Nameoftheprogramv")}
            error={errors.Nameoftheprogramv ? true : false}
            helperText={errors.Nameoftheprogramv?.message}
          /> */}
          <TextField
            id="outlined-basic"
            label="Name of the program (Max 500 Characters)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("Nameoftheprogramv", {
              required: "Name of the program is required",
              maxLength: {
                value: 500,
                message: "Name of the program must be less than 500 characters",
              },
            })}
            error={!!errors.Nameoftheprogramv}
            helperText={errors.Nameoftheprogramv?.message}
          />
          {/* <TextField
            id="outlined-basic"
            label="Activities/Involved (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={FellowshipName}

            // onChange={(e) => setActivities(e.target.value)}

            {...register("Activitiesv")}
            error={errors.Activitiesv ? true : false}
            helperText={errors.Activitiesv?.message}
          />

          <TextField
            id="outlined-basic"
            label="Village Name (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={FellowshipName}

            // onChange={(e) => setVillageName(e.target.value)}

            {...register("VillageNamev")}
            error={errors.VillageNamev ? true : false}
            helperText={errors.VillageNamev?.message}
          />

          <TextField
            id="outlined-basic"
            label="Number of Beneficiary (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={FellowshipName}

            // onChange={(e) => setNumberofBeneficiary(e.target.value)}

            {...register("NumberofBeneficiaryv")}
            error={errors.NumberofBeneficiaryv ? true : false}
            helperText={errors.NumberofBeneficiaryv?.message}
          />

          <TextField
            id="outlined-basic"
            label="Award Date"
            // type="date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("datev")}
            error={errors.datev ? true : false}
            helperText={errors.datev?.message}
          /> */}
 <TextField
        id="outlined-basic"
        label="Activities/Involved (Max 500 Characters)"
        variant="outlined"
        style={{ margin: "10px", width: "48%" }}
        {...register("Activitiesv", {
          required: "Activities/Involved is required",
          maxLength: {
            value: 500,
            message: "Activities/Involved must be less than 500 characters"
          }
        })}
        error={!!errors.Activitiesv}
        helperText={errors.Activitiesv?.message}
      />
      
      <TextField
        id="outlined-basic"
        label="Village Name (Max 500 Characters)"
        variant="outlined"
        style={{ margin: "10px", width: "48%" }}
        {...register("VillageNamev", {
          required: "Village Name is required",
          maxLength: {
            value: 500,
            message: "Village Name must be less than 500 characters"
          }
        })}
        error={!!errors.VillageNamev}
        helperText={errors.VillageNamev?.message}
      />

      <TextField
        id="outlined-basic"
        label="Number of Beneficiary (Max 500 Characters)"
        variant="outlined"
        style={{ margin: "10px", width: "48%" }}
        {...register("NumberofBeneficiaryv", {
          required: "Number of Beneficiary is required",
          maxLength: {
            value: 500,
            message: "Number of Beneficiary must be less than 500 characters"
          }
        })}
        error={!!errors.NumberofBeneficiaryv}
        helperText={errors.NumberofBeneficiaryv?.message}
      />

      <TextField
        id="outlined-basic"
        label="Award Date"
        type="date"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        style={{ margin: "10px", width: "48%" }}
        {...register("datev", {
          required: "Award Date is required",
          validate: (value) => value !== "" || "Award Date is required"
        })}
        error={!!errors.datev}
        helperText={errors.datev?.message}
      />
          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default C7Inside;
