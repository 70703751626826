import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { C6VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArC13Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [InvestigatorName,setInvestigatorName] = useState("")
  const [category,setCategory] = useState("")
  const [actionPlan,setActionPlan] = useState("")
  const [progress,setProgress] = useState("")
console.log(InvestigatorName)
  const [InvestigatorNameErr,setInvestigatorNameErr] = useState(false)
  const [categoryErr,setCategoryErr] = useState(false)
  const [actionPlanErr,setActionPlanErr] = useState(false)
  const [progressErr,setProgressErr] = useState(false)

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();
  const [inves, setInves] = useState([]);
  const [lov, setLov] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader,setLoader] =useState(true)
  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C6VALI),
  });

  const backs = () => {
    navigate("/ArC13");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Extension Activities");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute)
  }, []); 

  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId);
    setCategory(item.category);
    setActionPlan(item.actionPlan);
    setProgress(item.progress);
    setId(item.id);
  };


// Lov
const handleLov = async (token, Category) => {
  console.log(token);

  const h = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const { data } = await axios.get(
      `${window.BASE}${window.LOVTYPE}${"Category"}`,
      {
        headers: h,
      }
    );
    console.log(data);setLoader(false)
    setLov(data);
  } catch (error) {
    console.log(error);setLoader(false)
  }
};

// Investigator 
const handleInvestigators = async (token,id) => {
  const h = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
      headers: h,
    });
    console.log(data);setLoader(false)
    setInves(data);
  } catch (error) {
    console.log(error);setLoader(false)
  }
};


  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: InvestigatorName,
      Category: category,
      ActionPlan: actionPlan, 
      Progress: progress, 
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr13SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC13");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr13EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArC13");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  const formValidateFun=()=>{
    if(!InvestigatorName){
      setInvestigatorNameErr(true)
      document.getElementById("InvestigatorName").focus()
    }else if(!actionPlan){
      setActionPlanErr(true)
      document.getElementById("actionPlan").focus()
    }else if(!category){
      setCategoryErr(true)
      document.getElementById("category").focus()
    }else if(!progress){
      setProgressErr(true)
      document.getElementById("progress").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C13. MLT/OFT/Action Plan
  </span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>C13. MLT/OFT/Action Plan
 </h5>

          <FormControl style={{ width: "48%", margin: 12 }} error={InvestigatorNameErr}  id="InvestigatorName">
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Scientist In Charge
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="investigatorName"
              value={InvestigatorName}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setInvestigatorName(e.target.value)
                setInvestigatorNameErr(false)
              }}
            >
            {inves?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>

          <FormControl style={{ width: "48%", margin: 12 }} error={categoryErr}  id="category">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="linkagesType"
              value={category}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryErr(false)
              }}
            >
            {lov?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={actionPlanErr}
            id="actionPlan"
            label="Action Plan (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={actionPlan}
            onChange={(e) => {
                setActionPlan(e.target.value)
                setActionPlanErr(false)
              }}
          />

          <TextField
            error={progressErr}
            id="progress"
            label=" Progress during the Month"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={progress}
            onChange={(e) => {
                setProgress(e.target.value);
                setProgressErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={()=>formValidateFun()} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArC13Inside;
