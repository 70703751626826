import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../../utils/style";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import LoopIcon from "@mui/icons-material/Loop";
import { ToastContainer, toast } from "react-toastify";

import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function A1dLandArea({ head }) {
  const Navigate = useNavigate("");

  const nextButton = () => {
    Navigate("/ArA1(e)");
  };

  const Previousbutton = () => {
    Navigate("/ArA1(a-e)");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);
  const [dataList, setDataList] = useState([]);
  const [loader,setLoader] =useState(false)
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const [Mname, setName] = useState("");
  const [disables, setDisables] = useState(false)
  const [ii, setI] = useState(null)
  const [role, setRole] = useState("");

  const [l, setL] = useState();
  const handleClickOpen = () => {
    setOpen(true);
    navigate("/ArA1(d)Inside", { state: { TrnMonthlyReport: l } });
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);

    if (tok != null && tok != undefined) {
      if (myObject !== null) {
        handleDataList(tok, myObject.id);
        setI(myObject.id)
      }
    }
    if (location.state) {
      console.log(location.state.TrnMonthlyReport);
      setL(location.state.TrnMonthlyReport);
    }
    const role = localStorage.getItem("role");
    setRole(role);
    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true)
    } else {
      setDisables(false)
    }
    let isTrue = localStorage.getItem("Mname");
    if (isTrue !== null) {
      setName(isTrue);
    }
    head("Guest Lectures");
  }, []);

  const edit = (item) => {
    navigate("/ArA1(d)Inside", { state: item });
    console.log(item);
    setOpen(true);
    setId(item.id);
  };

  const onclickDelete = async (id) => {
    const result = window.confirm("Are you sure you want to delete?");
    if (result) {
      // Perform the delete operation
      deleteTransaction(id);
      console.log("Deleted successfully");
      // window.location.reload();
    } else {
      // Cancelled delete operation
      console.log("Delete cancelled");
    }
  };

  const deleteTransaction = async (id) => {
    const d = {
      Id: id,
    };
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(`${window.BASE}${window.ADDELETE}`, d, {
        headers: h,
      });
      handleDataList(token, ii)
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDataList = async (token, id) => {
    console.log("id",id);setLoader(false)
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.ADDATALIST}${"/" + id}`,
        { headers: h }
      );
      console.log(data);
      setDataList(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  //student table

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A1b. Land Area
 </span>
        </div>
      </div>

      <div className="tables-main">
        <div style={{ marginLeft: 20 }}>
          <button className="tables-btn" onClick={Previousbutton}>
            <div className="sidebar-icon" />
            Previous
          </button>
        </div>



        <div style={{ marginRight: 20 }}>
          <button className="tables-btn" onClick={nextButton}>
            Next
            <div className="sidebar-icon" />
          </button>
        </div>
      </div>



      <div className="table-container-grid">
      <div className="tables-mains">
          <div className="table-head">
            <div>
              <button className="top-button" onClick={handleClickOpen} disabled={role === "Director"? true : !disables ? false : true}>
                + Add
              </button>
            </div>
            <div className="Center-Month"><h4 className="h-6">{Mname}</h4></div>
          </div>
        </div>

        <Dialog
          className="dialog"
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="form-control"></div>
        </Dialog>

        {/* ************************************************************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell  style={{width:"40%"}}>Land Area Type</TableCell>
              <TableCell  style={{width:"40%"}}>Land Area Value </TableCell>
              <TableCell  style={{width:"20%"}}>Action </TableCell>


            </TableHead>

            <TableBody>
              {dataList?.map((row) => (
                <TableRow key={row.CourseId}>
                  <TableCell >{row.landAreaType}</TableCell>
                  <TableCell >{row.landAreaValue}</TableCell>
                  {/* <TableCell align="right">{row.courseName}</TableCell>

                  <TableCell align="right">{row.hours}</TableCell>
                  <TableCell align="right">{row.guestSpeaker}</TableCell>
                  <TableCell align="right">{row.affiliation}</TableCell>
                  <TableCell align="right">{row.titleofthelecture}</TableCell> */}

                
                    <TableCell >
                    <div className="edit-delete">
                      <button className="tablebutton" onClick={() => edit(row)} disabled={role === "Director"? true : !disables ? false : true}>
                        {<Edit className="sidebars-icon" />}
                      </button>

                      <button
                        className="deleted"
                        style={{ marginLeft: 3 }}
                        onClick={() => onclickDelete(row.id)}
                        disabled={role === "Director"? true : !disables ? false : true}
                      >
                        {<DeleteIcon className="sidebars-icon" />}
                      </button>
                      </div>
                    </TableCell>
               
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      

      </div>
    </div>
  );
}

export default A1dLandArea;
