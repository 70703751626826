import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import ComponentLoader from "../../../ComponentLoader";

function A11Insidee({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);


const [meetingAgenda,setMeetingAgenda] = useState("")
const [meetingDatee,setMeetingDate] = useState("")
const [remarks,setRemarks] = useState("");
const [loader,setLoader] =useState(true)

const [meetingAgendaErr,setMeetingAgendaErr] = useState(false)
const [meetingDateErr,setMeetingDateErr] = useState(false)
const [remarksErr,setRemarksErr] = useState(false)

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    // resolver: yupResolver(A8VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student facilitation");
    setLoader(false)
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
  }, []);

  const edit = (item) => {
    console.log(item);
    // setMeetingDate(item.meetingDate);
    setMeetingDate(moment(item.meetingDate).format("YYYY-MM-DD"));
    setMeetingAgenda(item.meetingAgenda);
    setRemarks(item.remarks);
    setId(item.id)
    setLoader(false)
  };
  
  const backs = () => {
    navigate("/ArA11");
  };

  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      MeetingAgenda: meetingAgenda,
      MeetingDate: meetingDatee,
      Remarks: remarks,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Ar11SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA11");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Ar11EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully updated");
          navigate("/ArA11");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const formValidateFun = () => {
    if (!meetingDatee) {
      setMeetingDateErr(true);
      document.getElementById("meetingDatee").focus();
    } else if (!meetingAgenda) {
      setMeetingAgendaErr(true);
      document.getElementById("meetingAgenda").focus();
    } else if (!remarks) {
      setRemarksErr(true);
      document.getElementById("remarks").focus();
    
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
        <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A11. Monthly meeting(s) organized during this month:
 </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(formValidateFun)}>
        <div className="form-control">
          <h5 style={{ width: 350, margin: 20 }}>
          A11. Monthly meeting(s) organized during this month:

          </h5>
   
          <TextField
            error={meetingDateErr}
            id="meetingDate"
            label="Date of monthly meeting"
            variant="outlined"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={meetingDatee}
            onChange={(e) => {
              setMeetingDate(e.target.value);
              setMeetingDateErr(false);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />
          <TextField
            error={meetingAgendaErr}
            id="meetingAgenda"
            label="Major Agenda (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={meetingAgenda}
            onChange={(e) => {
              setMeetingAgenda(e.target.value);
              setMeetingAgendaErr(false);
            }}
          />
          <TextField
            error={remarksErr}
            id="remarks"
            label="Remarks (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
              setRemarksErr(false);
            }}
          />



          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              // onClick={handleLeavepage}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default A11Insidee;
