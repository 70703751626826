import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { C2VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import ComponentLoader from "../../../../ComponentLoader";
import { dateFormat } from "../../../../utils/Date";

function C2Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [LovEventType, setLovEventType] = useState([])

  const [eventName, setEventName] = useState("");
  const [eventType, seteventType] = useState("");
  const [eventdate, setEventdate] = useState("");
  const [organizedby, setOrganizedby] = useState("");
  const [sponsor, setSponsor] = useState("");

  const [eventNameErr, setEventNameErr] = useState(false);
  const [eventTypeErr, seteventTypeErr] = useState(false);
  const [eventdateErr, setEventdateErr] = useState(false);
  const [organizedbyErr, setOrganizedbyErr] = useState(false);
  const [sponsorErr, setSponsorErr] = useState(false);
  const [loader, setLoader] = useState(true);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(C2VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Field days attended");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const backs = () => {
    navigate("/C2");
  };

  const edit = (item) => {
    console.log(item);
    setEventName(item.eventName);
    seteventType(item.eventType);
    setEventdate(dateFormat(item.eventdate));
    setOrganizedby(item.organizedby);
    setSponsor(item.sponsor);

    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov
  const handleLov = async (token, MeetingName) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"MeetingName"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovEventType(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      EventName: eventName,
      EventType: eventType,
      Eventdate: eventdate,
      Organizedby: organizedby,
      Sponsor: sponsor,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C2SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C2");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C2EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C2");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!eventName) {
      setEventNameErr(true)
      document.getElementById("eventName").focus()
    } else if (!eventType) {
      seteventTypeErr(true)
      document.getElementById("eventType").focus()
    } else if (!eventdate) {
      setEventdateErr(true)
      document.getElementById("eventdate").focus()
    } else if (!organizedby) {
      setOrganizedbyErr(true)
      document.getElementById("organizedby").focus()
    } else if (!sponsor) {
      setSponsorErr(true)
      document.getElementById("sponsor").focus()
    } else {
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(C2). Trainings & Meetings attended</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            C2. Trainings / Meetings / Field Days Attended
          </h5>

          <TextField
            error={eventNameErr}
            id="eventName"
            label="Event Name"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={eventName}
            onChange={(e, val) => {
              setEventName(e.target.value);
              setEventNameErr(false);
            }}
          />

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={eventTypeErr}
            id="eventType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Event Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={eventType}
              label="Programme Type"
              size="small"
              // sx={{ width: "100%" }}
              // style={{ width: "48%", margin: 12 }}
              onChange={(e) => {
                seteventType(e.target.value);
                seteventTypeErr(false);
              }}
            >
              {LovEventType?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={eventdateErr}
            id="eventdate"
            label="Event Date"
            variant="outlined"
            value={eventdate}
            type="date"
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
            onChange={(e) => {
              setEventdate(e.target.value);
              setEventdateErr(false);
            }}
          />

          {/* <TextField
            id="outlined-basic"
            label="Field Visit Date"
            type="date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
            onChange={(e) => {
              setEventdate(e.target.value);
              setEventdateErr(false);
            }}
          /> */}

          <TextField
            error={organizedbyErr}
            id="organizedby"
            label=" Organized by"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={organizedby}
            onChange={(e) => {
              setOrganizedby(e.target.value);
              setOrganizedbyErr(false);
            }}
          />

          <TextField
            error={sponsorErr}
            id="sponsor"
            label="Sponsor"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={sponsor}
            onChange={(e) => {
              setSponsor(e.target.value);
              setSponsorErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px", padding: "5px 30px 28px 26px", borderRadius: "3px", color: "#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={() => formValidateFun()} style={{ paddingTop: "5px" }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default C2Inside;
