import React from 'react'

// import './Leave.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import { Box, Container, Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';
import './Studentguides.css'
import LoopIcon from '@mui/icons-material/Loop';

import {useNavigate}  from 'react-router-dom';

function Studentguides({head}) {

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(red[500]),
        textTransform: 'none',
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        },
      }));

      const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([])
  const [Info, setInfo] = useState([])
  const [open, setOpen] = React.useState(false);

  
  const [Studentguidess, setStudentguidess] = useState([])

  const [studentId, setStudentId,] = useState("")
    const [studentName, setStudentName] = useState("")
    const [courseType, setCourseType] = useState("")
    const [thesistitle, setThesistitle] = useState("")
    const [fllowshipdetails, setFellowshipdetails] = useState("")
    const [thesissubmission, setThesissubmission] = useState("")


    const [token, setToken] = useState("");
    const [id, setId] = useState(0);

    const [noofmeetings, setNoofmeetings] = useState("")
    const [instituteId, setInstituteId] = useState("")
    const [internshipPlan, setInternshipPlan] = useState("")
    const [progress, setProgress] = useState("")



  const navigate =useNavigate()
  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("")

  const handleClickOpen = () => {
    setOpen(true);
    navigate("/StudentDetalis")
  };

 useEffect(() => {
        const tok = localStorage.getItem("token")
        setToken(tok);
        console.log(tok);
        if (tok != null && tok != undefined) {
            handleDirectorate(tok);
            
        }
        head("Student")
    }, [])




  
  const handleLeavepage = async () => {
    const h = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };
    console.log(token);

    const d = {

        id,
        StudentId: studentId,
        StudentName: studentName,
        CourseType: courseType,
        Thesistitle: thesistitle,
        Fellowshipdetails: fllowshipdetails,
        Thesissubmission: thesissubmission,

        Noofmeetings: noofmeetings,
        InstituteId: instituteId,
        InternshipPlan: internshipPlan,
        Progress: progress,


    }

    try {
        const { data } = await axios.post(`${window.BASE}${window.STUDENTSAVE}`, d, { headers: h });
        console.log(data);
        setStudentguidess(data)
        //   setDirect(data);

    } catch (error) {
        console.log(error);
    }
};

const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };

    try {
        const { data } = await axios.get(`${window.BASE}${window.STUDENTALL}`, { headers: h });
        console.log(data);
        setStudentguidess(data);
    } catch (error) {
        console.log(error);
    }
};

//student table


  return (
    <div>
        <div className='table-container-grid'>
      <div className="tables-mains">
        <div className='table-head'>
          <button className='top-button' onClick={handleClickOpen}>+ New</button>

          <div className='top-button-site'>
            <input type='search' />
            <button type='button' className='button-1'>{
               <LoopIcon className="sidebar-icon" />
               }</button>
            {/* <button type='button' className='button-1'></button> */}
          </div>
        </div>
      </div>

      <Dialog
      className="dialog"
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      
      >

          <div className='form-control'>
          </div>
    
      </Dialog>

      {/* ************************************************************************* */}

      <TableContainer sx={tableContainerStyle}>

        <Table
          sx={tableStyle}
          aria-labelledby="tableTitle"
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead className='headtable'>
            <TableCell align="right">Student Name</TableCell>
            <TableCell align="right">Course Type</TableCell>
            <TableCell align="right">Thesis Title</TableCell>
            <TableCell align="right">Fellowship details</TableCell>
            <TableCell align="right">thesis submission</TableCell>
            <TableCell align="right">No/Of meetings heldduring the month</TableCell>

            <TableCell align="right">National /International Institute identified for carrying out part of research</TableCell>
            <TableCell align="right">Plan for the internship</TableCell>
            <TableCell align="right">Progress</TableCell>
           
          </TableHead>

          <TableBody>
          {Studentguidess?.map((row) => (
                            <TableRow
                                key={row.RptDate}

                            >
                                <TableCell align="right">{row.studentName}</TableCell>
                                <TableCell align="right">{row.courseType}</TableCell>
                                <TableCell align="right"> {row.thesistitle}</TableCell>
                                <TableCell align="right">{row.fellowshipdetails}</TableCell>
                                <TableCell align="right"> {row.thesissubmission}</TableCell>
                                <TableCell align="right">{row.noofmeetings}</TableCell>

                                <TableCell align="right"> {row.instituteId}</TableCell>
                                <TableCell align="right">{row.internshipPlan}</TableCell>
                                <TableCell align="right"> {row.progress}</TableCell>


                            </TableRow>
                        ))}
          </TableBody>


        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={Studentguidess.length}
      //   rowsPerPage={masterManufacturer.rowsPerPage}
      //   page={masterManufacturer.page}
      //   onPageChange={handleChangePage}
      //   onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </div>
      
    </div>
  )
}

export default Studentguides
