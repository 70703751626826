import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,

} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";


// import { B1VALIDATION } from "../../../Validation";


function ArB9Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true)
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const[insti,setInsti]=useState("")
   const[data,setData]=useState({
    nameOfFaculty:"",
    designation:"",
    natureOfCoding:"",
    noOfClasses:"",
    nameOfFellow:"",
    noOfStudents:""
   })

   const[errdata,setErrdata]=useState({
    nameOfFaculty:false,
    designation:false,
    natureOfCoding:false,
    noOfClasses:false,
    nameOfFellow:false,
    noOfStudents:false
   })
   console.log(errdata);
  

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [inves, setInves] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  console.log(data,"data");

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    
    console.log(tok);
    if (tok != null && tok != undefined) {
            console.log(location.state);
          
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Research Area");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    // console.log(myObject,myObject.id);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    const institue = localStorage.getItem("loginId");
    setInsti(institue);
    handleInvestigators(tok,institue)
  }, []);

  const handleData = ((event)=>{
    const {name,value}=event.target
    setData(prev =>({
      ...prev,
      [name] : value
    }))

  })

  const edit = (item) => {
    console.log(item);
    
      setData({
        nameOfFaculty:item.investigatorId,
        designation:item.designation,
        natureOfCoding:item.natureofcoaching,
        noOfClasses:item.noofclassesoffered,
        nameOfFellow:item.fellowshipName,
        noOfStudents:item.noOfStudent
      })
      setId(item.id)
      console.log(item.id)
  };


  const handleBack = () => {
    navigate("/ArB9");
  };

  // Lov Type //

  const formValidateFun = () => {
    if (!data.nameOfFaculty) {
      setErrdata((prev) => ({ ...prev, nameOfFaculty: true }));
      document.getElementById("nameOfFaculty").focus();
    } 
      else if (!data.designation) {
      setErrdata((prev) => ({ ...prev, designation: true }));
      document.getElementById("Designation").focus();
    } else if (!data.natureOfCoding) {
      setErrdata((prev) => ({ ...prev, natureOfCoding: true }));
      document.getElementById("natureOfCoding").focus();
    } else if (!data.noOfClasses) {
      setErrdata((prev) => ({ ...prev,noOfClasses: true }));
      document.getElementById("noOfClasses").focus();
    } else if (!data.nameOfFellow) {
      setErrdata((prev) => ({ ...prev, nameOfFellow: true }));
      document.getElementById("nameOfFellow").focus();
    } else if (!data.noOfStudents) {
      setErrdata((prev) => ({ ...prev, noOfStudents: true }));
      document.getElementById("noOfStudents").focus();
    } 
    else {
      handleLeavepage();
    }
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleLeavepage = async () => {

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      investigatorId:data.nameOfFaculty,
      Designation:data.designation,
      Natureofcoaching:data.natureOfCoding,
      Noofclassesoffered:data.noOfClasses,
      FellowshipName:data.nameOfFellow,
      NoOfStudent:data.noOfStudents,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    console.log(d,"d");
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB9SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        // setStudentbtn(value);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArB9")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
         
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB9EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully updated");
          navigate("/ArB9")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>B9. Student facilitation
 </span>
        </div>
      </div>
      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
        {/* <div className=" col-lg-6 col-md-6 col-sm-12 Activies-main"></div> */}
        <div className="form-control">
          <h5>B9. Student facilitation
 </h5>

          

          {/* <TextField
            id=" "
            label="Name of the faculty"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="nameOfFaculty"
            // value={Natureofcoaching}
            onChange={(e) => {
              handleData(e);
              setErrdata((prev) => ({ ...prev, [errdata.nameOfFaculty]: false }));
            }}
          /> */}

        <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="nameOfFaculty"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Name of the faculty
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="nameOfFaculty"
              error={errdata.nameOfFaculty}
              style={{ height: "45px" }}
              value={data.nameOfFaculty}
              onChange={(e) => {
                handleData(e);
                setErrdata((prev) => ({ ...prev, nameOfFaculty: false }));
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
            id="Designation "
            label="Designation"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            error={errdata.designation}
            name="Designation"
            value={data.designation}
            onChange={(e) => {
              handleData(e);
              setErrdata((prev) => ({ ...prev, designation: false }));
            }}
          /> */}

        <TextField
            id="designation"
            label="Designation (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="designation"
            error={errdata.designation}
            value={data.designation}
            
           
            onChange={(e) => {
              handleData(e);
              setErrdata((prev) => ({ ...prev, designation: false }));
            }}
          />
          <TextField
            id="natureOfCoding"
            label="Nature of coaching offered (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="natureOfCoding"
            value={data.natureOfCoding}
            error={errdata.natureOfCoding}
           
            onChange={(e) => {
              handleData(e);
              setErrdata((prev) => ({ ...prev, natureOfCoding: false }));
            }}
          />
          <TextField
            id="noOfClasses "
            label="No. of classes offered (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="noOfClasses"
            value={data.noOfClasses}
            error={errdata.noOfClasses}
            // value={Natureofcoaching}
            // onChange={(e) => setTeamMember(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrdata((prev) => ({ ...prev, noOfClasses: false }));
            }}
          />
          <TextField
            id=" nameOfFellow"
            label="Name of the
            fellowship (Max 500 Character)
            "
            variant="outlined"
            name="nameOfFellow"
            style={{ margin: "10px", width: "48%" }}
            value={data.nameOfFellow}
            error={errdata.nameOfFellow}
            // value={Natureofcoaching}
            // onChange={(e) => setTeamMember(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrdata((prev) => ({ ...prev, nameOfFellow: false }));
            }}
            
          />
          <TextField
            id="noOfStudents "
            label="Number of
            students (Max 500 Character)
            "
            variant="outlined"
            name="noOfStudents"
            value={data.noOfStudents}
            error={errdata.noOfStudents}
            style={{ margin: "10px", width: "48%" }}
            // value={Natureofcoaching}
            onChange={(e) => {
              handleData(e);
              setErrdata((prev) => ({ ...prev, noOfStudents: false }));
            }}
            
           
          />


          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      {/* </form> */}
    </div>
  );
}

export default ArB9Inside;