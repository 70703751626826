import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '2008',
    'TNAU 25000': 50,
    'TN STATE': 40,
    'UNION': 35,
    'PRIVATE': 25
  },
  {
    name: '2009',
    'TNAU 25000': 75,
    'TN STATE': 65,
    'UNION': 60,
    'PRIVATE': 85
  },
  {
    name: '2010',
    'TNAU 25000': 50,
    'TN STATE': 40,
    'UNION': 55,
    'PRIVATE': 74
  },
  {
    name: '2011',
    'TNAU 25000': 75,
    'TN STATE': 65,
    'UNION': 85,
    'PRIVATE': 37
  },
  {
    name: '2012',
    'TNAU 25000': 100,
    'TN STATE': 90,
    'UNION': 40,
    'PRIVATE': 59
  }
];

const BarSimpleChart = () => {
  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="TNAU 25000" fill="#73b4ff" />
          <Bar dataKey="TN STATE" fill="#2ed8b6" />
          <Bar dataKey="UNION" fill="#ff869a" />
          <Bar dataKey="PRIVATE" fill="#ff869f" />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default BarSimpleChart;
