import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { C3VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArE2Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [insti, setInsti] = useState();

  const [Lovprogramtype, setLovprogramtype] = useState([]);
  const [Lovteamleader, setLovteamleader] = useState([]);
  const [inves, setInves] = useState([]);
  
  const [investigatorName,setInvestigatorName] = useState("")
  const [programtype, setprogramtype] = useState("");
  const [role, setrole] = useState("");
  const [place, setPlace] = useState("");
  const [nooftrials, setNooftrials] = useState("");

  const [investigatorNameErr,setInvestigatorNameErr] = useState(false)
  const [programtypeErr, setprogramtypeErr] = useState(false);
  const [roleErr, setroleErr] = useState(false);
  const [placeErr, setPlaceErr] = useState(false);
  const [nooftrialsErr, setNooftrialsErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handlebtns = () => {
    alert("Succesfully Submitted");
    window.location.reload();
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C3VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
        handleLovTeamleader(tok)
        
        handleLov(tok)
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("FFS Programs organized");
  }, []);
  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute)
  }, []);

  const backs = () => {
    navigate("/ArE2");
  };

  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId)
    setprogramtype(item.programmeType);
    setrole(item.teamleaderRole);
    setPlace(item.place);
    setNooftrials(item.nooftrials);
    setId(item.id);
  };

  // Investigator
  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

   // Lov program type
   const handleLov = async (token, ProgrammeType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"ProgrammeType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovprogramtype(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  // Lov Team leader
  const handleLovTeamleader = async (token, TeamleaderRole) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"TeamleaderRole"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovteamleader(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      InvestigatorId:investigatorName,
      ProgrammeType: programtype,
      TeamleaderRole: role,
      Place: place,
      Nooftrials: nooftrials,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Er2SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArE2");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Er2EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArE2");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const formValidateFun=()=>{
    if(!programtype){
      setprogramtypeErr(true)
      document.getElementById("programtype").focus()
    }else if(!role){
      setroleErr(true)
      document.getElementById("role").focus()
    }else if(!place){
      setPlaceErr(true)
      document.getElementById("place").focus()
    }else if(!nooftrials){
      setNooftrialsErr(true)
      document.getElementById("nooftrials").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
         <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>E2. OFT / FLD / FS / FFS Programs organized
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
          E2. OFT / FLD / FS / FFS Programs organized

          </h5>

          <FormControl
          style={{ width: "48%", margin: 12 }}
          error={investigatorNameErr}
          id="investigatorName"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Name of the faculty
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="investigatorName"
            value={investigatorName}
            label="Programme Type"
            size="small"
            sx={{ width: "100%" }}
            onChange={(e) => {
              setInvestigatorName(e.target.value);
              setInvestigatorNameErr(false);
            }}
          >
            {inves?.map((row, index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

          <FormControl style={{ width: "48%", margin: 12 }} error={programtypeErr} id="programtype">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Programme Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={programtype}
              label="Programme Type"
              size="small"
              // style={{ margin: "10px", width: "48%" }}
              // sx={{ width: "100%" }}
              onChange={(e) => {
                setprogramtype(e.target.value)
                setprogramtypeErr(false)
              }}
            >
              {Lovprogramtype?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ width: "48%", margin: 12 }} error={roleErr} id="role">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={role}
              label="Role"
              size="small"
              // style={{ margin: "10px", width: "48%" }}
              // sx={{ width: "100%" }}
              onChange={(e) => {
                setrole(e.target.value)
                setroleErr(false)
              }}
            >
              {Lovteamleader?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          
          <TextField
            error={placeErr}
            id="place"
            label="Place (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={place}
            onChange={(e) => {
                setPlace(e.target.value);
                setPlaceErr(false);
            }}
          />

          <TextField
            error={nooftrialsErr}
            id="nooftrials"
            label="No. of trials (Max 50 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={nooftrials}
            onChange={(e) => {
                setNooftrials(e.target.value);
                setNooftrialsErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={()=>formValidateFun()} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArE2Inside;
