import * as React from "react";
import "./Leave.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import { Link, Navigate, useNavigate } from "react-router-dom";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { ToastContainer } from "react-toastify";
import ComponentLoader from "../../ComponentLoader";

// import { Leaveess } from '../Validation';
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useForm } from 'react-hook-form';

// import './App.css'

function Leave({ head }) {
  const Navigate = useNavigate("");
  

  const backButton = () => {
    Navigate("/General");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader,setLoader] =useState(false)

  // const {
  //     register,
  //     handleSubmit,
  //     formState: { errors, isValid },
  //     setValue,
  //     reset,
  //     watch,
  // } = useForm({
  //     resolver: yupResolver(Leaveess),
  // });

  // const handleClickSubmit =()=>{

  // }
  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setId(item.id);
    setCode(item.levCode);
    setLeaveName(item.levName);
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
    }
    head("Leave");
  }, []);

  //    ***************************************************************

  function createData(code, Lname, option) {
    return { code, Lname, option };
  }

  const rows = [
    //     createData('CL', 'Casual Leave', 'Edit'),
    //     createData('SL', 'Sick Leave', 'Edit'),
  ];

  //  const edit = (item) => {
  //     console.log(item);
  //     setOpen(true);
  //     setValue("Leavecode", item.code)
  //     setValue("LeaveNamess", item.instituteName)
  //  }

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      LevCode: code,
      LevName: leavename,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.LEAVEPAGE}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data.result == "Success") {
        alert("Successfully Submitted");
        
        handleDirectorate(token)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);setLoader(true)
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.LEAVES}`, {
        headers: h,
      });
      console.log(data);
      setDirect(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleClickSubmit = () => {
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  return (
    <div className="main-container-margin">
      <ToastContainer /><ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Leave Type</span>
        </div>
      </div>
      <div className="table-container-grid table-container-grid1">
        <div className="table-head">
          {/* <div>
                    <button className='top-butto'  onClick={backButton}>

                        <ArrowBackIcon style={{ marginBottom: -5 }} className="sidebar-icon" />
                        Back</button>
                </div> */}
          <div>
            <button className="top-button" onClick={handleClickOpen}>
              + Add
            </button>
          </div>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

          // PaperProps={{
          //     component: "form",
          //     onSubmit: handleSubmit(handleLeavepage),
          // }}
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>Leave</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon className="dialogue_close_icon" />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Code"
                  value={code}
                  variant="outlined"

                  size="small"
                  onChange={(e) => setCode(e.target.value)}
                  //   {...register("Leavecode")}
                  //             error={errors.Leavecode ? true : false}
                  //             helperText={errors.Leavecode?.message}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Leave Name"
                  variant="outlined"
                  size="small"
                  value={leavename}
                  onChange={(e) => setLeaveName(e.target.value)}
                  //   {...register("Leavenotes")}
                  //             error={errors.Leavenotes ? true : false}
                  //             helperText={errors.Leavecode?.message}

                  // onChange={(e) => setCode(e.target.value)}
                  //             {...register("Icode")}
                  //             error={errors.Icode ? true : false}
                  //             helperText={errors.Icode?.message}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handleLeavepage}>Submit</button>
          </DialogActions>
        </Dialog>

        {/* ************************************************************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell align="right">Code</TableCell>
              <TableCell align="right">Leave Name</TableCell>
              <TableCell align="right">Option</TableCell>
            </TableHead>

            <TableBody>
              {direct.map((row) => (
                <TableRow
                  key={row.Leave}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right"> {row.levCode}</TableCell>
                  <TableCell align="right">{row.levName}</TableCell>

                  <TableCell align="right">
                    <button
                      className="btns"
                      variant="outlined"
                      onClick={() => edit(row)}
                    >
                      {<Edit className="sidebar-icon" />}
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
export default Leave;
