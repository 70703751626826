import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { B9VALIDATION } from "../../../Validation";

// import { signed } from '../../../Pages/Validation';

// import { Activetiesvalidation } from '../Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import ComponentLoader from "../../../../ComponentLoader";
import { dateFormat } from "../../../../utils/Date";

function B9Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [depatId, setDepatId] = useState("");
  const [organization, setOrganization] = useState("");
  const [purpose, setPurpose] = useState("");
  const [mOUDate, setMOUDate] = useState("");
  const [progress, setProgress] = useState("");

  const [depatIdErr, setDepatIdErr] = useState("");
  const [organizationErr, setOrganizationErr] = useState("");
  const [purposeErr, setPurposeErr] = useState("");
  const [mOUDateErr, setMOUDateErr] = useState("");
  const [progressErr, setProgressErr] = useState("");
  const [loader,setLoader] =useState(true);
  const [dropdown, setDropdown] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const [state,setState] = useState();
  const [assM,setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B9VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDropdown(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("MoUs signed/MTA/Linkages");
  }, []);

  useEffect(()=>{
    const objectString = localStorage.getItem('MReport');
    const myObject = JSON.parse(objectString);
    if(myObject !==null){
      setState(myObject)
    }
    const Month = localStorage.getItem('Month');
    if(Month !==null){
      setAssMonth(Month)
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  },[])

  const handleBack = () => {
    navigate("/B9");
  };

  // const SubmitBtn = () => {
  //   alert("Successfully Submitted");
  // }

  const edit = (item) => {
    console.log(item);
    setDepatId(item.depatId)
    setOrganization(item.organization)
    setPurpose(item.purpose)
    setMOUDate(dateFormat(item.mOUDate))
    setProgress(item.progress)
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // DeptId Dropdown //
  const handleDropdown = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.DeptId}`, {
        headers: h,
      });
      console.log(data);
      if (data) {
        setDropdown(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      DepatId: parseInt(depatId),
      Organization: organization,
      Purpose: purpose,
      MOUDate: mOUDate,
      Progress: progress,
      MRSId:state.id,
      investigatorId:parseInt(invis),
      rptMonth:parseInt(assM)
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B9SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted")
          navigate("/B9")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B9EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B9")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!depatId){
      setDepatIdErr(true)
      document.getElementById("depatId").focus()
    }else if(!organization){
      setOrganizationErr(true)
      document.getElementById("organization").focus()
    }else if(!purpose){
      setPurposeErr(true)
      document.getElementById("purpose").focus()
    }
    // else if(!mOUDate){
    //   setMOUDateErr(true)
    //   document.getElementById("mOUDate").focus()
    // }
    else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>MoUs signed Current Month (B9)</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>
            B9. MoUs signed/MTA/Linkages developed during this month:
          </h5>

           <FormControl style={{ width: "48%", margin: 12 }} error={depatIdErr} id="depatId">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Department Name
            </InputLabel>
            <Select
              variant="outlined"
              style={{ height: "44px" }}
              value={depatId}
              onChange={(e) => {
                setDepatId(e.target.value)
                setDepatIdErr(false)
              }}
            >
              {dropdown?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.instituteName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={organizationErr}
            id="organization"
            label="Organization Partnering Institute (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={organization}
            onChange={(e) => {
              setOrganization(e.target.value);
              setOrganizationErr(false);
            }}
          />

          <TextField
            error={purposeErr}
            id="purpose"
            label="Purpose (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={purpose}
            onChange={(e) => {
              setPurpose(e.target.value);
              setPurposeErr(false);
            }}
          />

          {/* <TextField
            error={mOUDateErr}
            id="outlined-basic"
            // label="Date of MoU"
            variant="outlined"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={mOUDate}
            onChange={(e) => {
              setMOUDate(e.target.value);
              setMOUDateErr(false);
            }}
          /> */}

          <TextField
          error={mOUDateErr}
            id="mOUDate"
            label="Award Date"
            type="date"
            variant="outlined"
            value={mOUDate}
            onChange={(e) => {
              setMOUDate(e.target.value);
              setMOUDateErr(false);
            }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={progressErr}
            id="Progress"
            label="Progress during this month (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={progress}
            onChange={(e) => {
              setProgress(e.target.value)
              setProgressErr(false)
            }}
          />


          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack} style={{paddingTop:"5px"}}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun} style={{paddingTop:"5px"}}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B9Inside;
