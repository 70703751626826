import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { useState, useEffect } from 'react';
import axios from "axios";
import Projectcodeinside from '../Pages/Project/Projectcodeinside';


function CheckboxList({ filterAdd, handleCheck }) {


    console.log(filterAdd);
    // const [token, setToken] = useState("");
    const [investigatorName, setInvestigatorName] = useState("")
    const [searchItem, setSearchItem] = useState("");
    const [filteruser, setFilteruser] = useState([]);
    const [facultyList, setFacultyList] = useState([]);
    // const [checked, setChecked] = useState([]);
    const [submit, setSubmit] = useState(false);

    //checkbox


    useEffect(() => {
        let change = filterAdd;
        setFilteruser(change)
    }, [filterAdd])



    const handleInputChange = (e) => {
        const searchItem = (e.target.value)
        setSearchItem(searchItem);

        const filteredItems = filteruser.filter((row) =>
            row.investigatorName.toLowerCase().includes(searchItem.toLowerCase()));

        setFilteruser(filteredItems);
    }

    const handleCheckbox = (e, value) => {

        if (facultyList.includes(value)) {
            setFacultyList(facultyList.filter(row => row !== value));
        }
        else {
            setFacultyList([...facultyList, value])
        }
    }



    const handleSubmit = () => {
        handleCheck(facultyList);
        setSubmit(true);

    }

    return (
        <div style={{ marginTop: 70 }}>
            <div
                class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title " id="exampleModalLongTitle">Additional Incharge</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <input
                                    type="text"
                                    value={searchItem}
                                    onChange={handleInputChange}
                                    placeholder='Type to search'
                                />
                                {filteruser?.map((row, index) => {
                                    const labelId = `checkbox-list-secondary-label-${row}`;
                                    return (
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <Checkbox
                                                    edge="end"
                                                    checked={facultyList.includes(row)}
                                                    onChange={(e) => handleCheckbox(e, row)}
                                                />
                                            }
                                            disablePadding
                                        >
                                            <ListItemButton>
                                                <ListItemText key={row.index} value={row.id}>
                                                    {row.investigatorName}</ListItemText>
                                            </ListItemButton>
                                        </ListItem>

                                    );
                                })}
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close" onClick={handleSubmit}>Submit</button>
                                </div>
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CheckboxList;

