import React, { useEffect, useState } from 'react'
import "./ManthyReport.css"
import { FaHandshake } from 'react-icons/fa'
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Navigate, useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import ComponentLoader from '../../ComponentLoader';
// import { head } from 'lodash';

function ManthyReport({ head }) {
    const Navigate = useNavigate('');
    useEffect(() => {
        head("ManthyReport")
        setLoader(false)
    }, [])

    const [loader,setLoader] =useState(true)

    const masterTransactionLeave = () => {
        Navigate("/transactionleave")
    }
    const masterTransactionOnduty = () => {
        Navigate("/transactionOnduty")
    }
    const masterTransactionTsms = () => {
        Navigate("/TransactionTsms")
    }
    const masterEducation = () => {
        Navigate("/Educational")
    }
    const masterStudentGuides = () => {
        Navigate("/Studentguides")
    }


    return (
        <div className='master-container'>
             <ComponentLoader isLoading={loader} />
            <div className='master-main'></div>

            <div className='master-flex'>
                <div className='master-1'>


                    <h4 className='master-heading'>General Info</h4>

                    <div className='master-icon'>
                        <div>
                            <AssuredWorkloadIcon className="sidebar-icon" />
                            <button className='master-data-btn' onClick={masterTransactionLeave}> Transaction Leave</button>
                        </div>
                        <p>2</p>
                    </div>

                    <div className='master-icon'>
                        <div>
                            <WorkIcon className="sidebar-icon" />
                            <button className='master-data-btn' onClick={masterTransactionOnduty}> Transaction OnDuty</button>
                        </div>
                        <p>3</p>
                    </div>

                    <div className='master-icon'>
                        <div>
                            <ExitToAppIcon className="sidebar-icon" />
                            <button className='master-data-btn' onClick={masterTransactionTsms}> Transaction TSMS</button>
                        </div>
                        <p>4</p>
                    </div>

                    <h4 className='master-heading'>Educational</h4>

                    <div className='master-icon'>
                        <div>
                            <ExitToAppIcon className="sidebar-icon" />
                            <button className='master-data-btn' onClick={masterEducation}> Educational</button>
                        </div>
                        <p>5</p>
                    </div>


                    <div className='master-icon'>
                        <div>
                            <ExitToAppIcon className="sidebar-icon" />
                            <button className='master-data-btn' onClick={masterStudentGuides}>Student Guides</button>
                        </div>
                        <p>8</p>
                    </div>
                </div>

                <div className='master-2'>

                    <p>research Activities</p>

                </div>


                <div className='master-3'>
                    <p>extra Activies</p>
                </div>
            </div>

        </div>
    )
}

export default ManthyReport
