import React from "react";
// import "./B3Inside.css";
import {
  TextField,
  Grid,
  // InputLabel,
  // Select,
  // MenuItem,
  DialogActions,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { B3VALIDATION } from "../../../Validation";
//  import { Targetproduct } from '../../../Pages/Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArB10Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true)
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [dropdown, setDropdown] = useState([]);
  const [PType, setPType] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [department, setDepartment] = useState("");
  const [degree, setDegree] = useState("");
  const [nameOfStudent, setNameOfStudent] = useState("");
  const [studentId, setStudentId] = useState("");
  const [year, setYear] = useState("");
  const [stPlacement, setStPlacement] = useState("");
  const [salary, setSalary] = useState("");
  const [placement, setPlacement] = useState("");
  const [faculty, setFaculty] = useState("");

  const [departmentErr, setDepartmentErr] = useState(false);
  const [degreeErr, setDegreeErr] = useState(false);
  const [nameOfStudentErr, setNameOfStudentErr] = useState(false);
  const [studentIdErr, setStudentIdErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const [stPlacementErr, setStPlacementErr] = useState(false);
  const [salaryErr, setSalaryErr] = useState(false);
  const [placementErr, setPlacementErr] = useState(false);
  const [facultyErr, setFacultyErr] = useState(false);

  const [insti, setInstitute] = useState();
  const [inves, setInves] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B3VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token"); 
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
    
      handleDropdown(tok);
      handleLovType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Target product");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token"); 
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    const instituteId = localStorage.getItem("loginId");
    setInstitute(instituteId);
    handleInvestigators(tok,instituteId);
  }, []);

  const edit = (item) => {
    console.log(item);
    setDepartment(item.departmentName);
    setDegree(item.degreeProgramme);
    setNameOfStudent(item.studentName);
    setStudentId(item.studentId);
    setYear(item.year);
    setStPlacement(item.stuPlacement);
    setSalary(item.salary);
    setPlacement(item.placement);
    setFaculty(item.investigatorId);
    setId(item.id);
  };

  // const SubmitBtn = () => {
  //   alert("SuccessFully Submited");
  //   // window.location.reload();
  // };

  const handleBack = () => {
    navigate("/ArB10");
  };

  // DeptId Dropdown //
  const handleDropdown = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.DeptId}`, {
        headers: h,
      });
      console.log(data);
      if (data) {
        setDropdown(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data); setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  // Lov Type //
  const handleLovType = async (token, ProductType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"ProductType"}`,
        {
          headers: h,
        }
      );
      console.log(data); setLoader(false)
      if (data) {
        setPType(data);
      }
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      InvestigatorId: faculty,
      DepartmentName: department,
      DegreeProgramme: degree,
      StudentId: studentId,
      StudentName: nameOfStudent,
      StuPlacement: stPlacement,
      Salary: salary,
      Placement: placement,
      Year: parseInt(year),
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    console.log(d);
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArB10SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArB10");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArB10EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Updated");
          navigate("/ArB10");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!department) {
      setDepartmentErr(true);
      document.getElementById("department").focus();
    } else if (!degree) {
      setDegreeErr(true);
      document.getElementById("degree").focus();
    } else if (!nameOfStudent) {
      setNameOfStudentErr(true);
      document.getElementById("nameOfStudent").focus();
    } else if (!studentId) {
      setStudentIdErr(true);
      document.getElementById("studentId").focus();
    } else if (!year) {
      setYearErr(true);
      document.getElementById("year").focus();
    } else if (!stPlacement) {
      setStPlacementErr(true);
      document.getElementById("stPlacement").focus();
    } else if (!salary) {
      setSalaryErr(true);
      document.getElementById("salary").focus();
    } else if (!placement) {
      setPlacementErr(true);
      document.getElementById("placement").focus();
    } else if (!faculty) {
      setFacultyErr(true);
      document.getElementById("faculty").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>B10. Student Placement
</span>
        </div>
      </div>
      <div className="from-mains">
        {/* <div className=" col-lg-6 col-md-6 col-sm-12 Activies-main"></div> */}
        <div className="form-control">
          <h5>B10. Student Placement
</h5>

          <TextField
            error={departmentErr}
            id="department"
            label="Department / College (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={department}
            onChange={(e) => {
              setDepartment(e.target.value);
              setDepartmentErr(false);
            }}
          />

          <TextField
            error={degreeErr}
            id="degree"
            label="Degree Programme (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={degree}
            onChange={(e) => {
              setDegree(e.target.value);
              setDegreeErr(false);
            }}
          />

          <TextField
            error={nameOfStudentErr}
            id="nameOfStudent"
            label="Name of the student"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={nameOfStudent}
            onChange={(e) => {
              setNameOfStudent(e.target.value);
              setNameOfStudentErr(false);
            }}
          />

          <TextField
            error={studentIdErr}
            id="studentId"
            label="Student IDNo."
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={studentId}
            onChange={(e) => {
              setStudentId(e.target.value);
              setStudentIdErr(false);
            }}
          />

          <TextField
            error={yearErr}
            id="year"
            label="Year/ Batch"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              setYearErr(false);
            }}
          />

          <TextField
            error={stPlacementErr}
            id="stPlacement"
            label="Student’s placement at (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={stPlacement}
            onChange={(e) => {
              setStPlacement(e.target.value);
              setStPlacementErr(false);
            }}
          />

          <TextField
            error={salaryErr}
            id="salary"
            label="Salary (p.m.) (Max 250 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={salary}
            onChange={(e) => {
              setSalary(e.target.value);
              setSalaryErr(false);
            }}
          />

          <TextField
            error={placementErr}
            id="placement"
            label="Placement through (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={placement}
            onChange={(e) => {
              setPlacement(e.target.value);
              setPlacementErr(false);
            }}
          />

          <FormControl
            style={{ width: "45%", margin: 12 }}
            error={facultyErr}
            id="faculty"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Placement Coordinator / Faculty in-charge
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              style={{ height: "45px" }}
              value={faculty}
              onChange={(e) => {
                setFaculty(e.target.value);
                setFacultyErr(false);
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <div className="from-last">
            <button
              // type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
              style={{ paddingTop: "5px" }}
            >
              Back
            </button>
            <button
              className="sbmit-buttons"
              style={{ paddingTop: "5px" }}
              onClick={() => formValidateFun()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArB10Inside;
