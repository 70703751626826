import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A5VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment/moment";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArB6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [studentId, setStudentId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [Role, setRole] = useState("");
  const [thesistitle, setThesistitle] = useState("");
  const [fellowshipdetails, setFellowshipdetails] = useState("");
  const [thesissubmission, setThesissubmission] = useState("");
  const [date, setdate] = useState("");
  const [dateViva, setDateViva] = useState("")
  const [loader,setLoader] =useState(true)
  const [studentIdErr, setStudentIdErr] = useState(false);
  const [studentNameErr, setStudentNameErr] = useState(false);
  const [courseTypeErr, setCourseTypeErr] = useState(false);
  const [RoleErr, setRoleErr] = useState(false);
  const [thesistitleErr, setThesistitleErr] = useState(false);
  const [fellowshipdetailsErr, setFellowshipdetailsErr] = useState(false);
  const [thesissubmissionErr, setThesissubmissionErr] = useState(false);
  const [dateErr, setdateErr] = useState(false);
  const [dateVivaErr, setDateVivaErr] = useState(false);

  const [inves, setInves] = useState([]);
  const [facultyErr, setFacultyErr] = useState(false);
  const [faculty, setfaculty] = useState("");

  const [dropcoursetype, setDropcoursetype] = useState([]);
  const [dropnatcoching, setDropnatcoching] = useState([]);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [Lovrole, setLovrole] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A5VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      handleCochingNaturetypes(tok);
      handleDirectorate(tok);
      handleCoursetypes(tok);
      handleInvestigators(tok)
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student Guidance");
  }, []);
  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    handleInvestigators(tok,investigator)
  }, []);

  const backs = () => {
    navigate("/ArB6");
  };
  const edit = (item) => {
    console.log(item);
    setfaculty(item.investigatorId);
    setStudentId(item.studentId);
    setStudentName(item.studentName);
    setCourseType(item.courseType);
    setRole(item.memberRole);
    setThesistitle(item.thesistitle);
    setFellowshipdetails(item.fellowshipdetails);
    setdate(dateFormat(item.thesissubmission));
    setDateViva(dateFormat(item.finalVVDate))
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleCoursetypes = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcoursetype(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data); setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };


  // Lov
  const handleLov = async (token, MemberRole) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"MemberRole"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovrole(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  // Lov
  const handleCochingNaturetypes = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnatcoching(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: faculty,
      StudentId: studentId,
      StudentName: studentName,
      CourseType: courseType,
      MemberRole: Role,
      Thesistitle: thesistitle,
      Fellowshipdetails: fellowshipdetails,
      Thesissubmission: date,
      FinalVVDate: dateViva,
      IAMRSId: state.id,
      InstituteId: parseInt(invis),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArB6");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB6EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArB6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.A5GETALL}`, {
        headers: h,
      });
      console.log(data); setLoader(false)
      // setStudentbtn(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const formValidateFun = (e) => {
    e.preventDefault()
    if (!faculty) {
      setFacultyErr(true);
      document.getElementById("faculty").focus();
    }
    else if (!studentId) {
      setStudentIdErr(true);
      document.getElementById("studentId").focus();
    } else if (!studentName) {
      setStudentNameErr(true);
      document.getElementById("studentName").focus();
    } else if (!courseType) {
      setCourseTypeErr(true);
      document.getElementById("courseType").focus();
    } else if (!Role) {
      setRoleErr(true);
      document.getElementById("Role").focus();
    } else if (!thesistitle) {
      setThesistitleErr(true);
      document.getElementById("thesistitle").focus();
    } else if (!fellowshipdetails) {
      setFellowshipdetailsErr(true);
      document.getElementById("fellowshipdetails").focus();
    }
    else if (!date) {
      setdateErr(true);
      document.getElementById("date").focus();
    }
    else if (!dateViva) {
      setDateVivaErr(true);
      document.getElementById("dateViva").focus();
    }
    else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">

        <div className="master-main-span">
          <span>B6. Student Guidance</span>
        </div>
      </div>
      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ margin: 20 }}>B6. Student Guidance</h5>



          <FormControl
            style={{ width: "48%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name of the faculty            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={facultyErr}
              style={{ height: "45px" }}
              value={faculty}
              onChange={(e) => {
                setfaculty(e.target.value);
                setFacultyErr(false);
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={studentIdErr}
            id="studentId"
            label="Student Id"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            value={studentId}
            onChange={(e) => {
              setStudentId(e.target.value);
              setStudentIdErr(false);
            }}
          />

          <TextField
            error={studentNameErr}
            id="studentName"
            label="Student Name"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={studentName}
            onChange={(e) => {
              setStudentName(e.target.value);
              setStudentNameErr(false);
            }}
          />

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={courseTypeErr}
            id="courseType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Degree
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Degree"
              size="small"
              sx={{ width: "100%" }}
              value={courseType}
              onChange={(e) => {
                setCourseType(e.target.value);
                setCourseTypeErr(false);
              }}
            >
              {dropnatcoching?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
            error={memberNameErr}
            id="memberName"
            label="Member Name"
            variant="outlined"
            value={memberName}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setMemberName(e.target.value);
              setMemberNameErr(false);
            }}
          /> */}
          <FormControl style={{ width: "48%", margin: 12 }} error={RoleErr} id="programtype">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={Role}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setRole(e.target.value)
                setRoleErr(false)
              }}
            >
              {Lovrole?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={thesistitleErr}
            id="thesistitle"
            label="Thesis Title (Max 500 Character)"
            variant="outlined"
            value={thesistitle}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setThesistitle(e.target.value);
              setThesistitleErr(false);
            }}
          />

          <TextField
            error={fellowshipdetailsErr}
            id="fellowshipdetails"
            label="Fellowship Details (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={fellowshipdetails}
            onChange={(e) => {
              setFellowshipdetails(e.target.value);
              setFellowshipdetailsErr(false);
            }}
          />

          {/* <TextField
            error={thesissubmissionErr}
            id="thesissubmission"
            label="Thesis Submission"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={thesissubmission}
            onChange={(e) => {
              setThesissubmission(e.target.value);
              setThesissubmissionErr(false);
            }}
          /> */}

          <TextField
            error={dateErr}
            id="date"
            label="Date of Thesis submission"
            type="date"
            variant="outlined"
            value={date}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setdate(e.target.value)
              setdateErr(false)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={dateVivaErr}
            id="date"
            label="Date of Final viva voce"
            type="date"
            variant="outlined"
            value={dateViva}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setDateViva(e.target.value)
              setDateVivaErr(false)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              // onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArB6Inside;
