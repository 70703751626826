import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Toast } from 'primereact/toast';
import { BounceLoader } from 'react-spinners';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axiosInstance from "../../Service/AxiosInstance";
import Carousel from 'react-bootstrap/Carousel';
import logo from "../../Images/tnau.png";
import img1 from "../../Images/header3/1.jpg";
import img2 from "../../Images/header3/2.jpg";
import img3 from "../../Images/header3/3.jpg";
import img4 from "../../Images/header3/4.jpg";
import img5 from "../../Images/header3/5.jpg";
import img6 from "../../Images/header3/6.jpg";
import img7 from "../../Images/header3/7.jpeg";
import img8 from "../../Images/header3/8.jpg";
import img9 from "../../Images/header3/9.jpg";
import img10 from "../../Images/header3/10.jpg";
import img11 from "../../Images/header3/11.jpg";
import img12 from "../../Images/header3/12.jpg";
import img13 from "../../Images/header3/13.jpg";
import img14 from "../../Images/header3/14.jpg";
import img15 from "../../Images/header3/15.jpg";
import img16 from "../../Images/header3/16.jpg";
import img17 from "../../Images/header3/17.jpeg";
import img18 from "../../Images/header2/14.jpg";
import img19 from "../../Images/header2/10.2.jpg";
import img20 from "../../Images/header2/12.jpg";
// import img18 from "../../Images/header2/18.jpg";


import "./Login.css";
import { Input, InputLabel } from "@mui/material";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Login = ({ callback }) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false)
  const toast = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState([]);

  const [close, setClose] = useState(true);

  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const tok = localStorage.getItem("token")
    // if (tok !=null && tok != undefined) {
    //   navigate('/')
    // }
    const userAgent = navigator.userAgent;
    console.log(userAgent);
  }, [])

  useEffect(() => {
    // Log to check if the useEffect hook is triggered
    console.log('AutoplayCarousel mounted');

    // Set up interval to change slides
    const intervalId = setInterval(() => {
      // Log to check if interval function is called
      console.log('Interval function called');

      // Get the current active carousel item
      const currentSlide = document.querySelector('.carousel-item.active');
      if (currentSlide) {
        // Get the next carousel item or the first one if there's no next item
        const nextSlide = currentSlide.nextElementSibling || document.querySelector('.carousel-item:first-child');
        // Remove the 'active' class from the current slide and add it to the next slide
        currentSlide.classList.remove('active');
        nextSlide.classList.add('active');
      }
    }, 3000); // Adjust the interval time in milliseconds (2000ms = 2 seconds)

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      // Log to check if cleanup function is called
      console.log('AutoplayCarousel unmounted');
    };
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { loginWithRedirect, user, isAuthenticated } = useAuth0()

  function refreshPage() {

    window.location.reload(true);
  }

  const loginSubmit = async (e) => {
    e.preventDefault();
    const d = {
      username,
      password,
    };
    console.log(username, password);
    if (username == "" || password == "") {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Plz Fill Required', life: 3000 });
      return;
    }
    setLoading(true)
    console.log(d)
    try {
      const { data } = await axios.post(`${window.BASE}${window.LOGIN}`, d);
   
      setValue(data);

      if (data.token != null) {

        localStorage.setItem("loginId", data.userId)
        localStorage.setItem("username", username)
        
        localStorage.setItem("token", data.token);
        localStorage.setItem("Name", data.name)
        localStorage.setItem('designation',data.designation)
        if(data.userType === 'Assistant'){
          localStorage.setItem("role", 'Principal')
        }else{
          localStorage.setItem("role", data.userType)
        }
  
        // localStorage.setItem("Admin",data.name)
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        // refreshPage()
        setLoading(false)
        navigate("/Dashboard");
        callback("Update")

      } else if (data.result == null) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Incorrect', life: 3000 });
        setLoading(false);

      }
      if (data.userType === "Principal" || data.userType === "Assistant" ) {
        handleDirectDetails(data.token, data.userId)
      }


    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  
  const handleDirectDetails = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(id);
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.INSTITUDE_ABOUT}${id}`,
        {
          headers: h,
        }
      );
      console.log(data)
      if (data) {
        localStorage.setItem("DirectorID", data.directorateId)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const passwordVisible = (e) => {
    e.preventDefault();
    setVisible(!visible);
  };
  const override = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "1000"
  }
  console.log(password);
  const def = {
    position: "fixed",
    width: "100%",
    zIndex: "1000",
  };
  return (
    <>
      {isLoading && <BounceLoader color="#ec764b" className="bounceloader" cssOverride={override} />}
      <Toast ref={toast} />
      <div className="login-container" >
        {/* <div className="login-header"> */}
        {/* <div>
            <div className="header" style={{ background: "#fff", height: "75px" }}>
              <div className="js-main-header "> */}
        {/* <div class="carousel-item-img7">

                </div> */}
        {/* <div style={{ marginLeft: 7, marginTop: 7 }} className="login-logo ">
                  <img src={logo} className="js-img-header top-responsive-header" />
                </div>
                <div className="top-center-header ">
                  <span style={{ fontSize: '21px' }}>

                    TamilNadu Agricultural University <br></br>
                    <span className="top-head">
                      Directorate of TamilNadu Rice Research Institute
                    </span>
                  </span>
                </div> */}
        {/* <div className="header-form">
                  <div className="header-form-flex ">
                    <div className="login-div login-marg1">
                      <span>User Name</span>
                      <TextField
                        id="padd"

                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        maxRows={4}
                        onChange={(e) => setUserName(e.target.value)}

                      ></TextField>
                     
                    </div>
                    <div className="login-div login-marg2">
                      <span>Password</span>
                      <FormControl sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} variant="outlined">
                       
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          onChange={(e) => setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword()}
                                onMouseDown={handleMouseDownPassword}

                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    </div>

                  </div>
                  <div id="header-login" className="loginbtn header-login" class="row">
                    <button onClick={(e) => loginSubmit(e)}>Login</button>
                  </div>
                </div> */}
       



        <div className="login-banner">
     





          <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle" style={{ color: "black" }}>LOGIN</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div id="loginform">

                    <form>
                      <div class="row">
                        <span>UserName</span>
                        <TextField
                          id="standard-multiline-flexible"

                          sx={{ width: '25ch' }}
                          maxRows={4}
                          onChange={(e) => setUserName(e.target.value)}
                          variant="standard"
                        />
                      </div>
                      <div class="row position-relative">

                        <span>Password</span>
                        <TextField
                          id="standard-multiline-flexible"
                          type="password"
                          sx={{ width: '25ch' }}
                          maxRows={4}
                          onChange={(e) => setPassword(e.target.value)}
                          variant="standard"

                        />

                      </div>

                      <div id="button" className="loginbtn" class="row">
                        <button onClick={(e) => loginSubmit(e)} data-dismiss="modal" aria-label="Close">Login</button>
                      </div>
                      <div className="forget">

                      </div>
                      <div className="login-bottom">
                        <p>
                          Designed By <a href="https://thepikture.com/" target="_blank">Pikture Associates Pvt Ltd</a>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        
        <div className="login-body">
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="d-block w-100" src={img1} alt="First slide" />
              </div>

              <div class="carousel-item" id="img-carousell">
                <img class="d-block w-100" src={img2} alt="Second slide" />
              </div>
            
              <div class="carousel-item">
                <img class="d-block w-100" src={img3} alt="Second slide" />
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img4} alt="Third slide" />

              </div>
             
              <div class="carousel-item" id="img-carousell">
                <img class="d-block w-100" src={img5} alt="Third slide" />

              </div>
              <div class="carousel-item" id="img-carousell">
                <img class="d-block w-100" src={img6} alt="Third slide" />

              </div>
              <div class="carousel-item" id="img-carousell">
                <img class="d-block w-100" src={img7} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img8} alt="Third slide" />

              </div>
             
              <div class="carousel-item">
                <img class="d-block w-100" src={img9} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img10} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img11} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img12} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img13} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img14} alt="Third slide" />

              </div>
            
              <div class="carousel-item">
                <img class="d-block w-100" src={img15} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img16} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img17} alt="Third slide" />

              </div>
              {/* <div class="carousel-item">
                <img class="d-block w-100" src={img18} alt="Third slide" />

              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={img19} alt="Third slide" />

              </div>
            
              <div class="carousel-item">
                <img class="d-block w-100" src={img20} alt="Third slide" />

              </div> */}
             
              {/* <div class="carousel-item">
                <img class="d-block w-100" src={img18} alt="Third slide" />
                <div class="carousel-caption d-none d-md-block">
                </div>
              </div> */}
              {/* <div className="banner-footer">
                <div style={{ paddingLeft: 40 }}>© 2024 Directorate of Tamil Nadu Rice Research Institute</div>
                <div style={{ paddingRight: 40 }} className="banner-footer1">Powered by <a href="https://thepikture.com/" target="blank">Pikture Associates</a></div>
              </div> */}
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>


        </div>

        <div>
          <div class="response-about">

            <p>Honourable Vice-Chancellor of Tamil Nadu Agricultural University, Coimbatore emphasized
              the need for improving the existing education and research management system to improve
              the efficiency of scientists.  Honourable Vice-Chancellor also tinted the need for improved
              monitoring mechanism for all the University Officers. This vital gap highlighted by the Honourable
              Vice-Chancellor of Tamil Nadu Agricultural University, Coimbatore was the root cause
              for the evolution of Digital Reporting System for Region II Research Station. </p>

            <p> This thought provoking discussion by the Honourable Vice-Chancellor of Tamil Nadu
              Agricultural University, Coimbatore kindled Dr.K. Subrahmaniyan, Director, Tamil
              Nadu Rice Research Station, Aduthurai and inspired to bridge this existing gap.
              Director understood that ICT tools have immense potential to solve this issue and
              also emphasized the need for one single platform for monitoring research work
              flow, generating reports and permanent information depository for the region II
              directorate. </p>

            <p>Based on the scrutiny of scientists available at TRRI, Authurai, Director assigned this splendid task
              to Dr.K.Manikandan, Assistant Professor (SS&AC) considering his previous expertise
              on use of ICT tools in developing decision support system for mushroom choice for entire Indian continent. </p>


            <p>Initially, the formal nod was obtained from the Honourable Vice-Chancellor of Tamil Nadu Agricultural
              University, Coimbatore to initiate this noble work. Dr.K. Subrahmaniyan, Director, TRRI, Aduthurai
              has kind enough to provide a separate web server for hosting this web
              application.
            </p>
            <p>
              In order to evolve a user friendly, acceptable web application module, a committee has been constituted
              on August, 2022, by involving Heads of Research Stations, Faculties and staff from all 12 Region
              II Research Stations under the chairmanship of Director, TRRI, Aduthurai. Wants and needs of individual
              users viz., Scientists, Staffs, Heads of Departments and University officers was discussed
              and elaborate information collection insisted by the committee to meet the objective of this process.
            </p>
            <p>
              Web designing and programming components evolved in an accepted manner by the Pikture Associates Ltd., Chennai.
              The prototype web application module was floated in the web on March, 2023 and comments from individual
              users from all 12 research stations were obtained. The corrections were carried out accordingly and web
              application module was refined technically. The aesthetic view of the front end of the application also
              improved.
            </p>
            <p>
              The trial run of web application was initiated in the month of August, 2023. Regular feedbacks from individual users were obtained and errors rectified now and then.
              The improved web application module was launched officially by  the Honourable Vice-Chancellor of Tamil Nadu Agricultural University on April, 2024.
            </p>
            <br></br>

          </div>
        </div>
      </div>
      {/* <div className="easyyy"> */}
      {/* <div className="login-flex" id={isLoading ? "login-flex" : null}>
        <Toast ref={toast} /> */}


      {/* <div id="loginform">
          <div className="login-Img">
            <img src={LoginImage} width={100} />
            <h1>Directorate of Tamil Nadu Rice Research Institute</h1>
            <h5 className="login-colors" style={{ marginTop: 13 }}>Monthly Reporting System</h5>
          </div>
          <form>
            <div class="row">             
              <TextField
                id="standard-multiline-flexible"
                label="User Name"
                sx={{ width: '25ch' }}
                maxRows={4}
                onChange={(e) => setUserName(e.target.value)}
                variant="standard"
              />
            </div>
            <div class="row position-relative">
              
              <FormControl sx={{ width: '25ch' }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <div id="button" className="loginbtn" class="row">
              <button onClick={(e) => loginSubmit(e)}>Login</button>
            </div>
            <div className="forget">
              
            </div>
            <div className="login-bottom">
              <p>
                Designed By <a href="https://thepikture.com/" target="_blank">Pikture Associates Pvt Ltd</a>
              </p>
            </div>
          </form>
        </div> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Login;