import React from 'react';
import h3 from "../Images/header/3.jpg";

function Utilities() {
  return (
    <>
      <div>
        <div class="utility-page">
          <div class="carousel-item-img5">


            <div class="content-page">
              <h5>Vision </h5>
              <p>Establishing a permanent repository of information for Region II Research
                Stations and aiding administration an ease flow of information for efficient monitoring
                of education, research and extension in a sustainable means. </p>
              <h5>Mission</h5>
              <p>Bestowing outstanding digital reporting platform for scientific, technical and
                administrative personnel in the sphere of improving agricultural education, research and
                extension with a focus on quality reporting  </p>
              <h5>Objectives </h5>
              <p>To provide ideal environment for official reporting of different users of  the region II
                research stations</p>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Utilities;
