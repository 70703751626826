import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { B7VALIDATION } from "../../../Validation";
// import { Activetiesvalidation } from '../Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../../ComponentLoader";

function B7Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [depatId, setDepatId] = useState("");
  const [descriptionoftheProduct, setDescriptionoftheProduct] = useState("");
  const [patentType, setPatentType] = useState("");
  const [remarks, setRemarks] = useState("");

  const [depatIdErr, setDepatIdErr] = useState("");
  const [descriptionoftheProductErr, setDescriptionoftheProductErr] = useState("");
  const [patentTypeErr, setPatentTypeErr] = useState("");
  const [remarksErr, setRemarksErr] = useState("");
  const [loader,setLoader] =useState(true);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [PType, setPType] = useState([]);
  console.log(PType);
  const [depatIdPatentType, setDepatIdPatentType] = useState("");
  const [dropdown, setDropdown] = useState([]);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(B7VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      handleLovType(tok);
      handleDropdown(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }

    head("PPVFRA registration");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  // dropdown
  const handleLovType = async (token, PatentType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"PatentType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setPType(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const edit = (item) => {
    console.log(item);
    setDepatId(item.depatId);
    setDescriptionoftheProduct(item.descriptionoftheProduct);
    setPatentType(item.patentType);
    setRemarks(item.remarks);
    setId(item.id);
  };

  const handleBack = () => {
    navigate("/B7");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // DeptId Dropdown //
  const handleDropdown = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.DeptId}`, {
        headers: h,
      });
      console.log(data);
      if (data) {
        setDropdown(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      DepatId: parseInt(depatId),
      DescriptionoftheProduct: descriptionoftheProduct,
      PatentType: patentType,
      Remarks: remarks,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B7SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B7")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B7EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted")
          navigate("/B7")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!depatId){
      setDepatIdErr(true)
      document.getElementById("depatId").focus()
    }else if(!descriptionoftheProduct){
      setDescriptionoftheProductErr(true)
      document.getElementById("descriptionoftheProduct").focus()
    }else if(!patentType){
      setPatentTypeErr(true)
      document.getElementById("patentType").focus()
    }else if(!remarks){
      setRemarksErr(true)
      document.getElementById("remarks").focus()
    }else{
      handleLeavepage()
    }
  }


  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Patents registration (B7)</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            B7.Patents / GIs / NBPGR / PPVFRA registration
          </h5>

          <FormControl style={{ width: "48%", margin: 12 }} error={depatIdErr} id="depatId">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Institute Name
            </InputLabel>
            <Select
              variant="outlined"
              style={{ height: "44px" }}
              value={depatId}
              onChange={(e) => {
                setDepatId(e.target.value)
                setDepatIdErr(false)
              }}
              // {...register("DepatId")}
              // error={errors.DepatId ? true : false}
              // helperText={errors.DepatId?.message}
            >
              {dropdown?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.instituteName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={descriptionoftheProductErr}
            id="prodcutSpecialFeature"
            label="Product Description (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={descriptionoftheProduct}
            onChange={(e) => {
              setDescriptionoftheProduct(e.target.value)
              setDescriptionoftheProductErr(false)
            }}
          />

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={patentTypeErr}
            id="patentType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Product Type
            </InputLabel>
            <Select
              variant="outlined"
              style={{ height: "45px" }}
              value={patentType}
              onChange={(e) => {
                setPatentType(e.target.value);
                setPatentTypeErr(false);
              }}
            >
              {PType?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={remarksErr}
            id="remarks"
            label="Remark"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value)
              setRemarksErr(false)
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              style={{paddingTop:"5px"}}
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              style={{paddingTop:"5px"}}
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B7Inside;
