import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import logo from "./logo3.png"
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CgProfile } from "react-icons/cg";
import { BiLogOut } from "react-icons/bi";
import axiosInstance from './Service/AxiosInstance';
import { BarLoader,BounceLoader,DotLoader } from 'react-spinners';
import HeaderClone from './Components/Header/HeadersClone';

const Loading = ({callback}) => {

  const override = {
    position:"absolute", 
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex:"1000"
     }
  return <BounceLoader color="#ec764b"  cssOverride={override} speedMultiplier={1}/>
    // <div className='loading'> 
    // </div>
  
}

export default Loading
