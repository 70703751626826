import React from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField,} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";

import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import ComponentLoader from "../../../ComponentLoader";

const ArA3Inside = ({head}) => {
    const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  

  const [dirr, setDepartment] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [inves, setInves] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti,setInstitude]=useState();
  const [loader,setLoader] =useState(true)
  // const [value,setvalue]=useState({
  //   InvestigatorId:"",
  //   Designation:"",
  //   WorkingWith:"",
  //   DateOfJoin:"",
  //   Responsibilites:""
  // })

  // const [errValue,setErrvalue]=useState({
  //   InvestigatorId:false,
  //   Designation:false,
  //   WorkingWith:false,
  //   DateOfJoin:false,
  //   Responsibilites:false
  // });

  const[value,setValue]=useState({
    InvestigatorId:"",
    Designation:"",
    WorkingWith:"",
    DateOfJoin:"",
    Responsibilites:""
   });

   const[errValue,setErrValue]=useState({
    InvestigatorId:false,
    Designation:false,
    WorkingWith:false,
    DateOfJoin:false,
    Responsibilites:false
   })
  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A6VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
    
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Advisory Committee Chairman");

    console.log("_---------------------");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem('MReport');
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject)
    }
    const Month = localStorage.getItem('Month');
    if (Month !== null) {
      setAssMonth(Month)
    }
    const institude = localStorage.getItem("loginId");
    const token = localStorage.getItem("token");
    setInstitude(institude);
    handleInvestigators(token,institude)
    
  }, [])
  console.log(state)

  const backs = () => {
    navigate("/ArA3")
  }

  const edit = (item) => {
    console.log(item);
    
    setValue({
    InvestigatorId:item.investigatorId,
    Designation:item.designation,
    WorkingWith:item.workingWith,
    DateOfJoin:(moment(item.dateOfJoin).format("YYYY-MM-DD")),
    Responsibilites:item.responsibilites
    })
    setId(item.id)
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };
  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
    

  })
  console.log("value",value);

  const handleLeavepage = async () => {
    console.log();
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      InvestigatorId: value.InvestigatorId,
      Designation: value.Designation,
      WorkingWith: value.WorkingWith,
      DateOfJoin: value.DateOfJoin,
      Responsibilites: value.Responsibilites,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM)
    };
    console.log("d",d);
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA3SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted")
          navigate("/ArA3")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA3EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted")
          navigate("/ArA3")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = (e) => {
    e.preventDefault()
    if (!value.InvestigatorId) {
      setErrValue((prev) => ({ ...prev, InvestigatorId: true }))
      document.getElementById("InvestigatorId").focus();
    } else if (!value.Designation) {
      setErrValue((prev) => ({ ...prev, Designation: true }))
      document.getElementById("Designation").focus();
    } else if (!value.WorkingWith) {
      setErrValue((prev) => ({ ...prev, WorkingWith: true }))
      document.getElementById("WorkingWith").focus();
    }
     else if (!value.DateOfJoin) {
      setErrValue((prev) => ({ ...prev, DateOfJoin: true }))
      document.getElementById("DateOfJoin").focus();
   
    } 
    else if (!value.Responsibilites) {
      setErrValue((prev) => ({ ...prev, Responsibilites: true }))
      document.getElementById("Responsibilites").focus();
   
    }
    else {
      handleLeavepage()
  };
}

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A3.  Permanent Staff
</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>

        <div className="form-control">
          <h5 style={{ width: 400, margin: 20 }}>
          A3.  Permanent Staff
        </h5>


          <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="nameOfFaculty"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
               Name of the Staff Member
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={errValue.InvestigatorId}
              style={{ height: "45px" }}
              value={value.InvestigatorId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

        
          <TextField
            id="Designation"
            label="Designation (Max 500 Character)"
            variant="outlined"
            name="Designation"
            style={{ margin: "10px", width: "48%" }}
            // value={Noofclassesoffered}
            value={value.Designation}
            error={errValue.Designation}

            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Designation: false }));
            }}
          />

          <TextField
            id="WorkingWith"
            label="Main/Scheme in which working (Max 500 Character)"
            variant="outlined"
            name="WorkingWith"
            style={{ margin: "10px", width: "48%" }}
            // value={Noofclassesoffered}
            value={value.WorkingWith}
            error={errValue.WorkingWith}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, WorkingWith: false }));
            }}
          />

          <TextField
            id="DateOfJoin"
            label="Date of joining in the present office"
            variant="outlined"
            name="DateOfJoin"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            // value={Noofclassesoffered}
            value={value.DateOfJoin}
            error={errValue.DateOfJoin}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, DateOfJoin: false }));
            }}
            InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                placeholder: "",
              }}
          />

          <TextField
            id="Responsibilites"
            label="Assigned Responsibiities (Max 500 Character)"
            variant="outlined"
            name="Responsibilites"
            style={{ margin: "10px", width: "48%" }}
            // value={Noofclassesoffered}
            value={value.Responsibilites}
            error={errValue.Responsibilites}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Responsibilites: false }));
            }}
          />



          <div className="from-last">
            <button type="submit" style={{ backgroundColor: "#008CBA", height: "20px" }} onClick={backs}>Back</button>
            <button
              type="submit"
              className="sbmit-buttons"

            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ArA3Inside

