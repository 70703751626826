import {toast } from "react-toastify";





export const toastSuccess = (success) => {
    toast.success(success, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
export const toastError = (error) => {
    toast.error(
      error,
      {
        position: toast.POSITION.TOP_RIGHT,
      },
      1000
    );
  };
