import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { C8VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArC14Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [LovGrouptype, setLovGrouptype] = useState([]);
  const [fund, setFund] = useState([])
  const [loader,setLoader] =useState(true)
  const [OrganizationGrouptype, setOrganizationGrouptype] = useState("");
  const [OrganizationName, setOrganizationName] = useState("");
  const [Noofmembers, setNoofmembers] = useState("");
  const [Activities, setActivities] = useState("");

  const [OrganizationGrouptypeErr, setOrganizationGrouptypeErr] =
    useState(false);
  const [OrganizationNameErr, setOrganizationNameErr] = useState(false);
  const [NoofmembersErr, setNoofmembersErr] = useState(false);
  const [ActivitiesErr, setActivitiesErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti, setInsti] = useState();

  const [inves, setInves] = useState([])
  const [InvestigatorName,setInvestigatorName] = useState("")
 const [InvestigatorNameErr,setInvestigatorNameErr] = useState(false)


  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [value, setValue] = useState({
    ProjectTitle: "",
    FundingAgencyId: "",
    Budget: "",
    SubmissionDate: "",
    Status: "",
  });

  const [errValue, setErrValue] = useState({
    ProjectTitle: false,
    FundingAgencyId: false,
    Budget: false,
    SubmissionDate: false,
    Status: false,

  });

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C8VALI),
  });

  const backs = () => {
    navigate("/ArC14");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleFund(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" SHG created");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    const tok = localStorage.getItem("token");
    setInsti(insti);
    handleInvestigators(tok, insti)
  }, []);

  const edit = (item) => {
    console.log(item);
    setValue({
      ProjectTitle: item.projectTitle,
      FundingAgencyId: item.fundingAgencyId,
      Budget: item.budget,
      SubmissionDate: dateFormat(item.submissionDate),
      Status: item.status,
    })
    setInvestigatorName(item.investigatorId);
    setId(item.id);
  };

  const handleData = ((event) => {
    const { name, value } = event.target
    setValue(prev => ({
      ...prev,
      [name]: value
    }))
  })

  console.log("value", value);

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleInvestigators = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  // Lov Group Type
  const handleFund = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.FUNDINGGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setFund(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {

      id,
      InvestigatorId: InvestigatorName,
      ProjectTitle: value.ProjectTitle,
      FundingAgencyId: value.FundingAgencyId,
      Budget: value.Budget,
      SubmissionDate: value.SubmissionDate,
      Status: value.Status,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARC14SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC14");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARC14EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC14");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if(!InvestigatorName){
      setInvestigatorNameErr(true)
      document.getElementById("InvestigatorName").focus()}

   else if (!value.ProjectTitle) {
      setErrValue((prev) => ({ ...prev, ProjectTitle: true }))
      document.getElementById("ProjectTitle").focus()
    } else if (!value.FundingAgencyId) {
      setErrValue((prev) => ({ ...prev, FundingAgencyId: true }))
      document.getElementById("FundingAgencyId").focus()
    } else if (!value.Budget) {
      setErrValue((prev) => ({ ...prev, Budget: true }))
      document.getElementById("Budget").focus()
    }
    else if (!value.SubmissionDate) {
      setErrValue((prev) => ({ ...prev, SubmissionDate: true }))
      document.getElementById("SubmissionDate").focus()
    }
    else if (!value.Status) {
      setErrValue((prev) => ({ ...prev, Status: true }))
      document.getElementById("Status").focus()
    }
    else {
      handleLeavepage()
    }

  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C14. New Project Proposed
          </span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            C14. New Project Proposed

          </h5>

          {/* <FormControl style={{ width: "48%", margin: 12 }} error={OrganizationGrouptypeErr} id="OrganizationGrouptype">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Organization/Group Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={OrganizationGrouptype}
              label="Programme Type"
              size="small"
              // style={{ margin: "10px", width: "48%" }}
              // sx={{ width: "100%" }}
              onChange={(e) => {
                setOrganizationGrouptype(e.target.value)
                setOrganizationGrouptypeErr(false)
              }}
            >
              {LovGrouptype?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
          {/* ProjectTitle:"",
    FundingAgencyId:"",
    DateOfClosure:"",
    SubmissionReport:"",
    SettlementOfAccounts:"", */}

          <FormControl style={{ width: "48%", margin: 12 }} error={InvestigatorNameErr} id="InvestigatorName">
            <InputLabel id="demo-simple-select-helper-label" size="small">
             Faculty Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="investigatorName"
              value={InvestigatorName}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setInvestigatorName(e.target.value)
                setInvestigatorNameErr(false)
              }}
            >
              {inves?.map((row, index) => {
                return (
                  <MenuItem key={row.index} value={row.id}>
                    {row.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField

            id="ProjectTitle"
            label="Title of the Project"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="ProjectTitle"
            error={errValue.ProjectTitle}
            value={value.ProjectTitle}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ProjectTitle: false }));
            }}
          />

          {/* <TextField
           
            id="FundingAgencyId"
            label="Funding
            Agency
            "
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="FundingAgencyId"
            error={errValue.FundingAgencyId}
            value={value.FundingAgencyId}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, FundingAgencyId: false }));
            }}
          /> */}


          <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="FundingAgencyId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Funding
              Agency                        </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="FundingAgencyId"
              error={errValue.FundingAgencyId}
              style={{ height: "45px" }}

              value={value.FundingAgencyId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, FundingAgencyId: false }));
              }}
            >
              {fund?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.fundingAgencyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* ProjectTitle:"",
    FundingAgencyId:"",
    Budget:"",
    SubmissionDate:"",
    Status:"", */}

          <TextField

            id="c"
            label="Budget 
            in lakhs
            "

            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Budget"
            type="number"
            error={errValue.Budget}
            value={value.Budget}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, DateOfClosure: false }));
            }}
          />

          <TextField

            id="SubmissionDate"
            label="Submission Date
                   
            "
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              placeholder: "",
            }}
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="SubmissionDate"
            error={errValue.SubmissionDate}
            value={value.SubmissionDate}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, SubmissionDate: false }));
            }}
          />
          <TextField

            id="Status"
            label="Status           
            "
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Status"
            error={errValue.Status}
            value={value.Status}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Status: false }));
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{
                backgroundColor: "#008CBA",
                height: "20px",
                padding: "5px 30px 28px 26px",
                borderRadius: "3px",
                color: "#fff",
              }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={() => formValidateFun()}
              style={{ paddingTop: "5px" }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArC14Inside;