import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  FormControl,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { C4VALI } from "../../../Validation";
import { Box } from "react-bootstrap-icons";
import moment from "moment";
import ComponentLoader from "../../../ComponentLoader";

function ArC11Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [investigatorName,setInvestigatorName] = useState("")
  const [patentType,setPatentType] = useState("")
  const [productDescription,setProductDescription] = useState("")
  const [remarks,setRemarks] = useState("")

  const [investigatorNameErr,setInvestigatorNameErr] = useState(false)
  const [patentTypeErr,setPatentTypeErr] = useState(false)
  const [productDescriptionErr,setProductDescriptionErr] = useState(false)
  const [remarksErr,setRemarksErr] = useState(false)

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();
  const [inves,setInves] = useState([])
  const [lov,setLov] = useState([])
  const [loader,setLoader] =useState(true)
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C4VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
     
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Farm Advisory");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute)
  }, []);

  const backs = () => {
    navigate("/ArC11");
  };

  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId);
    setPatentType(item.patentType);
    setProductDescription(item.prodcutDescription);
    setRemarks(item.remakrs);
    setId(item.id);
  };

   // Lov
   const handleLov = async (token, PatentType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"PatentType"}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      setLov(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async (data) => {
    console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      InvestigatorId: investigatorName,
      PatentType: patentType,
      ProdcutDescription: productDescription,
      Remakrs: remarks,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr11SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC11");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr11EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArC11");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  
  const formValidateFun = () => {
    if (!investigatorName) {
      setInvestigatorNameErr(true);
      document.getElementById("investigatorName").focus();
    } else if (!patentType) {
      setPatentTypeErr(true);
      document.getElementById("patentType").focus();
    } else if (!productDescription) {
      setProductDescriptionErr(true);
      document.getElementById("productDescription").focus();
    } else if (!remarks) {
      setRemarksErr(true);
      document.getElementById("remarks").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C11. Registration
</span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
          C11. Registration

          </h5>

          <FormControl style={{ width: "48%", margin: 12 }} error={investigatorNameErr}  id="investigatorName">
            <InputLabel id="demo-simple-select-helper-label" size="small">
             Scientist In Charge
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="investigatorName"
              value={investigatorName}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setInvestigatorName(e.target.value)
                setInvestigatorNameErr(false)
              }}
            >
            {inves?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>

          <FormControl style={{ width: "48%", margin: 12 }} error={patentTypeErr}  id="patentType">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Patent Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="patentType"
              value={patentType}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setPatentType(e.target.value)
                setPatentTypeErr(false)
              }}
            >
              {lov?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={productDescriptionErr}
            id="productDescription"
            label="Description of the product (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={productDescription}
            onChange={(e) => {
              setProductDescription(e.target.value);
              setProductDescriptionErr(false);
            }}
          />
          
          <TextField
            error={remarksErr}
            id="remarks"
            label="Remarks (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
              setRemarksErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA",padding:"5px",borderRadius:"3px",width:"80px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" style={{paddingTop:"5px"}} onClick={formValidateFun}>
            Save
            </button>
          </div>
        </div>
      {/* </form> */}
    </div>
  );
}

export default ArC11Inside;
