import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D3VALI } from "../../../Validation";

import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArE6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [investigatorName,setInvestigatorName] = useState("")
  const [responsibility,setResponsibility] = useState("")
  const [activity,setActivity] = useState("")
  const [place,setPlace] = useState("")
  const [noofFarmer,setNoofFarmer] = useState("")

  const [investigatorNameErr,setInvestigatorNameErr] = useState(false)
  const [responsibilityErr,setResponsibilityErr] = useState(false)
  const [activityErr,setActivityErr] = useState(false)
  const [placeErr,setPlaceErr] = useState(false)
  const [noofFarmerErr,setNoofFarmerErr] = useState(false)
  
 
  const [inves, setInves] = useState([]);
  const [loader,setLoader] =useState(true);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const backs = () => {
    navigate("/ArE6");
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D3VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {

      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    // head(" Research Responsibilities");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute)
  }, []); 
  
  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId);
    setResponsibility(item.responsibility);
    setActivity(item.activity);
    setPlace(item.place);
    setNoofFarmer(item.noofFarmer);
    setId(item.id);
  };

// Investigator 
const handleInvestigators = async (token,id) => {
  const h = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
      headers: h,
    });
    console.log(data);
    setInves(data);setLoader(false);
  } catch (error) {
    console.log(error);setLoader(false);
  }
};

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: investigatorName,
      Responsibility: responsibility,
      Activity: activity,
      Place: place,
      NoofFarmer: noofFarmer,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Er6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArE6");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Er6EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Updated");
          navigate("/ArE6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!investigatorName) {
      setInvestigatorNameErr(true);
      document.getElementById("investigatorName").focus();
    } else if (!responsibility) {
      setResponsibilityErr(true);
      document.getElementById("responsibility").focus();
    } else if (!activity) {
      setActivityErr(true);
      document.getElementById("activity").focus();
    } else if (!place) {
      setPlaceErr(true);
      document.getElementById("place").focus();
    } else if (!noofFarmer) {
      setNoofFarmerErr(true);
      document.getElementById("noofFarmer").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
         <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>E6. KAVIADP / ATMA / other extension services
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
          E6. KAVIADP / ATMA / other extension services

          </h5>

          <FormControl style={{ width: "48%", margin: 12 }} error={investigatorNameErr}  id="investigatorName">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name of the faculty
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="investigatorName"
              value={investigatorName}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setInvestigatorName(e.target.value)
                setInvestigatorNameErr(false)
              }}
            >
            {inves?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>

          <TextField
            error={responsibilityErr}
            id="responsibility"
            label="Responsibility (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={responsibility}
            onChange={(e) => {
              setResponsibility(e.target.value);
              setResponsibilityErr(false);
            }}
          />

          <TextField
            error={activityErr}
            id="activity"
            label="Activity during this Month (Max 200 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={activity}
            onChange={(e) => {
              setActivity(e.target.value);
              setActivityErr(false);
            }}
          />

          <TextField
            error={placeErr}
            id="place"
            label="Place (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={place}
            onChange={(e) => {
              setPlace(e.target.value);
              setPlaceErr(false);
            }}
          />

          <TextField
            error={noofFarmerErr}
            id="noofFarmer"
            label="Number of Farmer (Only Number)"
            type="number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={noofFarmer}
            onChange={(e) => {
              setNoofFarmer(e.target.value);
              setNoofFarmerErr(false);
            }}
          />
         

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>


        </div>
      </div>
    </div>
  );
}

export default ArE6Inside;
