import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D3VALI } from "../../../Validation";

import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArF3Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [activity, setActivity] = useState("");
  const [responsibility, setResponsibility] = useState("");

  const [activityErr, setActivityErr] = useState(false);
  const [responsibilityErr, setResponsibilityErr] = useState(false);
  const [loader,setLoader] =useState(true);

  const [dropcourse, setDropcourse] = useState([]);
  const [faculty, setfaculty] = useState("")
  const [facultyErr, setFacultyErr] = useState(false);
  const [inves, setInves] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const backs = () => {
    navigate("/ArF3");
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D3VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleCoursetypes(tok);
      
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Research Responsibilities");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
    handleInvestigators(tok,investigator)
  }, []);

  const edit = (item) => {
    console.log(item);
    setfaculty(item.investigatorId);

    setActivity(item.activity);
    setResponsibility(item.responsibility);
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleCoursetypes = async (token, ResearchResponsibilities) => {
    // console.log(Activities);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVEAPI}${"ResearchResponsibilities"}`,
        { headers: h }
      );
      console.log(data);

      setDropcourse(data);   setLoader(false);
    } catch (error) {
      console.log(error);   setLoader(false);
    }
  };


  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);   setLoader(false);
      setInves(data);
    } catch (error) {
      console.log(error);   setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      InvestigatorId: faculty,
      Activity: activity,
      Responsibility: responsibility,
      IAMRSId: state.id,
      InstituteId: parseInt(invis),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF3SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF3");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } 
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARF3EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArF3");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if(!faculty){
      setFacultyErr(true)
      document.getElementById("faculty").focus();
    }
    else if (!responsibility) {
      setResponsibilityErr(true);
      document.getElementById("responsibility").focus();
    } else if (!activity) {
      setActivityErr(true);
      document.getElementById("activity").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>F3. Academic / Research Responsibilities by the faculty members</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            {" "}
            F3. Academic / Research Responsibilities by the faculty members          </h5>


            <FormControl
            style={{ width: "48%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name of the faculty            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={facultyErr}
              style={{ height: "45px" }}
              value={faculty}
              onChange={(e) => {
                setfaculty(e.target.value);
                setFacultyErr(false);
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={responsibilityErr}
            id="responsibility"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Responsibility
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={responsibility}
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setResponsibility(e.target.value);
                setResponsibilityErr(false);
              }}
            >
              {dropcourse?.map((row) => {
                return <MenuItem value={row.lovName}>{row.lovName}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <TextField
            error={activityErr}
            id="outlined-basic"
            label="Activities (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={activity}
            onChange={(e) => {
              setActivity(e.target.value);
              setActivityErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </div>
    </div>
  );
}

export default ArF3Inside;
