

import React, { useEffect } from 'react'
import "./Report.css"
import { FaHandshake } from 'react-icons/fa'
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Navigate, useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';

function Report({head}) {
    const Navigate = useNavigate('');

    useEffect(() => {
        head("Report")
      },[])
     

    const masterDirector = () => {
        Navigate("/directorate")
    }
    const masterInstitute = () => {
        Navigate("/institute")
    }
    const masterInvestigator = () => {
        Navigate("/investigator")
    }

    const masterLeave = () => {
        Navigate("/leave")
    }
    const masterOnDuty = () => {
        Navigate("/masterOnDuty")
    }

    

    return (
        <div className='master-container'>
            <div className='master-main'></div>

            <div className='master-flex'>
                <div className='master-1'>
                                      

                    
                </div>

                <div className='master-2'>

                    <p>research Activities</p>

                </div>


                <div className='master-3'>
                    <p>extra Activies</p>
                </div>
            </div>
           
        </div>
    )
}

export default Report
