import React from "react";
import Svgicon from "../../src/Images/Scientists.png"


function Methodology() {
  return (
    <>
      <div>
        <div class="utility-page">
          <div class="carousel-item-img3">
            <div class="content-page">
              <h5>Methodology </h5>
              <p >Digital reporting system is meticulously designed and developed in the following steps;</p>
              <h5>Information gathering </h5>
              <p >Different users such as scientists, staffs, lab technicians, artisans, Assistant Agricultural Officers, etc. are some of the users of Tamil Nadu agricultural university and among which scientists and staff are responsible for reporting. Hence, information gathering targets the entire scientists and staff members of the university on individual basis. The users are initially grouped in to departments and office assistance.   
                Number of departments with or without one office together constitutes a research station or Institute or College.   One directorate may composed of number of research stations, institutes or college. Accordingly information pertaining to the research station / institute / college is pooled together from concern individual users.  </p>
              <h5>Database </h5>
              <p >In order to collect information from individual users forms were created and linked suitably with database. This facilitates permanent storing of information besides ease retrival in desired form. </p>

              <div className="flow-contain">
                <h5>Reporting Hierarchy Structure</h5>
                <img src={Svgicon}></img>
              </div>

            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default Methodology;
