import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import { B6VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";

function B6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [options, setOptions] = useState([]);
  console.log(options);

  const [CourseId, setCourseId] = useState("");

  const [cropCourse, setcropCourse] = useState(null);
  console.log(cropCourse);
  const [duringMonth, setDuringMonth] = useState("");
  const [totalGermplasmcollected, setTotalGermplasmcollected] = useState("");
  const [prodcutSpecialFeature, setProdcutSpecialFeature] = useState("");

  const [cropCourseErr, setcropCourseErr] = useState(false);
  const [duringMonthErr, setDuringMonthErr] = useState(false);
  const [totalGermplasmcollectedErr, setTotalGermplasmcollectedErr] =
    useState(false);
  const [prodcutSpecialFeatureErr, setProdcutSpecialFeatureErr] =
    useState(false);
    const [loader,setLoader] =useState(true);
  const [dropcourse, setDropcourse] = useState([]);
  const [CodeV, setCodeV] = useState([]);
  const [NameV, setNameV] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B6VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      handleCoursetypes(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Germplasm collection");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  // dropdown

  const handleCoursetypes = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.CROPGETALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcourse(data);
      setOptions(data);setLoader(false);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const edit = (item) => {
    console.log(item);
    setCourseId(item.cropId);
    setcropCourse(item.crop);
    setDuringMonth(item.duringMonth);
    setTotalGermplasmcollected(item.totalGermplasmcollected);
    setProdcutSpecialFeature(item.prodcutSpecialFeature);
    setId(item.id);
  };

  useEffect(() => {
    const getValue = options.find((data) => data.id === CourseId);
    if (getValue) {
      setcropCourse(getValue);
    }
    console.log("getId=============", getValue);
  }, [dropcourse]);

  const handleBack = () => {
    navigate("/B6");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async (data) => {
    console.log(data);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(id);
    const d = {
      id,
      // CropId: cropCourse.id,
      Crop:cropCourse,
      DuringMonth: duringMonth,
      TotalGermplasmcollected: totalGermplasmcollected,
      ProdcutSpecialFeature: prodcutSpecialFeature,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B6");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B6EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCrop = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      CropCode: CodeV,
      CropName: NameV,
    };
    try {
      const { data } = await axios.post(`${window.BASE}${window.CROPSAVE}`, d, {
        headers: h,
      });
      console.log(data);
      if (data.result == "Success") {
        alert("Succesfly Submited");
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formValidateFun = () => {
    if (!cropCourse) {
      setcropCourseErr(true);
      document.getElementById("cropCourse").focus();
    } else if (!duringMonth) {
      setDuringMonthErr(true);
      document.getElementById("duringMonth").focus();
    } else if (!totalGermplasmcollected) {
      setTotalGermplasmcollectedErr(true);
      document.getElementById("totalGermplasmcollected").focus();
    } else if (!prodcutSpecialFeature) {
      setProdcutSpecialFeatureErr(true);
      document.getElementById("prodcutSpecialFeature").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Germplasm collection (B6)</span>
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // PaperProps={{
        //   component: "form",
        //   onSubmit: handleSubmit(handleCrop),
        // }}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="dialogueTittle">
            <div>
              <h5>Crop</h5>
            </div>
            <div onClick={handleClose}>
              <CloseIcon
                className="dialogue_close_icon"
                style={{ backgroundColor: "black" }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="Dialog-box" style={{ padding: 0 }}>
          <div className="manu-form ">
            <div className="box width-50">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Code"
                variant="outlined"
                size="small"
                value={CodeV}
                onChange={(e) => setCodeV(e.target.value)}
                // {...register("CodeV")}
                // error={errors.CodeV ? true : false}
                // helperText={errors.CodeV?.message}
              />
            </div>

            <div className="box width-50">
              <TextField
                fullWidth
                id="outlined-basic"
                label=" Name"
                variant="outlined"
                size="small"
                value={NameV}
                onChange={(e) => setNameV(e.target.value)}
                // {...register("NameV")}
                // error={errors.NameV ? true : false}
                // helperText={errors.NameV?.message}
              />
            </div>

            <div className="box width-50"></div>
          </div>
        </DialogContent>
        <DialogActions className="dialogue_action_button">
          <button className="Backclose" type="submit" onClick={handleClose}>
            Back
          </button>
          <button type="submit" onClick={handleCrop}>
            Submit
          </button>
        </DialogActions>
      </Dialog>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>B6. Germplasm collection:</h5>

          {/* <div className="d-flex">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              onChange={(e, val) => {
                setcropCourse(val);
                setcropCourseErr(false);
              }}
              getOptionLabel={(option) => `${option.cropName}`}
              value={cropCourse}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Crop" error={cropCourseErr} />
              )}
            />
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div> */}

          <TextField
            error={cropCourseErr}
            id="cropCourse"
            label="Crop (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={cropCourse}
            onChange={(e) => {
              setcropCourse(e.target.value);
              setcropCourseErr(false);
            }}
          />

          <TextField
            error={duringMonthErr}
            id="duringMonth"
            label="Germplasm collected during this month (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={duringMonth}
            onChange={(e) => {
              setDuringMonth(e.target.value);
              setDuringMonthErr(false);
            }}
          />

          <TextField
            error={totalGermplasmcollectedErr}
            id="totalGermplasmcollected"
            label="Total Germplasm Collected"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={totalGermplasmcollected}
            onChange={(e) => {
              setTotalGermplasmcollected(e.target.value);
              setTotalGermplasmcollectedErr(false);
            }}
          />

          <TextField
            error={prodcutSpecialFeatureErr}
            id="prodcutSpecialFeature"
            label="Special Feature (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={prodcutSpecialFeature}
            onChange={(e) => {
              setProdcutSpecialFeature(e.target.value);
              setProdcutSpecialFeatureErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              style={{paddingTop:"5px"}}
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              style={{paddingTop:"5px"}}
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B6Inside;
