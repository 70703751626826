import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";

// import { Teachingrelated } from '../../../Pages/Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";
// import { A7VALI } from "../../../Validation";

function ArA4Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [dropcourse, setDropcourse] = useState([]);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti, setInsti] = useState();
  const [inves, setInves] = useState([]);

  const [dropnature, setDropnature] = useState([]);
  const [dropnatcoching, setDropnatcoching] = useState([]);
  const [CourseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [dropcoursetype, setDropcoursetype] = useState([]);
  const [courseNo, setCourseNo] = useState("");
  const [hourss, sethourss] = useState("");

  const [options, setOptions] = useState([]);
  // Comman Id Send to set value
  const [courseDetailsId, setCourseDetailsId] = useState("");

  const [courseDetails, setCourseDetails] = useState(null);
  const [NatureCoch, setNatureCoch] = useState("");
  const [Number, setNumber] = useState("");
  const [DetailsOfWork, setDetailsOfWork] = useState("");

  const [courseDetailsErr, setCourseDetailsErr] = useState(false);
  const [NatureCochErr, setNatureCochErr] = useState(false);
  const [NumberErr, setNumberErr] = useState(false);
  const [DetailsOfWorkErr, setDetailsOfWorkErr] = useState(false);
  const [loader, setLoader] = useState(true)

  const [value, setValue] = useState({
    InvestigatorId: "",
    Designation: "",
    UPFCPFNo: "",
    CLDays: "",
    CPLDays: "",
    RHDays: "",
    ODDays: "",
    ELMLDays: "",
    Dates: "",
    Reason: ""
  });

  const [errValue, setErrValue] = useState({
    InvestigatorId: false,
    Designation: false,
    UPFCPFNo: false,
    CLDays: false,
    CPLDays: false,
    RHDays: false,
    ODDays: false,
    ELMLDays: false,
    Dates: false,
    Reason: false
  });

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A7VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleCochingNaturetypes(tok);
      // handleCoursetypes(tok);
      // handleNaturetypes(tok);

      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Teaching related");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const instidude = localStorage.getItem("loginId");
    setInsti(instidude);
    handleInvestigators(tok, instidude)
  }, []);

  const backs = () => {
    navigate("/ArA4");
  };


  const edit = (item) => {
    console.log(item);

    setValue({
      InvestigatorId: item.investigatorId,
      Designation: item.designation,
      UPFCPFNo: item.upfcpfNo,
      CLDays: item.clDays,
      CPLDays: item.cplDays,
      RHDays: item.rhDays,
      ODDays: item.odDays,
      ELMLDays: item.elmlDays,
      Dates: item.dates,
      Reason: item.reason
    })
    setId(item.id)

  };

  useEffect(() => {
    console.log("========== ", options);
    options.forEach((item) => {
      console.log(item);
    });
    const getValue = options.find((data) => data.id === courseDetailsId);
    setCourseDetails(getValue);
  }, [dropcourse, courseDetailsId]);

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  //dropdown
  const handleData = ((event) => {
    const { name, value } = event.target
    setValue(prev => ({
      ...prev,
      [name]: value
    }))


  })
  console.log("value", value);


  const handleInvestigators = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data); setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      InvestigatorId: value.InvestigatorId,
      Designation: value.Designation,
      UPFCPFNo: value.UPFCPFNo,
      CLDays: value.CLDays === undefined ? 0 :parseFloat(value.CLDays),
      CPLDays:  value.CPLDays === undefined ? 0 :parseFloat(value.CPLDays),
      RHDays:  value.RHDays === undefined ? 0 :parseFloat(value.RHDays),
      ODDays:  value.ODDays === undefined ? 0 :parseFloat(value.ODDays),
      ELMLDays:  value.ELMLDays === undefined ? 0 :parseFloat(value.ELMLDays),
      Dates: dateFormat(value.Dates),
      Reason: value.Reason,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
      IAMRSId: state.id
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA4SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA4");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA4EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArA4");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const reload = () => {
    window.location.reload();
  };

 

  const formValidateFun = (e) => {
    e.preventDefault()
    if (!value.InvestigatorId) {
      setErrValue((prev) => ({ ...prev, InvestigatorId: true }))
      document.getElementById("InvestigatorId").focus();
    }
    else if (!value.Designation) {
      setErrValue((prev) => ({ ...prev, Designation: true }))
      document.getElementById("Designation").focus();
    }
    else if (!value.UPFCPFNo) {
      setErrValue((prev) => ({ ...prev, UPFCPFNo: true }))
      document.getElementById("UPFCPFNo").focus();
    }
    
    else if (!value.Dates) {
      setErrValue((prev) => ({ ...prev, Dates: true }))
      document.getElementById("Dates").focus();
    }
    else if (!value.Reason) {
      setErrValue((prev) => ({ ...prev, Reason: true }))
      document.getElementById("Reason").focus();
    }
    else {
      handleLeavepage();
    }
  };

  return (

    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>
            A4.  Leave Info
          </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>

        <div className="form-control">
          <h5 style={{ width: 400, margin: 20 }}>
            A4.  Leave Info

          </h5>

          {/* <TextField
            id="InvestigatorId"
            label="InvestigatorId"
            variant="outlined"
            name="InvestigatorId"
          
            style={{ margin: "10px", width: "48%" }}
            value={value.InvestigatorId}
            // onChange={(e) => setStudentName(e.target.value)}

            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
            }}
            error={errValue.InvestigatorId}
            helperText={errors.StudentNamev?.message}
          /> */}

          <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name of the faculty
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={errValue.InvestigatorId}
              style={{ height: "45px" }}
              value={value.InvestigatorId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="Designation"
            label="Designation  (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Designation"
            value={value.Designation}
            // onChange={(e) => setNoofmeetings(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Designation: false }));
            }}
            error={errValue.Designation}
            helperText={errors.Noofmeetingsv?.message}
          />


          <TextField
            id="UPFCPFNo"
            label="UPF/CPF.No (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={value.UPFCPFNo}
            name="UPFCPFNo"
            // onChange={(e) => setInternshipPlan(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, UPFCPFNo: false }));
            }}
            error={errValue.UPFCPFNo}
            helperText={errors.InstituteIdv?.message}
          />

          <TextField
            id="CLDays"
            label="CL"
            type="number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="CLDays"
            value={value.CLDays}
            // onChange={(e) => setInternshipPlan(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, CLDays: false }));
            }}
            error={errValue.CLDays}
            helperText={errors.InternshipPlanv?.message}
          />

          <TextField
            id="CPLDays"
            label="CPL"
            type="number"
            variant="outlined"
            name="CPLDays"
            style={{ margin: "10px", width: "48%" }}
            value={value.CPLDays}
            // onChange={(e) => setInternshipPlan(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, CPLDays: false }));
            }}
            error={errValue.CPLDays}
            helperText={errors.InternshipPlanv?.message}
          />

          <TextField
            id="RHDays"
            label="RH"
            type="number"
            variant="outlined"
            name="RHDays"
            style={{ margin: "10px", width: "48%" }}
            value={value.RHDays}
            // onChange={(e) => setProgress(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, RHDays: false }));
            }}
            error={errValue.RHDays}
            helperText={errors.Progressv?.message}
          />

          <TextField
            id="ODDays"
            label="OD"
            type="number"
            variant="outlined"
            name="ODDays"
            style={{ margin: "10px", width: "48%" }}
            value={value.ODDays}

            // onChange={(e) => setProgress(e.target.value)}

            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ODDays: false }));
            }}
            error={errValue.ODDays}
            helperText={errors.Progressv?.message}
          />

          <TextField
            id="ELMLDays"
            label="EL/ML   "
            type="number"
            variant="outlined"
            name="ELMLDays"
            style={{ margin: "10px", width: "48%" }}
            value={value.ELMLDays}
            // onChange={(e) => setProgress(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ELMLDays: false }));
            }}
            error={errValue.ELMLDays}
            helperText={errors.Progressv?.message}
          />

          <TextField
            id="Dates"
            // label="Dates"
            variant="outlined"
            name="Dates"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={value.Dates}
            // onChange={(e) => setProgress(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Dates: false }));
            }}
            error={errValue.Dates}
            helperText={errors.Progressv?.message}
          />
          <TextField
            id="Reason"
            label="Reason For Leave (Max 500 Character)"
            variant="outlined"
            name="Reason"
            style={{ margin: "10px", width: "48%" }}
            value={value.Reason}
            // onChange={(e) => setProgress(e.target.value)}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Reason: false }));
            }}
            error={errValue.Reason}
            helperText={errors.Progressv?.message}
          />




          <div className="from-last">
            <button type="submit" style={{ backgroundColor: "#008CBA", height: "20px" }} onClick={backs}>Back</button>
            <button
              type="submit"
              className="sbmit-buttons"

            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArA4Inside;