import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import RichObjectTreeView from './text';
import Dd from "./Pages/DashboardData/Dd";
import Sidebar from './navitem/Sidebare';

const root = ReactDOM.createRoot(document.getElementById('root'));







root.render(

  // <React.StrictMode>


  <BrowserRouter>
    {/* <RichObjectTreeView /> */}
    <App></App>
    {/* <Dd/> */}
    {/* <Sidebar/> */}
  </BrowserRouter> 


  // </React.StrictMode>
);


reportWebVitals();
