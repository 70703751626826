import React from 'react'
import './Activities.css'
import { TextField, Grid, InputLabel, Select, MenuItem, DialogActions } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

import { Activetiesvalidation } from '../Validation';
import { FormControl } from 'react-bootstrap';
import { Box } from 'react-bootstrap-icons';





function CurrentDate() {
  const currentDate = new Date().toLocaleDateString();
}

function Activities({ head }) {

  //education 
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = useState("");
  const [direct, setDirect] = useState([])
  const [education, setEducation] = useState([])

  const [rptDate, setRptDate] = useState("")
  const [courseId, setCourseId] = useState("")
  const [Hours, setHours] = useState("")
  const [courseType, setCourseType] = useState("")
  const [courseTeacher, setCourseTeacher] = useState("")
  const [noOfClassTP, setNoOfClassTP] = useState("")

  const [noofslowlearners, setNoofslowlearners] = useState("")
  const [noofweakness, setNoofweakness] = useState("")
  const [ndditionalefforts, setAdditionalefforts] = useState("")
  const [pass, setPass] = useState("")


  const [guestSpeaker, setGuestSpeaker] = useState("")
  const [affiliation, setAffiliation] = useState("")
  const [titleofthelecture, setTitleofthelecture] = useState("")


  const [instituteId, setInstituteId] = useState("")
  const [noOfStudents, setNoOfStudents] = useState("")

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);


  const [dirr, setDepartment] = useState([])
  const [dropcoursetype,setDropcoursetype] = useState([])
const [dropcourse,setDropcourse] = useState([])
  const [course, setCourse] = useState([])
  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setId(item.id)
    //  setCode(item.levCode)
    //  setLeaveName(item.levName)


  }

  //   const dateFormat = (e) => {
  //     let a = new Date(e.target.value).toISOString().replace(/T.*/,'').split('-').reverse().join('-')
  //     //console.log(d.toLocaleDateString()) // D.M.YYYY
  //     setRptDate(a);
  // }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(Activetiesvalidation),
  });



  // dropdown


  const handleCoursetypes = async (token) => {
    console.log(token);


    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, { headers: h });
      console.log(data);
      setDropcoursetype(data);
      setDropcourse(data)
    } catch (error) {
      console.log(error);
    }
  };

  const handleInstituteName = async (token) => {
    console.log(token);


    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INSTITUTES}`, { headers: h });
      console.log(data);
      setDepartment(data);
    } catch (error) {
      console.log(error);
    }
  };


  //   const toastSuccess = (data) => {
  //     toast.success(data, {
  //         position: toast.POSITION.TOP_RIGHT,
  //     });
  // };
  // const toastError = (data) => {
  //     toast.error(data, {
  //         position: toast.POSITION.TOP_RIGHT,
  //     });
  // };

  const deleteButton = () => {
    const result = window.confirm('Are you sure you want to delete?');
    if (result) {
      // Perform the delete operation
      deleteTransaction(id)
      console.log('Deleted successfully');
    } else {
      // Cancelled delete operation
      console.log('Delete cancelled');
    }
  }


  const deleteTransaction = async (id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.TRANSACTIONREMOVE}${id}`, { headers: h });
      console.log(data);

    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    const tok = localStorage.getItem("token")
    setToken(tok);
    console.log(tok);
    handleLeaveDr(tok)
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      // handleInstituteName(tok)
      handleInstituteName(tok)
      handleCoursetypes(tok)


    }
    head("Educational")
  }, [])
  // console.log(tok);





  const handleLeaveDr = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, { headers: h });
      console.log(data);
      setCourse(data);
    } catch (error) {
      console.log(error);
    }
  };


  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {

      id,
      RptDate: rptDate,
      CourseId: parseInt(courseId),
      Hours: Hours,
      CourseType: courseType,
      CourseTeacher: courseTeacher,
      NoOfClassTP: parseInt(noOfClassTP),

      Noofslowlearners: parseInt(noofslowlearners),
      Noofweakness: parseInt(noofweakness),
      Additionalefforts: ndditionalefforts,
      Pass: parseInt(pass),

      GuestSpeaker: guestSpeaker,
      Affiliation: affiliation,
      Titleofthelecture: titleofthelecture,

      InstituteId: parseInt(instituteId),
      NoOfStudents: parseInt(noOfStudents),
    }

    try {
      const { data } = await axios.post(`${window.BASE}${window.EDUCATIONSAVE}`, d, { headers: h });
      console.log(data);

      setDirect(data);

    } catch (error) {
      console.log(error);
    }
  };



  const handleClickSubmit = () => {
    setOpen(true);
  }
  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    setId(0)
  };



  return (

    <form className='from-mains' onSubmit={handleSubmit(handleLeavepage)}>
      <div className=' col-lg-6 col-md-6 col-sm-12 Activies-main'>

        {/* <TextField id="outlined-basic"
          label="Date"
          variant="outlined"

          // onChange={dateFormat}

          // onChange={(e) => setRptDate(e.target.value)}
          {...register("dateActi")}
          error={errors.dateActi ? true : false}
          helperText={errors.dateActi?.message}
          onChange={(e) => setRptDate(e.target.value)}
        /> */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"


        >
          <DialogTitle id="scroll-dialog-title">

            <div className="dialogueTittle">
              <div>
                <h5>Course</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon

                  className="dialogue_close_icon"
                />

              </div>
            </div>
          </DialogTitle>
          <DialogContent className='Dialog-box' style={{ padding: 0 }}>

            <div className="manu-form ">
              <div className='box width-50'>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course No"
                  // value={courseNo}
                  variant="outlined"
                  size='small'
                // onChange={(e) => setCourseNo(e.target.value)}

                />

              </div>

              <div className='box width-50'>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course Type"

                  variant="outlined"
                  size='small'
                  value={courseType}
                // onChange={(e) => setCourseType(e.target.value)}

                />


              </div>
              <div className='box width-50'>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course Name"

                  variant="outlined"
                  size='small'
                // value={CourseName}
                // onChange={(e) => setCourseName(e.target.value)}
                />


              </div>

              <div className='box width-50'>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="button">

            <button variant="outlined" onClick={handleClose} className='btn2' >Deactivate</button>
            <button variant="outlined" onClick={handleLeavepage} className='btn1'>Submit</button>

          </DialogActions>
        </Dialog>


        {/* <TextField id="outlined-basic"
          label="Course"
          variant="outlined"
          style={{ width: 400, marginRight: 10 }}
          {...register("CourseActi")}
          error={errors.CourseActi ? true : false}
          helperText={errors.CourseActi?.message}
          onChange={(e) => setCourseId(e.target.value)}
        /> */}


        <Select
          style={{ width: 420, margin: 10 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={age}
          label="Course "
          size='small'
          sx={{ width: "100%" }}
          // onChange={handleChange}

          onChange={(e) => setCourseId(e.target.value)}

          {...register("CoursetypeidActi")}
          error={errors.CoursetypeidActi ? true : false}
          helperText={errors.CoursetypeidActi?.message}
        >
          {dropcourse?.map((row) => {
            return (

              <MenuItem value={row.id}>{row.courseName}</MenuItem>

            )
          })}
        </Select>

        <button className='top-buttons' onClick={handleClickOpen}>
          <AccountBalanceIcon style={{ marginBottom: -5 }} className="sidebar-icon" />

        </button>
      </div>


      <div className='form-control'>

        <h5 style={{ width: 150, margin: 20 }}>A1.Course Handled</h5>
        {/* <TextField id="outlined-basic" 
              label="Date" variant="outlined" 
              // onChange={(e) => setRptDate(e.target.value)}
               />
              <TextField id="outlined-basic" 
              label="Course Name" 
              variant="outlined" 
              // onChange={(e) => setCourseId(e.target.value)} 
              />  */}
        <TextField id="outlined-basic"
          label="Hours"
          variant="outlined"
          style={{ margin: '10px' }}


          {...register("HourseActi")}
          error={errors.HourseActi ? true : false}
          helperText={errors.HourseActi?.message}
          onChange={(e) => setHours(e.target.value)}
        />



        {/* <TextField id="outlined-basic"
          label="CourseType"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("CoursetypeidActi")}
          error={errors.CoursetypeidActi ? true : false}
          helperText={errors.CoursetypeidActi?.message}

          onChange={(e) => setCourseType(e.target.value)}
        /> */}


        <Select
          style={{ width: 220, margin: 10 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={age}
          label="Course Type"
          size='small'
          sx={{ width: "100%" }}
          // onChange={handleChange}

          onChange={(e) => setCourseType(e.target.value)}

          {...register("CoursetypeidActi")}
          error={errors.CoursetypeidActi ? true : false}
          helperText={errors.CoursetypeidActi?.message}
        >
          {dropcoursetype?.map((row) => {
            return (

              <MenuItem value={row.id}>{row.courseType}</MenuItem>

            )
          })}
        </Select>

        <TextField id="outlined-basic"
          label="CourseTeacher"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("CourseTeacherActiveties")}
          error={errors.CourseTeacherActiveties ? true : false}
          helperText={errors.CourseTeacherActiveties?.message}

          onChange={(e) => setCourseTeacher(e.target.value)}
        />
        <TextField id="outlined-basic"
          label="NoOfClassTP"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("classeActi")}
          error={errors.classeActi ? true : false}
          helperText={errors.classeActi?.message}

          onChange={(e) => setNoOfClassTP(e.target.value)}
        />

        <h5 style={{ width: 250, margin: 20 }}>A2.Addition Effort in Teacher</h5>
        <TextField id="outlined-basic"
          label="No of slow learners"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("SlowlearnActi")}
          error={errors.SlowlearnActi ? true : false}
          helperText={errors.SlowlearnActi?.message}

          onChange={(e) => setNoofslowlearners(e.target.value)}
        />

        <TextField id="outlined-basic"
          label="No of weakness"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("weeknesActi")}
          error={errors.weeknesActi ? true : false}
          helperText={errors.weeknesActi?.message}

          onChange={(e) => setNoofweakness(e.target.value)}
        />

        <TextField id="outlined-basic"
          label="Additional efforts"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("AddtionActiveties")}
          error={errors.AddtionActiveties ? true : false}
          helperText={errors.AddtionActiveties?.message}

          onChange={(e) => setAdditionalefforts(e.target.value)}
        />

        <TextField id="outlined-basic"
          label="Pass 90%"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("PassActi")}
          error={errors.PassActi ? true : false}
          helperText={errors.PassActi?.message}

          onChange={(e) => setPass(e.target.value)}
        />

        <h5 style={{ width: 250, margin: 20 }}>A3.Guest Lecture Organized</h5>
        <TextField id="outlined-basic"
          label="GuestSpeaker"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("GueastActi")}
          error={errors.GueastActi ? true : false}
          helperText={errors.GueastActi?.message}

          onChange={(e) => setGuestSpeaker(e.target.value)}
        />

        <TextField id="outlined-basic"
          label="Affiliation"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("AffilitionActi")}
          error={errors.AffilitionActi ? true : false}
          helperText={errors.AffilitionActi?.message}

          onChange={(e) => setAffiliation(e.target.value)}
        />

        <TextField id="outlined-basic"
          label="Title of the lecture"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("LeactureActiveties")}
          error={errors.LeactureActiveties ? true : false}
          helperText={errors.LeactureActiveties?.message}

          onChange={(e) => setTitleofthelecture(e.target.value)}
        />

        <h5 style={{ width: 250, margin: 20 }}>A4.Exposive Visit Organized</h5>




        <Select
          style={{ width: 220, margin: 10 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={age}
          label="Institute"
          size='small'
          sx={{ width: "100%" }}
          // onChange={handleChange}

          onChange={(e) => setInstituteId(e.target.value)}
          {...register("Instiname")}
          error={errors.Instiname ? true : false}
          helperText={errors.Instiname?.message}
        >
          {dirr?.map((row) => {
            return (

              <MenuItem value={row.id}>{row.instituteName}</MenuItem>

            )
          })}
        </Select>


        <TextField id="outlined-basic"
          label="No Of Students"
          variant="outlined"
          style={{ margin: '10px' }}

          {...register("StudentActi")}
          error={errors.StudentActi ? true : false}
          helperText={errors.StudentActi?.message}

          onChange={(e) => setNoOfStudents(e.target.value)}
        />

        <div className='from-last'>
          <button type="submit" className='deactivate-buttons' onClick={deleteButton}>Delete</button>
          <button type="submit" className='sbmit-buttons' onClick={handleLeavepage}>Submit</button>
        </div>

        <TableBody>
          {education?.map((row) => (
            <TableRow
              key={row.RptDate}

            >
              <TableCell align="right">{row.RptDate}</TableCell>
              <TableCell align="right">{row.CourseId}</TableCell>
              <TableCell align="right"> {row.Hours}</TableCell>
              <TableCell align="right">{row.CourseType}</TableCell>
              <TableCell align="right"> {row.CourseTeacher}</TableCell>
              <TableCell align="right">{row.NoOfClassTP}</TableCell>

              <TableCell align="right"> {row.Noofslowlearners}</TableCell>
              <TableCell align="right">{row.Noofweakness}</TableCell>
              <TableCell align="right"> {row.Additionalefforts}</TableCell>
              <TableCell align="right">{row.Pass}</TableCell>

              <TableCell align="right"> {row.GuestSpeaker}</TableCell>
              <TableCell align="right">{row.Affiliation}</TableCell>
              <TableCell align="right">{row.Titleofthelecture}</TableCell>

              {/* <TableCell align="right">{row.InstituteId}</TableCell> */}
              <TableCell align="right">{row.NoOfStudents}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </div>
    </form>
  )
}
export default Activities

