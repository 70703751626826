import React from "react";

// import './B3.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
// import { tableContainerStyle, tableStyle } from "../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";
// import './B3.css'
import LoopIcon from "@mui/icons-material/Loop";
import { ToastContainer, toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { tableContainerStyle, tableStyle } from "../../../utils/style";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArB1({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/monthyReport");
  };

  const nextButton = () => {
    Navigate("/ArB2");
  };

  const Previousbutton = () => {
    Navigate("/ArA11");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [loader,setLoader] =useState(false)

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [Studentguidess, setStudentguidess] = useState([]);
  const [listData, setListData] = useState([]);

  const [teamMember, setTeamMember] = useState("");
  const [depatIdProductType, setDepatIdProductType] = useState("");
  const [productName, setProductName] = useState("");
  const [ProdcutSpecialFeature, setProdcutSpecialFeature] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [Mname, setName] = useState("");
  const [role, setRole] = useState("");

  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");
  const [disables, setDisables] = useState(false);
  const [ii, setI] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    navigate("/ArB1inside");
  };
  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);

    if (tok != null && tok != undefined) {
      if (myObject !== null) {
        handleDataList(tok, myObject.id);
        setI(myObject.id);
      }
    }
    const role = localStorage.getItem("role");
    setRole(role);
    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true);
    } else {
      setDisables(false);
    }
    let isTrue = localStorage.getItem("Mname");
    if (isTrue !== null) {
      setName(isTrue);
    }
    head(" Research Area");
  }, []);

  const edit = (item) => {
    navigate("/ArB1Inside", { state: item });
    console.log(item);
    setOpen(true);
    setId(item.id);
  };

  const onclickDelete = async (id) => {
    const result = window.confirm("Are you sure you want to delete?");
    if (result) {
      // Perform the delete operation
      deleteTransaction(id);
      console.log("Deleted successfully");
      // window.location.reload();
    } else {
      // Cancelled delete operation
      console.log("Delete cancelled");
    }
  };

  const deleteTransaction = async (id) => {
    const d = {
      Id: id,
    };
    console.log(d);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(`${window.BASE}${window.ARB1DELETE}`, d, {
        headers: h,
      });
      handleDataList(token, ii);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDataList = async (token, id) => {
    setLoader(true)
    console.log(token);
    console.log(id);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.ARB1DATALIST}${"/" + id}`,
        { headers: h }
      );
      console.log(data);
      setListData(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };


  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span> B1. Student strength
          </span>
        </div>
      </div>

      <div className="tables-main">
        <div style={{ marginLeft: 20 }}>
          <button className="tables-btn" onClick={Previousbutton}>
            <div className="sidebar-icon" />
            Previous
          </button>
        </div>
        <div style={{ marginRight: 20 }}>
          <button className="tables-btn" onClick={nextButton}>
            Next
            <div className="sidebar-icon" />
          </button>
        </div>
      </div>
      <div className="table-container-grid">
        <div className="tables-mains">
          <div className="table-head">
            {/* <div> */}

            {/* <button className='top-butto' onClick={backButton} >

              <ArrowBackIcon style={{ marginBottom: 3 }} className="sidebar-icon" />
              Back</button> */}
            {/* 
          </div> */}

            <div>
              <button
                className="top-button"
                onClick={handleClickOpen}
                disabled={role === "Director" ? true : !disables ? false : true}
              >
                + Add
              </button>
            </div>
            <div className="Center-Month">
              <h4 className="h-6">{Mname}</h4>
            </div>
          </div>
        </div>

        <Dialog
          className="dialog"
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="form-control"></div>
        </Dialog>

        {/* ************************************************************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell style={{ width: "80%" }}>
                Name of the College/Departments/Research Station
              </TableCell>
              <TableCell style={{ width: "80%" }}>
                Name of the Diploma / UG / PG / Ph.D. Program
              </TableCell>
              <TableCell style={{ width: "80%" }}>
                Male students{" "}
              </TableCell>
              <TableCell align="right" style={{ width: "80%" }}>
                Female students{" "}
              </TableCell>
              <TableCell style={{ width: "80%" }}>
                Total number of students
              </TableCell>
              <TableCell style={{ width: "80%" }}>
                State Govt. sponsored fellowship(s)
              </TableCell>
              <TableCell style={{ width: "80%" }}>
                Central Govt. sponsored fellowship(s){" "}
              </TableCell>
              <TableCell style={{ width: "80%" }}>
                Govt. competitive fellowship(s){" "}
              </TableCell>
              <TableCell style={{ width: "80%" }}>
                Private fellow- ship(s){" "}
              </TableCell>

              <TableCell style={{ width: "20%" }}>
                Total Fellowship
              </TableCell>
              <TableCell style={{ width: "20%" }}>
                Option
              </TableCell>
            </TableHead>

            <TableBody>
              {listData?.map((row) => (
                <TableRow key={row.researchAreaCrop}>
                  <TableCell >{row.instituteName}</TableCell>
                  <TableCell >{row.courseType}</TableCell>
                  <TableCell >{row.noOfMaleStudents}</TableCell>
                  <TableCell >{row.noOfFemaleStudents}</TableCell>
                  <TableCell >{row.totalNoStudents}</TableCell>
                  <TableCell >{row.stateGovtFellowship}</TableCell>
                  <TableCell >{row.centralGovtFellowship}</TableCell>
                  <TableCell >{row.govtCompetitiveFellowship}</TableCell>
                  <TableCell >{row.privateFellowship}</TableCell>
                  <TableCell >{row.totalFellowship}</TableCell>

                 
                    <TableCell >
                      <div className="edit-delete">
                        <button
                          className="tablebutton"
                          onClick={() => edit(row)}
                          disabled={role === "Director" ? true : !disables ? false : true}
                        >
                          {<Edit className="sidebars-icon" />}
                        </button>

                        <button
                          className="deleted"
                          style={{ marginLeft: 3 }}
                          onClick={() => onclickDelete(row.id)}
                          disabled={role === "Director" ? true : !disables ? false : true}
                        >
                          {<DeleteIcon className="sidebars-icon" />}
                        </button>
                      </div>
                    </TableCell>
                
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ArB1;
