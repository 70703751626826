import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { B14VALIDATION } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";

function B14Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true);
  const [Authors, setAuthors] = useState("");
  const [Publicationsyear, setPublicationsyear] = useState("");
  const [Titleofpublications, setTitleofpublications] = useState("");
  const [Journal, setJournal] = useState("");
  const [Volume, setVolume] = useState("");
  const [Issue, setIssue] = useState("");
  const [Pageno, setPageno] = useState("");
  const [ratingType, setratingType] = useState("");
  const [ISSNNAASID, setISSNNAASID] = useState("");
  const [NAASRating, setNAASRating] = useState("");
  const [SCIIndex, setSCIIndex] = useState("");

  const [ratingTypeDrop, setratingTypeDrop] = useState([]);

  const [AuthorsErr, setAuthorsErr] = useState(false);
  const [PublicationsyearErr, setPublicationsyearErr] = useState(false);
  const [TitleofpublicationsErr, setTitleofpublicationsErr] = useState(false);
  const [JournalErr, setJournalErr] = useState(false);
  const [VolumeErr, setVolumeErr] = useState(false);
  const [IssueErr, setIssueErr] = useState(false);
  const [PagenoErr, setPagenoErr] = useState(false);
  const [ratingTypeErr, setratingTypeErr] = useState(false);
  const [ISSNNAASIDErr, setISSNNAASIDErr] = useState(false);
  const [NAASRatingErr, setNAASRatingErr] = useState(false);
  const [SCIIndexErr, setSCIIndexErr] = useState(false);

  const [JournalIssueType, setJournalIssueType] = useState([]);
  const [Journalissuedate, setJournalissuedate] = useState("");
  const [ratingUpdate, setratingUpdate] = useState("");

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B14VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleJournalIssue(tok);
      handleRatingType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Publications");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const handleBack = () => {
    navigate("/B14");
  };

  // const BtnSubmit = () => {
  //   alert("Successfully Submitted")
  // }

  const edit = (item) => {
    console.log(item);
    setAuthors(item.authors);
    setPublicationsyear(item.publicationsYear);
    setTitleofpublications(item.titleOfPublications);
    setJournal(item.journal);
    setVolume(item.volume);
    setIssue(item.issue);
    setratingType(item.ratingType);
    setPageno(item.pageNo);
    setISSNNAASID(item.issnnaasid);
    setNAASRating(item.naasRating);
    setSCIIndex(item.sciIndex);
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Journal Issue Lov Type //
  const handleJournalIssue = async (token, JournalIssue) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"JournalIssue"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setJournalIssueType(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // rating type Lov Type //
  const handleRatingType = async (token, RatingType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"RatingType"}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false);
      if (data) {
        setratingTypeDrop(data);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      Authors: Authors,
      PublicationsYear: Publicationsyear,
      TitleOfPublications: Titleofpublications,
      Journal: Journal,
      Volume: Volume,
      Issue: Issue,
      RatingType: ratingType,
      PageNo: Pageno,
      ISSNNAASID: ISSNNAASID,
      NAASRating: NAASRating,
      SCIIndex: SCIIndex,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B14SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B14");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B14EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B14");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!Authors) {
      setAuthorsErr(true);
      document.getElementById("Authors").focus();
    } else if (!Publicationsyear) {
      setPublicationsyearErr(true);
      document.getElementById("Publicationsyear").focus();
    } else if (!Titleofpublications) {
      setTitleofpublicationsErr(true);
      document.getElementById("Titleofpublications").focus();
    } else if (!Journal) {
      setJournalErr(true);
      document.getElementById("Journal").focus();
    } else if (!Volume) {
      setVolumeErr(true);
      document.getElementById("Volume").focus();
    } else if (!Issue) {
      setIssueErr(true);
      document.getElementById("Issue").focus();
    } else if (!ratingType) {
      setratingTypeErr(true);
      document.getElementById("ratingType").focus();
    } else if (!Pageno) {
      setPagenoErr(true);
      document.getElementById("Pageno").focus();
    } else if (!ISSNNAASID) {
      setISSNNAASIDErr(true);
      document.getElementById("ISSNNAASID").focus();
    } else if (!NAASRating) {
      setNAASRatingErr(true);
      document.getElementById("NAASRating").focus();
    } else if (!SCIIndex) {
      setSCIIndexErr(true);
      document.getElementById("SCIIndex").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Publications (B14)</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>B14. Publications</h5>
          <TextField
            error={AuthorsErr}
            id="Authors"
            label="Authors"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Authors}
            onChange={(e) => {
              setAuthors(e.target.value);
              setAuthorsErr(false);
            }}
          />

          <TextField
            error={PublicationsyearErr}
            id="Publicationsyear"
            label="Year"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Publicationsyear}
            onChange={(e) => {
              setPublicationsyear(e.target.value);
              setPublicationsyearErr(false);
            }}
          />

          <TextField
            error={TitleofpublicationsErr}
            id="Titleofpublications"
            label="Title (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Titleofpublications}
            // onChange={(e) => setTitleofpublications(e.target.value)}
            onChange={(e) => {
              setTitleofpublications(e.target.value);
              setTitleofpublicationsErr(false);
            }}
          />

          <TextField
            erroe={JournalErr}
            id="Journal"
            label="Journal (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Journal}
            onChange={(e) => {
              setJournal(e.target.value);
              setJournalErr(false);
            }}
          />

          <TextField
            error={VolumeErr}
            id="Volume"
            label="Volume (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Volume}
            onChange={(e) => {
              setVolume(e.target.value);
              setVolumeErr(false);
            }}
          />

          <TextField
            error={IssueErr}
            id="Issue"
            label="Issue (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Issue}
            onChange={(e) => {
              setIssue(e.target.value);
              setIssueErr(false);
            }}
          />

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={ratingTypeErr}
            id="ratingType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Rating Type
            </InputLabel>
            <Select
              variant="outlined"
              // label="Rating Type"
              style={{ height: "45px" }}
              value={ratingType}
              onChange={(e) => {
                setratingType(e.target.value);
                setratingTypeErr(false);
              }}
            >
              {ratingTypeDrop?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={PagenoErr}
            id="Pageno"
            label="Page No"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Pageno}
            onChange={(e) => {
              setPageno(e.target.value);
              setPagenoErr(false);
            }}
          />

          <TextField
            error={ISSNNAASIDErr}
            id="ISSNNAASID"
            label="ISSN NAAS ID"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={ISSNNAASID}
            onChange={(e) => {
              setISSNNAASID(e.target.value);
              setISSNNAASIDErr(false);
            }}
          />

          <TextField
            error={NAASRatingErr}
            id="NAASRating"
            label="NAAS rating/ Impact factor"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={NAASRating}
            onChange={(e) => {
              setNAASRating(e.target.value);
              setNAASRatingErr(false);
            }}
          />

          <TextField
            error={SCIIndexErr}
            id="SCIIndex"
            label="SCI Index Yes/No"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={SCIIndex}
            onChange={(e) => {
              setSCIIndex(e.target.value);
              setSCIIndexErr(false);
            }}
            select // Add select prop to indicate it's a dropdown
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default B14Inside;
