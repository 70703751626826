import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D2VALI } from "../../../Validation";

import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import { TokenOutlined } from "@mui/icons-material";
import ComponentLoader from "../../../ComponentLoader";

function ArD2Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true);
  const [Studentbtn, setStudentbtn] = useState([]);

  const [activity, setActivity] = useState("");
  const [responsibility, setResponsibility] = useState("");

  const [activityErr, setActivityErr] = useState(false);
  const [responsibilityErr, setResponsibilityErr] = useState(false);

  const [dropcourse, setDropcourse] = useState([]);
  const [bookType, setBookType] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const[value,setValue]=useState({
    Authors:"",
    Year:"",
    BookType:"",
    BookName:"",
    TitleOfBookChapter:"",
    Publisher:"",
    PageNo:"",
    ISBN:"",
    
   });

   const[errValue,setErrValue]=useState({
    Authors:"",
    Year:"",
    BookType:"",
    BookName:"",
    TitleOfBookChapter:"",
    Publisher:"",
    PageNo:"",
    ISBN:"",
    
   });

  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti, setIsti] = useState();

  const toastSuccess = (data) => {
    toast.success(data, { 
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D2VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleCoursetypes(tok);
      handleBookType(tok)
      

      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("  Additional Responsibilities");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setIsti(insti);
  }, []);

  const edit = (item) => {

    setValue({
      Authors:item.authors,
      Year:item.year,
      BookType:item.bookType,
      BookName:item.bookName,
      TitleOfBookChapter:item.titleOfBookChapter,
      Publisher:item.publisher,
      PageNo:item.pageNo,
      ISBN:item.isbn,
    })
   
    setId(item.id);
  };

  // dropdown
  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
 })

  console.log("value",value);

  const handleCoursetypes = async (token, Activities) => {
    console.log(Activities);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVEAPI}${"AdditionalResponsibilities"}`,
        { headers: h }
      );
      console.log(data);

      setDropcourse(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const backs = () => {
    navigate("/ArD2");
  };

  const handleBookType = async (token, BookType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"BookType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setBookType(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      Authors:value.Authors,
    Year:value.Year,
    BookType:value.BookType,
    BookName:value.BookName,
    TitleOfBookChapter:value.TitleOfBookChapter,
    Publisher:value.Publisher,
    PageNo:value.PageNo,
    ISBN:value.ISBN,
    IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARD2SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArD2");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs[0])
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARD2EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArD2");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  console.log(errors);

 
  const formValidateFun = (e) => {
    // Authors:"",
    // Year:"",
    // BookType:"",
    // BookName:"",
    // TitleOfBookChapter:"",
    // Publisher:"",
    // PageNo:"",
    // ISBN:"",
  
    if (!value.Authors) {
      setErrValue((prev) => ({ ...prev, Authors: true }))
      document.getElementById("Authors").focus()
    } else if (!value.Year) {
      setErrValue((prev) => ({ ...prev, Year: true }))
      document.getElementById("Year").focus()
    } else if (!value.BookType) {
      setErrValue((prev) => ({ ...prev, BookType: true }))
      document.getElementById("BookType").focus()
    }
  else if (!value.BookName) {
    setErrValue((prev) => ({ ...prev, BookName: true }))
    document.getElementById("BookName").focus()
  }
  else if (!value.TitleOfBookChapter) {
    setErrValue((prev) => ({ ...prev, TitleOfBookChapter: true }))
    document.getElementById("TitleOfBookChapter").focus()
  }
  else if (!value.Publisher) {
    setErrValue((prev) => ({ ...prev, Publisher: true }))
    document.getElementById("Publisher").focus()
  }
  else if (!value.PageNo) {
    setErrValue((prev) => ({ ...prev, PageNo: true }))
    document.getElementById("PageNo").focus()
  }
  else if (!value.ISBN) {
    setErrValue((prev) => ({ ...prev, ISBN: true }))
    document.getElementById("ISBN").focus()
  }
  else{
    handleLeavepage()
  }
      
    };


  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>D2. Books/Book Chapter </span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}> D2. Books/Book Chapter </h5>

          {/* <FormControl style={{ width: "48%", margin: 12 }} error={responsibilityErr} id="responsibility">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Responsibility
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={responsibility}
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setResponsibility(e.target.value)
                setResponsibilityErr(false)
              }}
            >
              {dropcourse?.map((row) => {
                return <MenuItem value={row.lovName}>{row.lovName}</MenuItem>;
              })}
            </Select>
          </FormControl> */}

{/* Authors:"",
    Year:"",
    BookType:"",
    BookName:"",
    TitleOfBookChapter:"",
    Publisher:"",
    PageNo:"",
    ISBN:"", */}

          <TextField
           
            id="Authors"
            label="Authors"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Authors"
            error={errValue.Authors}
            value={value.Authors}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Authors: false }));
            }}
          />
          <TextField
           
            id="Year"
            label="Year"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Year"
            error={errValue.Year}
            value={value.Year}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Year: false }));
            }}
          />
          {/* <TextField
           
            id="BookType"
            label="Book Type"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="BookType"
            error={errValue.BookType}
            value={value.BookType}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, BookType: false }));
            }}
          /> */}
          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={errValue.BookType}
            id="BookType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Book Type
            </InputLabel>
            <Select
              variant="outlined"
              // label="Rating Type"
              style={{ height: "45px" }}
              name="BookType"
            
              value={value.BookType}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, BookType: false }));
              }}
            >
              {bookType?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
           
            id="BookName"
            label="Book Name"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="BookName"
            error={errValue.BookName}
            value={value.BookName}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, BookName: false }));
            }}
          />
          <TextField
           
            id="TitleOfBookChapter"
            label="Title"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="TitleOfBookChapter"
            error={errValue.TitleOfBookChapter}
            value={value.TitleOfBookChapter}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, TitleOfBookChapter: false }));
            }}
          />
          <TextField
           
            id="Publisher"
            label="Publisher"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Publisher"
            error={errValue.Publisher}
            value={value.Publisher}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Publisher: false }));
            }}
          />
          <TextField
           
            id="PageNo"
            label="PageNo"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="PageNo"
            error={errValue.PageNo}
            value={value.PageNo}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, PageNo: false }));
            }}
          />
          <TextField
           
            id="ISBN"
            label="ISBN"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="ISBN"
            error={errValue.ISBN}
            value={value.ISBN}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ISBN: false }));
            }}
          />

          <div className="from-last">
            <button
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </div>
    </div>
  );
}

export default ArD2Inside;