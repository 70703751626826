import { useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";
import axios from "axios";
import HomeIcon from "@mui/icons-material/Home";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, TextField, IconButton, } from "@mui/material";
import { toastSuccess } from "../../utils/toast";
import ComponentLoader from "../../ComponentLoader";
import { Textarea } from "react-bootstrap-icons";
import CheckboxList from "../../utils/list";
import { TextareaAutosize } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";





const Projectcodeinside = () => {



    const navigate = useNavigate();

    const [token, setToken] = useState("");
    const [project, setProject] = useState([]);
    const [science, setScience] = useState([]);
    const [scienceMeet, setScienceMeet] = useState([]);
    const [agency, setAgency] = useState([]);
    // const [incharge, setIncharge] = useState([]);
    const [inves, setInves] = useState([]);
    const [investigatorName, setInvestigatorName] = useState("")
    const [additionalincharge, setAdditionalincharge] = useState("")
    const [add, setAdd] = useState([]);
    const [open, setOpen] = useState(false);
    const [filterAdd, setFilterAdd] = useState([]);
    const [addDetails, setAddDetails] = useState([]);


    console.log(addDetails);

    //error

    const [projectNameErr, setProjectNameErr] = useState(false);
    const [projectcodeErr, setProjectCodeErr] = useState(false);
    const [startDateErr, setStartDateErr] = useState(false);
    const [endDateErr, setEndDateErr] = useState(false);
    const [scienceErr, setScienceErr] = useState(false);
    const [scienceMeetErr, setScienceMeetErr] = useState(false);
    const [projectTypeErr, setProjectTypeErr] = useState(false);
    const [fundingErr, setFundingErr] = useState(false);
    const [budgetErr, setBudgetErr] = useState(false);
    const [outputErr, setOutputErr] = useState(false);
    const [objectiveErr, setObjectiveErr] = useState(false);
    const [methodolgyErr, setMethodologyErr] = useState(false);
    const [inchargeErr, setInchargeErr] = useState(false);
    const [statusErr, setStatusErr] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        const tok = localStorage.getItem("token");
        setToken(tok);
        console.log(tok);
        handleProjectType(tok);
        handleScience(tok);
        handleScienceMeet(tok);
        handleFundingAgency(tok);

        const institute = localStorage.getItem("loginId");
        // setInsti(institute);
        handleInvestigators(tok, institute);

        const additional = localStorage.getItem("loginId");
        handleAdditionalcharge(tok, additional);
    }, [])


    const inchargeField = (e) => {

        const investigatorName = (e.target.value);
        setInvestigatorName(investigatorName);
        setInchargeErr(false);

        const filterchange = inves?.filter(item => item.id !== investigatorName);
        setFilterAdd(filterchange);
    }

    //child data passing

    const handleCheck = (facultyList) => {
        console.log(facultyList);
        setAddDetails(facultyList);
    }

    //Delete Button

    const deleteButton = (id) => {
        setAddDetails([...addDetails].filter(row => row.id !== id));
    }


    //PROJECTTYPE API
    const handleProjectType = async (token, ProjectType) => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        console.log(token);
        try {
            const { data } = await axios.get(`${window.BASE}${window.LOVTYPE}${"ProjectType"}`, {
                headers: h,
            });
            console.log(data);
            setProject(data);
        } catch (error) {
            console.log(error)
        }
    };

    //SCIENCE API 
    const handleScience = async (token, Science) => {

        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        console.log(token);
        try {
            const { data } = await axios.get(`${window.BASE}${window.LOVTYPE}${"Science"}`, {
                headers: h,
            });
            console.log(data);
            setScience(data);
        } catch (error) {
            console.log(error)
        }
    }

    //SCIENCEMEET API
    const handleScienceMeet = async (token, ScienceMeet) => {

        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        console.log(token);
        try {
            const { data } = await axios.get(`${window.BASE}${window.LOVTYPE}${"Science Meet"}`, {
                headers: h,
            });
            console.log(data);
            setScienceMeet(data);
        } catch (error) {
            console.log(error)
        }
    }

    //FUNDING AGENCY API

    const handleFundingAgency = async (token) => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        try {
            const { data } = await axios.get(
                `${window.BASE}${window.FUNDINGGETALL}`,
                {
                    headers: h,
                }
            );
            console.log(data);
            if (data) {
                setAgency(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //INCHARGE DETAILS API WITH ID BASED
    const handleInvestigators = async (token, id) => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        try {
            const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
                headers: h,
            });
            console.log(data);
            setInves(data);
        } catch (error) {
            console.log(error);
        };

    }
    //ADDITIONAL INCHARGE API WITH ID BASED

    const handleAdditionalcharge = async (token, id) => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        try {
            const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
                headers: h,
            });
            console.log(data);
            setAdd(data);
        } catch (error) {
            console.log(error);
        };
    }
    const handleBack = () => {
        navigate("/projectcode");
    }

    return (
        <div style={{ marginTop: 70 }}>

            <div className="master-main d-flex">
                <div className="head-icon">
                    <Link to="/projectcode">
                        <HomeIcon style={{ color: "white", marginLeft: 38, padding: 5 }} />
                    </Link>
                </div>
                <div className="master-main-span">
                    <span>Project</span>
                </div>
            </div>
            <div className="from-mains">
                <div className="form-control">
                    <h5 style={{ margin: 20 }}> Add Project Details </h5>
                    <TextField
                        fullWidth
                        error={projectcodeErr}
                        style={{ margin: "10px", width: "46%" }}
                        id="investigatorname"
                        label="Project Code"
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                            // setInvestigatorName(e.target.value);
                            setProjectCodeErr(false);
                        }}

                    />
                    <TextField
                        fullWidth
                        error={projectNameErr}
                        style={{ margin: "10px", width: "46%" }}
                        id="investigatorname"
                        label="Project Name"
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                            setProjectNameErr(false);
                        }}
                    />

                    <TextField
                        fullWidth
                        error={startDateErr}
                        style={{ margin: "10px", width: "46%" }}
                        id="outlined-basic"
                        label="Start Date"
                        variant="outlined"
                        type="date"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            placeholder: "",
                        }}
                        onChange={(e) => {
                            setStartDateErr(false);


                        }}
                    />
                    <TextField
                        fullWidth
                        error={endDateErr}
                        style={{ margin: "10px", width: "46%" }}
                        id="outlined-basic"
                        label="End Date"
                        variant="outlined"
                        type="date"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            placeholder: "",
                        }}
                        onChange={(e) => {
                            setEndDateErr(false);

                        }}
                    />
                    <FormControl
                        style={{ width: "46%", margin: 12 }}
                        error={scienceErr}
                        id="facultyType"                    >
                        <InputLabel id="demo-simple-select-helper-label" size="small">
                            Science
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Faculty Type"
                            style={{ height: "45px" }}
                            onChange={(e) => {
                                handleScience(e.target.value);
                                setScienceErr(false);


                            }}
                        >
                            {science?.map((option, index) => {
                                return (
                                    <MenuItem key={index} value={option.id}>
                                        {option.lovName}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ width: "46%", margin: 12 }}
                        error={scienceMeetErr}
                        id="facultyType"
                    >
                        <InputLabel id="demo-simple-select-helper-label" size="small">
                            Sccience Meet
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Faculty Type"
                            style={{ height: "45px" }}
                            onChange={(e) => {
                                handleScienceMeet(e.target.value);
                                setScienceMeetErr(false);
                            }}
                        >
                            {scienceMeet?.map((option, index) => {
                                return (
                                    <MenuItem key={index} value={option.id}>
                                        {option.lovName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ width: "46%", margin: 12 }}
                        error={projectTypeErr}
                        id="facultyType"                    >
                        <InputLabel id="demo-simple-select-helper-label" size="small">
                            Project Type
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Faculty Type"
                            style={{ height: "45px" }}
                            onChange={(e) => {
                                handleProjectType(e.target.value);
                                setProjectTypeErr(false);
                            }}
                        >
                            {project?.map((option, index) => {
                                return (
                                    <MenuItem key={index} value={option.id}>
                                        {option.lovName}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ width: "46%", margin: 12 }}
                        error={fundingErr}
                        id="facultyType"                    >
                        <InputLabel id="demo-simple-select-helper-label" size="small">
                            Funding Agency
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Faculty Type"
                            style={{ height: "45px" }}
                            onChange={(e) => {
                                handleFundingAgency(e.target.value);
                                setFundingErr(false);
                            }}
                        >
                            {agency?.map((option, index) => {
                                return (
                                    <MenuItem key={index} value={option.id}>
                                        {option.fundingAgencyName}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                    <TextField
                        id="budget"
                        error={budgetErr}
                        label="Budget(Rs. in  Lakhs) (Max 500 Character)"
                        variant="outlined"
                        type="number"
                        style={{ margin: "10px", width: "46%" }}
                        onChange={(e) => {
                            setBudgetErr(false);
                        }}
                    />
                    <FormControl
                        style={{ width: "46%", margin: 12 }}
                        error={statusErr}
                        id="facultyType"
                    >
                        <InputLabel id="demo-simple-select-helper-label" size="small">
                            Status
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Faculty Type"
                            style={{ height: "45px" }}
                            onChange={(e) => {
                                setStatusErr(false);
                            }}
                        >
                            <MenuItem value="Teaching Staff"> Active</MenuItem>
                            <MenuItem value="Non Teaching Staff"> InActive</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <TextareaAutosize
                        fullWidth
                        error={outputErr}
                        style={{ margin: "10px", width: "95%", height: "65px" }}
                        aria-label="empty textarea"
                        id="outlined-multiline-static"
                        label="Output"
                        variant="outlined"
                        placeholder="Output"
                        onChange={(e) => {
                            setOutputErr(false);
                        }}
                    />; */}
                    <TextField
                        error={outputErr}
                        id="outlined-multiline-static"
                        style={{ margin: "10px", width: "46%" }}
                        label="Output"
                        variant="outlined"
                        placeholder="Output"
                        onChange={(e) => {
                            setOutputErr(false);
                        }}
                    />

                    {/* <TextareaAutosize
                        fullWidth
                        error={objectiveErr}
                        style={{ margin: "10px", width: "95%", height: "65px" }}
                        aria-label="empty textarea"
                        id="outlined-multiline-static"
                        label="Objective"
                        variant="outlined"
                        placeholder="Objective"
                        onChange={(e) => {
                            setObjectiveErr(false);
                        }}
                    />; */}
                    <TextField
                        error={objectiveErr}
                        id="outlined-multiline-static"
                        label="Objective"
                        variant="outlined"
                        placeholder="Objective"
                        style={{ margin: "10px", width: "46%" }}
                        onChange={(e) => {
                            setObjectiveErr(false);
                        }}
                    />
                    <TextareaAutosize
                        fullWidth
                        error={methodolgyErr}
                        style={{ margin: "10px", width: "95%", height: "65px" }}
                        aria-label="empty textarea"
                        id="outlined-multiline-static"
                        label="Methodology"
                        variant="outlined"
                        placeholder="Methodology"
                        onChange={(e) => {
                            setMethodologyErr(false);
                        }}
                    />

                    <TextField
                        error={methodolgyErr}
                        style={{ margin: "10px", width: "46%" }}
                        id="outlined-multiline-static"
                        label="Methodology"
                        variant="outlined"
                        placeholder="Methodology"
                        onChange={(e) => {
                            setMethodologyErr(false);
                        }}
                    />


                    <div>
                        <FormControl
                            style={{ width: "40%", margin: 12 }}
                            error={inchargeErr}
                            id="investigatorName"
                        >
                            <InputLabel id="demo-simple-select-helper-label" size="small">
                                In Charge
                            </InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="investigatorName"
                                value={investigatorName}
                                label="Faculty Type"
                                size="small"
                                style={{ height: "45px" }}
                                onChange={inchargeField}
                            >
                                {inves?.map((row, index) => {
                                    return (
                                        <MenuItem key={row.index} value={row.id}>
                                            {row.investigatorName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {/* <div>
                          
                        </div> */}
                        <button  type="button" class="top-button only-add" data-toggle="modal" data-target="#exampleModalCenter">
                        <IconButton
                                onClick={handleClickOpen}
                                className="addbtn"
                                style={{ fontSize: "14px", marginTop: "12px" }}
                               
                            >
                                <AddIcon />
                            </IconButton>
                    </button>
                    </div>
                    


                    {investigatorName &&
                        <FormControl
                            style={{ width: "40%", margin: 12 }}
                            // error={investigatorNameErr}
                            id="investigatorName"
                        >
                            <TableContainer>
                                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell align="right">Desigination</TableCell>
                                            <TableCell align="right">Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {addDetails?.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="right">{row.investigatorName}</TableCell>
                                                <TableCell align="right">{row.designation} </TableCell>
                                                <TableCell><button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close" onClick={() => deleteButton(row.id)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </FormControl>
                    }

                    <div
                        className="dialogue_action_button"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div>
                            <button
                                style={{ backgroundColor: "#0abb87", height: "20px", padding: "15px", borderRadius: "5px", color: "#fff" }}
                                onClick={handleBack}>
                                Back
                            </button>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <button type="submit" className="sbmit-buttons">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <CheckboxList filterAdd={filterAdd} handleCheck={handleCheck} />
        </div >
    );
}
export default Projectcodeinside;