import React from "react";

import {
    TextField,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import { DateField } from '@mui/x-date-pickers/DateField';


import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
// import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";
import { truncate } from "lodash";

function ArA10Inside({ head }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = React.useState(false);

    const [Studentbtn, setStudentbtn] = useState([]);

    const [Natureofcoaching, setNatureofcoaching] = useState("");
    const [Noofclassesoffered, setNoofclassesoffered] = useState("");
    const [FellowshipName, setFellowshipName] = useState("");
    const [NoOfStudent, setNoOfStudent] = useState("");

    const [token, setToken] = useState("");
    const [id, setId] = useState(0);

    const [state, setState] = useState();
    const [assM, setAssMonth] = useState("");
    const [invis, setInvestigator] = useState();
    const [insti,setInsti] =useState()
    const [loader,setLoader] =useState(true)

    const[value,setValue]=useState({
        Issues:"",
        StaffInvolved:"",
        LegalDetails:"",
        DateOfOrigin:"",
        Status:"",
       });

       const[errValue,setErrValue]=useState({
        Issues:false,
        StaffInvolved:false,
        LegalDetails:false,
        DateOfOrigin:false,
        Status:false,
       });

    const navigate = useNavigate();
    const location = useLocation();
console.log(value.DateOfOrigin);
    const [dropnature, setDropnature] = useState([]);

    const toastSuccess = (data) => {
        toast.success(data, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        // setValue,
        reset,
        watch,
    } = useForm({
        // resolver: yupResolver(A8VALI),
    });

    useEffect(() => {
        const tok = localStorage.getItem("token");
        setToken(tok);
        console.log(tok);
        if (tok != null && tok != undefined) {
            // handleDirectorate(tok);
            console.log(location.state);
            if (location.state) {
                edit(location.state);
            }
        }
        head(" Student facilitation");
        setLoader(false)
    }, []);

    useEffect(() => {
        const objectString = localStorage.getItem("MReport");
        const myObject = JSON.parse(objectString);
        if (myObject !== null) {
            setState(myObject);
        }
        const Month = localStorage.getItem("Month");
        if (Month !== null) {
            setAssMonth(Month);
        }
        const insti = localStorage.getItem("loginId");
        setInsti(insti);
    }, []);

    const edit = (item) => {
        console.log(item);
        
        setValue({
            Issues:item.issues,
            StaffInvolved:item.staffInvolved,
            LegalDetails:item.legalDetails,
            DateOfOrigin:dateFormat(item.dateOfOrigin),
            Status:item.status,
            
        })
        setId(item.id)
        setLoader(false)
    };

    const handleData = ((event)=>{
        const {name,value}=event.target
        setValue(prev =>({
          ...prev,
          [name] : value
        }))
      })
      console.log("value",value);
    
    const backs = () => {
        navigate("/ArA10");
    };

    const handleClickOpen = () => {
        setOpen(true);
        //  navigate("/Activities")
    };

    const handleClose = () => {
        setOpen(false);
        // setId(0)
    };

    const handleLeavepage = async (data) => {
        const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };
        console.log(token);
       
        const d = {
            id,
            Issues: value.Issues,
            StaffInvolved: value.StaffInvolved,
            LegalDetails: value.LegalDetails,
            DateOfOrigin: value.DateOfOrigin,
            Status: value.Status,
            IAMRSId: state.id,
            InstituteId: parseInt(insti),
            RptMonth: parseInt(assM),
        };


        if (id === 0) {
            try {
                const { data } = await axios.post(`${window.BASE}${window.ARA10SAVE}`, d, {
                    headers: h,
                });
                console.log(data);
                setStudentbtn(data);

                if (data.result === "Success") {
                    alert("Successfully Submitted");
                    navigate("/ArA10")
                    toastSuccess("Successfully Submitted");
                    // setId(0);
                    setOpen(false);
                } else {
                    alert(data.errorMsgs[0])
                }

                //   setDirect(data);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const { data } = await axios.post(`${window.BASE}${window.ARA10EDIT}`, d, {
                    headers: h,
                });
                console.log(data);
                if (data.result === "Success") {
                    alert("Successfully Submitted");
                    navigate("/ArA10")
                    toastSuccess("Successfully updated");
                    setId(0);
                    setOpen(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
//     value.Issues
// value.StaffInvolved
// value.LegalDetails
// value.DateOfOrigin
// value.Status
    const formValidateFun = () => {
        if (!value.Issues) {
            setErrValue((prev) => ({ ...prev, Issues: true }));
          document.getElementById("Issues").focus();
        } else if (!value.StaffInvolved) {
            setErrValue((prev) => ({ ...prev, StaffInvolved: true }));
          document.getElementById("StaffInvolved").focus();
        } else if (!value.LegalDetails) {
            setErrValue((prev) => ({ ...prev, LegalDetails: true }));
          document.getElementById("LegalDetails").focus();
        } else if (!value.DateOfOrigin ) {
            setErrValue((prev) => ({ ...prev, DateOfOrigin: true }));
          document.getElementById("DateOfOrigin").focus();       
        
        }
        else if (!value.Status) {
            setErrValue((prev) => ({ ...prev, Status: true }));
          document.getElementById("Status").focus();       
        
        }
         else {
          handleLeavepage();
        }
      };
    return (
        <div className="main-container-margin">
             <ComponentLoader isLoading={loader} />
            <div className="master-main d-flex">
                <div className="head-icon">
                    <Link to="/GeneralAR">
                        <HomeIcon style={{ color: "white", marginLeft: 70 }} />
                    </Link>
                </div>
                <div className="master-main-span">
                    <span>
                    A10.  Details on the Legal / Disciplinary cases

                    </span>
                </div>
            </div>

            <form className="from-mains" onSubmit={handleSubmit(formValidateFun)}>
                <div className="form-control">
                    <h5 style={{ width: 350, margin: 20 }}>
                    A10.  Details on the Legal / Disciplinary cases

                    </h5>
                  
                    <TextField
                        id="Issues"
                        label="Issue. (Max 500 Character)"
                        variant="outlined"
                        name="Issues"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.Issues}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, Issues: false }));
                          }}
                        error={errValue.Issues}
                        helperText={errors.Natureofcoaching?.message}
                    />

                    <TextField
                        id="StaffInvolved"
                        label="Staff involved (Max 500 Character)"
                        variant="outlined"
                        name="StaffInvolved"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.StaffInvolved}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, StaffInvolved: false }));
                          }}
                        error={errValue.StaffInvolved}
                        helperText={errors.Noofclassesoffered?.message}
                    />

                    <TextField
                        id="LegalDetails"
                        label="Legal or Disciplinary (L/D) (Max 500 Character)"
                        variant="outlined"
                        name="LegalDetails"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.LegalDetails}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, LegalDetails: false }));
                          }}
                        error={errValue.LegalDetails}
                        helperText={errors.FellowshipName?.message}
                    />

                    <TextField
                        id="DateOfOrigin"
                        label=" Date of origin"
                        variant="outlined"
                        name="DateOfOrigin"
                        type="date"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.DateOfOrigin}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, DateOfOrigin: false }));
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            placeholder: "",
                          }}
                        error={errValue.DateOfOrigin}
                        helperText={errors.NoOfStudent?.message}
                    />



                    <TextField
                        id="Status"
                        label="Status (Max 500 Character)"
                        variant="outlined"
                        name="Status"
                        style={{ margin: "10px", width: "48%" }}
                        value={value.Status}
                        onChange={(e) => {
                            handleData(e);
                            setErrValue((prev) => ({ ...prev, Status: false }));
                          }}
                        error={errValue.Status}
                        helperText={errors.NoOfStudent?.message}
                    />
                   
                

                    <div className="from-last">
                        <button
                            type="submit"
                            style={{ backgroundColor: "#008CBA", height: "20px" }}
                            onClick={backs}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className="sbmit-buttons"
                        // onClick={handleLeavepage}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ArA10Inside;