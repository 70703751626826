import React from "react";

const Utility = () => {
  return (
    <div>
      <>
        <div>
          <div class="utility-page">
            <div class="carousel-item-img6">
              <div class="content-page utility-li">
                <h5>Farm Application </h5>
                <ul class="team-list">
                  <li>
                    <a href="https://trritnau.com:8088/soil" target="_blank">
                      Soil Test Report
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://trritnau.com:8088/fertilizer"
                      target="_blank"
                    >
                      Fertilizer Calculator
                    </a>
                  </li>
                  <li>
                    <a href="https://trritnau.com:8088/water" target="_blank">
                      Water Quality Test
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Utility;
