import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import LoopIcon from "@mui/icons-material/Loop";
import { ToastContainer, toast } from "react-toastify";

import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArA1ae({ head }) {
  const Navigate = useNavigate("");
  const backButton = () => {
    Navigate("/ManthyReport");
  };
  const Previousbutton=()=>{
    Navigate("/exsummary")
  }

  const nextButton = () => {
    Navigate("/ArA1(d)");
  };

  // const Previousbutton = () => {
  //   Navigate("/TransactionTsms");
  // };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [initialData,setInitialData] =useState()
  const [globalID,setGlobalID]= useState(0)
  const [loader,setLoader] =useState(true)

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [Studentguidess, setStudentguidess] = useState([]);
  const [courseNumber, setCourseNumber] = useState("");
  const [courseId, setCourseId] = useState("");
  const [courseType, setCourseType] = useState("");
  const [courseTeacher, setCourseTeacher] = useState("");
  const [hours, setHours] = useState("");
  const [noOfClassTP, setNoOfClassTP] = useState("");

  const [reportOfficer, setReportOfficer] = useState("");
  const [mandate, setMandate] = useState("");

  const [editReportOfficer,setEditReportOfficer] = useState("")
 

  const [editMandate,setEditMandate] =useState("");
  

  const [reportOfficerErr, setReportOfficerErr] = useState(false);
  const [mandateErr, setMandateErr] = useState(false);

  console.log(editMandate,editReportOfficer,"are u ok");

  const [state, setState] = useState();
  const [insti, setInsti] = useState();
  const [assM, setAssMonth] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const location = useLocation();

  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");
  const [Mname, setMame] = useState("");
  const [v, setV] = useState(false);
  const [disables, setDisables] = useState(false);
  const [ii, setI] = useState(null);
  const [role, setRole] = useState("");

  const [l, setL] = useState();

  const handleClickOpen = () => {
    setOpen(true);
    navigate("/ArA1Inside", { state: { TrnMonthlyReport: l } });
  };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {

    
    let is = localStorage.getItem("btnss");
    if (is !== null) {
      setV(true);
    }
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);

    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    
    if (myObject !== null) {
      setState(myObject);
      handleGet(tok,myObject.id)
    }

    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }

    const institute = localStorage.getItem("loginId");
    setInsti(institute);

    if (tok != null && tok != undefined) {
      if (myObject !== null) {
        // handleDirectorate(tok, myObject.id);
        setI(myObject.id);
      }
    }
    head("Courses handled");

    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true)
    } else {
      setDisables(false)
    }
    var rolee= localStorage.getItem("role");
    setRole(rolee);
    setLoader(false)
  }, []);


 

  const handleGet= async(tok,id)=>{
    console.log(tok,id)
    const h = {
        Authorization: `Bearer ${tok}`,
        "Content-Type": "application/json",
    };
    try {
        const { data } = await axios.get(
          `${window.BASE}${window.Ar1DATALIST}${"/" + id}`,
          { headers: h }
        );
     
        if(data.length > 0){
          let i = data.length -1
          const showData=data[i];
          setId(showData.id)
          setReportOfficer(showData.reportingOfficer);
        setMandate(showData.mandate);
        }
        console.log(data)
       
        
        
        // setGlobalID(showData.id);
       
      } catch (error) {
        console.log(error);
      
      }
}
console.log(initialData)


// setEditMandate(initialData.showData.mandate)
        // setReportOfficer(initialData.showData.reportingOfficer);
 

  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
  console.log(id,"iddddddddddd");
    const d = {
      id,
      ReportingOfficer: reportOfficer,
      Mandate: mandate,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    console.log(id,"heyy");
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Ar1SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentguidess(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          setId(1)
          setOpen(false);
          // setReportOfficer("");
          // setMandate("");
          // window.location.reload()
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Ar1EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully updated");
        
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleTake=(e)=>{
    setReportOfficer(e.target.value);
    setReportOfficerErr(false);
    
  }
  const handleTeke=(e)=>{
    setMandate(e.target.value);
    setMandateErr(false);
   
  }
  console.log(id,"crct");

  const formValidateFun = () => {
    if (!reportOfficer) {
      setReportOfficerErr(true);
      document.getElementById("reportOfficer").focus();
    } else if (!mandate) {
      setMandateErr(true);
      document.getElementById("mandate").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <>
       {/* <ComponentLoader isLoading={true} /> */}
      <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
        <div className="master-main d-flex">
          <div className="head-icon">
            <Link to="/GeneralAR">
              <HomeIcon style={{ color: "white", marginLeft: 38 }} />
            </Link>
          </div>
          <div className="master-main-span">
            <span> A1a. Institution Info</span>
          </div>
        </div>
        <div className="tables-main">
        <div style={{ marginLeft: 20 }}>
          <button className="tables-btn" onClick={Previousbutton}>
            <div className="sidebar-icon" />
            Previous
          </button>
        </div>
          <div style={{ marginRight: 20 }}>
            <button className="tables-btn" onClick={nextButton}>
              Next
              <div className="sidebar-icon" />
            </button>
          </div>
        </div>
        <div className="table-container-grid">
          <div className="tables-mains">
            <div className="table-head">
              <div>
               
              </div>
             
            </div>
          </div>
          <>
            <div>
              <div>
                <div>
                  <table className="table tables">
                    <tbody>
                      {/* <tr>
                        <td scope="row" className="th-row">
                          Name of the College / Department / Research Station / KVK
                        </td>
                        <td className="table-size" style={{ width: "20px", fontSize: "18px", fontWeight: "bold" }}
                          scope="row">                      
                          <input type="text" name="collegeName" />
                        </td>
                      </tr> */}
                      <tr>
                        <td
                          className="table-size"
                          style={{
                            width: "20px",
                            fontSize: "18px",
                            // fontWeight: "bold",
                          }}
                          scope="row"
                        >
                          {" "}
                          Name of the Reporting Officer
                        </td>
                        <td className="th-row3">
                          <input
                            error={reportOfficerErr}
                            value={reportOfficer}
                            id="reportOfficer"
                            style={{ width: "100%" }}
                            type="text"
                            name="reportingOfficer"
                            // value={reportOfficer}
                            disabled={role === "Director" ? true : false}
                            onChange={(e) => {
                              handleTake(e)
                             
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="table-size"
                          style={{
                            width: "20px",
                            fontSize: "18px",
                            // fontWeight: "bold",
                          }}
                          scope="row"
                        >
                          {" "}
                          Mandate(Max 500 Character)
                        </td>
                        <td className="th-row3">
                          <input
                            error={mandateErr}
                            value={mandate}
                            id="mandate"
                            style={{ width: "100%" }}
                            type="text"
                            name="mandate"
                            disabled={role === "Director" ? true : false}
                            // value={mandate}
                            onChange={(e) => {
                              handleTeke(e)
                              
                            }}
                          />
                        </td>
                      </tr>

                  
                    </tbody>
                  </table>
                  <div style={{ justifyContent: "end" }} className="from-last">
                    <button
                      type="submit"
                      style={{ paddingTop: "5px" }}
                      className="sbmit-buttons"
                      onClick={formValidateFun}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default ArA1ae;
