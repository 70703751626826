import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Autocomplete,
  Button,
  IconButton,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { A1VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import "./A1Inside.css";
import { OpacityRounded } from "@material-ui/icons";
import ComponentLoader from "../../../../ComponentLoader";

function A1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  // const [dropcourse, setDropCourse] = useState([]);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true);
  const [courseNumber, setCourseNumber] = useState(null);

  const [courseId, setCourseId] = useState(null);

  const [courseDetails, setCourseDetails] = useState(null);
  const [courseDetailsId, setCourseDetailsId] = useState("");
  
  const [dropcourse, setDropcourse] = useState([]);
  const [dropcoursetype, setDropcoursetype] = useState("");
  const [courseTeacher, setCourseTeacher] = useState("");
  const [classcontucted, setclasscontucted] = useState("");
  const [courseNo, setcourseNo] = useState("");
  const [hours, sethours] = useState("");

  const [courseDetailsErr, setCourseDetailsErr] = useState(false);
  const [dropcourseErr, setDropcourseErr] = useState(false);
  const [dropcoursetypeErr, setDropcoursetypeErr] = useState(false);
  const [courseTeacherErr, setCourseTeacherErr] = useState(false);
  const [classcontuctedErr, setclasscontuctedErr] = useState(false);

  const [value, setValues] = useState();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [courseType, setCourseType] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [hourss, sethourss] = useState("");
  const [dropnature, setDropnature] = useState([]);
  const [dropnatcoching, setDropnatcoching] = useState([]);
  const [Lovrole, setLovrole] = useState([]);

  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();
  // console.log(TrnMonthlyReport);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  // const customStyle = {
  //   minheight: '20px', // Adjust this value to control the maximum height
  // };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(A1VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      handleNaturetypes(tok);
      handleCoursetypes(tok);
      console.log(location.state.trnMonthlyReport);
      if (location.state.TrnMonthlyReport) {
        console.log(location.state.TrnMonthlyReport);
        setTrnMonthlyReport(location.state.TrnMonthlyReport);
      } else {
        edit(location.state);
      }
    }
    head("Courses handled");
  }, []);

  const edit = (item) => {
    const tok = localStorage.getItem("token");
    setDropcourse(item.courseName);
    setDropcoursetype(item.courseType);
    setCourseTeacher(item.courseRole);
    setclasscontucted(item.noOfClassTP);
    setId(item.id);
    setCourseDetailsId(item.courseId);
  };

  useEffect(() => {
    console.log("========== ", options);
    options.forEach((item) => {
      console.log(item);
    });
    const getValue = options.find((data) => data.id === courseDetailsId);
    setCourseDetails(getValue);
  }, [dropcourse, courseDetailsId]);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const backs = () => {
    navigate("/A1");
  };

  const reload = () => {
    window.location.reload();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov
  const handleLov = async (token, CourseRole) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseRole"}`,
        {
          headers: h,
        }
      );
      console.log(data); setLoader(false);
      setLovrole(data);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  //dropdown
  const handleCoursetypes = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcourse(data);
      setLoader(false);
      setOptions(data);
      // setCourseId(1);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      CourseId: courseDetails.id,
      CourseType: dropcoursetype,
      CourseNumber: courseDetails.courseNo,
      CourseRole: courseTeacher,
      Hours: courseDetails.hours,
      NoOfClassTP: classcontucted,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    console.log(d);
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A1SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/A1");
          // toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A1EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/A1");
         
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleNaturetypes = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnatcoching(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleDialog = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      CourseNo: courseNo,
      CourseName: CourseName,
      // CourseType: courseType,
      Hours: hourss,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COURSESAVEBUT}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data.result == "Success") {
        alert("Successfully Submitted");
        reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formValidateFun = () => {
    console.log("================ ", dropcoursetype);
    if (!courseDetails || Object.keys(courseDetails).length === 0) {
      setCourseDetailsErr(true);
      document.getElementById("dropcourse").focus();
    } else if (!dropcoursetype) {
      setDropcoursetypeErr(true);
      document.getElementById("dropcoursetype").focus();
    } else if (!courseTeacher) {
      setCourseTeacherErr(true);
      document.getElementById("courseTeacher").focus();
    } else if (!classcontucted) {
      setclasscontuctedErr(true);
      document.getElementById("classcontucted").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>Course</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon
                  className="dialogue_close_icon"
                  style={{ backgroundColor: "black" }}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course No"
                  value={courseNo}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setcourseNo(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course Name"
                  variant="outlined"
                  size="small"
                  value={CourseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Hours"
                  variant="outlined"
                  size="small"
                  value={hourss}
                  onChange={(e) => sethourss(e.target.value)}
                />
              </div>

              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            {/* <button className="Backclose" type="submit" onClick={handleClose}>
              Back
            </button> */}
            <button type="submit" onClick={handleDialog}>
              Submit
            </button>
          </DialogActions>
        </Dialog>

        <div className="master-main-span">
          <span>(A1) Course Handling</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            A1. Diploma / UG / PG / Ph.D. Courses handled
          </h5>

          <div className="d-flex">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              // onChange={(e, val) => courseAutocompleteOnchange(val)}
              onChange={(e,val)=>{
                setCourseDetails(val);
                setCourseDetailsErr(false);
              }}
              getOptionLabel={(option) =>
                `${option.courseNo} - ${option.courseName} - ${option.hours}`
              }
              value={courseDetails}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Course"
                  error={courseDetailsErr}
                />
              )}
            />
            {/* 
            <FormControl
              style={{ width: "94%", margin: 12 }}
              id="dropcourse"
              error={dropcourseErr}
            >
              <Autocomplete
                id="demo-simple-select"
                options={options || []}
                getOptionLabel={(option) =>
                  `${option.courseNo} - ${option.courseName} - ${option.hours}`
                }
                filterOptions={(options, state) => {
                  const filteredOptions = options.filter(
                    (option) =>
                      option.courseName
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option.courseNo
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option.hours
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  );
                  return filteredOptions;
                }}
                // onChange={handleChange}
                // value={courseType}
                // onChange={(e) => setCourseType(e.target.value)}
                onChange={(e) => {
                  setDropcourse(e.target.value);
                  setDropcourseErr(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Course"
                    size="small"
                    sx={{ width: "100%" }}
                    error={errors.CourseIdV ? true : false}
                    helperText={errors.CourseIdV?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.id} value={option}>
                    {option.courseNo} - {option.courseName} - {option.hours}
                  </MenuItem>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <MenuItem key={option.id} {...getTagProps({ index })}>
                      {option.courseNo} - {option.courseName} - {option.hours}
                    </MenuItem>
                  ))
                }
              />
            </FormControl> */}
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={dropcoursetypeErr}
            id="dropcoursetype"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Course Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Course Type"
              size="small"
              sx={{ width: "100%" }}
              value={dropcoursetype}
              onChange={(e) => {
                setDropcoursetype(e.target.value);
                setDropcoursetypeErr(false);
              }}
            >
              {dropnatcoching?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
            error={courseTeacherErr}
            id="courseTeacher"
            label="Course Teacher"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={courseTeacher}
            onChange={(e) => {
              setCourseTeacher(e.target.value);
              setCourseTeacherErr(false);
            }}
          /> */}
          <FormControl style={{ width: "48%", margin: 12 }} error={courseTeacherErr} id="programtype">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={courseTeacher}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setCourseTeacher(e.target.value)
                setCourseTeacherErr(false)
              }}
            >
              {Lovrole?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={classcontuctedErr}
            id="classcontucted"
            label="Classes Conducted"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={classcontucted}
            onChange={(e) => {
              setclasscontucted(e.target.value);
              setclasscontuctedErr(false);
            }}
          />
          

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              // onClick={handleLeavepage}
              type="submit"
              className="sbmit-buttons"
              onClick={() => formValidateFun()}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default A1Inside;
