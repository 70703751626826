import { useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";

import axios from "axios";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toastError, toastSuccess } from "../../utils/toast";
import ComponentLoader from "../../ComponentLoader";

const InstitudeInside = () => {

  const [openReset, setOpenReset] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  // const [resetPassword, setResetPassword] = useState("");

  const [token, setToken] = useState("");

  const [code, setCode] = useState("");
  const [PrincipalName, setPrincipalName] = useState("");
  const [principalemail, setPrincipalEmail] = useState("");
  const [directordepartmentname, setDirectordeepartmentname] = useState("");
  const [principalmobileno, setPrincipalMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [position, setPosition] = useState("");

  const [codeError, setCodeError] = useState(false)
  const [instituteNameerror, setInstituteNameError] = useState(false);
  const [PrincipalNameerror, setPrincipalNameError] = useState(false);
  const [principalemailerror, setPrincipalEmailError] = useState(false);
  const [principalmobilenoerror, setPrincipalMobileNoError] = useState(false);
  const [passworderror, setPasswordError] = useState(false);
  const [departmentErr, SetDepartmenterr] = useState(false);
  const [positionErr, setPositionErr] = useState(false);

  const [resetPassword, setResetPassword] = useState("");
  const [resetpasswordErr, setResetPasswordErr] = useState("")
  const [loader, setLoader] = useState(true)

  const [depatdirector, setDepatdirector] = useState([])

  const [id, setId] = useState(0);


  const minLength = 6;

  const location = useLocation();
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      //   handleInstitute(tok);
      handleDepartmentName(tok);
     
      if (location.state) {
        edit(location.state);
      }
    }
    // head("Institute");
  }, []);


  const handleResetOpen = () => {
    setOpenReset(true);
  };

  const handleResetClose = () => {
    setOpenReset(false);
  };
  const navigate = useNavigate();


  const handleBack = () => {
    navigate("/institute")
  }

  const edit = (item) => {
    // setOpen(true);
    setShowSubmitButton(true);


    setInstituteName(item.instituteName);
    setPrincipalName(item.principalName);
    setPassword(item.password);
    setPrincipalEmail(item.principalEmail);
    setPrincipalMobileNo(item.principalMobileNo);
    setDirectordeepartmentname(item.directorateId)
    setId(item.id);
    setPosition(item.position);
    setCode(item.code);
    // setValids(Instituteexss);
  }


  const handleDepartmentName = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.DIRECTORATE}`, {
        headers: h,
      });
      console.log(data);
      setDepatdirector(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };


  const handleResetPassword = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      username: principalemail,
      password: resetPassword
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.RESTPASSWORD}`,
        d,
        { headers: h }
      );
      console.log(data); setLoader(false)
      if (data.result == "Password Reset Successfully.") {
        toastSuccess("Successfully Reset");
        navigate("/institute")

        setOpenReset(false)
        // setTimeout(function () {
        //   window.location.reload()
        //   navigate("/institute")
        // }, 5000);
      } else {
        toastError(data.errorMsgs[0])
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handlePopupInstitute = async () => {



    console.log(token);


    if (id === 0) {

      const h = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };


      const d = {
        id,
        InstituteName: instituteName,
        PrincipalName: PrincipalName,
        PrincipalEmail: principalemail,
        PrincipalMobileNo: principalmobileno,
        Position: parseInt(position),
        PassWord: password,
        Code: code,
        DirectorateId: parseInt(directordepartmentname)
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INSTITUTEADD}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          toastSuccess("Successfully Submitted");
          setId(0);
          navigate("/institute")
          //   setOpen(false);
          //   reload()
          //   handleInstitute(token);
        } else {
          alert("Please Contact to Technology Partner");
        }
      } catch (error) {
        console.log(error);
      }
    } else {

      const h = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };


      const d = {
        id,
        InstituteName: instituteName,
        PrincipalName: PrincipalName,
        PrincipalEmail: principalemail,
        PrincipalMobileNo: principalmobileno,
        Position: parseInt(position),
        Code: code,
        DirectorateId: parseInt(directordepartmentname)
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INSTITUTEUPDATE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          toastSuccess("Successfully updated");
          setId(0);
          navigate("/institute")
          //   setOpen(false);
          //   handleInstitute(token);
        }
      } catch (error) {
        console.log(error);
      }
    }
    // setValids(Instituteex);
  };

  const formValidateFun = () => {


    console.log("code");
    if (!code) {
      setCodeError(true);
      document.getElementById("code").focus();
    } else if (!instituteName) {
      setInstituteNameError(true);
      document.getElementById("instituteName").focus();
    }
    else if (!PrincipalName) {
      setPrincipalNameError(true);
      document.getElementById("PrincipalName").focus();
    } else if (!principalemail) {
      setPrincipalEmailError(true);
      document.getElementById("principalemail").focus();
    } else if (!principalmobileno) {
      setPrincipalMobileNoError(true);
      document.getElementById("PrincipalMobileNo").focus();
    }
    else if (!directordepartmentname) {
      SetDepartmenterr(true);
      document.getElementById("directordepartmentname").focus();
    }
    else if (!position) {
      setPositionErr(true)
      document.getElementById("position").focus();
    }

    else if (id === 0 && !password) {
      setPasswordError(true);
      document.getElementById("password").focus();
    }
    else {
      handlePopupInstitute();
    }

  };


  return (
    <div style={{ marginTop: 70 }}>
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">

        <div className="master-main-span">
          <span>Institude </span>
        </div>
      </div>

      <Dialog
        open={openReset}
        onClose={handleResetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h5 style={{ color: "#fff", fontSize: "15px" }}>
            Reset Password
          </h5>
        </DialogTitle>
        <DialogContent style={{ padding: "30px" }}>
          {/* {id > 0 ? null : ( */}

          <TextField
            //   error={passworderror}
            fullWidth
            id="resetPassword"
            label="Reset Password"
            //   InputProps={{
            //     inputProps: {
            //       maxLength: minLength,
            //     },
            //   }}
            variant="outlined"
            size="small"
            //   value={resetpasswordErr}
            onChange={(e) => {
              setResetPassword(e.target.value);
              // setResetPasswordErr(false);
            }}
          />

          {/* )} */}
        </DialogContent>
        <div
          className="dialogue_action_button"
          style={{ justifyContent: "center" }}
        >
          <button type="submit" onClick={handleResetPassword}>
            Submit
          </button>
        </div>
      </Dialog>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}> Institude</h5>



          <TextField
            fullWidth
            id="outlined-basic"
            style={{ margin: "10px", width: "46%" }}
            label="Code"
            variant="outlined"
            size="small"
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
              setCodeError(false)
            }
            }
            // {...register("Icode")}
            error={codeError}
          // helperText={errors.Icode?.message}
          />



          <TextField
            fullWidth
            id="outlined-basic"
            label=" Institute"
            style={{ margin: "10px", width: "46%" }}
            variant="outlined"
            size="small"
            value={instituteName}
            onChange={(e) => {
              setInstituteName(e.target.value)
              setInstituteNameError(false)
            }
            }
            // {...register("Insname")}
            error={instituteNameerror}
          // helperText={errors.Insname?.message}
          />
          <span style={{ color: "red", fontSize: 10 }}>
            {instituteNameerror}
          </span>



          <TextField
            fullWidth
            id="outlined-basic"
            label="Institute Head Name"
            variant="outlined"
            style={{ margin: "10px", width: "46%" }}
            size="small"
            onChange={(e) => {
              setPrincipalName(e.target.value)
              setPrincipalNameError(false)
            }
            }
            // {...register("Prinname")}
            error={PrincipalNameerror}
            value={PrincipalName}
          // helperText={errors.Prinname?.message}
          />
          {/* <span style={{ color: "red", fontSize: 10 }}>
                    {PrincipalNameerror}
                  </span> */}



          <TextField
            fullWidth
            id="outlined-basic"
            label="Email Id"
            variant="outlined"
            style={{ margin: "10px", width: "46%" }}
            size="small"
            autoComplete="off"
            disabled={id > 0 ? true : false}
            onChange={(e) => {
              setPrincipalEmail(e.target.value)
              setPrincipalEmailError(false)
            }}
            value={principalemail}
            // {...register("Prinemail")}
            error={principalemailerror}
          // helperText={errors.Prinemail?.message}
          />
          {/* <span style={{ color: "red", fontSize: 10 }}>
                    {principalemailerror}
                  </span> */}



          <TextField
            fullWidth
            id="outlined-basic"
            label="Mobile No"
            variant="outlined"
            style={{ margin: "10px", width: "46%" }}
            size="small"
            value={principalmobileno}
            onChange={(e) => {
              setPrincipalMobileNo(e.target.value)
              setPrincipalMobileNoError(false)
            }}
            // {...register("Prinemobileno")}
            error={principalmobilenoerror}
          // helperText={errors.Prinemobileno?.message}
          />
          {/* <span style={{ color: "red", fontSize: 10 }}>
                    {principalmobilenoerror}
                  </span> */}


          {/* <div className="box width-40"> */}
          <FormControl
            style={{ margin: "10px", width: "46%" }}
            id="depadtmentname"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Director Department Name
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              style={{ height: "45px" }}
              value={directordepartmentname}
              onChange={(e) => {
                setDirectordeepartmentname(e.target.value)
                SetDepartmenterr(false)
              }}
              // {...register("Directordepartname")}
              error={departmentErr}
            // helperText={errors.Directordepartname?.message}
            >
              {depatdirector?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.departmentName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* </div> */}

          <TextField
            fullWidth
            id="outlined-basic"
            label="Position"
            style={{ margin: "10px", width: "46%" }}
            autoComplete="off"
            variant="outlined"
            size="small"
            value={position}
            onChange={(e) => {
              setPosition(e.target.value)
              setPositionErr(false)
            }
            }

            error={positionErr}

          ></TextField>

          {id > 0 ? null : (

            <TextField
              fullWidth
              id="outlined-basic"
              label="password"
              style={{ margin: "10px", width: "46%" }}
              type="Password"
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              variant="outlined"
              size="small"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
                setPasswordError(false)
              }
              }

              error={passworderror}

            ></TextField>

          )}

          <div
            className="dialogue_action_button"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <button
                style={{ backgroundColor: "#0abb87", height: "20px", padding: "15px", borderRadius: "5px", color: "#fff" }}
                onClick={handleBack}
              >
                Back
              </button>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {showSubmitButton && (
                <button
                  type="submit"
                  onClick={handleResetOpen}
                  style={{ width: "auto" }}
                >
                  Reset Password
                </button>
              )}

              <button type="submit" onClick={formValidateFun}>
                Submit
              </button>

            </div>
            {/* </div> */}

          </div>
        </div>

      </div>
    </div>
  )

}

export default InstitudeInside;