import { useEffect, useState } from "react";


import axios from "axios";
import HomeIcon from "@mui/icons-material/Home";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import ComponentLoader from "../../../ComponentLoader";




const FacultyProfile = () => {

  const [Idname, setIdname] = useState([]);
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate();
  console.log(Idname);
  useEffect(() => {
    const tok = localStorage.getItem("token");
    const userID = localStorage.getItem("loginId");
    handleInvestigatorIdMapName(tok, userID)
  }, [])

  const handleInvestigatorIdMapName = async (token, id) => {
    console.log(token, id);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(id);
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.MY_PROFILE}${id}`,
        {
          headers: h,
        }
      );
      console.log(data); setLoader(false)
      setIdname(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  //   Dashboard

  return (
    <div  style={{marginTop:120}}>
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/Dashboard">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>

        <div className="master-main-span">
          <span> My Profile </span>
        </div>
      </div>


     
      <div className="profile-data-all">
      <h5 style={{ margin: 20 }}> {Idname?.investigatorName} </h5><hr></hr>
        <div className="profile-data-list">
        
          <div className="profile-data-show">
            <div className="profile-heading">Investigator Name</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.investigatorName}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Qualification</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.qualification}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Discipline</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.discipline}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Designation</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.designation}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Address</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.address}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Doj</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.doj}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Institute</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.instituteName}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Department Name</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.departmentName}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Email Id</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.emailId}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Mobile No </div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.mobileNo}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Specialization</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.specialization}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">UPFCPF NO </div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.upfcpfNo}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Google Scholar ID</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.googleScholarID}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">IRINS ID </div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.irinsid}</div>
          </div>

          <div className="profile-data-show">
            <div className="profile-heading">Position</div>
            <div className="profile-sympol">:</div>
            <div className="profile-name">{Idname?.position}</div>
          </div>

        </div>
      </div>
    </div>
  );
}



export default FacultyProfile;