import React from "react";
import MenuIcon from "@mui/icons-material/Menu";

import { BsFillBuildingsFill, BsBank, BsFillRouterFill } from "react-icons/bs";
import { BiBasket } from "react-icons/bi";
import { GrProductHunt, GrChannel } from "react-icons/gr";
import {
  FaStore,
  FaUserAlt,
  FaStoreAlt,
  FaHandshake,
  FaHandsHelping,
  FaLocationArrow,
  FaHome,
} from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { CiLocationOn } from "react-icons/ci";
import { GiMechanicalArm } from "react-icons/gi";
import { TbReceiptTax, TbBrandReason } from "react-icons/tb";
import {
  MdAccountBalance,
  MdWifiChannel,
  MdScheduleSend,
} from "react-icons/md";
import { SiBrandfolder, SiLevelsdotfyi } from "react-icons/si";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import { useLocation } from "react-router-dom";
import { AiOutlineAlipay } from "react-icons/ai";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import WorkIcon from '@mui/icons-material/Work';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReportIcon from '@mui/icons-material/Report';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

// ,GrProductHunt,FaStore,GrLocationCiLocationOn
// role:["superadmin","admin","manufactureradmin","distributoradmin"]
const SidebarData = [

  // {
  //   title: "Home",
  //   path: "/master/master",
  //   icons: <FaHome className="sidebar-icon" />,
  //   cname: "droplist",
  //   name: "Welcome To TNAUMS",
  //   role: ["superadmin", "manufactureradmin", "distributoradmin"],
  // },
  // {
  //   title: "Directorate",
  //   path: "/directorate",
  //   icons: < FaHandshake className="sidebar-icon" />,
  //   cname: "droplist",
  //   name: "Directorate",
  //   role: ["manufactureradmin", "distributoradmin"],
  // },
  // {
  //   title: "Institute",
  //   path: "/institute",
  //   icons: <AssuredWorkloadIcon className="sidebar-icon" />,
  //   cname: "droplist",
  //   name: "Institute",
  //   role: ["superadmin", "distributoradmin", "manufactureradmin"],
    
  // },
  // {
  //   title: "Investigator",
  //   path: "/investigator",
  //   icons: <BedroomParentIcon className="sidebar-icon" />,
  //   cname: "droplist",
  //   name: "Investigator",
  //   role: ["superadmin","manufactureradmin"],
    
  // },
  // {
  //   title: "Leave",
  //   path: "/leave",
  //   icons: <ExitToAppIcon className="sidebar-icon" />,
  //   cname: "droplist",
  //   name: "Leave",
  //   role: ["superadmin","manufactureradmin"],
    
  // },

  // {
  //   title: "MasterOnDuty",
  //   path: "/masterOnDuty",
  //   icons: <MdScheduleSend className="sidebar-icon" />,
  //   cname: "droplist",
  //   name: "MasterOnDuty",
  //   role: ["manufactureradmin"],
  // },
  // {
  //   title: "Transactionleave",
  //   // title2:"Master Data Management",
  //   path: "transactionleave",
  //   icons: <AssuredWorkloadIcon className="sidebar-icon" />,
  //   cname: "droplist",
  //   name: "Transactionleave",
  //   role: ["manufactureradmin", "distributoradmin"],
  // },
  //   children: [
      // {
      //   title: "TransactionOnduty",
      //   path: "/transactionOnduty",
      //   icons: <WorkIcon className="sidebar-icon" />,
      //   cname: "droplist",
      //   name: "TransactionOnduty",
      //   role: ["manufactureradmin", "distributoradmin"],
      // },
      // {
      //   title: "TransactionTsms",
      //   path: "/TransactionTsms",
      //   icons: <BsBank className="sidebar-icon" />,
      //   cname: "droplist",
      //   name: "TransactionTsms",
      //   role: ["manufactureradmin", "distributoradmin"],
      // },
      {
        title: "MonthyReport",
        path: "/monthyReport",
        icons: <AccountCircleIcon className="sidebar-icon" />,
        cname: "droplist",
        name: "Monthly Report",
        role: ["manufactureradmin", "distributoradmin"],
      },

    
      // {
      //   title: "ManthyReport",
      //   path: "/ManthyReport",
      //   icons:  <ReportIcon className="sidebar-icon" />,
      //   cname: "droplist",
      //   name: "General",
      //   role: ["manufactureradmin", "distributoradmin"],
      // },


      {
        title: "MasterData",
        path: "/MasterData",
        icons:  <BsBank className="sidebar-icon" />,
        cname: "droplist",
        name: "General",
        role: ["manufactureradmin", "distributoradmin"],
      },

      
      {
        title: "Report",
        path: "/Report",
        icons:  <ReportGmailerrorredIcon className="sidebar-icon" />,
        cname: "droplist",
        name: "General",
        role: ["manufactureradmin", "distributoradmin"],
      },
      // {
      //   title: "Studentguides",
      //   path: "/Studentguides",
      //   icons:  <ReportGmailerrorredIcon className="sidebar-icon" />,
      //   cname: "droplist",
      //   name: "General",
      //   role: ["manufactureradmin", "distributoradmin"],

      // },

      // {
      //   title: "StudentDetalis",
      //   path: "/StudentDetalis",
      //   icons: <AccountCircleIcon className="sidebar-icon" />,
      //   cname: "droplist",
      //   name: "General",
      //   role: ["manufactureradmin", "distributoradmin"],
      // },

    // ],
//   },
 ];

export default SidebarData;
