import * as React from 'react';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';




const data = {
  id: "root",
  name: "Reports",
  key:"",
  child: [
    // {
    //   id:"Dd",
    //   name:"Dashboard",
    //   key:"DB"
    // },
    {
      id:"Fac",
      name: "Faculty",
      key:"",
      child: [
        {
          id:"Teach",
          name:"Teaching Staff",
          key: "Tstaff",
          RptType:"Teachingstaff"
        },
        {
          id:"Non",
          name:"Non Teaching Staff",
          key: "Nstaff",
          RptType:"Nonteachingstaff"
        },
      ]
    },
    {
      id: "Pub",
      name: "Publications",
      key:"",
      child: [
        {
          id: "Pub01",
          name: "Research article with NASS rating > 6",
          key:"NAAS",
          RptType:"Rating>6"
        },
        {
          id: "Pub02",
          name: "Research article with NASS rating < 6",
          key:"NAAS",
          RptType:"Rating<6"
        },
        {
          id: "Pub03",
          name: "Books with ISBN",
          key:"pub",
          ISBN:"BookWithISBN"
        },
        {
          id: "Pub04",
          name: "Books without ISBN",
          key:"pub",
          ISBN:"BookWithoutISBN"
        },
        {
          id: "Pub05",
          name: "Book chapters with ISBN",
          key:"pub",
          ISBN:"ChapterWithISBN"
        },
        {
          id: "Pub06",
          name: "Book chapters without ISBN",
          key:"pub",
          ISBN:"ChapterWithoutISBN"
        }
      ]
    },
    {
      id: "RS",
      name: "Research",
      key:"",
      child: [
        {
          id: "RS01",
          name: "Ongoing",
          key:"res",
          Ongoing:"All",
          child: [
            {
              id: "RS011",
              name: "URP",
              key:"res",
              Ongoing:"University Research Project",
            },
            {
              id: "RS012",
              name: "Externally funded project",
              key:"res",
              Ongoing:"Externally funded project",
            },
            {
              id: "RS013",
              name: "AICRP",
              key:"res",
              Ongoing:"All India Coordinated Research Project",
            },
            {
              id: "RS014",
              name: "VCS",
              key:"res",
              Ongoing:"Venture Capital Scheme",
            },
            {
              id: "RS015",
              name: "RF",
              key:"res",
              Ongoing:"Revolving Fund Scheme",
            },
            {
              id: "RS016",
              name: "NADP",
              key:"res",
              Ongoing:"National Agricultural Development Programme",
            }
          ]
        },
        {
          id: "PRS02",
          name: "Completed",
          key:"complete"
          
        },
        {
          id: "RS03",
          name: "New projects proposed",
          key:"NewProjects"
         
        }
      ]
    },{
      id:"A1",
      name:"Course Handling",
      key:"CH"
    },
    {
      id:"A5",
      name:"Students Guidance",
      key:"SG"
    },
    {
      id:"B7",
      name:"Patent Registration",
      key:"PR"
    },
    
    {
      id:"B9",
      name:"MoU Signed",
      key:"MS"
    },
    {
      id:"C1",
      name:"Training And Meeting Organized",
      key:"TMO"
    },
    {
      id:"C2",
      name:"Training And Meeting Attended",
      key:"TMA"
    },
    {
      id:"C3",
      name:"OFT/FLD/FS Organized",
      key:"OFF"
    },
    {
      id:"D2",
      name:"Additional Responsibilities",
      key:"D2AR"
    },
    {
      id:"D3",
      name:"Academic Responsibilities",
      key:"D3AR"
    },
    {
      id:"D4",
      name:"Audit",
      key:"ADUIT"
    },
    {
      id:"D5",
      name:"Budget & Expenditure",
      key:"BUGEXP"
    },
    {
      id:"D6",
      name:"Income",
      key:"income"
    },
  ]
}


export default function RichObjectTreeView({handleTree}) {

 
  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} onClick={()=>handleTree(nodes)} className='tree-odd'>
      {Array.isArray(nodes.child)
        ? nodes.child.map((node) => renderTree(node))
        : null}
    </TreeItem>
  )

  return (
    <Box sx={{ minHeight: 110, flexGrow: 1, maxWidth: 600 }}>
      <TreeView
      className='tree-font'
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {renderTree(data)}
      </TreeView>
    </Box>
  );
}
