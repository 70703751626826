import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { D6VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import ComponentLoader from "../../../../ComponentLoader";

function D6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true);
  const [Studentbtn, setStudentbtn] = useState([]);

  const [Lov, setLov] = useState([]);

  const [nameoftheAward, setNameoftheAward] = useState("");
  const [awardedby, setAwardedby] = useState("");
  const [awardDate, setAwardDate] = useState("");
  const [Recongnition, setRecongnition] = useState("");

  const [nameoftheAwardErr, setNameoftheAwardErr] = useState(false);
  const [awardedbyErr, setAwardedbyErr] = useState(false);
  const [awardDateErr, setAwardDateErr] = useState(false);
  const [RecongnitionErr, setRecongnitionErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(D6VALI),
  });

  const backs = () => {
    navigate("/D6");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Fellowship");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);
  const edit = (item) => {
    console.log(item);
    setNameoftheAward(item.nameoftheAward);
    setAwardedby(item.awardedby);
    setAwardDate(moment(item.awardDate).format("YYYY-MM-DD"));
    setRecongnition(item.recognitionType);
    // setProjectstarttime(moment(item.ProjectStartTime).format("YYYY-MM-DD"))
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov
  const handleLov = async (token, RecognitionType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"RecognitionType"}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      setLov(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      NameoftheAward: nameoftheAward,
      Awardedby: awardedby,
      AwardDate: awardDate,
      RecognitionType:Recongnition,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.D6SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/D6");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.D6EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/D6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!nameoftheAward){
      setNameoftheAwardErr(true)
      document.getElementById("nameoftheAward").focus()
    }else if(!awardedby){
      setAwardedbyErr(true)
      document.getElementById("awardedby").focus()
    }else if(!awardDate){
      setAwardDateErr(true)
      document.getElementById("awardDate").focus()
    }else if(!Recongnition){
      setRecongnitionErr(true)
      document.getElementById("Recongnition").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(D6). Fellowship</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}> D6. Fellowship</h5>

          <TextField
            error={nameoftheAwardErr}
            id="nameoftheAward"
            label=" Name of the Award /Recognition / Fellowship"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={nameoftheAward}
            onChange={(e) => {
              setNameoftheAward(e.target.value)
              setNameoftheAwardErr(false)
            }}
          />

          <TextField
            error={awardedbyErr}
            id="awardedby"
            label="Awarded By"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={awardedby}
            onChange={(e) => {
              setAwardedby(e.target.value)
              setAwardedbyErr(false)
            }}
          />

          {/* <TextField
            id="outlined-basic"
            label="Award Date"
            type="date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("AwardDatev")}
            error={errors.AwardDatev ? true : false}
            helperText={errors.AwardDatev?.message}
          /> */}

          <TextField
            error={awardDateErr}
            id="awardDate"
            label="Award Date / dd-mm-yyyy"
            // type="date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={awardDate}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // InputProps={{
            //   placeholder: "",
            // }}
            onChange={(e) => {
              setAwardDate(e.target.value)
              setAwardDateErr(false)
            }}
          />

          <FormControl style={{ width: "48%", margin: 12 }} error={RecongnitionErr} id="Recongnition">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Recognition Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={Recongnition}
              label="Programme Type"
              size="small"
              // sx={{ width: "100%" }}
              // style={{ margin: "10px", width: "48%" }}
              onChange={(e) => {
                setRecongnition(e.target.value)
                setRecongnitionErr(false)
              }}
            >
              {Lov?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "18px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={()=>formValidateFun()} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </div>
    </div>
  );
}

export default D6Inside;
