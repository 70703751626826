import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Navigate, useNavigate } from "react-router-dom";

// import { masonduty } from '../Validation';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { masonduty } from "../Validation";
import ComponentLoader from "../../ComponentLoader";

// import './App.css'

function MasterOnDuty({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/MasterData");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [loader,setLoader] =useState(true)

  // const [code,setCode] =useState("");
  const [leavename, setLeaveName] = useState("");

  const [code, setCode] = useState("");
  const [tourOrDutyName, setTourOrDutyName] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(masonduty),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
    }
    head("Master Onduty");
  }, []);

  //    ***************************************************************

  function createData(code, Lname, option) {
    return { code, Lname, option };
  }

  const rows = [
    //     createData('CL', 'Casual Leave', 'Edit'),
    //     createData('SL', 'Sick Leave', 'Edit'),
  ];

  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setId(item.id);
    setValue("Leavecode", item.code);
    setValue("LeaveNamess", item.instituteName);

    setCode(item.code);
    setTourOrDutyName(item.tourOrDutyName);
  };

  const handleOnDutypage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      Code: code,
      TourOrDutyName: tourOrDutyName,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ONDUTYSAVE}`,
        d,
        { headers: h }
      );
      console.log(data);setLoader(false)
      // setDirect(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.ONDUTYS}`, {
        headers: h,
      });
      console.log(data);
      setDirect(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleClickSubmit = () => {
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  return (
    <div className="table-container-grid">
      <ComponentLoader isLoading={loader} />
      <div className="table-head">
        <div>
          <button className="top-butto" onClick={backButton}>
            <ArrowBackIcon
              style={{ marginBottom: -5 }}
              className="sidebar-icon"
            />
            Back
          </button>
        </div>
        <div>
          <button className="top-button" onClick={handleClickOpen}>
            + New
          </button>
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit(handleOnDutypage),
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="dialogueTittle">
            <div>
              <h5>masonduty</h5>
            </div>
            <div onClick={handleClose}>
              <CloseIcon className="dialogue_close_icon" />
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="Dialog-box" style={{ padding: 0 }}>
          <div className="manu-form ">
            <div className="box width-50">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Code"
                value={code}
                variant="outlined"
                size="small"
                onChange={(e) => setCode(e.target.value)}

                //   {...register("ondutycode")}
                //   error={errors.ondutycode ? true : false}
                //   helperText={errors.ondutycode?.message}
              />
            </div>

            <div className="box width-50">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Leave Name"
                value={tourOrDutyName}
                variant="outlined"
                size="small"
                onChange={(e) => setTourOrDutyName(e.target.value)}

                // onChange={(e) => setCode(e.target.value)}
                //             {...register("Icode")}
                //             error={errors.Icode ? true : false}
                //             helperText={errors.Icode?.message}

                // {...register("ondutynotes")}
                // error={errors.ondutynotes ? true : false}
                // helperText={errors.ondutynotes?.message}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="button">
          <button variant="outlined" onClick={handleClose} className="btn2">
            Deactivate
          </button>
          <button
            variant="outlined"
            onClick={handleOnDutypage}
            className="btn1"
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>

      {/* ************************************************************************* */}

      <TableContainer sx={tableContainerStyle}>
        <Table
          sx={tableStyle}
          aria-labelledby="tableTitle"
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead className="headtable">
            <TableCell align="right">Code</TableCell>
            <TableCell align="right">Onduty Name</TableCell>
            <TableCell align="right">Option</TableCell>
          </TableHead>

          <TableBody>
            {direct.map((row) => (
              <TableRow
                key={row.Leave}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right"> {row.code}</TableCell>
                <TableCell align="right">{row.tourOrDutyName}</TableCell>

                <TableCell align="right">
                  <button
                    className="btns"
                    variant="outlined"
                    onClick={handleClickSubmit}
                  >
                    {<Edit className="sidebar-icon" />}
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={direct.length}
        //   rowsPerPage={masterManufacturer.rowsPerPage}
        //   page={masterManufacturer.page}
        //   onPageChange={handleChangePage}
        //   onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
export default MasterOnDuty;
