import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
  FormLabel,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FormControl from "@mui/material/FormControl";
import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { A2VALI } from "../../../Validation";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../../ComponentLoader";

function A2Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [dropcourse, setDropcourse] = useState([]);

  const [courseNo, setCourseNo] = useState("");
  const [courseId, setCourseId] = useState("");
  const [hours, setHours] = useState("");
  const [hourss, sethourss] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();
  console.log(TrnMonthlyReport);

  const [CourseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [dropcoursetype, setDropcoursetype] = useState([]);
  const [dropnature, setDropnature] = useState([]);
  const [loader,setLoader] =useState(true);
  const [options, setOptions] = useState([]);
  // Comman Id Send to set value
  const [courseDetailsId, setCourseDetailsId] = useState("");

  const [courseDetails, setCourseDetails] = useState(null);
  const [Noofslowlearners, setNoofslowlearners] = useState("");
  const [Noofweakness, setNoofweakness] = useState("");
  const [Additionalefforts, setAdditionalefforts] = useState("");
  const [Pass, setPass] = useState("");

  const [courseDetailsErr, setCourseDetailsErr] = useState(false);
  const [NoofslowlearnersErr, setNoofslowlearnersErr] = useState(false);
  const [NoofweaknessErr, setNoofweaknessErr] = useState(false);
  const [AdditionaleffortsErr, setAdditionaleffortsErr] = useState(false);
  const [PassErr, setPassErr] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A2VALI),
  });

  const backs = () => {
    navigate("/A2");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleNaturetypes(tok);
      handleCoursetypes(tok);
      console.log(location.state.trnMonthlyReport);
      if (location.state.TrnMonthlyReport) {
        console.log(location.state.TrnMonthlyReport);
        setTrnMonthlyReport(location.state.TrnMonthlyReport);
      } else {
        edit(location.state);
      }
    }
    head("Teaching");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setCourseDetailsId(item.courseId);
    setNoofslowlearners(item.noofslowlearners);
    setNoofweakness(item.noofweakness);
    setAdditionalefforts(item.additionalefforts);
    setPass(item.pass);
    setId(item.id);
  };

  useEffect(() => {
    console.log("========== ", options);
    options.forEach((item) => {
      console.log(item);
    });
    const getValue = options.find((data) => data.id === courseDetailsId);
    setCourseDetails(getValue);
  }, [dropcourse, courseDetailsId]);

  const reload = () => {
    window.location.reload();
  };

  // const btnclick = () => {
  //     alert("Succesfly Submited")
  //     window.location.reload()

  // }
  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };
  
  //dropdown

  const handleCoursetypes = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcoursetype(data); setLoader(false);
      setDropcourse(data);
      setOptions(data);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleNaturetypes = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data); setLoader(false);
      setDropnature(data);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      CourseNumber: courseDetails.courseNo,
      CourseId: parseInt(courseDetails.id),
      Hours: courseDetails.hours,
      Noofslowlearners: Noofslowlearners,
      Noofweakness: Noofweakness,
      Additionalefforts: Additionalefforts,
      Pass: Pass,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A2SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          toastSuccess("Successfully Submitted");
          navigate("/A2");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A2EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/A2");
        
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  console.log(errors);

  const handleDialog = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      CourseNo: courseNo,
      CourseName: CourseName,
      // CourseType: courseType,
      Hours: hourss,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COURSESAVEBUT}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data.result == "Success") {
        alert("Successfully Submitted");
        reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formValidateFun = () => {
    console.log("================ ", Noofslowlearners);
    if (!courseDetails || Object.keys(courseDetails).length === 0) {
      setCourseDetailsErr(true);
      document.getElementById("dropcourse").focus();
    } else if (!Noofslowlearners) {
      setNoofslowlearnersErr(true);
      document.getElementById("Noofslowlearners").focus();
    } else if (!Noofweakness) {
      setNoofweaknessErr(true);
      document.getElementById("Noofweakness").focus();
    } else if (!Additionalefforts) {
      setAdditionaleffortsErr(true);
      document.getElementById("Additionalefforts").focus();
    } else if (!Pass) {
      setPassErr(true);
      document.getElementById("Pass").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>Course</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon
                  className="dialogue_close_icon"
                  style={{ backgroundColor: "black" }}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course No"
                  value={courseNo}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setCourseNo(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course Name"
                  variant="outlined"
                  size="small"
                  value={CourseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Hours"
                  variant="outlined"
                  size="small"
                  value={hourss}
                  onChange={(e) => sethourss(e.target.value)}
                />
              </div>

              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handleDialog}>
              Submit
            </button>
          </DialogActions>
        </Dialog>

        <div className="master-main-span">
          <span>Additional Teaching Effort (A2)</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>A2. Additional Efforts in Teaching</h5>

          <div className="d-flex">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              onChange={(e, val) => {
                setCourseDetails(val);
                setCourseDetailsErr(false);
              }}
              getOptionLabel={(option) =>
                `${option.courseNo} - ${option.courseName} - ${option.hours}`
              }
              value={courseDetails}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Course"
                  error={courseDetailsErr}
                />
              )}
            />
            {/* <FormControl style={{ width: "94%", margin: 12 }}>
              <Autocomplete
                id="demo-simple-select"
                options={dropcourse || []}
                getOptionLabel={(option) => `${option.courseNo} - ${option.courseName} - ${option.hours}`}
                filterOptions={(options, state) => {
                  const filteredOptions = options.filter(
                    (option) =>
                      option.courseName
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option.courseNo
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option.hours
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  );
                  return filteredOptions;
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValue("CourseIdV", newValue.id);
                    setValue("CourseNumberv", newValue.courseNo);
                    setValue("hoursv", newValue.hours);
                  } else {
                    setValue("CourseIdV", null);
                    setValue("CourseNumberv", null);
                    setValue("hoursv", null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Course"
                    size="small"
                    sx={{ width: "100%" }}
                    error={errors.CourseIdV ? true : false}
                    helperText={errors.CourseIdV?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.id} value={option}>
                    {option.courseNo} - {option.courseName} -{" "}
                    {option.hours}
                  </MenuItem>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <MenuItem key={option.id} {...getTagProps({ index })}>
                      {option.courseNo} - {option.courseName} -{" "}
                      {option.hours}
                    </MenuItem>
                  ))
                }
              />
            </FormControl> */}
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          {/* <TextField
            id="outlined-basic"
            label=" Hours"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("hoursv")}
            error={errors.hoursv ? true : false}
            helperText={errors.hoursv?.message}
          /> */}

          <TextField
            error={NoofslowlearnersErr}
            id="Noofslowlearners"
            label="No of Slow Learners (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Noofslowlearners}
            onChange={(e) => {
              setNoofslowlearners(e.target.value);
              setNoofslowlearnersErr(false);
            }}
          />

          <TextField
            error={NoofweaknessErr}
            id="Noofweakness"
            label="Identified (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Noofweakness}
            onChange={(e) => {
              setNoofweakness(e.target.value);
              setNoofweaknessErr(false);
            }}
          />

          <TextField
            error={AdditionaleffortsErr}
            id="Additionalefforts"
            label="Taken For Slow Learners (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Additionalefforts}
            onChange={(e) => {
              setAdditionalefforts(e.target.value);
              setAdditionaleffortsErr(false);
            }}
          />

          <TextField
            error={PassErr}
            id="Pass"
            label="Pass %"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Pass}
            onChange={(e) => {
              setPass(e.target.value);
              setPassErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun}
              style={{paddingTop:"5px"}}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default A2Inside;
