import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { dateFormat } from "../../utils/Date";
import ComponentLoader from "../../ComponentLoader";

function MonthReportStatus() {
  const [rolee, setRolee] = useState("");
  const [isInvesIsTrue, setInvesIsTrue] = useState("");
  const [listDataInsti, setListDataInsti] = useState([]);
  const [listAll, SetListll] = useState([])
  const [llll, setIII] = useState("");
  const [ins, setIns] = useState();
  const [assM, setAssMonth] = useState("");
  const [listDataDepat, setListDataDepat] = useState([]);
  const [monthData, setMonthData] = useState([])
  const [loader,setLoader] =useState(true)
  const [masState, setMasState] = useState({
    inIv: 0,
    inTu: 0,
    diId: 0,
  });

  const isTrue = rolee === "Investigator";
  const isAdmin = rolee === "Principal";


  useEffect(() => {
    const investigator = localStorage.getItem("loginId");
    const inst = localStorage.getItem("loginId");
    

    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      // setAssMonth(Month);
    }

    const tok = localStorage.getItem("token");
    setIII(tok);
    monthList(tok)

    const rolee = localStorage.getItem("role");
    if(rolee ==="Principal"){
      setIns(inst)
    }
    setRolee(rolee);

    let invesIsTrue = localStorage.getItem("role");
    setInvesIsTrue(invesIsTrue);
    setMasState((prev) => ({
      ...prev,
      diId: investigator,
    }));

    if (rolee === "Director") {
      handleInstitute(tok, investigator);
    }

    if (rolee == "Principal") {
      handleDepat(tok, investigator);
    }
    if (rolee === "Director") {
      handleDepat(tok, investigator);
    }
  }, []);

  const handleInstitute = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      //  setHandleDepat(data)
      setListDataInsti(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleDepat = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEDEPARTMENT}`,
        {
          headers: h,
        }
      );
      console.log(data);
      //  setHandleDepat(data)
      setListDataDepat(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const monthList = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.MONTH_LIST}`, { headers: h, }
      );
      console.log(data);
      setMonthData(data);setLoader(false)
   
    } catch (error) {
      console.log(error); setLoader(false)
    }

  }

  const getInfo = async (value, opt, inds) => {
    if(assM == ""){
      alert("Please Select Month")
    }else{
    setLoader(true)
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    let d = {
      InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
      RptMonth: assM !== "" ? parseInt(assM) : "",
      // DirectorateId: masState.diId > 0 ? parseInt(masState.diId) : 0,
      DirectorateId: isAdmin ? 0 : masState.diId > 0 ? parseInt(masState.diId) : 0,

      InstituteId: ins > 0 ? parseInt(ins) : 0,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.REPORTSTATUS}`,
        d,
        { headers: h }
      );
      SetListll(data)
      console.log(data);
      if(data){
        setLoader(false)
      }
      if (data.monthlyReport?.length > 0) {
        const di = JSON.stringify(d);
        localStorage.setItem("getinfoDId", di);
        //setMonthlyReport(data.monthlyReport[0]);
        const objectString = JSON.stringify(data.monthlyReport[0]);
        localStorage.setItem("MReport", objectString);
        console.log(data);
      } else {
        //setMonthlyReport({});
        localStorage.removeItem("MReport");
      }
    } catch (error) {
      console.log(error);
    }}
  };
  console.log(isInvesIsTrue);

  const funInstitute = (e) => {
  
   
    const { value } = e.target
    setMasState(prev => ({
      ...prev,
      inTu: value,
    }))
    if (isInvesIsTrue === "Director") {
      setMasState(prev => ({
        ...prev,
        inTu: value,
      }))
      setIns(e.target.value);
    }
    else {
      setMasState(prev => ({
        ...prev,
        inTu: 0,
      }))
    }
  }

  return (
    <div style={{ marginTop: 70 }}>
      <ToastContainer /><ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Monthly Report Submitted Status</span>
        </div>
      </div>
      <div className="table-container-grid table-gridss">
        {/* <div className="table-head"> */}
        <div>
          <div
            style={{ height: "60px" }}
            className="headerr d-flex py-2 align-items-center head-fix headsss"
          >
            <div className="d-flex ">
              <Link to={isTrue ? "/MasterDataFaculty" : "/MasterData"}>
                {/* <HomeIcon
                  style={{ marginTop: 12, marginLeft: 10, color: "white" }}
                /> */}
              </Link>
              <h5 className="mt-3 mr-3">&nbsp;&nbsp;&nbsp;Filter:&nbsp;</h5>
              <div className="pr-2">
                <select
                  value={assM}
                  onChange={(e) => {
                    setAssMonth(e.target.value)
                  }}
                  id="months"
                  name="months"
                >
                  <option value="">Select Month</option>
                  {monthData?.map((item) => (
                    <option value={item.monthCode}> {item.monthName} </option>
                  ))
                  }
                </select>
              </div>
            </div>

            <div className="Admin-Select">
              {isInvesIsTrue === "Director" ? (
                <>
                  <select
                    id="months"
                    name="months"
                    value={masState.inTu}
                    style={{ marginLeft: "10px" }}
                    onChange={funInstitute}
                  >
                    <option value="Example">Institute</option>
                    {listDataDepat?.map((item) => (
                      <option value={item.id}>{item.instituteName}</option>
                    ))}
                  </select>
                </>
              ) : null}
            </div>
            <div className="header-btn mt-2 mr-2">
              <button onClick={getInfo}>Get Info</button>
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* ************************* */}
        <div style={{ marginTop: "10px" }}>
          <TableContainer>
            <Table
              // sx={tableStyle}
              aria-labelledby="tableTitle"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="headtable">
                <TableCell align="left">S.No</TableCell>
                <TableCell align="left">Institute Name</TableCell>
                <TableCell align="left">Faculty Name</TableCell>
                <TableCell align="left">Report Submit Date</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableHead>

              <TableBody>
                {listAll.map((val, index) => (
                  <TableRow key={index} className="statusCell" >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{val.instituteName}</TableCell>
                    <TableCell align="left">{val.investigatorName}</TableCell>
                    <TableCell align="left">{dateFormat(val.submittedOn) == "1900-01-01" ? "-" : dateFormat(val.submittedOn)}</TableCell>
                    <TableCell align="left">{val.activeStatus == "" ? "-" : val.activeStatus}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default MonthReportStatus;
