import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { B18VALI } from "../../../Validation";
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import moment from "moment";
import ComponentLoader from "../../../../ComponentLoader";

function B18Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true);
  const [eventName, setEventName] = useState("");
  const [noOfdays, setNoOfdays] = useState("");
  const [eventdate, setEventdate] = useState("");
  const [organizedby, setOrganizedby] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B18VALI),
  });

  const backs = () => {
    navigate("/B18");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate (tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Workshop attended");
    setLoader(false);
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setValue("EventNameV", item.eventName);
    setValue("NoOfdaysV", item.noOfdays);
    setValue("EventdateV", moment(item.eventdate).format("YYYY-MM-DD"));
    setValue("OrganizedbyV", item.organizedby);
    setValue("SponsorV", item.sponsor);
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async (data) => {
    console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      EventName: data.EventNameV,
      NoOfdays: data.NoOfdaysV,
      Eventdate: data.EventdateV,
      Organizedby: data.OrganizedbyV,
      Sponsor: data.SponsorV,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B18SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B18");
         
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B18EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B18");
       
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Workshop Attended (B18)</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>B18.Conference/Seminar/Symposium/Training/Work Shop attended</h5>

          <TextField
            id="outlined-basic"
            label="Event Name"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Natureofcoaching}

            onChange={(e) => setEventName(e.target.value)}
            {...register("EventNameV")}
            error={errors.EventNameV ? true : false}
            helperText={errors.EventNameV?.message}
          />

          <TextField
            id="outlined-basic"
            label="No. of Days"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Noofclassesoffered}

            onChange={(e) => setNoOfdays(e.target.value)}
            {...register("NoOfdaysV")}
            error={errors.NoOfdaysV ? true : false}
            helperText={errors.NoOfdaysV?.message}
          />

          <TextField
            id="outlined-basic"
            label="Date"
            // type="date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={FellowshipName}
            onChange={(e) => setEventdate(e.target.value)}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // InputProps={{
            //   placeholder: "",
            // }}
            {...register("EventdateV")}
            error={errors.EventdateV ? true : false}
            helperText={errors.EventdateV?.message}
          />

          <TextField
            id="outlined-basic"
            label=" Organized By"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={NoOfStudent}
            onChange={(e) => setOrganizedby(e.target.value)}
            {...register("OrganizedbyV")}
            error={errors.OrganizedbyV ? true : false}
            helperText={errors.OrganizedbyV?.message}
          />

          <TextField
            id="outlined-basic"
            label="Sponsor"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={NoOfStudent}
            onChange={(e) => setSponsor(e.target.value)}
            {...register("SponsorV")}
            error={errors.SponsorV ? true : false}
            helperText={errors.SponsorV?.message}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons">
            Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </form>
    </div>
  );
}

export default B18Inside;
