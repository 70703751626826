import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { Code } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { target } from "glamor";

import { FUNDINGVALI } from "../Validation";
import { useForm } from "react-hook-form";
import Edit from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";

import { Link, Navigate, useNavigate } from "react-router-dom";

// yupResolver
import { yupResolver } from "@hookform/resolvers/yup";
import ComponentLoader from "../../ComponentLoader";
  
// import './App.css'

function Funding({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/MasterData");
  };
  const theme = useTheme();
  const useStyles = makeStyles({
    dialogue: {
      maxHeight: "none",
      height: "350px",
      [theme.breakpoints.down("md")]: {
        width: "100vw",
        height: "70vh",
        margin: 0,
        maxHeight: "none",
        maxWidth: "none",
        borderRadius: 0,
      },
    },
  });
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [instituteupdate, setInstituteUpdate] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [loader,setLoader] =useState(true)
  const [code, setCode] = useState(null);
  const [PrincipalName, setPrincipalName] = useState(null);
  const [principalemail, setPrincipalEmail] = useState(null);
  const [principalmobileno, setPrincipalMobileNo] = useState(null);
  const [password, setPassword] = useState(null);
  const [instituteName, setInstituteName] = useState(null);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);


  const [instituteNameerror, setInstituteNameError] = useState("");
  const [PrincipalNameerror, setPrincipalNameError] = useState("");
  const [principalemailerror, setPrincipalEmailError] = useState("");
  const [principalmobilenoerror, setPrincipalMobileNoError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  // const [valids, setValids] = useState(Instituteex);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(FUNDINGVALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleInstitute(tok);
    }
    head("Institute");
  }, []);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setId(0);
    reset({ errors: {} });
    setValue("Icode", "");
    setValue("Insname", "");

  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  

  //edit button function value pass
  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setValue("Codev", item.code);
    setValue("FundingAgencyNamev", item.instituteName);

    setId(item.id);
    setCode(item.code);
    // setValids(Instituteexss);
    // setMasterInsititute((prev)=>{return{...prev,Address:"mi"}})
  };

  //institutes Table
  const handleInstitute = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.FUNDINGGETALL}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setDirect(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  //+new button click function date value and
  // Directorregister api
  const handlePopupInstitute = async (data) => {
    
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      code: data.Codev,
      FundingAgencyName: data.FundingAgencyNamev,
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.FUNDINGSAVE}`,
          d,
          { headers: h }
        );
        console.log(data);setLoader(false)
        if (data.result === "Success") {
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
          handleInstitute(token);
        } else {
          toastError(data.errorMsgs);
        }
      } catch (error) {
        console.log(error);setLoader(false)
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.FUNDINGEDIT}`,
          d,
          { headers: h }
        );
        console.log(data);setLoader(false)
        if (data.result === "Success") {
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
          handleInstitute(token);
        }
      } catch (error) {
        console.log(error);setLoader(false)
      }
    }
  };


  return (
    <div style={{ marginTop: 70 }}>
      <ToastContainer /> <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Funding Agency</span>
        </div>
      </div>
      <div>
        <ToastContainer />
        <div className="table-container-grid table-container-grid1">
          <div className="table-head">
            
            <div>
              <button className="top-button" onClick={handleClickOpen}>
                + Add
              </button>
            </div>
          </div>

          <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              component: "form",
              onSubmit: handleSubmit(handlePopupInstitute),
            }}
            classes={{ paper: classes.dialogue }}
          >
            <DialogTitle id="scroll-dialog-title">
              <div className="dialogueTittle">
                <div style={{ marginBottom: 4 }}>
                  <h4>Funding Agency</h4>
                </div>
                <div onClick={handleClose}>
                  <CloseIcon className="dialogue_close_icon" />
                </div>
              </div>
            </DialogTitle>

            <DialogContent className="Dialog-box" style={{ padding: 0 }}>
              <div className="manu-form manu-form2">
                <div className="box width-40">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Code"
                    variant="outlined"
                    size="small"
                
                    {...register("Codev")}
                    error={errors.Codev ? true : false}
                    helperText={errors.Codev?.message}
                  />
                </div>

                <div className="box width-40">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Funding Agency"
                    variant="outlined"
                    size="small"
               
                    {...register("FundingAgencyNamev")}
                    error={errors.FundingAgencyNamev ? true : false}
                    helperText={errors.FundingAgencyNamev?.message}
                  />
                  <span style={{ color: "red", fontSize: 10 }}>
                    {PrincipalNameerror}
                  </span>
                </div>


                <div className="box width-40"></div>
              </div>
              <div className="dialogue_action_button"></div>
            </DialogContent>
            <DialogActions className="dialogue_action_button">
              <button type="submit">Submit</button>
            </DialogActions>
          </Dialog>
          
          <TableContainer sx={tableContainerStyle}>
            <Table
              sx={tableStyle}
              aria-labelledby="tableTitle"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className="headtable">
                {/* <TableCell align="right">code</TableCell> */}
                <TableCell align="right">Code</TableCell>
                <TableCell align="right">Funding Agency Name</TableCell>
              
         
                <TableCell align="right">Option</TableCell>
              </TableHead>
              <TableBody>
                {direct.map((row) => (
                  <TableRow
                    key={row.PrincipalName}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    

                    <TableCell align="right">{row.code}</TableCell>
                    <TableCell align="right">{row.fundingAgencyName}</TableCell>
             
              
                    <TableCell align="right">
                      <button className="tablebutton" onClick={() => edit(row)}>
                        {<Edit className="sidebar-icon" />}
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
         
        </div>
      </div>
    </div>
  );
}
export default Funding;