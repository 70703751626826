import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
  FormLabel,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FormControl from "@mui/material/FormControl";
import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A2VALI } from "../../../Validation";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArA1fInside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true)
  const [Studentbtn, setStudentbtn] = useState([]);

  const [dropcourse, setDropcourse] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();
  console.log(TrnMonthlyReport);

  const [CourseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [dropcoursetype, setDropcoursetype] = useState([]);
  const [dropnature, setDropnature] = useState([]);
  const [cropN,setCropN] = useState([])

  const [options, setOptions] = useState([]);
  // Comman Id Send to set value
  const [courseDetailsId, setCourseDetailsId] = useState("");

  const[value,setValue]=useState({
    CropId:"",
    Variety:"",
    Area:"",
    Purpose:"",
   });

   const[errValue,setErrValue]=useState({
    CropId:false,
    Variety:false,
    Area:false,
    Purpose:false,
   })

  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInstitude] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

 
  const backs = () => {
    navigate("/ArA1(f)");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleNaturetypes(tok);
      handleCoursetypes(tok);
      handleCrop(tok)
      console.log(location.state.trnMonthlyReport);
      if (location.state.TrnMonthlyReport) {
        console.log(location.state.TrnMonthlyReport);
        setTrnMonthlyReport(location.state.TrnMonthlyReport);
      } else {
        edit(location.state);
      }
    }
    head("Teaching");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institude = localStorage.getItem("loginId");
    setInstitude(institude);
  }, []);

  const edit = (item) => {
    console.log(item);
    setValue({
      CropId:item.cropId,
      Variety:item.variety,
      Area:item.area,
      Purpose:item.purpose
    })
    setId(item.id)
   
  };

  useEffect(() => {
    console.log("========== ", options);
    options.forEach((item) => {
      console.log(item);
    });
    const getValue = options.find((data) => data.id === courseDetailsId);
    // setCourseDetails(getValue);
  }, [dropcourse, courseDetailsId]);

  const reload = () => {
    window.location.reload();
  };

  
  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  //dropdown

  const handleCoursetypes = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,  
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcoursetype(data);
      setDropcourse(data);
      setOptions(data);
      setLoader(false)
    } catch (error) {
      console.log(error);  setLoader(false)
    }
  };


  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
    

  })

  console.log("value",value);

  const handleCrop = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.CROPGETALL}`, {
        headers: h,
      });
      console.log(data);
      setCropN(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleNaturetypes = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnature(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      CropId: parseInt(value.CropId),
      Variety: value.Variety,
      Area: value.Area,
      Purpose:value.Purpose,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    console.log(d,"d");
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA1FSAVE}`, d, {
          headers: h,
        });
        console.log(data,"data");
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          toastSuccess("Successfully Submitted");
          navigate("/ArA1(f)");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARA1FEDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArA1(f)");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // console.log(errors);


  const formValidateFun = () => {
    // console.log("================ ", Noofslowlearners);
    if (!value.CropId) {
      setErrValue((prev) => ({ ...prev, CropId: true }))
      document.getElementById("CropId").focus();
    } else if (!value.Variety) {
      setErrValue((prev) => ({ ...prev, Variety: true }))
      document.getElementById("Variety").focus();
    } else if (!value.Area) {
      setErrValue((prev) => ({ ...prev, Area: true }))
      document.getElementById("Area").focus();
    } else if (!value.Purpose) {
      setErrValue((prev) => ({ ...prev, Purpose: true }))
      document.getElementById("Purpose").focus();
   
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>



        <div className="master-main-span">
          <span> A1d. Crops Info
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}> A1d. Crops Info
</h5>

          

          {/* <TextField
            error={errValue.CropId}
            id="CropId"
            label="Crop (Only Integer Value)"
            variant="outlined"
            name="CropId"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            value={value.CropId}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, CropId: false }));
            }}
          /> */}

<FormControl
          style={{ width: "48%", margin: 12 }}
          error={errValue.CropId}
          id="CropId"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Crop
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="CropId"
            label=" Crop Id"
            size="small"
            name="CropId"
            sx={{ width: "100%" }}
            value={value.CropId}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, CropId: false }));
            }}
          >
            {cropN?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.cropName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

          <TextField
            error={errValue.Variety}
            id="Additionalefforts"
            label="Variety (Max 500 Character)"
            variant="outlined"
            name="Variety"
            style={{ margin: "10px", width: "48%" }}
            value={value.Variety}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Variety: false }));
            }}
          />

          <TextField
            error={errValue.Area}
            id="Pass"
            label="Area (ac.) (Only Integer Value)"
            variant="outlined"
            name="Area"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            value={value.Area}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Area: false }));
            }}
          />


          <TextField
            
            id="Pass"
            label="Purpose (Max 500 Character)"
            variant="outlined"
            name="Purpose"
            style={{ margin: "10px", width: "48%" }}
            value={value.Purpose}
            error={errValue.Purpose}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Purpose: false }));
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px", padding: "5px 30px 28px 26px", borderRadius: "3px", color: "#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun}
              style={{ paddingTop: "5px" }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArA1fInside;