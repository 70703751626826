import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FormControl from "@mui/material/FormControl";
import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { B17VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import moment from "moment";
import ComponentLoader from "../../../ComponentLoader";

function ArC6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [authors, setAuthors] = useState("");
  const [year, setYear] = useState("");
  const [Title, setTitle] = useState("");
  const [magazineNewspaper, setMagazineNewspaper] = useState("");
  const [pageno, setpageno] = useState("");
  const [inves, setInves] = useState([]);

  const [authorsErr, setAuthorsErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const [TitleErr, setTitleErr] = useState(false);
  const [magazineNewspaperErr, setMagazineNewspaperErr] = useState(false);
  const [pagenoErr, setpagenoErr] = useState(false);
  const [loader,setLoader] =useState(true)
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti,setInsti] =useState()

  const[value,setValue]=useState({
    ProjectNo:"",
    ProjectTitle:"",
    InvestigatorId:"",
    Budget:"",
    StartDate:"",
    EndDate:"",
    Progress:"",
   });

   const[errValue,setErrValue]=useState({
    ProjectNo:false,
    ProjectTitle:false,
    InvestigatorId:false,
    Budget:false,
    StartDate:false,
    EndDate:false,
    Progress:false,
   });


  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B17VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
     
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Popular articles");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
    handleInvestigators(tok,insti)
  }, []);

  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
    

  })
  console.log("value",value);

  const edit = (item) => {
    console.log(item);
   
    setValue({
      ProjectNo:item.projectNo,
      ProjectTitle:item.projectTitle,
      InvestigatorId:item.investigatorId,
      Budget:parseFloat(item.budget),
      StartDate: (moment(item.startDate).format("YYYY-MM-DD")),
      EndDate: (moment(item.endDate).format("YYYY-MM-DD")),
      Progress:item.progress,
    })
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const backs = () => {
    navigate("/ArC6");
  };
  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };
  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      ProjectNo:value.ProjectNo,
      ProjectTitle:value.ProjectTitle,
      InvestigatorId:value.InvestigatorId,
      Budget:value.Budget,
      StartDate:value.StartDate,
      EndDate:value.EndDate,
      Progress:value.Progress,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARC6SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC6");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARC6EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = (e) => {
e.preventDefault();
    
    if (!value.ProjectNo) {
      setErrValue((prev) => ({ ...prev, ProjectNo: true }))
      document.getElementById("ProjectNo").focus()
    } else if (!value.ProjectTitle) {
      setErrValue((prev) => ({ ...prev, ProjectTitle: true }))
      document.getElementById("ProjectTitle").focus()
    } else if (!value.InvestigatorId) {
      setErrValue((prev) => ({ ...prev, InvestigatorId: true }))
      document.getElementById("InvestigatorId").focus()
    }
  else if (!value.Budget) {
    setErrValue((prev) => ({ ...prev, Budget: true }))
    document.getElementById("Budget").focus()
  }
    else if (!value.StartDate) {
      setErrValue((prev) => ({ ...prev, StartDate: true }))
      document.getElementById("StartDate").focus()
    } 
    else if (!value.EndDate) {
      setErrValue((prev) => ({ ...prev, EndDate: true }))
      document.getElementById("EndDate").focus()
    }
    else if (!value.Progress) {
      setErrValue((prev) => ({ ...prev, Progress: true }))
      document.getElementById("Progress").focus()
    }
     else {
      handleLeavepage()
    }
  }


  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>  C6. Venture Capital Projects</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>  C6. Venture Capital Projects   </h5>

          {/* ProjectNo:"",
    ProjectTitle:"",
    ProjectLeader:"",
    StartDate:"",
    EndDate:"",
    Progress:"", */}
          <TextField
            error={errValue.ProjectNo}
            id="ProjectNo"
            label="Project no (Maximum 500 Characters)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={value.ProjectNo}
            name="ProjectNo"
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ProjectNo: false }));
            }}
          />

          <TextField
            error={errValue.ProjectTitle}
            id="ProjectTitle"
            label="Title of the project (Maximum 500 Characters)"
            variant="outlined"
            name="ProjectTitle"
            style={{ margin: "10px", width: "48%" }}
            value={value.ProjectTitle}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, ProjectTitle: false }));
            }}
          />

          
<FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
           Scientist Name
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={errValue.InvestigatorId}
              style={{ height: "45px" }}
              value={value.InvestigatorId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>


          <TextField
            error={errValue.Budget}
            id="Budget"
            label="Budget"
            variant="outlined"
            name="Budget"
          type="number"
            style={{ margin: "10px", width: "48%" }}
            value={value.Budget}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Budget: false }));
            }}
          />
          
          <TextField
            error={errValue.StartDate}
            id="StartDate"
            label="Start date"
            variant="outlined"
            name="StartDate"
            type="date"
            style={{ margin: "10px", width: "48%" }}
            value={value.StartDate}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, StartDate: false }));
            }}
            InputLabelProps={{
              shrink:true
            }}
            inputProps={{
              placeholder:"",
            }}
          />

          <TextField 
                      id="EndDate"
                        label="End date"
                        variant="outlined"
                        style={{ margin: '10px', width: "48%" }}
                        name="EndDate"
                        type="date"
                        value={value.EndDate}
                        onChange={(e) => {
                          handleData(e);
                          setErrValue((prev) => ({ ...prev, EndDate: false }));
                        }}
                        InputLabelProps={{
                          shrink:true
                        }}
                        inputProps={{
                          placeholder:"",
                        }}
                    error={errValue.EndDate}
                    helperText={errors.PlaceV?.message}
                    />

          <TextField id="Progress"
                        label="Progress during this Month  (Maximum 500 Characters)"
                        variant="outlined"
                        style={{ margin: '10px', width: "48%" }}
                       name="Progress"
                        value={value.Progress}
                        onChange={(e) => {
                          handleData(e);
                          setErrValue((prev) => ({ ...prev, Progress: false }));
                        }}
                        error={errValue.Progress}
                        helperText={errors.OrganizerV?.message}
                    />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" >
              Save
            </button>
          </div>

        </div>
      </form>
    </div>
  );
}

export default ArC6Inside;