import React from "react";

function Contact() {
  return (
    <>
      <div className="table-container-grid">
        <div>
          <section className="contact-us" style={{ marginTop: "30px" }}>
            <div className="contact-grid">
              <div className="contact-box">
                <h2>Iyyankannu</h2>

                <p>Email: iyyan@thepikture.com</p>
                <p>Phone: 98401 45598</p>
              </div>

              <div className="contact-box">
                <h2>Pikture Associate Pvt Ltd</h2>
                <p>
                  Address: 5057, 21st Main Road, G Block, Anna Nagar, Chennai -
                  600040
                </p>

                <p>Phone: +91 89259 14524</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Contact;
