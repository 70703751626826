import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A5VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArD6Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true);
  const [inves, setInves] = useState([]);
  const [fundingAgencyDrop, setfundingAgencyDrop] = useState([]);
// EventName,InvestigatorId,Numberofparticipants,Eventdate,TargetGroup,FundingAgencyId,Budget
  const [facultyName, setFacultyName] = useState("");
  const [eventName, setEventName] = useState("");
  const [numberofparticipants, setNumberofparticipants] = useState("");
  const [eventDate, setEventdate] = useState("");
  const [targetGroup, setTargetGroup] = useState("");
  const [fundingAgency, setFundingAgency] = useState("");
  const [budget, setBudget] = useState("");
  
  const [facultyNameErr, setFacultyNameErr] = useState(false);
  const [eventNameErr, setEventNameErr] = useState(false);
  const [numberofparticipantsErr, setNumberofparticipantsErr] = useState(false);
  const [eventDateErr, setEventdateErr] = useState(false);
  const [targetGroupErr, setTargetGroupErr] = useState(false);
  const [fundingAgencyErr, setFundingAgencyErr] = useState(false);
  const [budgetErr, setBudgetErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [check, setCheck] = useState(0);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A5VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
    
      handleFundingAgency(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student Guidance");
  }, []);


  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute);
  }, []);

  const backs = () => {
    navigate("/ArD6");
  };

  const edit = (item) => {
    console.log(item);
    setFacultyName(item.investigatorId);
    setEventName(item.eventName);
    setNumberofparticipants(item.numberofparticipants);
    setEventdate(moment(item.eventdate).format("YYYY-MM-DD"))
    setTargetGroup(item.targetGroup);
    setFundingAgency(item.fundingAgencyId);
    setBudget(item.budget);
    setId(item.id);
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false);
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

    //Funding Agency //
    const handleFundingAgency = async (token) => {
      const h = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
  
      try {
        const { data } = await axios.get(
          `${window.BASE}${window.FUNDINGGETALL}`,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data) {
          setfundingAgencyDrop(data);setLoader(false);
        }
      } catch (error) {
        console.log(error);setLoader(false);
      }
    };
  
  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: facultyName,
      EventName: eventName,
      Numberofparticipants: numberofparticipants,
      Eventdate: eventDate,
      TargetGroup: targetGroup,
      FundingAgencyId: fundingAgency,
      Budget: budget,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD6SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArD6");
          
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ArD6EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArD6");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  const formValidateFun = () => {
    if (!facultyName) {
      setFacultyNameErr(true);
      document.getElementById("facultyName").focus();
    } else if (!eventName) {
      setEventNameErr(true);
      document.getElementById("eventName").focus();
    } else if (!numberofparticipants) {
      setNumberofparticipantsErr(true);
      document.getElementById("numberofparticipants").focus();
    } else if (!eventDate) {
      setEventdateErr(true);
      document.getElementById("eventDate").focus();
    } else if (!targetGroup) {
      setTargetGroupErr(true);
      document.getElementById("targetGroup").focus();
    } else if (!fundingAgency) {
      setFundingAgencyErr(true);
      document.getElementById("fundingAgency").focus();
    } else if (!budget) {
      setBudgetErr(true);
      document.getElementById("budget").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>
            D6. Conference / Seminar / Symposium / Training / Workshop organized
          </span>
        </div>
      </div>
      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control" style={{ marginTop: 50 }}>
        <h5 style={{ margin: 20 }}>
          {" "}
          D6. Conference / Seminar / Symposium / Training / Workshop organized
        </h5>
        
        <FormControl
            style={{ width: "45%", margin: 12 }}
            error={facultyNameErr}
            id="facultyName"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Faculty Name 
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              style={{ height: "45px" }}
              value={facultyName}
              onChange={(e) => {
                setFacultyName(e.target.value);
                setFacultyNameErr(false);
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

        <TextField
          error={eventNameErr}
          id="eventName"
          label="Name of the event (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={eventName}
          onChange={(e) => {
            setEventName(e.target.value);
            setEventNameErr(false);
          }}
        />

        <TextField
          error={eventDateErr}
          id="eventDate"
          label="Event Date"
          type="date"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={eventDate}
          onChange={(e) => {
            setEventdate(e.target.value);
            setEventdateErr(false);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            placeholder: "",
          }}
        />

        <TextField
          error={numberofparticipantsErr}
          id="numberofparticipants"
          label="Number of participants (Max 50 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={numberofparticipants}
          onChange={(e) => {
            setNumberofparticipants(e.target.value);
            setNumberofparticipantsErr(false);
          }}
        />

        <TextField
          error={targetGroupErr}
          id="targetGroup"
          label="Target group (Max 500 Character)"
          variant="outlined"
          value={targetGroup}
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            setTargetGroup(e.target.value);
            setTargetGroupErr(false);
          }}
        />

          <FormControl
            style={{ width: "45%", margin: 12 }}
            error={fundingAgencyErr}
            id="fundingAgency"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Faculty Name 
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              style={{ height: "45px" }}
              value={fundingAgency}
              onChange={(e) => {
                setFundingAgency(e.target.value);
                setFundingAgencyErr(false);
              }}
            >
              {fundingAgencyDrop?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.fundingAgencyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

        <TextField
          error={budgetErr}
          id="budget"
          label="Budget(Rs. in Lakhs)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={budget}
          onChange={(e) => {
            setBudget(e.target.value);
            setBudgetErr(false);
          }}
        />

        <div className="from-last">
          <button
            type="submit"
            style={{
              backgroundColor: "#008CBA",
              padding: "5px",
              borderRadius: "3px",
              width: "80px",
              color: "#fff",
            }}
            onClick={backs}
          >
            Back
          </button>
          <button
            type="submit"
            className="sbmit-buttons"
            onClick={formValidateFun}
            style={{ paddingTop: "5px" }}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArD6Inside;
