import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";

// import { A4VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArA1hInside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);



  const [surveyNo,setSurveyNo] = useState("")
  const [buildingDetail,setBuldingDetail] = useState("")
  const [area,setArea] = useState("")
  const [budjet,setBudjet] = useState("")
  const [year,setYear] = useState("")
  const [issue,setIssue] = useState("")
  const [reason,setReason] = useState("");
  console.log()
  const [loader,setLoader] =useState(true)
  const [surveyNoErr,setSurveyNoErr] = useState(false)
  const [buildingDetailErr,setBuldingDetailErr] = useState(false)
  const [areaErr,setAreaErr] = useState(false)
  const [budjetErr,setBudjetErr] = useState(false)
  const [yearErr,setYearErr] = useState(false)
  const [issueErr,setIssueErr] = useState(false)
  const [reasonErr,setReasonErr] = useState(false)

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A4VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("visits arranged");
    setLoader(false)
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
  }, []);

  const backs = () => {
    navigate("/ArA1(h)");
  };

  const edit = (item) => {
    console.log(item);
    setSurveyNo(item.surveyNo)
    setBuldingDetail(item.buildingDetail)
    setArea(item.area)
    setBudjet(item.budget)
    setYear(item.year)
    setIssue(item.issues)
    setReason(item.reason)

    setId(item.id);
  };


  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    
    const d = {
      id,
      SurveyNo: surveyNo,
      BuildingDetail: buildingDetail,
      Area: area,
      Budget: budjet,
      Year: year,
      Issues: issue,
      Reason: reason,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.AHSAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArA1(h)");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.AHEDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArA1(h)");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  
  const formValidateFun = () => {
    if (!surveyNo) {
      setSurveyNoErr(true);
      document.getElementById("surveyNo").focus();
    } else if (!buildingDetail) {
      setBuldingDetailErr(true);
      document.getElementById("buildingDetail").focus();
    } else if (!area) {
      setAreaErr(true);
      document.getElementById("area").focus();
    } else if (!budjet) {
      setBudjetErr(true);
      document.getElementById("budjet").focus();
    } else if (!year) {
      setYearErr(true);
      document.getElementById("year").focus();
    } else if (!issue) {
      setIssueErr(true);
      document.getElementById("issue").focus();
    } else if (!reason) {
      setReasonErr(true);
      document.getElementById("reason").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>

        <div className="master-main-span">
          <span>A1f. Building Info
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5>A1f. Building Info
 </h5>

          <TextField
            error={surveyNoErr}
            id="surveyNo"
            label=" Serial No."
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={surveyNo}
            onChange={(e) => {
              setSurveyNo(e.target.value)
              setSurveyNoErr(false)
            }}
          />


          <TextField
            error={buildingDetailErr}
            id="buildingDetail"
            label="Building Details (Maximum 500 characters)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={buildingDetail}
            onChange={(e) => {
              setBuldingDetail(e.target.value)
              setBuldingDetailErr(false)
            }}
          />


          <TextField
            error={areaErr}
            id="area"
            label="Area (Square Feet)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={area}
            onChange={(e) => {
              setArea(e.target.value)
              setAreaErr(false)
            }}
          />


          <TextField
            error={budjetErr}
            id="budjet"
            label="Budget (in lakh)"
            type="number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={budjet}
            onChange={(e) => {
              setBudjet(e.target.value)
              setBudjetErr(false)
            }}
          />


          <TextField
            error={yearErr}
            id="year"
            label="Year of construction"
            // type="date"
            variant="outlined"
            value={year}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setYear(e.target.value)
              setYearErr(false)
            }}
          />


          <TextField
            error={issueErr}
            id="issue"
            label="Issue there of"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={issue}
            onChange={(e) => {
              setIssue(e.target.value)
              setIssueErr(false)
            }}
          />

          <TextField
            error={reasonErr}
            id="reason"
            label="If not in use, reason there of"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={reason}
            onChange={(e) => {
              setReason(e.target.value)
              setReasonErr(false)
            }}
          />


          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px", padding: "5px 30px 28px 26px", borderRadius: "3px", color: "#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{ paddingTop: "5px" }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArA1hInside;
