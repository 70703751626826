import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { C1VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment/moment";
import ComponentLoader from "../../../../ComponentLoader";

function C1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [meetingName, setMeetingName] = useState("");
  const [dOM, setDOM] = useState("");
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [direct, setDirect] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [Code, setCode] = useState("");
  const [Fundingagencyname, setFundingagencyname] = useState("");
  const [loader, setLoader] = useState(true);
  const [fundingAgency, setfundingAgency] = useState([]);
  const [options, setOptions] = useState([]);
  // set
  const [Fundid, setFundid] = useState("");

  const [Fundingagencyid, setFundingagencyid] = useState(null);
  const [programtype, setprogramtype] = useState("");
  const [Eventname, setEventname] = useState("");
  const [dom, setDom] = useState("");
  const [numberofpartispant, setnumberofpartispant] = useState("");
  const [targetGroup, settargetGroup] = useState("");
  const [Budjet, setBudjet] = useState("");

  const [FundingagencyidErr, setFundingagencyidErr] = useState(false);
  const [programtypeErr, setprogramtypeErr] = useState(false);
  const [EventnameErr, setEventnameErr] = useState(false);
  const [domErr, setDomErr] = useState(false);
  const [numberofpartispantErr, setnumberofpartispantErr] = useState(false);
  const [targetGroupErr, settargetGroupErr] = useState(false);
  const [BudjetErr, setBudjetErr] = useState(false);

  ///dropdown
  const [droplov, setDroplov] = useState([]);
  const [dropnatcoching, setDropnatcoching] = useState([]);
  console.log(dropnatcoching);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(C1VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      handleFundingAgency(tok);
      handleCoursetypes(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Field Days organized");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  // dropdown

  const handleCoursetypes = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDroplov(data);
    } catch (error) {
      console.log(error);
    }
  };

  // Lov
  const handleLov = async (token, MeetingName) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"MeetingName"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnatcoching(data);
    } catch (error) {
      console.log(error);
    }
  };

  const edit = (item) => {
    console.log(item);
    setFundid(item.fundingAgencyId);
    setprogramtype(item.meetingName);
    setEventname(item.eventName);
    setDom(moment(item.dom).format("YYYY-MM-DD"));
    setnumberofpartispant(item.numberofparticipants);
    settargetGroup(item.targetGroup);
    setBudjet(item.budget);
    setId(item.id);

    setCode(item.Code);
    setFundingagencyname(item.Fundingagencyname);
  };

  useEffect(() => {
    const getValue = options.find((data) => data.id === Fundid);
    if (getValue) {
      setFundingagencyid(getValue);
    }
    console.log("getId=============", getValue);
  }, [fundingAgency]);

  const backs = () => {
    navigate("/C1");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const reload = () => {
    window.location.reload();
  };

  //Funding Agency //
  const handleFundingAgency = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.FUNDINGGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setfundingAgency(data);
        setOptions(data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      FundingAgencyId: Fundingagencyid.id,
      MeetingName: programtype,
      EventName: Eventname,
      DOM: dom,
      Numberofparticipants: numberofpartispant,
      TargetGroup: targetGroup,
      Budget: Budjet,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C1SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C1");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          toastError(data.errorMsgs);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.C1EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/C1");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePopupInstitute = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      code: Code,
      FundingAgencyName: Fundingagencyname,
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.FUNDINGSAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          // reload();
          setId(0);
          setOpen(false);
          // handleInstitute(token);
        } else {
          toastError(data.errorMsgs);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!Fundingagencyid) {
      setFundingagencyidErr(true);
      document.getElementById("Fundingagencyid")?.focus();
    } else if (!programtype) {
      setprogramtypeErr(true);
      document.getElementById("programtype").focus();
    } else if (!Eventname) {
      setEventnameErr(true);
      document.getElementById("Eventname").focus();
    } else if (!dom) {
      setDomErr(true);
      document.getElementById("dom")?.focus();
    } else if (!numberofpartispant) {
      setnumberofpartispantErr(true);
      document.getElementById("numberofpartispant").focus();
    } else if (!targetGroup) {
      settargetGroupErr(true);
      document.getElementById("targetGroup").focus();
    } else if (!Budjet) {
      setBudjetErr(true);
      document.getElementById("Budjet").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(C1). Trainings & Meetings organized</span>
        </div>
      </div>

      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div style={{ marginBottom: 4 }}>
                <h4>Funding Agency</h4>
              </div>
              <div onClick={handleClose}>
                <CloseIcon
                  className="dialogue_close_icon"
                  style={{ backgroundColor: "black" }}
                />
              </div>
            </div>
          </DialogTitle>

          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form manu-form2">
              <div className="box width-40">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Code"
                  variant="outlined"
                  size="small"
                  value={Code}
                  onChange={(e) => setCode(e.target.value)}
                  // {...register("Codev")}
                  // error={errors.Codev ? true : false}
                  // helperText={errors.Codev?.message}
                />
              </div>

              <div className="box width-40">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Funding Agency"
                  variant="outlined"
                  size="small"
                  value={Fundingagencyname}
                  onChange={(e) => setFundingagencyname(e.target.value)}
                  // {...register("FundingAgencyNamev")}
                  // error={errors.FundingAgencyNamev ? true : false}
                  // helperText={errors.FundingAgencyNamev?.message}
                />
              </div>

              <div className="box width-40"></div>
            </div>
            <div className="dialogue_action_button"></div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handlePopupInstitute}>
              Submit
            </button>
          </DialogActions>
        </Dialog>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            C1. Trainings / Meetings / Field Days organized
          </h5>

          <div className="d-flex">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              onChange={(e, val) => {
                setFundingagencyid(val);
                setFundingagencyidErr(false);
              }}
              style={{ width: "48%", margin: 12 }}
              getOptionLabel={(option) => `${option.fundingAgencyName}`}
              value={Fundingagencyid}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Funding Agency"
                  error={FundingagencyidErr}
                />
              )}
            />

            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={programtypeErr}
            id="programtype"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Programme Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={programtype}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setprogramtype(e.target.value);
                setprogramtypeErr(false);
              }}
            >
              {dropnatcoching?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={EventnameErr}
            id="Eventname"
            label="Event Name"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Eventname}
            onChange={(e) => {
              setEventname(e.target.value);
              setEventnameErr(false);
            }}
          />

          <TextField
            error={domErr}
            id="DOM"
            label="DOM"
            type="date"
            variant="outlined"
            value={dom}
            onChange={(e) => {
              setDom(e.target.value);
              setDomErr(false);
            }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={numberofpartispantErr}
            id="numberofpartispant"
            label="Number of Participants (Numbers Only)"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            value={numberofpartispant}
            onChange={(e) => {
              setnumberofpartispant(e.target.value);
              setnumberofpartispantErr(false);
            }}
          />

          {/* <FormControl style={{ width: "48%",height: "30px", margin: 12 }}>
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Funding Agency
            </InputLabel>
            <Select
              variant="outlined"
              {...register("FundingAgencyIdv")}
              error={errors.FundingAgencyIdv ? true : false}
              helperText={errors.FundingAgencyIdv?.message}
            >
              {fundingAgency?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.fundingAgencyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <TextField
            error={targetGroupErr}
            id="targetGroup"
            label="Target Group"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={targetGroup}
            onChange={(e) => {
              settargetGroup(e.target.value);
              settargetGroupErr(false);
            }}
          />

          <TextField
            error={BudjetErr}
            id="Budjet"
            label="Budget(In lakhs)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Budjet}
            onChange={(e) => {
              setBudjet(e.target.value);
              setBudjetErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{
                backgroundColor: "#008CBA",
                height: "20px",
                padding: "5px 30px 28px 26px",
                borderRadius: "3px",
              }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              style={{ paddingTop: "5px" }}
              onClick={formValidateFun}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default C1Inside;
