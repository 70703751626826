import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@mui/material";
import { withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import axios from "axios";
import RichObjectTreeView from "../../text";
import { dateFormat } from "../../utils/Date";
import ComponentLoader from "../../ComponentLoader";
import styled from "@emotion/styled";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import { funFindPath } from './Data';

const FilterData = () => {
  const [token, setToken] = useState("");
  const [listDataDepat, setListDataDepat] = useState([]);
  const [showInstitudeName, setShowInstitudeName] = useState("");
  const [listDataInsti, setListDataInsti] = useState([]);
  const [showFaculty, setShowFaculty] = useState("");
  const [treeValue, setTreeValue] = useState("");
  const [listAll, setListAll] = useState([]);
  const [myObject, setMyObject] = useState({});
  const [ress, setRes] = useState([]);
  const [nassList, setNassList] = useState([]);
  const [completeList, setCompleteList] = useState([]);
  const [newProjects, setNewPRojects] = useState([]);
  const [loader, setLoader] = useState(true);
  const [reportType, setReportType] = useState("");
  const [isbn, setIsbn] = useState("");
  const [institudeId, setInstitudeID] = useState(0);
  const [monthData, setMonthData] = useState([]);
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [record, setRecord] = useState(0);
  const [directorate, setDirectorate] = useState(0);
  const [faculty, setFaclty] = useState([]);
  const [isInvesIsTrue, setInvesIsTrue] = useState("");
  const [masState, setMasState] = useState({
    inIv: 0,
    inTu: 0,
    diId: 0,
  });
  const [teaching, setTeaching] = useState([]);
  const [nonteaching, setNonteaching] = useState([]);
  const [listAlldata, setListAlldata] = useState([]);
  const [listbudjet, setListbudjet] = useState([]);
  const [value, setValue] = useState({
    Ch: [],
    Sg: [],
    Pr: [],
    Ms: [],
    Tmo: [],
    Tma: [],
    Off: [],
    D2Ar: [],
    D3Ar: [],
  });
  const [income, setIncome] = useState([]);
  const [label, setLebel] = useState({
    director: "",
    institude: "",
    faculty: "",
    frmonth: "",
    tomonth: "",
    prevMonth:"",
  });
  const [memId, setMemId] = useState("");
  //(monthData, "idcheck");
  useEffect(() => {
    const tok = localStorage.getItem("token");
    handleDepat(tok);
    setToken(tok);
    monthList(tok);
    let invesIsTrue = localStorage.getItem("role");
    if (invesIsTrue === "Director") {
      const insti = localStorage.getItem("loginId");
      setDirectorate(insti);
    } else if (invesIsTrue === "Principal") {
      let directId = localStorage.getItem("DirectorID");
      setDirectorate(directId);
    } else {
      setDirectorate(0);
    }
    setInvesIsTrue(invesIsTrue);

    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);

    if (tok != null && tok != undefined) {
      if (myObject !== null) {
        handleDirectorate(tok, myObject.id);
        setMyObject(myObject);
        setMemId(myObject.id);
      }
    }
    const insti = localStorage.getItem("loginId");
    if (invesIsTrue === "Principal") {
      setInstitudeID(insti);
    }
    handleInstitute(tok, insti);
  }, []);
  const handleTree = (nodes) => {
    if (fromMonth === "") {
      alert("Please Select From Month");
    } else if (toMonth === "") {
      alert("Please Select To Month");
    } else {
      setTreeValue(nodes.key);
      setReportType(nodes.RptType);
      setIsbn(nodes.ISBN);
      if (nodes.key === "pub") {
        handleDirectorate(token, nodes.ISBN);
        setLoader(true);
      } else if (nodes.key === "res") {
        res(token, nodes.Ongoing);
        setLoader(true);
      } else if (nodes.key === "NAAS") {
        const tok = localStorage.getItem("token");
        nass(tok, nodes.RptType);
        setLoader(true);
      } else if (nodes.key === "complete") {
        completeHandle(token);
      } else if (nodes.key === "NewProjects") {
        newDataHandle(token);
      } else if (nodes.key === "CH") {
        courseHandling(token);
      } else if (nodes.key === "SG") {
        studentsGuidance(token);
      } else if (nodes.key === "PR") {
        patentRegistration(token);
      } else if (nodes.key === "MS") {
        mouSigned(token);
      } else if (nodes.key === "TMO") {
        meetingOrganized(token);
      } else if (nodes.key === "TMA") {
        meetingAttended(token);
      } else if (nodes.key === "OFF") {
        offOrganized(token);
      } else if (nodes.key === "D2AR") {
        addditionalResponsobilities(token);
      } else if (nodes.key === "D3AR") {
        academicResponsibilities(token);
      } else if (nodes.key === "Tstaff") {
        const filterchange = faculty?.filter(
          (item) => item.facultyType !== "Non Teaching Staff"
        );
        setTeaching(filterchange);
        setRecord(filterchange.length);
      } else if (nodes.key === "Nstaff") {
        const filterchange = faculty?.filter(
          (item) => item.facultyType === "Non Teaching Staff"
        );
        setTeaching(filterchange);
        setRecord(filterchange.length);
      } else if (nodes.key === "ADUIT") {
        handleAudit(token, memId);
      } else if (nodes.key === "BUGEXP") {
        handleBudjet(token, memId);
      } else if (nodes.key === "income") {
        handleIncome(token, memId);
      }
    }
  };
  const handleIncome = async (token, id) => {
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.INCOME_FILTER}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setIncome(groupedData);
      setLoader(false);
      setRecord(data.length);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };
  const handleDetails = () => {
    if (label.director === "") {
      setLebel((prev) => ({
        ...prev,
        director: "TRRI",
      }));
    }
    if (label.institude === "") {
      setLebel((prev) => ({
        ...prev,
        institude: "ALL Institutes",
      }));
    }
    if (label.faculty === "") {
      setLebel((prev) => ({
        ...prev,
        faculty: "ALL Faculties",
      }));
    }
    let role = localStorage.getItem("role");
    if (role === "Principal") {
      handlFaculty(token, institudeId);
    } else {
      handlFaculty(token, masState.inTu);
    }
  };

  const filterDirectorate = (val, opt, indx) => {
    setDirectorate(val);
    const selectedText = opt[indx].text;
    //(selectedText);
  };
  const handlFaculty = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      setFaclty(data);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };
  const handleInstitute = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      setListDataInsti(data);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const newDataHandle = async (token) => {
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.NEWPROJECTS_GET}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setNewPRojects(groupedData);
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const monthList = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.MONTH_LIST}`, {
        headers: h,
      });
      //(data.monthCode);

      setMonthData(data);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  //   const checkMonth =(e)=>{
  //  //();
  //     if( e < fromMonth){

  //         alert("Please Select Proper Month");
  //         setToMonth("");
  //     }else{
  //         setToMonth(e);
  //     }

  //   }
  const completeHandle = async (token) => {
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COMPLETEPROJECTS_GET}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setCompleteList(groupedData);
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const offOrganized = async (token) => {
    setLoader(true);
    //("haiii");
    //(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(`${window.BASE}${window.OFF_GET}`, d, {
        headers: h,
      });
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        Off: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const addditionalResponsobilities = async (token) => {
    //("haiii");
    //(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ADDITIONAL_RESPONSIBILITIES}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        D2Ar: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const academicResponsibilities = async (token) => {
    //("haiii");
    setLoader(true);
    //(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ADDITIONAL_ACADEMICS}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        D3Ar: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const meetingAttended = async (token) => {
    //("haiii");
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MEETING_ATTENDED}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        Tma: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const meetingOrganized = async (token) => {
    //("haiii");
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MEETING_ORGANIZED}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        Tmo: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const mouSigned = async (token) => {
    //("haiii");
    setLoader(true);
    //(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MOU_SIGNED}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        Ms: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const patentRegistration = async (token) => {
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.PATENT_REGISTRATION}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        Pr: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  // const monthView = (val) => {
  //   console.log(val);
  //   const sortedData = val.sort(
  //     (a, b) => new Date(b.rptDate) - new Date(a.rptDate)
  //   );

  //   const groupedData = sortedData.reduce((acc, item) => {
  //     const monthYear = new Date(item.rptDate).toLocaleString("default", {
  //       month: "long",
  //       year: "numeric",
  //     });
  //     if (!acc[monthYear]) {
  //       acc[monthYear] = [];
  //     }
  //     acc[monthYear].push(item);
  //     return acc;
  //   }, {});
  //   return groupedData;
  // };

  const monthView = (val) => {
    // Sort data by rptMonth in ascending order
    const sortedData = val.sort((a, b) => b.rptMonth - a.rptMonth);

    // Group data by rptMonth
    const groupedData = sortedData.reduce((acc, item) => {
      const year = item.rptMonth.toString().slice(0, 4);
      const month = item.rptMonth.toString().slice(4, 6);
      const monthYear = new Date(year, month - 1).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(item);
      return acc;
    }, {});

    return groupedData;
  };

  const studentsGuidance = async (token) => {
    //("haiii");
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.STUDENTS_GUIDANCE}`,
        d,
        { headers: h }
      );
      //(data);
      data.map((val) => {
        //(val.rptDate);
      });
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        Sg: groupedData,
      }));

      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const courseHandling = async (token) => {
    //("haiii");
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COURSE_HANDLING}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setValue((prev) => ({
        ...prev,
        Ch: groupedData,
      }));
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };
  const nass = async (token, reportType) => {
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
      RptType: reportType,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.RESEARCH_GET}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setNassList(groupedData);
      setRecord(data.length);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const res = async (token, Ongoing) => {
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
      RptType: Ongoing,
    };

    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ONGOING_GET}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setLoader(false);
      setRes(groupedData);
      setRecord(data.length);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };
  //(institudeId);
  const handleDirectorate = async (token, isbn) => {
    // const apiPath = funFindPath("pub")
    // //(apiPath,"------------------------------------");

    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      InvestigatorId: parseInt(masState.inIv),
      FromMonth: parseInt(fromMonth),
      ToMonth: parseInt(toMonth),
      RptType: isbn,
    };
    try {
      const { data } = await axios.post(`${window.BASE}${window.BOOK_GET}`, d, {
        headers: h,
      });
      //(data);
      const groupedData = monthView(data);
      setListAll(groupedData);
      setLoader(false);
      setRecord(data.length);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const handleDepat = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEDEPARTMENT}`,
        { headers: h }
      );
      //(data);

      setListDataDepat(data);
      setShowInstitudeName(data.instituteName);
      setLoader(false);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };
  const handleForMonth = (val, opt, indx) => {
    setFromMonth(val);
    const selectedText = opt[indx].text;
    setLebel((prev) => ({
      ...prev,
      frmonth: selectedText,
    }));
  };
  const handleToMonth = (val, opt, indx) => {
    setToMonth(val);
   
    
    const selectedText = opt[indx].text;
    const previousmonth =opt[indx-1].text;
console.log(previousmonth);
    setLebel((prev) => ({
      ...prev,
      tomonth: selectedText,
      prevMonth:previousmonth
    }));

    if (isInvesIsTrue === "Principal") {
      setLebel((prev) => ({
        ...prev,
        faculty: "All Faculties",
      }));
    }
  };
  const getInvestigator = async (id, opt, indx) => {
    const selectedText = opt[indx].text;
    //(selectedText);
    setLebel((prev) => ({
      ...prev,
      institude: selectedText,
      faculty: "All Faculties",
    }));
    if (isInvesIsTrue === "VC") {
      setLebel((prev) => ({
        ...prev,
        director: "TRRI",
      }));
    }
    setMasState((prev) => ({
      ...prev,
      inTu: id,
    }));
    setInstitudeID(id);
    if (id == 0) {
      setListDataInsti([]);

      return;
    }
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        { headers: h }
      );
      //(data);
      setLoader(false);
      setListDataInsti(data);
      setShowFaculty(data.investigatorName);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const AdminDirector = async (value, opt, indx) => {
    //(value);
    const selectedText = opt[indx].text;
    //(selectedText, "selectedText");
    setLebel((prev) => ({
      ...prev,
      faculty: selectedText,
    }));
    setMasState((prev) => ({
      ...prev,
      inIv: value,
    }));
    //(value);
  };

  //Audit API
  const handleAudit = async (token, id) => {
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      FromMonth: fromMonth,
      ToMonth: toMonth,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.AUDIT_FILTER}`,
        d,
        { headers: h }
      );
      //(data);
      const groupedData = monthView(data);
      setLoader(false);
      setRecord(data.length);
      setListAlldata(groupedData);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  //Budjet APi
  const handleBudjet = async (token, id) => {
    //(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      DirectorateId: directorate,
      InstituteId: parseInt(institudeId),
      FromMonth: fromMonth,
      ToMonth: toMonth,
    };

    try {
      const { data } = await axios.post(
        `${window.BASE}${window.BUDJET_FILTER}`,
        d,
        { headers: h }
      );
      //(data);

      const groupedData = monthView(data);
      setLoader(false);

      setRecord(data.length);
      setListbudjet(groupedData);
    } catch (error) {
      //(error);
      setLoader(false);
    }
  };

  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #d1ffd1;
    }
    &:nth-of-type(even) {
      background-color: #f1f1f1;
      color: black;
    }
    & > td {
      color: black;
    }
  `;

  return (
    <div style={{ marginTop: 57 }}>
      <ComponentLoader isLoading={loader} />
      <div className="filter-container"></div>

      <div>
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title"
                  id="exampleModalLongTitle"
                  style={{ color: "black" }}
                >
                  Filter
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div
                  className={`${
                    isInvesIsTrue === "VC" ? "filter-datas6" : "filter-datas1"
                  }`}
                >
                  <label>Period</label>
                  <select
                    onChange={(e) => {
                      handleForMonth(
                        e.target.value,
                        e.target.options,
                        e.target.selectedIndex
                      );
                    }}
                  >
                    <option value="">From</option>
                    {monthData?.map((item) => (
                      <option value={item.monthCode}> {item.monthName} </option>
                    ))}
                  </select>
                </div>
                <div
                  className={`${
                    isInvesIsTrue === "VC" ? "filter-datas6" : "filter-datas1"
                  }`}
                >
                  <label>Period</label>
                  <select
                    onChange={(e) => {
                      handleToMonth(
                        e.target.value,
                        e.target.options,
                        e.target.selectedIndex
                      );
                    }}
                  >
                    <option value="">To</option>
                    {monthData?.map((item) => (
                      <option value={item.monthCode}> {item.monthName} </option>
                    ))}
                  </select>
                </div>

                {isInvesIsTrue === "VC" ? (
                  <>
                    <div className="filter-datas7">
                      <label>Directorate </label>
                      <select
                        onChange={(e) =>
                          filterDirectorate(
                            e.target.value,
                            e.target.options,
                            e.target.selectedIndex
                          )
                        }
                      >
                        <option value={0}> TRRI</option>
                      </select>
                    </div>

                    <div className="filter-datas7">
                      <label>Institute </label>
                      <select
                        onChange={(e) =>
                          getInvestigator(
                            e.target.value,
                            e.target.options,
                            e.target.selectedIndex
                          )
                        }
                      >
                        <option value={0}>Institute</option>
                        {listDataDepat?.map((item) => (
                          <option value={item.id}>{item.instituteName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="filter-datas7">
                      <label>Faculty </label>
                      <select
                        onChange={(e) =>
                          AdminDirector(
                            e.target.value,
                            e.target.options,
                            e.target.selectedIndex
                          )
                        }
                      >
                        <option value={0}>All</option>
                        {listDataInsti?.map((item) => (
                          <option value={item.id}>
                            {item.investigatorName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : null}

                {isInvesIsTrue === "Director" ? (
                  <>
                    <div className="filter-datas2">
                      <label>Institute </label>
                      <select
                        onChange={(e) =>
                          getInvestigator(
                            e.target.value,
                            e.target.options,
                            e.target.selectedIndex
                          )
                        }
                      >
                        <option value={0}>All</option>
                        {listDataDepat?.map((item) => (
                          <option value={item.id}>{item.instituteName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="filter-datas2">
                      <label>Faculty </label>
                      <select
                        onChange={(e) =>
                          AdminDirector(
                            e.target.value,
                            e.target.options,
                            e.target.selectedIndex
                          )
                        }
                      >
                        <option value={0}>All</option>
                        {listDataInsti?.map((item) => (
                          <option value={item.id}>
                            {item.investigatorName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : null}

                {isInvesIsTrue === "Principal" ? (
                  <>
                    <div className="filter-datas5">
                      <label>Faculty </label>
                      <select
                        onChange={(e) =>
                          AdminDirector(
                            e.target.value,
                            e.target.options,
                            e.target.selectedIndex
                          )
                        }
                      >
                        <option value={0}>All</option>
                        {listDataInsti?.map((item) => (
                          <option value={item.id}>
                            {item.investigatorName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : null}

                <div className="filter-footer">
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleDetails}
                  >
                    Get Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-body">
        <div className="filter-section1">
          <div className="filter-tree">
            <div className="filter-home-filter">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="home-header">
                  <Link to={"/MasterData"}>
                    <HomeIcon
                      style={{
                        marginLeft: 20,
                        color: "blue",
                        fontSize: "30px",
                      }}
                    />
                  </Link>
                </div>
              </div>
              <div>
                {" "}
                <div className="filter-header1">
                  <button data-toggle="modal" data-target="#exampleModalCenter">
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div className="filter-section-tree">
              <RichObjectTreeView handleTree={handleTree} />
            </div>
            <div className="button-flexi"></div>
          </div>
        </div>
        <div className="filter-section2">
          <div className="filter-list">
            <div className="Filter-hidden">
              <p>TamilNadu Agricultural University </p>
              <p className="filter-bold">
                Directorate Of TamilNadu Rice Research Institute
              </p>
            </div>

            {treeValue === "" ? null : (
              <>
                <div className="header-count">
                  <div className=" filter-print-button">
                    <button
                      onClick={() => {
                        window.print();
                      }}
                    >
                      Print
                    </button>
                  </div>
                  <div className="header-record-count">
                    Record Count :{record}
                  </div>
                </div>
              </>
            )}

            {treeValue === "Tstaff" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={10}>
                        Institute
                      </TableCell>
                      <TableCell className="wrap" width={70}>
                        Investigator Name
                      </TableCell>
                      <TableCell className="wrap" width={70}>
                        Designation
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Institute Name
                      </TableCell>
                      <TableCell className="wrap" width={80}>
                        Email Id
                      </TableCell>
                      <TableCell className="wrap" width={30}>
                        Mobile No
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {teaching?.map((row) => (
                        <TableRowStyled key={row.Natureofcoaching}>
                          <TableCell className="wrap">
                            {" "}
                            {row.instituteCode}
                          </TableCell>
                          <TableCell className="wrap">
                            {row.investigatorName}
                          </TableCell>
                          <TableCell className="wrap">
                            {row.designation}
                          </TableCell>
                          <TableCell className="wrap">
                            {row.instituteName}
                          </TableCell>
                          <TableCell className="wrap">{row.emailId}</TableCell>
                          <TableCell className="wrap">{row.mobileNo}</TableCell>
                        </TableRowStyled>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "Nstaff" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={10}>
                        Institute
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Investigator Name
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Designation
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Institute Name
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Email Id
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Mobile No
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {teaching?.map((row) => (
                        <TableRowStyled key={row.Natureofcoaching}>
                          <TableCell className="wrap">
                            {" "}
                            {row.instituteCode}
                          </TableCell>
                          <TableCell className="wrap">
                            {row.investigatorName}
                          </TableCell>
                          <TableCell className="wrap">
                            {row.designation}
                          </TableCell>
                          <TableCell className="wrap">
                            {row.instituteName}
                          </TableCell>
                          <TableCell className="wrap">{row.emailId}</TableCell>
                          <TableCell className="wrap">{row.mobileNo}</TableCell>
                        </TableRowStyled>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "pub" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={10}>
                        Institute
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Authors
                      </TableCell>
                      <TableCell className="wrap" width={10}>
                        Year
                      </TableCell>
                      <TableCell className="wrap" width={10}>
                        Book Type
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Book Name
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Title
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Publisher
                      </TableCell>
                      <TableCell className="wrap" width={10}>
                        PageNo
                      </TableCell>
                      <TableCell className="wrap" width={10}>
                        ISBN
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(listAll).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={9}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell className="wrap">
                                {" "}
                                {row.instituteCode}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.authors}
                              </TableCell>
                              <TableCell className="wrap">{row.year}</TableCell>
                              <TableCell className="wrap">
                                {row.bookType}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.bookName}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.titleOfBookChapter}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.publisher}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.pageNo}
                              </TableCell>
                              <TableCell className="wrap">{row.isbn}</TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "res" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={40}>
                        Institute
                      </TableCell>
                      <TableCell className="wrap" width={40}>
                        Investigator
                      </TableCell>
                      <TableCell className="wrap" width={80}>
                        Project No
                      </TableCell>
                      <TableCell className="wrap" width={70}>
                        Title of the Scheme
                      </TableCell>
                      <TableCell className="wrap" width={90}>
                        Project Type
                      </TableCell>
                      <TableCell className="wrap" width={40}>
                        Role
                      </TableCell>
                      <TableCell className="wrap" width={3}>
                        Budget
                      </TableCell>
                      <TableCell className="wrap" width={30}>
                        Start Date
                      </TableCell>
                      <TableCell className="wrap" width={30}>
                        End Date
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Progress
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(ress).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={10}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell className="wrap">
                                {" "}
                                {row.instituteCode}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.investigatorName}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.projectNo}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.titleoftheScheme}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.projectType}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.investigatorType}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.budget}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {dateFormat(row.projectStartTime)}
                              </TableCell>
                              <TableCell className="wrap">
                                {dateFormat(row.projectEndTime)}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.progress}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "complete" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={30}>
                        Institute
                      </TableCell>
                      <TableCell className="wrap" width={80}>
                        Project No
                      </TableCell>
                      <TableCell className="wrap" width={80}>
                        Name of the scientists
                      </TableCell>
                      <TableCell className="wrap" width={120}>
                        Title of the Project
                      </TableCell>
                      <TableCell className="wrap" width={40}>
                        Funding Agency
                      </TableCell>
                      <TableCell className="wrap" width={40}>
                        Date of closure
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Submission of completion report
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(completeList).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell className="wrap">
                                {" "}
                                {row.instituteCode}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.projectNo}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.titleoftheProject}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.fundingAgencyName}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {dateFormat(row.dateofclosure)}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.completionReport}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "NewProjects" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Project No
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Name of the scientist
                      </TableCell>
                      <TableCell className="wrap" width={200}>
                        Title of the Project
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Role
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Funding Agency
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Budget in Lakhs
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Date of Submission
                      </TableCell>
                      <TableCell className="wrap" width={100}>
                        Status
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(newProjects).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={9}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell className="wrap">
                                {" "}
                                {row.instituteCode}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.projectNo}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.titleoftheProject}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.investigatorType}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.fundingAgencyName}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.budget}
                              </TableCell>
                              <TableCell className="wrap">
                                {dateFormat(row.dateofSubmission)}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.status}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "NAAS" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell width={300} className="wrap">
                        Authors
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        Year
                      </TableCell>
                      <TableCell width={300} className="wrap">
                        Title
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        Journal
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        Volume
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        Issue
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        Page No
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        ISSN # NAAS ID
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        NAAS rating
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        Impact Factor
                      </TableCell>
                      <TableCell width={100} className="wrap">
                        SCI Index
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(nassList).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={12}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell className="wrap">
                                {" "}
                                {row.instituteCode}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.authors}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.publicationsYear}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.titleOfPublications}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.journal}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.volume}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.issue}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.pageNo}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.issnnaasid}
                              </TableCell>
                              <TableCell className="wrap">
                                {" "}
                                {row.ratingType}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.naasRating}
                              </TableCell>
                              <TableCell className="wrap">
                                {row.sciIndex}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "CH" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Name Of The Faculty
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Designation
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Course No
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Course Name
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Course Type
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Role
                      </TableCell>
                      <TableCell align="right" width={200}>
                        No. of classes conducted
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(value?.Ch).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={8}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.designation}
                              </TableCell>
                              <TableCell align="right">
                                {row.courseNumber}
                              </TableCell>
                              <TableCell align="right">
                                {row.courseName}
                              </TableCell>
                              <TableCell align="right">
                                {row.courseType}
                              </TableCell>
                              <TableCell align="right">
                                {row.courseRole}
                              </TableCell>
                              <TableCell align="right">
                                {row.noOfClassTP}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "SG" && (
              <TableContainer className="print-table">
                <Table
                  aria-labelledby="tableTitle"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead className="headtable2" width={100}>
                    <TableRow>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Faculty Name
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Student Name
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Degree
                      </TableCell>
                      <TableCell align="right" width={500}>
                        Thesis Title
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Fellowship Details
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Thesis Submission/FVV
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* monthView */}
                  <TableBody>
                    {Object.entries(value?.Sg).map(([monthYear, rows]) => (
                      <React.Fragment key={monthYear}>
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#58d9e2",
                            }}
                          >
                            {monthYear}
                          </TableCell>
                        </TableRow>
                        {rows?.map((row) => (
                          <TableRowStyled key={row.id}>
                            <TableCell align="right">
                              {row.instituteCode}
                            </TableCell>
                            <TableCell align="right">
                              {row.investigatorName}
                            </TableCell>
                            <TableCell align="right">
                              {row.studentName}
                            </TableCell>
                            <TableCell align="right">
                              {row.courseType}
                            </TableCell>
                            <TableCell align="right">
                              {row.thesistitle}
                            </TableCell>
                            <TableCell align="right">
                              {row.fellowshipdetails}
                            </TableCell>
                            <TableCell align="right">
                              {row.thesissubmission.substring(0, 10)}
                            </TableCell>
                          </TableRowStyled>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {treeValue === "PR" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Name Of the Faculty
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Designation
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Department
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Description Of the Product
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Patent/GI/NBPGR/PPVFRA details
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Remarks
                      </TableCell>
                    </TableHead>

                    <TableBody>
                      {Object.entries(value?.Pr).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.designation}
                              </TableCell>
                              <TableCell align="right">
                                {row.instituteName}
                              </TableCell>
                              <TableCell align="right">
                                {row.descriptionoftheProduct}
                              </TableCell>
                              <TableCell align="right">
                                {row.patentType}
                              </TableCell>
                              <TableCell align="right">{row.remarks}</TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "MS" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2">
                      <TableCell className="wrap">Institute</TableCell>
                      <TableCell align="right">Name Of The Faculty</TableCell>
                      <TableCell align="right">Department Name</TableCell>
                      <TableCell align="right">
                        Partnering Organization
                      </TableCell>
                      <TableCell align="right">Purpose</TableCell>
                      <TableCell align="right">Date of MoU</TableCell>
                      <TableCell align="right">
                        Progress during the month
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(value?.Ms).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.instituteName}
                              </TableCell>
                              <TableCell align="right">
                                {row.organization}
                              </TableCell>
                              <TableCell align="right">{row.purpose}</TableCell>
                              <TableCell align="right">
                                {dateFormat(row.mouDate)}
                              </TableCell>
                              <TableCell align="right">
                                {row.progress}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "TMO" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Name of the Faculty
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Programme Type
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Date
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Event Name
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Number of Participants
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Funding Agency
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Budget Rs. In Lakhs
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(value?.Tmo).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={8}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.meetingName}
                              </TableCell>
                              <TableCell align="right">
                                {row.dom.substring(0, 10)}
                              </TableCell>
                              <TableCell align="right">
                                {row.eventName}
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                {row.numberofparticipants}
                              </TableCell>

                              <TableCell align="right">
                                {row.fundingAgencyName}
                              </TableCell>
                              <TableCell align="right">{row.budget}</TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "TMA" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Name of the Faculty
                      </TableCell>
                      <TableCell align="right" width={200}>
                        Event Name
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Date(s)
                      </TableCell>
                      <TableCell align="right" width={200}>
                        Organized By
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Sponsor
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(value?.Tma).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.eventName}
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                {row.eventdate}
                              </TableCell>
                              <TableCell align="right">
                                {row.organizedby}
                              </TableCell>
                              <TableCell align="right">{row.sponsor}</TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "OFF" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Name Of The Faculty
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Programme Type
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Role
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Place
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Details
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(value?.Off).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.investigatorName}>
                              <TableCell align="right">
                                {" "}
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.programmeType}
                              </TableCell>
                              <TableCell align="right">
                                {row.teamleaderRole}
                              </TableCell>
                              <TableCell align="right"> {row.place}</TableCell>
                              <TableCell align="right">
                                {row.nooftrials}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "D2AR" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Name Of The Faculty
                      </TableCell>
                      <TableCell align="right" width={600}>
                        Activities
                      </TableCell>
                      <TableCell align="right" width={200}>
                        Responsibility
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(value?.D2Ar).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.activity}
                              </TableCell>
                              <TableCell align="right">
                                {row.responsibility}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "D3AR" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Name Of The Faculty
                      </TableCell>
                      <TableCell align="right" width={600}>
                        Activity
                      </TableCell>
                      <TableCell align="right" width={200}>
                        Responsibility
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(value?.D3Ar).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.investigatorName}
                              </TableCell>
                              <TableCell align="right">
                                {row.activity}
                              </TableCell>
                              <TableCell align="right">
                                {row.responsibility}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {treeValue === "ADUIT" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable2" width={100}>
                      <TableCell className="wrap" width={100}>
                        Institute
                      </TableCell>
                      <TableCell align="right" width={100}>
                        No. of audit objections pending at the beginning of the
                        month (A) 
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Reply sent during this month 
                      </TableCell>
                      <TableCell align="right" width={100}>
                        No. of audit objections dropped during the month (B)
                      </TableCell>
                      <TableCell align="right" width={100}>
                        Pending audit objections (A-B)
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(listAlldata).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows?.map((row) => (
                            <TableRowStyled key={row.Natureofcoaching}>
                              <TableCell align="right">
                                {row.instituteCode}
                              </TableCell>
                              <TableCell align="right">
                                {row.noOfAudit}
                              </TableCell>
                              <TableCell align="right">
                                {row.replyDetails}
                              </TableCell>
                              <TableCell align="right">
                                {row.auditDropped}
                              </TableCell>
                              <TableCell align="right">
                                {row.pendingAudit}
                              </TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}
            {treeValue === "income" ? (
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead className="headtable" width={100}>
                    <TableCell className="wrap" width={100}>
                      Institute
                    </TableCell>
                    <TableCell width={100}>Source of revenue</TableCell>
                    <TableCell width={100}>
                      Revenue generated up to the last year (Rs.) Upto
                      31.03.2023
                    </TableCell>
                    <TableCell width={100}>
                      Revenue generated last month (Rs.) {label?.prevMonth}
                    </TableCell>
                    <TableCell width={100}>
                      Revenue generated during this month (Rs.) {label?.tomonth}
                    </TableCell>
                    <TableCell width={100}>Total revenue(Rs)</TableCell>
                  </TableHead>
                  <TableBody>
                    {Object.entries(income).map(([monthYear, rows]) => (
                      <React.Fragment key={monthYear}>
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#58d9e2",
                            }}
                          >
                            {monthYear}
                          </TableCell>
                        </TableRow>
                        {rows?.map((row) => (
                          <TableRow key={row.Natureofcoaching}>
                            <TableCell align="right">
                              {row.instituteCode}
                            </TableCell>
                            <TableCell>{row.revenueSource}</TableCell>
                            <TableCell>{row.revenueLastYear}</TableCell>
                            <TableCell>{row.revenueLastMonth}</TableCell>
                            <TableCell>{row.revenueCurMonth}</TableCell>
                            <TableCell>{row.revenueTotal}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            {treeValue === "BUGEXP" ? (
              <div>
                <ComponentLoader isLoading={loader} />
                <TableContainer className="print-table">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="headtable">
                      <TableCell>Scheme</TableCell>
                      <TableCell> Head</TableCell>
                      <TableCell>Sanctioned Budget (Rs)</TableCell>
                      <TableCell> Budget received (Rs)</TableCell>
                      <TableCell>
                        Budget available at the beginning of the month (Rs.)
                      </TableCell>
                      <TableCell>
                        Expenditure during this month (Rs.){" "}
                      </TableCell>
                      <TableCell>Balance(Rs.) </TableCell>
                    </TableHead>

                    <TableBody>
                      {Object.entries(listbudjet).map(([monthYear, rows]) => (
                        <React.Fragment key={monthYear}>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#58d9e2",
                              }}
                            >
                              {monthYear}
                            </TableCell>
                          </TableRow>
                          {rows.map((row, inde) => (
                            <TableRowStyled key={inde}>
                              <TableCell>{row.schemeName}</TableCell>
                              <TableCell>{row.acHead}</TableCell>
                              <TableCell>{row.sanctionedBudget}</TableCell>
                              <TableCell>{row.budgetReceived}</TableCell>
                              <TableCell>{row.availAmount}</TableCell>
                              <TableCell>{row.expAmount}</TableCell>
                              <TableCell>{row.balance}</TableCell>
                            </TableRowStyled>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}
          </div>

          {treeValue === "" ? null : (
            <div className="filter-header">
              <div className="filter-header-data">
                <div className="label-list">
                  {label.frmonth === "" ? null : (
                    <p>
                      {" "}
                      Month : <span>{label.frmonth}</span> <span>to</span>{" "}
                      <span>{label.tomonth}</span>
                    </p>
                  )}
                  {/* {label.tomonth === "" ? null : (
                    <p>
                      {" "}
                      To Month : <span>{label.tomonth}</span>
                    </p>
                  )} */}
                  {isInvesIsTrue === "VC" ? (
                    <>
                      {label.director === "" ? null : (
                        <p>
                          {" "}
                          Directorate : <span>{label.director}</span>
                        </p>
                      )}{" "}
                    </>
                  ) : null}
                  {isInvesIsTrue === "Principal" ? null : (
                    <>
                      {label.institude === "" ? null : (
                        <p>
                          {" "}
                          Institute : <span>{label.institude}</span>
                        </p>
                      )}
                    </>
                  )}
                  {label.faculty === "" ? null : (
                    <p>
                      {" "}
                      Faculty : <span>{label.faculty}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterData;
