import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import { Link, Navigate, useNavigate } from "react-router-dom";

import Edit from "@mui/icons-material/Edit";
import { ToastContainer } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { Leaveess } from '../Validation';
import { STATION } from "../Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// import './App.css'

function StationType({ head }) {
  const Navigate = useNavigate("");

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [code, setCode] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(STATION),
  });

  // const handleClickSubmit =()=>{

  // // }
  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setId(item.id);
    setValue("CodeV", item.code);
    setValue("NameV", item.name);
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
    }
    head("Course");
  }, []);

  //    ***************************************************************

  function createData(code, Lname, option) {
    return { code, Lname, option };
  }

  const rows = [
    //     createData('CL', 'Casual Leave', 'Edit'),
    //     createData('SL', 'Sick Leave', 'Edit'),
  ];

  const handleClickSubmit = () => {
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  const handleLeavepage = async (data) => {
    console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      Code: data.CodeV,
      Name: data.NameV,
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.CATAGERYSAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result == "Success") {
          alert("Succesfly Submited");
          window.location.reload();
        }
        // setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.CATAGERYEDIT}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result == "Success") {
          alert("Succesfly Submited");
          window.location.reload();
        }
        // setDirect(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.CATAGERYGETALL}`,
        { headers: h }
      );
      console.log(data);
      setDirect(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: 70 }}>
      <ToastContainer />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>StationType</span>
        </div>
      </div>
      <div className="table-container-grid">
        <div className="table-head">
          <div>
            <button className="top-button" onClick={handleClickOpen}>
              + Add
            </button>
          </div>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            component: "form",
            onSubmit: handleSubmit(handleLeavepage),
          }}
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>StationTypes</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon className="dialogue_close_icon" />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Code"
                  variant="outlined"
                  size="small"
                  {...register("CodeV")}
                  error={errors.CodeV ? true : false}
                  helperText={errors.CodeV?.message}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=" Name"
                  variant="outlined"
                  size="small"
                  {...register("NameV")}
                  error={errors.NameV ? true : false}
                  helperText={errors.NameV?.message}
                />
              </div>

              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit">Submit</button>
          </DialogActions>
        </Dialog>

        {/* ************************************************************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell align="right">Code</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Option</TableCell>
            </TableHead>

            <TableBody>
              {direct.map((row) => (
                <TableRow
                  key={row.Leave}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right"> {row.code}</TableCell>
                  <TableCell align="right">{row.name}</TableCell>

                  <TableCell align="right">
                    <button
                      className="btns"
                      variant="outlined"
                      onClick={() => edit(row)}
                    >
                      {<Edit className="sidebar-icon" />}
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={direct.length}
              rowsPerPage={masterManufacturer.rowsPerPage}
              page={masterManufacturer.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
      </div>
    </div>
  );
}
export default StationType;
