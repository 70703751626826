import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { C1VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArC8Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [lov, setLov] = useState([]);
  const [funding, setfunding] = useState([]);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();
  const [loader,setLoader] =useState(true)
  const [projectNo,setProjectNo] = useState("")
  const [projectTitle,setProjectTitle] = useState("")
  const [investigatorType,setInvestigatorType] = useState("")
  const [fundinAgency,setFundingAgency] = useState("")
  const [budget,setBudget] = useState("")
  const [startDate,setStartDate] = useState("")
  const [endDate,setEndDate] = useState("")
  const [progress,setProgress] = useState("")

  const [projectNoErr,setProjectNoErr] = useState(false)
  const [projectTitleErr,setProjectTitleErr] = useState(false)
  const [investigatorTypeErr,setInvestigatorTypeErr] = useState(false)
  const [fundinAgencyErr,setFundingAgencyErr] = useState(false)
  const [budgetErr,setBudgetErr] = useState(false)
  const [startDateErr,setStartDateErr] = useState(false)
  const [endDateErr,setEndDateErr] = useState(false)
  const [progressErr,setProgressErr] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C1VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      handleFundingAgency(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Field Days organized");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
  }, []);

  // Lov
  const handleLov = async (token, InvestigatorType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"InvestigatorType"}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      setLov(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const edit = (item) => {
    console.log(item);
    setProjectNo(item.projectNo);
    setProjectTitle(item.projectTitle);
    setInvestigatorType(item.investigatorType);
    setFundingAgency(item.fundingAgencyId);
    setBudget(item.budget);
    setStartDate(moment(item.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(item.endDate).format("YYYY-MM-DD"));
    setProgress(item.progress);
    setId(item.id);
  };

  const backs = () => {
    navigate("/ArC8");
  };

  //Funding Agency //
  const handleFundingAgency = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.FUNDINGGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setfunding(data);setLoader(false)
      }
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      ProjectNo: projectNo,
      ProjectTitle: projectTitle,
      InvestigatorType:investigatorType,
      FundingAgencyId: fundinAgency,
      Budget: budget,
      StartDate: startDate,
      EndDate: endDate,
      Progress: progress,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr8SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC8");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr8EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArC8");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!projectNo){
      setProjectNoErr(true)
      document.getElementById("projectNo").focus()
    }else if(!projectTitle){
      setProjectTitleErr(true)
      document.getElementById("projectTitle").focus()
    }else if(!investigatorType){
      setInvestigatorTypeErr(true)
      document.getElementById("investigatorType").focus()
    }else if(!fundinAgency){
      setFundingAgencyErr(true)
      document.getElementById("fundinAgency").focus()
    }else if(!budget){
      setBudgetErr(true)
      document.getElementById("budget").focus()
    }else if(!startDate){
      setStartDateErr(true)
      document.getElementById("startDate").focus()
    }else if(!endDate){
      setEndDateErr(true)
      document.getElementById("endDate").focus()
    } else if(!progress){
      setProgressErr(true)
      document.getElementById("progress").focus()
    } else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C8. External Fund Projects
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
          C8. External Fund Projects

          </h5>

          <TextField
            error={projectNoErr}
            id="projectNo"
            label="Project No (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={projectNo}
            onChange={(e) => {
              setProjectNo(e.target.value)
              setProjectNoErr(false)
            }}
          />

          <TextField
            error={projectTitleErr}
            id="projectTitle"
            label="Project Title (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={projectTitle}
            onChange={(e) => {
              setProjectTitle(e.target.value)
              setProjectTitleErr(false)
            }}
          />

          <FormControl style={{ width: "48%", margin: 12 }} error={investigatorTypeErr} id="investigatorType">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Investigator Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={investigatorType}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setInvestigatorType(e.target.value)
                setInvestigatorTypeErr(false)
              }}
            >
              {lov?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ width: "48%", margin: 12 }} error={fundinAgencyErr} id="fundinAgency">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Funding Agency
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={fundinAgency}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setFundingAgency(e.target.value)
                setFundingAgencyErr(false)
              }}
            >
              {funding?.map((row,index) => {
                return (
                  <MenuItem key={row.index} value={row.id}>
                    {row.fundingAgencyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={budgetErr}
            id="budget"
            label="Budget (Rs.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={budget}
            onChange={(e) => {
              setBudget(e.target.value)
              setBudgetErr(false)
            }}
          />

          <TextField
            error={startDateErr}
            id="startDate"
            label="Start date"
            type="date"
            variant="outlined"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
              setStartDateErr(false)
            }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />
          <TextField
            error={endDateErr}
            id="endDate"
            label="End date"
            type="date"
            variant="outlined"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
              setEndDateErr(false)
            }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={progressErr}
            id="progress"
            label="Progress during this Month (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={progress}
            onChange={(e) => {
              setProgress(e.target.value)
              setProgressErr(false)
            }}
          />        

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" style={{paddingTop:"5px"}} onClick={()=>formValidateFun()}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArC8Inside;
