import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { C9VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArC16Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [documentDescription, setDocumentDescription] = useState("");
  const [realeaseType, setRealeaseType] = useState("");
  const [leadAuthor, setLeadAuthor] = useState("");

  const [documentDescriptionErr, setDocumentDescriptionErr] = useState(false);
  const [realeaseTypeErr, setRealeaseTypeErr] = useState(false);
  const [leadAuthorErr, setLeadAuthorErr] = useState(false);
  const [loader,setLoader] =useState(true)
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti,setInsti] =useState()
  const [inves, setInves] = useState([]);

  const [dropcourse, setDropcourse] = useState([]);
  const [lovValue,setLovValue]= useState([]);
  const [Lovteamleaderrole, setLovteamleaderrole] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const[value,setValue]=useState({
    InvestigatorId:"",
     Organization:"",
    EditorialBoardReviewer:"",
    NoOfPaperReviewed:"",
    
   });

   const[errValue,setErrValue]=useState({
    InvestigatorId:false,
    Organization:false,
    EditorialBoardReviewer:false,
    NoOfPaperReviewed:false,
    
   });



  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C9VALI),
  });

  const backs = () => {
    navigate("/ArC16");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      handleCoursetypes(tok);
      handleLovType(tok)
     
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Policy documents");
  }, []);
  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInsti(investigator);
    handleInvestigators(tok,investigator)
  }, []);
  const edit = (item) => {
    console.log(item);
    // investigatorName
    // organization
    // editorialBoardReviewer
    // editorialBoardReviewer
    setValue({
      InvestigatorId:item.investigatorId,
     Organization:item.organization,
    EditorialBoardReviewer:item.editorialBoardReviewer,
    NoOfPaperReviewed:item.noOfPaperReviewed,
    })
    setId(item.id);
  };
  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
 })

  console.log("value",value);
  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };
  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);
    } catch (error) {
      console.log(error);
    }
  };
  // Lov Team Leader Role
  const handleLov = async (token, TeamleaderRole) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"TeamleaderRole"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovteamleaderrole(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  // dropdown
  const handleCoursetypes = async (token, Activities) => {
    console.log(Activities);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVEAPI}${"RealeaseType"}`,
        { headers: h }
      );
      console.log(data);

      setDropcourse(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };
  const handleLovType = async (token, EditorialBoardReviewer) => {
   
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"EditorialBoardReviewer"}`,
        { headers: h }
      );
      console.log(data);setLoader(false)

      setLovValue(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      InvestigatorId:value.InvestigatorId,
      Organization:value.Organization,
     EditorialBoardReviewer:value.EditorialBoardReviewer,
     NoOfPaperReviewed:value.NoOfPaperReviewed,
     IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARC16SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC16");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
          // setalertDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARC16EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC16");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
  
  if (!value.InvestigatorId) {
    setErrValue((prev) => ({ ...prev, InvestigatorId: true }))
    document.getElementById("InvestigatorId").focus()
  } else if (!value.Organization) {
    setErrValue((prev) => ({ ...prev, Organization: true }))
    document.getElementById("Organization").focus()
  } else if (!value.EditorialBoardReviewer) {
    setErrValue((prev) => ({ ...prev, EditorialBoardReviewer: true }))
    document.getElementById("EditorialBoardReviewer").focus()
  }
else if (!value.NoOfPaperReviewed) {
  setErrValue((prev) => ({ ...prev, NoOfPaperReviewed: true }))
  document.getElementById("v").focus()
}else{
  handleLeavepage()
}
    }


  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C16. Editorial/Review service
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>C16. Editorial/Review service
</h5>

         <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
           Scientist Name
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={errValue.InvestigatorId}
              style={{ height: "45px" }}
             
              value={value.InvestigatorId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          

          <TextField
           
            id="Organization"
            label="Organization / Journal (Maximum 500 Characters)"
           
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            name="Organization"
            error={errValue.Organization}
            value={value.Organization}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Organization: false }));
            }}
          />
          {/* <TextField
           
            id="EditorialBoardReviewer"
            label="Editorial Board / Reviewer"
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink:true
            }}
            inputProps={{
              placeholder:"",
            }}
            name="EditorialBoardReviewer"
            style={{ margin: "10px", width: "48%" }}
            error={errValue.EditorialBoardReviewer}
            value={value.EditorialBoardReviewer}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, EditorialBoardReviewer: false }));
            }}
          /> */}

<FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="EditorialBoardReviewer"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Editorial Board / Reviewer
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="EditorialBoardReviewer"
              error={errValue.EditorialBoardReviewer}
              style={{ height: "45px" }}
             
              value={value.EditorialBoardReviewer}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, EditorialBoardReviewer: false }));
              }}
            >
              {lovValue?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
           
            id="NoOfPaperReviewed"
            label="No. of papers reviewed (Maximum 500 Characters)"
            variant="outlined"
            name="NoOfPaperReviewed"
            style={{ margin: "10px", width: "48%" }}
            error={errValue.NoOfPaperReviewed}
            value={value.NoOfPaperReviewed}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, NoOfPaperReviewed: false }));
            }}
          />


          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </div>
    </div>
  );
}

export default ArC16Inside;