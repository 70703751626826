import React from "react";
import h1 from "../Images/header/1.jpg";
import h2 from "../Images/header/2.jpg";

function Stations() {
  return (
    <>
      <div>
        <div class="utility-page">
          <div class="carousel-item-img1">
            <div class="content-page">
              <h5>Why this project </h5>
              <p>
                This project is emanated in order to fill the following gaps
                present in the existing mechanisms.
                <ui>
                  <li>No permanent repository of information</li>
                  <li>
                    No digital system to register the records of scientists and
                    Institution in a time bound manner
                  </li>
                  <li>Existing monthly reporting system is complex</li>
                  <li>
                    Scientists waste their time on repeated reports submission
                    to different higher officials
                  </li>
                  <li>
                    No single platform for the unified monitoring of research,
                    education and extension works
                  </li>
                  <li>
                    Need for unified monitoring mechanism at different hierarchy
                    level such as HoD, Director, Dean, University Officers and
                    Vice-Chancellor
                  </li>
                  <li>Difficulty in retrieving old information</li>
                  <li>
                    Older information ( &gt;10 years) is missing due to existing
                    record maintenance system
                  </li>
                </ui>
              </p>
              <p>
                This digital reporting system aptly fills the above listed all
                gaps by providing a digital reporting system which collects
                information on education, research and extension from scientists
                and staff with time data and thus by aids monitoring education,
                research and extension works in a time bound manner besides
                recording all information on a permanent basis.{" "}
              </p>

              <h5> Project benefits </h5>

              <p>
                <ul>
                  <li>
                    Permanent information gathering with time and information
                  </li>
                  <li>
                    Ease retrieval of information by different users i.e.,
                    scientists, Heads of Department and University officers
                  </li>
                  <li>
                    Retrieval of information in any desired manner (any period
                    for any scientists / Institute / Directorate for any of the
                    parameter collected)
                  </li>
                  <li>Report generation is easier at all levels for any parameters</li>
                  <li>Efficient monitoring of research projects, scientists and administration</li>
                  <li>
                    Ease assessment of performance of Institute / Department /
                    Scientist
                  </li>
                </ul>
              </p>

              {/* <h5>Scope for expansion </h5>
                        <p><ul>
                            <li>More elaborate integration of research projects</li>
                            <li>Removal of duplicate information in specific parameters</li>
                            <li>Integrating regular office procedures such as approval of leaves, purchase permissions, etc.</li>
                        </ul></p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stations;
