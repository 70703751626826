import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Reportsystem.css";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useTheme } from "styled-components";
import { Col, Row } from "react-bootstrap";
import ComponentLoader from "../../ComponentLoader";

function Reportingsystem() {
  let Navigate = useNavigate("");

  const NaviReportTab = () => {
    Navigate("/reporttable");
  };

  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [rolee, setRolee] = React.useState("");
  const isTrue = rolee === "Investigator";
  const [token, setToken] = useState("");
  const [month, setMonth] = useState(null);
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [months, setMonths] = useState("");
  const [ins, setIns] = useState();
  const [state, setState] = useState();
  const theme = useTheme();
  const [disables, setDisables] = useState(false);

  const [listDataDepat, setListDataDepat] = useState([]);
  console.log(listDataDepat);
  const [listDataInsti, setListDataInsti] = useState([]);
  console.log(listDataInsti);
  const [isInvesIsTrue, setInvesIsTrue] = useState("");
  const [handledepat, setHandleDepat] = useState([]);
  const [llll, setIII] = useState("");
  console.log(handledepat);

  const [monthData, setMonthData] = useState([])

  // Admin Director Count //
  const [Investigatorid, setInvestigatorid] = useState("");
  const [Rptmonth, setRptmonth] = useState("");
  const [Directorated, setDirectorated] = useState();
  const [InstituteId, setInstituteId] = useState("");

  const [masState, setMasState] = useState({
    inIv: 0,
    inTu: 0,
    diId: 0,
  });
  const [loader,setLoader] =useState(true)
  const [monthlyReport, setMonthlyReport] = useState();
  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();
  console.log(TrnMonthlyReport);
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [inM, setIndMonth] = useState(null);
  const arr = [{ 202306: "June" }, { 202307: "July" }];
  const arrs = [{ month: "June" }, { month: "July" }];

  useEffect(() => {
    // head("Monthly Report");

    const rolee = localStorage.getItem("role");
    setRolee(rolee);

    const tok = localStorage.getItem("token");
    setIII(tok);
    monthList(tok)
    const investigator = localStorage.getItem("loginId");
    const inst = localStorage.getItem("loginId");
    setInvestigator(investigator);
    setMasState((prev) => ({
      ...prev,
      diId: investigator,
    }));
    setToken(tok);
    setIns(inst);
    // AdminDirector(token)
    // setInvestigatorid(Investigatorid)
    // setDirectorated(Directorated)
    // setInstituteId(InstituteId)

    setToken(tok);

    console.log(tok);
    if (tok != null && tok != undefined) {
      handleChooseMonth(tok);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
      setMonths(Month);
      console.log("s---------------------------", Month);
      handleCountRender(Month, investigator, tok);
    }

    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true);
    } else {
      setDisables(false);
    }

    // const Months = localStorage.getItem('Months');
    // if (Months == null) {
    //   setMonths(Months)
    //   console.log("s---------------------------", Months);
    //    submitFun(Months, investigator, tok)

    // }

    let objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (objectString) {
      setMonthlyReport(myObject);
      setState(myObject);
      console.log(myObject);
    }
    if (rolee == "Principal") {
      handleDepat(tok, investigator);
    }
    if (rolee === "Director") {
      handleDepat(tok, investigator);
      // handleInstitute(tok, investigator);
    } else {
      handleInstitute(tok, investigator);
    }

    let invesIsTrue = localStorage.getItem("role");
    setInvesIsTrue(invesIsTrue);
  }, []);

  const reload = () => {
    window.location.reload();
  };

  const ReportDownloadPageNavigate = () => {
    Navigate("/reportdownloadpage");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountRender = async (value, id, token) => {
    // const selectedText = id[token].text;
    // console.log(selectedText);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    setAssMonth(value);
    setMonth(parseInt(value));
    let monthYear = arr.map((item) => Object.keys(item) == value);
    let ind = monthYear.findIndex((item) => item == true);
    setIndMonth(ind);
    localStorage.setItem("Month", value);
    const d = {
      InvestigatorId: parseInt(id),
      RptMonth: parseInt(value),
    };
    if (rolee === "") {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.COUNTNUMBER}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);setLoader(false)
        if (data.monthlyReport?.length > 0) {
          setMonthlyReport(data.monthlyReport[0]);
          const objectString = JSON.stringify(data.monthlyReport[0]);
          localStorage.setItem("MReport", objectString);
          console.log(data);
        }
      } catch (error) {
        console.log(error);setLoader(false)
      }
    } else {
    }
  };

  const monthList = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.MONTH_LIST}`, { headers: h, }
      );
      console.log(data);
      setMonthData(data);setLoader(false)
      
    } catch (error) {
      console.log(error); setLoader(false)
    }

  }

  // Count Fetch API //
  const handleCount = async (value, opt, inds) => {
    const selectedText = opt[inds].text;
    console.log(selectedText);
    const storedArrayJSON = localStorage.getItem("myArrayKey");
    if (storedArrayJSON !== null) {
      const storedArray = JSON.parse(storedArrayJSON);
      console.log(storedArray);
      let some = storedArray.some((item) => item == selectedText);
      if (some) {
        setDisables(true);
      } else {
        let stored = [];
        storedArray.map((item) => stored.push(item));
        stored.push(selectedText);
        localStorage.setItem("myArrayKey", JSON.stringify(stored));
        setDisables(false);
      }
    } else {
      let myArray = [selectedText];
      localStorage.setItem("myArrayKey", JSON.stringify(myArray));
    }
    // Parse the JSON string back into an array

    // let get = localStorage.getItem()

    localStorage.setItem("Mname", selectedText);
    // console.log(selectedText.slice(5,));
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    setAssMonth(value);
    setMonth(parseInt(value));
    let monthYear = arr.map((item) => Object.keys(item) == value);
    let ind = monthYear.findIndex((item) => item == true);
    setIndMonth(ind);
    localStorage.setItem("Month", value);
    const d = {
      InvestigatorId: parseInt(invis),
      RptMonth: parseInt(value),
    };
    localStorage.removeItem("disable");

    // if()
  };

  const submitFun = async () => {
    setDisables(true);
    localStorage.setItem("btnss", true);
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      MRSId: state.id,
      investigatorId: parseInt(ins),
      rptMonth: parseInt(months),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MONTHSUBMIT}`,
        d,
        { headers: h }
      );
      console.log(data);

      if (data.result === true) {
        alert("Successfully Submitted");
        setDisables(true);
        // Disable the button after a successful submission
        localStorage.setItem("disable", true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInstitute = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      console.log(data);
      //  setHandleDepat(data)
      setListDataInsti(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleDepat = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEDEPARTMENT}`,
        {
          headers: h,
        }
      );
      console.log(data);
      //  setHandleDepat(data)
      setListDataDepat(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  // Admin,Director Count //
  const AdminDirector = async (value) => {
    console.log(value);
    setMasState((prev) => ({
      ...prev,
      inIv: value,
    }));
    console.log(value);
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    console.log(token);
  };

  // Report Fetch API //
  const handleChooseMonth = async (event) => {};

  const NextPage = (path) => {
    console.log(TrnMonthlyReport);
    if (assM !== "") {
      Navigate(path, { state: { TrnMonthlyReport: monthlyReport } });
    } else {
      alert("Please Select Month");
    }
  };
  console.log(assM);

  const getInvestigator = async (id) => {
    setMasState((prev) => ({
      ...prev,
      inTu: id,
    }));
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setListDataInsti(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getInfoInvestigator = async () => {
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    const d = {
      InvestigatorId: invis,
      RptMonth: assM !== "" ? parseInt(assM) : "",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COUNTNUMBER}`,
        d,
        {
          headers: h,
        }
      );
      console.log(data);
      window.location.href = "/transactionleave";
      if (data.monthlyReport?.length > 0) {
        setMonthlyReport(data.monthlyReport[0]);
        const objectString = JSON.stringify(data.monthlyReport[0]);
        localStorage.setItem("MReport", objectString);
      } else {
        setMonthlyReport({});
        localStorage.removeItem("MReport");
      }
     
      setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const getInfo = async (value, opt, inds) => {
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    if (rolee === "Investigator") {
      getInfoInvestigator();
    } else {
      let d = {
        InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
        RptMonth: assM !== "" ? parseInt(assM) : "",
        DirectorateId: masState.diId > 0 ? parseInt(masState.diId) : 0,
        InstituteId: masState.inTu > 0 ? parseInt(masState.inTu) : 0,
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ADMINDIRECTOR}`,
          d,
          { headers: h }
        );
        console.log(data);setLoader(false)
        if (data.monthlyReport?.length > 0) {
          const di = JSON.stringify(d);
          localStorage.setItem("getinfoDId", di);
          setMonthlyReport(data.monthlyReport[0]);
          const objectString = JSON.stringify(data.monthlyReport[0]);
          localStorage.setItem("MReport", objectString);
          console.log(data);
        } else {
          setMonthlyReport({});
          localStorage.removeItem("MReport");
        }
      } catch (error) {
        console.log(error);setLoader(false)
      }
    }
  };

  return (
    <div  style={{marginTop:120}}>
      <div className="master-main d-flex">
      <ComponentLoader isLoading={loader} />
        {/* <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div> */}
        <div className="master-main-span">
          <span>TRRI - Reporting System</span>
        </div>
      </div>

<Row>
  <Col xs={12} sm={7} lg={6} xl={4} style={{paddingRight:"0px"}}>
  <div className="reportsystem-header d-flex">
        <div>
          <div className="selectmonth">
            <select
              value={assM}
              onChange={(e) => {
                handleCountRender();
                handleCount(
                  e.target.value,
                  e.target.options,
                  e.target.selectedIndex
                );
              }}
            
              name="months"
            >
             <option value="">Select Month</option>
              {monthData?.map((item) => (
                <option value={item.monthCode}> {item.monthName} </option>
              ))
              }
            </select>
          </div>
        </div>
        <div className="reportingsystem-btn">
          <button onClick={() => {
              if(assM !==""){
                getInfo()
              }else{
                alert("Please Select Month");
              }
         }}>Get Info</button>
        </div>
      </div>
  </Col>
</Row>
      
    </div>
  );
}

export default Reportingsystem;
