import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import { A5VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArA2Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [postGroup, setPostGroup] = useState("");
  const [post, setPost] = useState("");
  const [sanctionedPostMain, setSanctionedPostMain] = useState("");
  const [sanctionedPostScheme, setSanctionedPostScheme] = useState("");
  const [positionMain, setPositionMain] = useState("");
  const [positionScheme, setPositionScheme] = useState("");
  const [vacancyMain, setVacancyMain] = useState("");
  const [vacancyScheme, setVacancyScheme] = useState("");
  const [loader,setLoader] =useState(true)
  const [postGroupErr, setPostGroupErr] = useState(false);
  const [postErr, setPostErr] = useState(false);
  const [sanctionedPostMainErr, setSanctionedPostMainErr] = useState(false);
  const [sanctionedPostSchemeErr, setSanctionedPostSchemeErr] = useState(false);
  const [positionMainErr, setPositionMainErr] = useState(false);
  const [positionSchemeErr, setPositionSchemeErr] = useState(false);
  const [vacancyMainErr, setVacancyMainErr] = useState(false);
  const [vacancySchemeErr, setVacancySchemeErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [Lovrole, setLovrole] = useState([]);
  const [LovroleParam, setLovroleParam] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const [check,setCheck] = useState(0)

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A5VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student Guidance");
  }, []);

   useEffect(()=>{
    const tok = localStorage.getItem("token");
    if(location.state && check === 0){
      editHandleLovParam(location.state.postGroup,tok)
      setCheck(1)
    }
   
   },[])

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
  }, []);

  const backs = () => {
    navigate("/ArA2");
  };

  const edit = (item) => {
    
    console.log(item);
    setPostGroup(item.postGroup);
    setPost(item.post);
    setSanctionedPostMain(item.sanctionedPostMain);
    setSanctionedPostScheme(item.sanctionedPostScheme);
    setPositionMain(item.positionMain);
    setPositionScheme(item.positionScheme);
    setVacancyMain(item.vacanyMain);
    setVacancyScheme(item.vacanyScheme);
    setId(item.id);
  };

console.log(vacancyScheme);
  // Lov
  const handleLov = async (token, PostGroup) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"PostGroup/"}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      setLovrole(data);
      // setPost(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const editHandleLovParam = async (value,token) => {


    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${value}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      setLovroleParam(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  // Lov
  const handleLovParam = async (value) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${value}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovroleParam(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      PostGroup: postGroup,
      Post: post,
      SanctionedPostMain: sanctionedPostMain,
      SanctionedPostScheme: sanctionedPostScheme,
      PositionMain: positionMain,
      PositionScheme: positionScheme,
      VacanyMain: vacancyMain,
      VacanyScheme: parseInt(vacancyScheme),
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Ar2SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArA2");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Ar2EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArA2");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const formValidateFun = () => {
    if (!postGroup) {
      setPostGroupErr(true);
      document.getElementById("postGroup").focus();
    } else if (!post) {
      setPostErr(true);
      document.getElementById("post").focus();
    } else if (!sanctionedPostMain) {
      setSanctionedPostMainErr(true);
      document.getElementById("sanctionedPostMain").focus();
    } else if (!sanctionedPostScheme) {
      setSanctionedPostSchemeErr(true);
      document.getElementById("sanctionedPostScheme").focus();
    } else if (!positionMain) {
      setPositionMainErr(true);
      document.getElementById("positionMain").focus();
    } else if (!positionScheme) {
      setPositionSchemeErr(true);
      document.getElementById("positionScheme").focus();
    } else if (!vacancyMain) {
      setVacancyMainErr(true);
      document.getElementById("vacancyMain").focus();
    } else if (!vacancyScheme) {
      setVacancySchemeErr(true);
      document.getElementById("vacancyScheme").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>A2.  Staff Info
 </span>
        </div>
      </div>
      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
        <div className="form-control">
          <h5 style={{ margin: 20 }}> A2.  Staff Info
 </h5>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={postGroupErr}
            id="postGroup"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Post Group
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="postGroup"
              value={postGroup}
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setPostGroup(e.target.value);
                setPostGroupErr(false);
                handleLovParam(e.target.value)
              }}
            >
              {Lovrole?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={postErr}
            id="post"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Post 
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="post"
              value={post}
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setPost(e.target.value);
                setPostErr(false);
              }}
            >
              {LovroleParam?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={sanctionedPostMainErr}
            id="sanctionedPostMain"
            label="Sanctioned Post Main (Only Integer Value)"
            type="number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={sanctionedPostMain}
            onChange={(e) => {
              setSanctionedPostMain(e.target.value);
              setSanctionedPostMainErr(false);
            }}
          />

          <TextField
            error={sanctionedPostSchemeErr}
            id="sanctionedPostScheme"
            label="Sanctioned Post Scheme (Only Integer Value)"
            type="number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={sanctionedPostScheme}
            onChange={(e) => {
              setSanctionedPostScheme(e.target.value);
              setSanctionedPostSchemeErr(false);
            }}
          />

          <TextField
            error={positionMainErr}
            id="positionMain"
            label="Position Main (Only Integer Value)"
            type="number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={positionMain}
            onChange={(e) => {
              setPositionMain(e.target.value);
              setPositionMainErr(false);
            }}
          />

          <TextField
            error={positionSchemeErr}
            id="positionScheme"
            label="Position Scheme (Only Integer Value)"
            type="number"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={positionScheme}
            onChange={(e) => {
              setPositionScheme(e.target.value);
              setPositionSchemeErr(false);
            }}
          />

          <TextField
            error={vacancyMainErr}
            id="vacancyMain"
            label="Vacancy Main (Only Integer Value)"
            variant="outlined"
            type="number"
            value={vacancyMain}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setVacancyMain(e.target.value);
              setVacancyMainErr(false);
            }}
          />

          <TextField
            error={vacancySchemeErr}
            id="vacancyScheme"
            label="Vacancy Scheme (Only Integer Value)"
            variant="outlined"
            type="number"
            style={{ margin: "10px", width: "48%" }}
            value={vacancyScheme}
            onChange={(e) => {
              setVacancyScheme(e.target.value);
              setVacancySchemeErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA",padding:"5px",borderRadius:"3px",width:"80px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun}
              style={{paddingTop:"5px"}}
            >
              Save
            </button>
          </div>
        </div>
      {/* </form> */}
    </div>
  );
}

export default ArA2Inside;
