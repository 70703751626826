import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";

// import { A3VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import { dateFormat } from "../../../utils/Date";
import ComponentLoader from "../../../ComponentLoader";

function ArB4Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [options, setOptions] = useState([]);
  // Comman Id Send to set value
  const [courseDetailsId, setCourseDetailsId] = useState("");

  const [courseDetails, setCourseDetails] = useState(null);
  const [date, setdate] = useState("");
  const [GuestSpeaker, setGuestSpeaker] = useState("");
  const [Affiliation, setAffiliation] = useState("");
  const [Titleofthelecture, setTitleofthelecture] = useState("");

  const [inves, setInves] = useState([]);
  const [facultyErr,setFacultyErr] =useState(false);
  const [faculty,setfaculty]=useState("");
  const [loader,setLoader] =useState(true)
  const [courseDetailsErr, setCourseDetailsErr] = useState(false);
  const [dateErr, setdateErr] = useState(false);
  const [GuestSpeakerErr, setGuestSpeakerErr] = useState(false);
  const [AffiliationErr, setAffiliationErr] = useState(false);
  const [TitleofthelectureErr, setTitleofthelectureErr] = useState(false);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti,setInsti] =useState()

  const [dropcourse, setDropcourse] = useState([]);

  const [CourseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [dropcoursetype, setDropcoursetype] = useState([]);
  const [dropnature, setDropnature] = useState([]);
  const [courseNo, setCourseNo] = useState("");

  const [hourss, sethourss] = useState("");

  // const [state,setState] = useState();
  // const [assM,setAssMonth] = useState("");
  // const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();
  console.log(TrnMonthlyReport);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A3VALI),
  });

  const backs = () => {
    navigate("/ArB4");
  };
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleNaturetypes(tok);
      handleCoursetypes(tok);
     
      console.log(location.state.trnMonthlyReport);
      if (location.state.TrnMonthlyReport) {
        console.log(location.state.TrnMonthlyReport);
        setTrnMonthlyReport(location.state.TrnMonthlyReport);
      } else {
        edit(location.state);
      }
    }
    head("Guest Lectures");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInsti(investigator);
    handleInvestigators(tok,investigator)
  }, []);

  const reload = () => {
    window.location.reload();
  };

  const edit = (item) => {
    console.log(item);
    setfaculty(item.investigatorId);
    setCourseDetailsId(item.courseId);
    // setdate(moment(item.guestLectureDate).format("YYYY-MM-DD"));
    setdate(dateFormat(item.guestLectureDate));
    setGuestSpeaker(item.guestSpeaker);
    setAffiliation(item.affiliation);
    setTitleofthelecture(item.titleofthelecture);
    setId(item.id);
  };
   
  useEffect(() => {
    console.log("========== ", options);
    options.forEach((item) => {
      console.log(item);
    });
    const getValue = options.find((data) => data.id === courseDetailsId);
    setCourseDetails(getValue);
  }, [dropcourse, courseDetailsId]);

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  //dropdown

  const handleCoursetypes = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcoursetype(data);
      setDropcourse(data);
      setOptions(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleNaturetypes = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnature(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      InvestigatorId:faculty,
      CourseNumber: courseDetails.courseNo,
      CourseId: parseInt(courseDetails.id),
      Hours: courseDetails.hours,
      GuestSpeaker: GuestSpeaker,
      Affiliation: Affiliation,
      GuestLectureDate: date,
      Titleofthelecture: Titleofthelecture,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB4SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArB4");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
          // handleLeavepage(token);
        } else {
          toastError(data.errorMsgs);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB4EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArB4");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDialog = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      CourseNo: courseNo,
      CourseName: CourseName,
      // CourseType: courseType,
      Hours: hourss,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COURSESAVEBUT}`,
        d,
        { headers: h }
      );
      console.log(data);
      handleCoursetypes(token);
      if (data.result == "Success") {
        alert("Successfully Submitted");
        // reload();
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(errors);

  const formValidateFun = (e) => {
    e.preventDefault();
    if(!faculty){
      setFacultyErr(true);
      document.getElementById("faculty").focus();
    }
    else if (!courseDetails || Object.keys(courseDetails).length === 0) {
      setCourseDetailsErr(true);
      document.getElementById("dropcourse").focus();
    
    }
    else if (!date) {
      setdateErr(true);
      document.getElementById("date").focus();
    } 
    else if (!GuestSpeaker) {
      setGuestSpeakerErr(true);
      document.getElementById("GuestSpeaker").focus();}
    else if (!Affiliation) {
      setAffiliationErr(true);
      document.getElementById("Affiliation").focus();
    } else if (!Titleofthelecture) {
      setTitleofthelectureErr(true);
      document.getElementById("Titleofthelecture").focus();
    }
    else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
       
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>Course</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon className="dialogue_close_icon" style={{ backgroundColor: "black" }}/>
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course No (Max 50 Character)"
                  // value={courseNo}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setCourseNo(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course Name"
                  variant="outlined"
                  size="small"
                  // value={CourseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Hours (Max 50 Character)"
                  variant="outlined"
                  size="small"
                  // value={hourss}
                  onChange={(e) => sethourss(e.target.value)}
                />
              </div>

              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handleDialog}>
              Submit
            </button>
          </DialogActions>
        </Dialog>

        <div className="master-main-span">
          <span> B4. Guest Lectures organized </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ margin: 20 }}> B4. Guest Lectures organized </h5>

          {/* <TextField
            id="outlined-basic"
            label="Course No"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Natureofcoaching}

            // onChange={(e) => setCourseNumber(e.target.value)}

            {...register("CourseNumberv")}
            error={errors.CourseNumberv ? true : false}
            helperText={errors.CourseNumberv?.message}
          /> */}

          <div className="d-flex">

          <FormControl
              style={{ width: "48%", margin: 12 }}
              // error={facultyErr}
              id="InvestigatorId"
              size="small"
            >
              <InputLabel id="demo-simple-select-helper-label" size="small">
                Name of the faculty            </InputLabel>
              <Select
                variant="outlined"
                size="small"
                name="InvestigatorId"
                error={facultyErr}
                style={{ height: "45px" }}
                value={faculty}
                onChange={(e) => {
                  setfaculty(e.target.value);
                 setFacultyErr(false);
                }}
              >
                {inves?.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.id}>
                      {option.investigatorName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              size="small"
              style={{ margin: "10px", width: "48%" }}
              onChange={(e,val)=>{
                setCourseDetails(val);
                setCourseDetailsErr(false);
              }}
              getOptionLabel={(option) =>
                `${option.courseNo} - ${option.courseName} - ${option.hours}`
              }
              value={courseDetails}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Course"
                  error={courseDetailsErr}
                />
              )}
            />
            {/* <FormControl style={{ width: "94%", margin: 12 }}>
              <Autocomplete
                id="demo-simple-select"
                options={dropcourse || []}
                getOptionLabel={(option) => `${option.courseNo} - ${option.courseName} - ${option.hours}`}
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option.courseName.toLowerCase().includes(inputValue) ||
                      option.courseNo.toLowerCase().includes(inputValue) ||
                      option.hours
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  );
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValue("CourseIdv", newValue.id);
                    setValue("CourseNumberv", newValue.courseNo);
                    setValue("hoursv", newValue.hours);
                  } else {
                    setValue("CourseIdv", null);
                    setValue("CourseNumberv", null);
                    setValue("hoursv", null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Course"
                    size="small"
                    sx={{ width: "100%" }}
                    error={errors.CourseIdV ? true : false}
                    helperText={errors.CourseIdV?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.id} value={option}>
                    {option.courseNo} - {option.courseName} -{" "}
                    {option.hours}
                  </MenuItem>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <MenuItem key={option.id} {...getTagProps({ index })}>
                      {option.courseNo} - {option.courseName} -{" "}
                      {option.hours}
                    </MenuItem>
                  ))
                }
              />
            </FormControl> */}
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          <TextField
            error={dateErr}
            id="date"
            label="Date Of Guest Lecture"
            type="date"
            variant="outlined"
            value={date}
            style={{ margin: "10px", width: "48%" }}
            onChange={(e) => {
              setdate(e.target.value);
              setdateErr(false);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={GuestSpeakerErr}
            id="GuestSpeaker"
            label="Guest Speaker (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={GuestSpeaker}
            onChange={(e) => {
              setGuestSpeaker(e.target.value);
              setGuestSpeakerErr(false);
            }}
          />

          <TextField
            error={AffiliationErr}
            id="Affiliation"
            label=" Affiliation (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Affiliation}
            onChange={(e) => {
              setAffiliation(e.target.value);
              setAffiliationErr(false);
            }}
          />

          <TextField
            error={TitleofthelectureErr}
            id="Titleofthelecture"
            label="Title of the Lecture (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Titleofthelecture}
            onChange={(e) => {
              setTitleofthelecture(e.target.value);
              setTitleofthelectureErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" >
            Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArB4Inside;
