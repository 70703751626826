import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import moment from "moment/moment";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { B10VALIDATION } from "../../../Validation";

// import { Activetiesvalidation } from '../Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";
import { dateFormat } from "../../../../utils/Date";

function B10Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  //   const [Natureofcoaching, setNatureofcoaching] = useState("");
  //   const [Noofclassesoffered, setNoofclassesoffered] = useState("");
  //   const [FellowshipName, setFellowshipName] = useState("");
  //   const [NoOfStudent, setNoOfStudent] = useState("");
  const [drop, setdrop] = useState([]);

  const [LovInvestype, setLovInvestype] = useState([]);
  const [loader,setLoader] =useState(true);
  const [dropLov, setdropLov] = useState("");
  const [Titleofthescheme, setTitleofthescheme] = useState("");
  const [ProjectType, setProjectType] = useState("");
  const [Picopi, setPicopi] = useState("");
  const [Budget, setBudget] = useState("");
  const [Projectstarttime, setProjectstarttime] = useState("");
  const [Projectendtime, setProjectendtime] = useState("");
  const [Progress, setProgress] = useState("");
  const [projectNo,setProjectNo] =useState("")

  const [TitleoftheschemeErr, setTitleoftheschemeErr] = useState("");
  const [ProjectTypeErr, setProjectTypeErr] = useState(false);
  const [PicopiErr, setPicopiErr] = useState("");
  const [InvestigatortypeErr, setInvestigatortypeErr] = useState("");
  const [BudgetErr, setBudgetErr] = useState("");
  const [ProjectstarttimeErr, setProjectstarttimeErr] = useState("");
  const [ProjectendtimeErr, setProjectendtimeErr] = useState("");
  const [ProgressErr, setProgressErr] = useState("");
  const [projectNoErr,setProjectNoErr] =useState(false)

  const [depatIdProjectType, setDepatIdProjectType] = useState("");
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B10VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      handleLovType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Research Projects");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setProjectNo(item.projectNo)
    setTitleofthescheme(item.titleoftheScheme);
    setProjectType(item.projectType);
    setPicopi(item.investigatorType);
    setBudget(item.budget);
    setProjectstarttime(dateFormat(item.projectStartTime));
    setProjectendtime(dateFormat(item.projectEndTime));
    setProgress(item.progress);
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleBack = () => {
    navigate("/B10");
  };

  // const SubmitBtn = () => {
  //   alert("Successfully Submitted");
  // };

  // Lov Investigator type
  const handleLov = async (token, InvestigatorType) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"InvestigatorType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovInvestype(data);
    } catch (error) {
      console.log(error);
    }
  };


  // Lov Type //
  const handleLovType = async (token, ProjectType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"ProjectType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setdrop(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      projectNo:projectNo,
      TitleoftheScheme: Titleofthescheme,
      ProjectType: ProjectType,
      InvestigatorType: Picopi,
      Budget: Budget,
      ProjectStartTime: Projectstarttime,
      ProjectEndTime: Projectendtime,
      Progress: Progress,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B10SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B10");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B10EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B10");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if(!projectNo){
      setProjectNoErr(true);
      document.getElementById("projectNo").focus();
    }else if (!Titleofthescheme) {
      setTitleoftheschemeErr(true);
      document.getElementById("Titleofthescheme").focus();
    } 
    else if (!ProjectType) {
      setProjectTypeErr(true);
      document.getElementById("ProjectType").focus();
    } 
    
    else if (!Picopi) {
      setPicopiErr(true);
      document.getElementById("Picopi").focus();
    } else if (!Budget) {
      setBudgetErr(true);
      document.getElementById("Budget").focus();
    } else if (!Projectstarttime) {
      setProjectstarttimeErr(true);
      document.getElementById("Projectstarttime").focus();
    } else if (!Projectendtime) {
      setProjectendtimeErr(true);
      document.getElementById("Projectendtime").focus();
    } else if (!Progress) {
      setProgressErr(true);
      document.getElementById("Progress").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Research Projects (B10)</span>
        </div>
      </div>
      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>
            B10. Research Projects – On going
          </h5>


          <TextField
            error={projectNoErr}
            id="projectNo"
            label="Project No"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={projectNo}
            onChange={(e) => {
              setProjectNo(e.target.value);
              setProjectNoErr(false);
            }}
          />

          <TextField
            error={TitleoftheschemeErr}
            id="Titleofthescheme"
            label="Title of the Scheme (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Titleofthescheme}
            onChange={(e) => {
              setTitleofthescheme(e.target.value);
              setTitleoftheschemeErr(false);
            }}
          />

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={ProjectTypeErr}
            id="ProjectType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Project Type
            </InputLabel>
            <Select
              variant="outlined"
              style={{ height: "45px" }}
              value={ProjectType}
              onChange={(e) => {
                setProjectType(e.target.value);
                setProjectTypeErr(false);
              }}
            >
              {drop?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
            error={PicopiErr}
            id="Picopi"
            label="Role"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Picopi}
            onChange={(e) => {
              setPicopi(e.target.value);
              setPicopiErr(false);
            }}
          /> */}

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={PicopiErr}
            id="Picopi"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={Picopi}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setPicopi(e.target.value);
                setPicopiErr(false);
              }}
            >
              {LovInvestype?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={BudgetErr}
            id="Budget"
            label="Budget(In Lakhs)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Budget}
            onChange={(e) => {
              setBudget(e.target.value);
              setBudgetErr(false);
            }}
          />

          <TextField
            error={ProjectstarttimeErr}
            id="Projectstarttime"
            label="Project Start Time"
            type="Date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Projectstarttime}
            onChange={(e) => {
              console.log("==============", e.target.value);
              setProjectstarttime(e.target.value);
              setProjectstarttimeErr(false);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={ProjectendtimeErr}
            id="Projectendtime"
            label="Project End Time"
            type="Date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Projectendtime}
            onChange={(e) => {
              console.log(e.target.value);
              setProjectendtime(e.target.value);
              setProjectendtimeErr(false);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={ProgressErr}
            id="Progress"
            label="Progress during the Month  (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Progress}
            onChange={(e) => {
              setProgress(e.target.value);
              setProgressErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
              style={{paddingTop:"5px"}}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={formValidateFun}
              style={{paddingTop:"5px"}}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B10Inside;
