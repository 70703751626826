import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  FormControl,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { D5VALI } from "../../../Validation";
import { Box } from "react-bootstrap-icons";
import moment from "moment/moment";
import ComponentLoader from "../../../ComponentLoader";

function ArE1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [inves, setInves] = useState([]);
  const [fundingAgency, setfundingAgency] = useState([]);

  // InvestigatorId,MeetingName,MeetingDate,NoofMale,NoOfFemale,NoofSCST,Total,FundingAgencyId,TargetGroup,Budget
  const [investigatorName, setInvestigatorName] = useState("")
  const [meetingName, setMeetingName] = useState("")
  const [MeetingDate, setMeetingDate] = useState("")
  const [noofMale, setNoofMale] = useState("")
  const [noofFeMale, setNoofFeMale] = useState("")
  const [noofScst, setNoofScst] = useState("")
  const [total, setTotal] = useState(0)
  const [fundingAgencyName, setFundingAgencyName] = useState("")
  const [targetGroup, setTargetGroup] = useState("")
  const [budget, setBudget] = useState("")
  const [loader, setLoader] = useState(true);
  const [investigatorNameErr, setInvestigatorNameErr] = useState(false)
  const [meetingNameErr, setMeetingNameErr] = useState(false)
  const [MeetingDateErr, setMeetingDateErr] = useState(false)
  const [noofMaleErr, setNoofMaleErr] = useState(false)
  const [noofFeMaleErr, setNoofFeMaleErr] = useState(false)
  const [noofScstErr, setNoofScstErr] = useState(false)
  const [totalErr, setTotalErr] = useState(false)
  const [fundingAgencyNameErr, setFundingAgencyNameErr] = useState(false)
  const [targetGroupErr, setTargetGroupErr] = useState(false)
  const [budgetErr, setBudgetErr] = useState(false)

  const [stateType, setStateType] = useState([]);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(D5VALI),
  });

  const backs = () => {
    navigate("/ArE1");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {

      handleFundingAgency(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("items purchased");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok, institute);
  }, []);

  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId);
    setMeetingName(item.meetingName);
    setMeetingDate(moment(item.meetingDate).format("YYYY-MM-DD"));
    setNoofMale(item.noofMale);
    setNoofFeMale(item.noOfFemale);
    setNoofScst(item.noofSCST);
    setTotal(item.total);
    setFundingAgencyName(item.fundingAgencyId);
    setTargetGroup(item.targetGroup);
    setBudget(item.budget);
    setId(item.id);
  };

  const handleFirstChange = (e) => {
    setNoofMale();
  };

  const handleSecondChange = (e) => {
    setNoofFeMale();
  };

  const handleThirdChange = (e) => {
    setNoofScst();
  };

  //Funding Agency //
  const handleFundingAgency = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.FUNDINGGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setfundingAgency(data); setLoader(false);
      }
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  // Investigator
  const handleInvestigators = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data); setLoader(false);
      setInves(data);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      InvestigatorId: investigatorName,
      MeetingName: meetingName,
      MeetingDate: MeetingDate,
      NoofMale: noofMale,
      NoOfFemale: noofFeMale,
      NoofSCST: noofScst,
      Total: total,
      FundingAgencyId: fundingAgencyName,
      TargetGroup: targetGroup,
      Budget: budget,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Er1SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArE1");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.Er1EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArE1");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!investigatorName) {
      setInvestigatorNameErr(true);
      document.getElementById("investigatorName").focus();
    } else if (!meetingName) {
      setMeetingNameErr(true);
      document.getElementById("meetingName").focus();
    } else if (!MeetingDate) {
      setMeetingDateErr(true);
      document.getElementById("MeetingDate").focus();
    } else if (!noofMale) {
      setNoofMaleErr(true);
      document.getElementById("noofMale").focus();
    } else if (!noofFeMale) {
      setNoofFeMaleErr(true);
      document.getElementById("noofFeMale").focus();
    } else if (!noofScst) {
      setNoofScstErr(true);
      document.getElementById("noofScst").focus();
    } else if (!total) {
      setTotalErr(true);
      document.getElementById("total").focus();
    } else if (!fundingAgencyName) {
      setFundingAgencyNameErr(true);
      document.getElementById("fundingAgencyName").focus();
    } else if (!targetGroup) {
      setTargetGroupErr(true);
      document.getElementById("targetGroup").focus();
    } else if (!budget) {
      setBudgetErr(true);
      document.getElementById("budget").focus();
    } else {
      handleLeavepage();
    }
  };

  useEffect(() => {
    let male = noofMale ? parseInt(noofMale) : 0;
    let female = noofFeMale ? parseInt(noofFeMale) : 0;
    let scst = noofScst ? parseInt(noofScst) : 0;
    let tot = (male + female + scst)
    setTotal(tot)
  }, [noofMale, noofFeMale, noofScst])

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span> E1. Trainings / Meetings / Field Days organized</span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}> */}
      <div className="form-control">
        <h5 style={{ margin: 20 }}>
          {" "}
          E1. Trainings / Meetings / Field Days organized
        </h5>

        <FormControl
          style={{ width: "48%", margin: 12 }}
          error={investigatorNameErr}
          id="investigatorName"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Name of the faculty
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="investigatorName"
            value={investigatorName}
            label="Programme Type"
            size="small"
            sx={{ width: "100%" }}
            onChange={(e) => {
              setInvestigatorName(e.target.value);
              setInvestigatorNameErr(false);
            }}
          >
            {inves?.map((row, index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          error={meetingNameErr}
          id="meetingName"
          label="Name of the Meeting (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={meetingName}
          onChange={(e) => {
            setMeetingName(e.target.value);
            setMeetingNameErr(false);
          }}
        />

        <TextField
          error={MeetingDateErr}
          id="MeetingDate"
          label="Meeting Date"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={MeetingDate}
          onChange={(e) => {
            setMeetingDate(e.target.value);
            setMeetingDateErr(false);
          }}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        // InputProps={{
        //   placeholder: "",
        // }}
        />

        <TextField
          error={noofMaleErr}
          id="noofMale"
          label="Number of participants (Male)"
          variant="outlined"
          type="number"
          style={{ margin: "10px", width: "48%" }}
          value={noofMale}
          onChange={(e) => {
            handleFirstChange()
            setNoofMale(e.target.value);
            setNoofMaleErr(false);
          }}
        />

        <TextField
          error={noofFeMaleErr}
          id="noofFeMale"
          type="number"
          label="Number of participants (Female)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={noofFeMale}
          onChange={(e) => {
            handleSecondChange()
            setNoofFeMale(e.target.value);
            setNoofFeMaleErr(false);
          }}
        />

        <TextField
          error={noofScstErr}
          id="noofScst"
          label="Number of participants (SC/ST)"
          variant="outlined"
          type="number"
          style={{ margin: "10px", width: "48%" }}
          value={noofScst}
          onChange={(e) => {
            handleThirdChange()
            setNoofScst(e.target.value);
            setNoofScstErr(false);
          }}
        />

        <TextField
          error={totalErr}
          id="total"
          label="Number of participants (Total)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={total}
          onChange={(e) => {
            setTotal();
            // setTotalErr(false);
          }}
          disabled
        />

        <FormControl
          style={{ width: "48%", margin: 12 }}
          error={fundingAgencyNameErr}
          id="fundingAgencyName"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Funding Agency Name
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="fundingAgencyName"
            value={fundingAgencyName}
            label="Programme Type"
            size="small"
            sx={{ width: "100%" }}
            onChange={(e) => {
              setFundingAgencyName(e.target.value);
              setFundingAgencyNameErr(false);
            }}
          >
            {fundingAgency?.map((row, index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.fundingAgencyName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          error={targetGroupErr}
          id="targetGroup"
          label="Target group (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={targetGroup}
          onChange={(e) => {
            setTargetGroup(e.target.value);
            setTargetGroupErr(false);
          }}
        />

        <TextField
          error={budgetErr}
          id="budget"
          label="Budget(Rs. in  Lakhs) (Max 500 Character)"
          variant="outlined"
          style={{ margin: "10px", width: "48%" }}
          value={budget}
          onChange={(e) => {
            setBudget(e.target.value);
            setBudgetErr(false);
          }}
        />

        <div className="from-last">
          <button
            type="submit"
            style={{
              backgroundColor: "#008CBA",
              padding: "5px",
              borderRadius: "3px",
              width: "80px",
              color: "#fff",
            }}
            onClick={backs}
          >
            Back
          </button>
          <button
            type="submit"
            style={{ paddingTop: "5px" }}
            className="sbmit-buttons"
            onClick={formValidateFun}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArE1Inside;
