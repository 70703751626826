import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { D4VALI } from "../../../Validation";
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import moment from "moment";
import ComponentLoader from "../../../../ComponentLoader";

// import { DatePicker } from '@mui/lab';

function D4Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [loader,setLoader] =useState(true);
  const [visitorName, setVisitorName] = useState("");
  const [visitingDate, setVisitingDate] = useState("");
  const [purpose, setPurpose] = useState("");

  const [visitorNameErr, setVisitorNameErr] = useState(false);
  const [visitingDateErr, setVisitingDateErr] = useState(false);
  const [purposeErr, setPurposeErr] = useState(false);

  const [activity, setActivity] = useState("");
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const backs = () => {
    navigate("/D4");
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(D4VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("individual scientist");
    setLoader(false)
  }, []);
  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setVisitorName(item.visitorName);
    setVisitingDate(moment(item.visitingDate).format("YYYY-MM-DD"));
    setPurpose(item.purpose);
    // setValue("Activityv",item.activity)
    setId(item.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      id,
      VisitorName: visitorName,
      VisitingDate: visitingDate,
      Purpose: purpose,
      // Activity:data.Activityv,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.D4SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/D4");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.D4EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/D4");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!visitorName){
      setVisitorNameErr(true)
      document.getElementById("visitorName").focus()
    }else if(!visitingDate){
      setVisitingDateErr(true)
      document.getElementById("visitingDate").focus()
    }else if(!purpose){
      setPurposeErr(true)
      document.getElementById("purpose").focus()
    }else{
      handleLeavepage()
    }
   }

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(D4). Visitors handled individual scientist</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            {" "}
            D4. Visitors handled by the individual scientist
          </h5>
          <TextField
            error={visitorNameErr}
            id="visitorName"
            label=" Name of the Visitor"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={visitorName}
            onChange={(e) => {
                setVisitorName(e.target.value)
                setVisitorNameErr(false)
              }}
          />

          <TextField
            error={visitingDateErr}
            id="visitingDate"
            label="Date"
            variant="outlined"
            type="date"
            value={visitingDate}
            onChange={(e) => {
                setVisitingDate(e.target.value)
                setVisitingDateErr(false)
              }}
            style={{ margin: "10px", width: "48%" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
          />

          <TextField
            error={purposeErr}
            id="purpose"
            label="Purpose (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={purpose}
            onChange={(e) => {
                setPurpose(e.target.value)
                setPurposeErr(false)
              }}
          />

          {/* <TextField id="outlined-basic"
                        label="Activity"
                        variant="outlined"
                        style={{ margin: '10px', width: "48%" }}
                        // value={FellowshipName}
                        // onChange={(e) => setFellowshipName(e.target.value)}
                        {...register("Activityv")}
                        error={errors.Activityv ? true : false}
                        helperText={errors.Activityv?.message}
                    /> */}

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default D4Inside;
