import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";
import axios from "axios";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { toastSuccess } from "../../utils/toast";
import ComponentLoader from "../../ComponentLoader";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: "500px",
    minWidth: "500px",
  },
  pa: {
    padding: "10px 30px 10px 30px",
  },
});

const InvestigatorInsdie = () => {
  const classes = useStyles();
  const [institue, setinstitue] = useState("");
  const [investigatorname, setInvestigatorName] = useState("");
  const [qualification, setQualification] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");
  const [doj, setDoj] = useState("");
  const [specialization, setspecialization] = useState("");
  const [directorName, setdirectorName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const [upfcpFNo, setUPFCPFNo] = useState("");
  const [googleScholarID, setGoogleScholarID] = useState("");
  const [irinsid, setIRINSID] = useState("");
  const [password, setpassword] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [position, setPosition] = useState("");
  const [facultyType, setFacultyType] = useState("");
  const [open, setOpen] = React.useState(false);
  console.log(facultyType);
  const [loader, setLoader] = useState(true);

  const [token, setToken] = useState(null);
  const [id, setId] = useState(0);

  const [dir, setDir] = useState([]);
  const [di, setDepartment] = useState([]);
  const [transferdata, setTransferdata] = useState("");
  const [transfer, setTransfer] = useState([]);
  const [transfernotes, setTransferNotes] = useState("");
  const [transferdate, setTransferDate] = useState("");

  const [openblock, setOpenblock] = useState(false);
  const [blockdate, setBlockdate] = useState("");
  const [blocknotes, setBlocknotes] = useState("");

  //error
  const [facultyTypeErr, setFacultyTypeErr] = useState(false);
  const [institueErr, setinstitueErr] = useState(false);
  const [investigatornameErr, setInvestigatorNameErr] = useState(false);
  const [qualificationErr, setQualificationErr] = useState(false);
  const [disciplineErr, setDisciplineErr] = useState(false);
  const [designationErr, setDesignationErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [dojErr, setDojErr] = useState(false);
  const [specializationErr, setspecializationErr] = useState(false);
  const [directorNameErr, setdirectorNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [mobilenoErr, setMobileNoErr] = useState(false);
  const [upfcpFNoErr, setUPFCPFNoErr] = useState(false);
  const [googleScholarIDErr, setGoogleScholarIDErr] = useState(false);
  const [irinsidErr, setIRINSIDErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [resetPasswordErr, setResetPasswordErr] = useState(false);
  const [positionErr, SetPositionErr] = useState(false);

  const [bloackdateErr, setBlockdateErr] = useState(false);

  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [role, setRole] = useState("");
  const [dirId, setDirId] = useState("");
  const [institudeName, setInstitudeName] = useState("");
  console.log(showSubmitButton, facultyType, "jgasjvascadgc");

  const location = useLocation();

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    const tok = localStorage.getItem("token");
    const userID_ = localStorage.getItem("loginId");
    const rolee = localStorage.getItem("role");
    const insName = localStorage.getItem("Name");
    const directId = localStorage.getItem("DirectorID");
    setRole(rolee);
    if (rolee === "Principal") {
      setinstitue(userID_);
      setInstitudeName(insName);
      setDirId(directId);
    } else if (rolee === "Director") {
      setDirId(userID_);
    }

    console.log(tok);
    if (tok != null && tok != undefined) {
      setToken(tok);
      handleInstituteName(tok);
      handleDirectorate(tok);
      handleTransfer(tok);

      if (location.state) {
        edit(location.state);
      }
    }
    // setShowSubmitButton(true)

    setTransferDate(getCurrentDate());
    setBlockdate(getCurrentDate());
  }, []);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/investigator");
  };
  const handleResetOpen = () => {
    setOpenReset(true);
  };

  const handleResetClose = () => {
    setOpenReset(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handelTransfer = () => {
    setOpen(true);
  };

  const handleBlock = () => {
    setOpenblock(true);
  };

  const handleblockClose = () => {
    setOpenblock(false);
  };

  const handleInstituteName = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INSTITUTES}`, {
        headers: h,
      });
      console.log(data);
      setLoader(false);
      setDir(data);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  //Transfer  To APi

  const handleTransfer = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEDEPARTMENT}`,
        { headers: h }
      );
      console.log(data);
      setTransfer(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectorate = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.DEPARTMENTGETALL}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDepartment(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  console.log("password", token, email, resetPassword);

  const handleResetPassword = async () => {
    console.log("password", token, email, resetPassword);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      username: email,
      password: resetPassword,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.RESTPASSWORD}`,
        d,
        { headers: h }
      );
      console.log(data);
      setLoader(false);
      if (data.result == "Password Reset Successfully.") {
        setTimeout(function () {
          alert("Successfully Reset");
          setOpenReset(false);
          navigate("/investigator");
          //   reload();
        }, 2000);
      } else {
        // toastError(data.errorMsgs[0]);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const edit = (item) => {
    console.log(item);
    // setOpen(true);
    setShowSubmitButton(true);

    setInvestigatorName(item.investigatorName);
    setQualification(item.qualification);
    setDiscipline(item.discipline);
    setDesignation(item.designation);
    setAddress(item.address);
    let dojUp = item.doj.slice(0, 10);
    setDoj(dojUp);
    setdirectorName(item.departmentId);
    setinstitue(item.instituteId);
    setEmail(item.emailId);
    setMobileNo(item.mobileNo);
    setUPFCPFNo(item.upfcpfNo);
    setGoogleScholarID(item.googleScholarID);
    setIRINSID(item.irinsid);
    setspecialization(item.specialization);
    setPosition(item.position);
    setFacultyType(item.facultyType);

    setId(item.id);
    console.log(item.id, "okai");
    // setCode(item.code);
    setInvestigatorName(item.investigatorName);
    console.log(item.specialization);
  };

  const handlePopupInvestigators = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    if (id === 0) {
      const d = {
        id,
        InvestigatorName: investigatorname,
        Qualification: qualification,
        Discipline: discipline,
        Designation: designation,
        Address: address,
        DOJ: doj,
        DepartmentId: parseInt(directorName),
        DirectorateId: parseInt(dirId),
        InstituteId: parseInt(institue),
        EmailId: email,
        Position: position,
        MobileNo: mobileno,
        UPFCPFNo: upfcpFNo,
        GoogleScholarID: googleScholarID,
        IRINSID: irinsid,
        Password: password,
        Specialization: specialization,
        FacultyType: facultyType,
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INVESTIGATORADD}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          toastSuccess("Successfully Submitted");

          navigate("/investigator");
          setId(0);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const d = {
        id,
        InvestigatorName: investigatorname,
        Qualification: qualification,
        Discipline: discipline,
        Designation: designation,
        Address: address,
        DirectorateId: parseInt(dirId),
        DOJ: doj,
        DepartmentId: parseInt(directorName),
        InstituteId: parseInt(institue),
        EmailId: email,
        MobileNo: mobileno,
        Position: position,
        UPFCPFNo: upfcpFNo,
        GoogleScholarID: googleScholarID,
        IRINSID: irinsid,
        Specialization: specialization,
        FacultyType: facultyType,
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INVESTIGATORUPDATE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          toastSuccess("Successfully updated");
          navigate("/investigator");
          setId(0);
        }
        else{
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInvestigators = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    if (id === 0) {
      const d = {
        id,
        InvestigatorName: investigatorname,
        Qualification: qualification,
        Discipline: discipline,
        Designation: designation,
        Address: address,
        DOJ: doj,
        DepartmentId: parseInt(directorName),
        DirectorateId: parseInt(dirId),
        InstituteId: parseInt(institue),
        EmailId: email,
        Position: position,
        MobileNo: mobileno,
        UPFCPFNo: upfcpFNo,
        GoogleScholarID: googleScholarID,
        IRINSID: irinsid,
        Password: password,
        Specialization: specialization,
        FacultyType: facultyType,
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.NONTEACHING_INVESTGATORADD}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          // toastSuccess("Successfully Submitted");
          alert("Successfully Submitted")
          navigate("/investigator");
          setId(0);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const d = {
        id,
        InvestigatorName: investigatorname,
        Qualification: qualification,
        Discipline: discipline,
        Designation: designation,
        Address: address,
        DirectorateId: parseInt(dirId),
        DOJ: doj,
        DepartmentId: parseInt(directorName),
        InstituteId: parseInt(institue),
        EmailId: email,
        MobileNo: mobileno,
        Position: position,
        UPFCPFNo: upfcpFNo,
        GoogleScholarID: googleScholarID,
        IRINSID: irinsid,
        Specialization: specialization,
        FacultyType: facultyType,
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INVESTIGATORUPDATE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          toastSuccess("Successfully updated");
          navigate("/investigator");
          setId(0);
        }
        else{
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleTransferData = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      InvestigatorId: id,
      NewInstitutionId: transferdata,
      TransferDate: transferdate,
      TransferNotes: transfernotes,
    };

    try {
      const { data } = await axios.post(
        `${window.BASE}${window.TRANSFER_INSTITUTE}`,
        d,
        { headers: h }
      );
      console.log(data, "sdfgdsa");
      if (data.result === "Success") {
        // toastSuccess("Successfully Transfer");
        alert("Successfully Transfer");
        navigate("/investigator");
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockdata = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      investigatorId: id,
      BlockingDate: blockdate,
      BlockReason: blocknotes,
    };

    try {
      const { data } = await axios.post(
        `${window.BASE}${window.BLOCK_INVESTIGATOR}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data.result === "Success") {
        // toastSuccess("Successfully Block");
        alert("Successfully Blocked");
        console.log("helllo");
        handleBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formValidateFun = () => {
    console.log("kuyiutyrf");
    if (!investigatorname) {
      setInvestigatorNameErr(true);
      document.getElementById("investigatorname").focus();
    } else if (!qualification) {
      setQualificationErr(true);
      document.getElementById("qualification").focus();
    } else if (!discipline) {
      setDisciplineErr(true);
      document.getElementById("discipline").focus();
    } else if (!designation) {
      setDesignationErr(true);
      document.getElementById("designation").focus();
    } else if (!address) {
      setAddressErr(true);
      document.getElementById("address").focus();
    } else if (!doj) {
      setDojErr(true);
      document.getElementById("doj").focus();
    } else if (!institue) {
      setinstitueErr(true);
      document.getElementById("institue").focus();
    } else if (!directorName) {
      setdirectorNameErr(true);
      document.getElementById("directorName").focus();
    } else if (!email) {
      setEmailErr(true);
      document.getElementById("email").focus();
    } else if (!mobileno) {
      setMobileNoErr(true);
      document.getElementById("mobileno").focus();
    } else if (!upfcpFNo) {
      setUPFCPFNoErr(true);
      document.getElementById("upfcpFNo").focus();
    } else if (!googleScholarID) {
      setGoogleScholarIDErr(true);
      document.getElementById("googleScholarID").focus();
    } else if (!irinsid) {
      setIRINSIDErr(true);
      document.getElementById("irinsid").focus();
    } else if (!specialization) {
      setspecializationErr(true);
      document.getElementById("specialization").focus();
    } else if (!position) {
      SetPositionErr(true);
      document.getElementById("position").focus();
    } else if (!facultyType) {
      setFacultyTypeErr(true);
      document.getElementById("facultyType").focus();
    }
    // else if (!blockdate) {
    //   setBlockdateErr(true);
    //   document.getElementById("blockdate").focus();
    // }
    else {
      if (facultyType === "Teaching Staff") {
        if (id === 0 && !password) {
          setpasswordErr(true);
          document.getElementById("password").focus();
        } else {
          handlePopupInvestigators();
        }
      } else if (facultyType === "Non Teaching Staff") {
        handleInvestigators();
      }
    }
  };

  return (
    // <div style={{ marginTop: 70 }}>
    //   <ComponentLoader isLoading={loader} />
    //   <div className="master-main d-flex">

    //     <div className="master-main-span">
    //       <span>Investigator </span>
    //     </div>
    //   </div>

    <div style={{ marginTop: 130 }}>
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/investigator">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Investigator</span>
        </div>
      </div>

      <Dialog
        open={openReset}
        onClose={handleResetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h5 style={{ color: "#fff", fontSize: "15px" }}>Reset Password</h5>
        </DialogTitle>
        <DialogContent style={{ padding: "30px" }}>
          {/* {id > 0 ? null : ( */}
          <div className="box width-60">
            <TextField
              error={resetPasswordErr}
              fullWidth
              id="resetPassword"
              label="Reset Password"
              // InputProps={{
              //   inputProps: {
              //     maxLength: minLength,
              //   },
              // }}
              variant="outlined"
              size="small"
              value={resetPassword}
              onChange={(e) => {
                setResetPassword(e.target.value);
                setResetPasswordErr(false);
              }}
            />
          </div>
          {/* )} */}
        </DialogContent>
        <div
          className="dialogue_action_button"
          style={{ justifyContent: "center" }}
        >
          <button type="submit" onClick={handleResetPassword}>
            Submit
          </button>
        </div>
      </Dialog>

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{        
          classes: { root: classes.root },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="dialogueTittle">
            <div>
              <h5>Transfer</h5>
            </div>
            <div onClick={handleClose}>
              <CloseIcon className="dialogue_close_icon" />
            </div>
          </div>
        </DialogTitle>

        <DialogContent style={{ padding: 10 }} className={classes.pa}>
          <div style={{ width: "90%", marginLeft: "5%" }}>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue="Small"
              label="Transfer Date"
              size="small"
              type="date"
              disabled
              value={transferdate}
              sx={{ width: "100%", marginBottom: "10px" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                placeholder: "",
                inputProps: {
                  min: getCurrentDate(),
                  max: getCurrentDate(),
                },
              }}
              onChange={(e) => {
                setTransferDate(e.target.value);
              }}
            />
            <br />

            <FormControl style={{ width: "100%" }} id="facultyType">
              <InputLabel id="demo-simple-select-helper-label" size="small">
                Transfer To
              </InputLabel>
              <Select
                variant="outlined"
                label="Faculty Type"
                style={{ height: "45px" }}
                sx={{ width: "100%", marginBottom: "10px" }}
                value={transferdata}
                onChange={(e) => {
                  setTransferdata(e.target.value);
                }}
              >
                {transfer?.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.id}>
                      {option.instituteName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <br />

            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Notes"
              multiline
              size="small"
              rows={4}
              sx={{ width: "100%", marginBottom: "10px" }}
              value={transfernotes}
              onChange={(e) => {
                setTransferNotes(e.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="dialogue_action_button">
          <button
            style={{ textAlign: "center" }}
            type="submit"
            onClick={handleTransferData}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openblock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{      
          classes: { root: classes.root },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="dialogueTittle">
            <div>
              <h5>Block</h5>
            </div>
            <div onClick={handleblockClose}>
              <CloseIcon className="dialogue_close_icon" />
            </div>
          </div>
        </DialogTitle>

        <DialogContent style={{ padding: 10 }} className={classes.pa}>
          <div style={{ width: "90%", marginLeft: "5%" }}>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              // error={bloackdateErr}
              // id="blockdate"
              defaultValue="Small"
              label="Blocking Date"
              size="small"
              type="date"
              disabled
              value={blockdate}
              sx={{ width: "100%", marginBottom: "10px" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                placeholder: "",
                // Set the minimum and maximum dates
                inputProps: {
                  min: getCurrentDate(),
                  max: getCurrentDate(),
                },
              }}
              onChange={(e) => {
                setBlockdate(e.target.value);
                // setBlockdateErr(false)
              }}
            />
            <br />
            <br />

            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Blocking Reason"
              multiline
              size="small"
              rows={4}
              sx={{ width: "100%", marginBottom: "10px" }}
              value={blocknotes}
              onChange={(e) => {
                setBlocknotes(e.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="dialogue_action_button">
          <button
            style={{ textAlign: "center" }}
            type="submit"
            onClick={handleBlockdata}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}> Investigator</h5>

          <TextField
            fullWidth
            error={investigatornameErr}
            style={{ margin: "10px", width: "46%" }}
            id="investigatorname"
            label="Investigator Name"
            value={investigatorname}
            variant="outlined"
            size="small"
            onChange={(e) => {
              setInvestigatorName(e.target.value);
              setInvestigatorNameErr(false);
            }}
          />

          <TextField
            error={qualificationErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="ualification"
            label="Qualification"
            variant="outlined"
            size="small"
            value={qualification}
            onChange={(e) => {
              setQualification(e.target.value);
              setQualificationErr(false);
            }}
          />

          <TextField
            error={disciplineErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="discipline"
            label="Discipline"
            variant="outlined"
            size="small"
            value={discipline}
            onChange={(e) => {
              setDiscipline(e.target.value);
              setDisciplineErr(false);
            }}
          />

          <TextField
            error={designationErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="designation"
            label="Designation"
            variant="outlined"
            size="small"
            value={designation}
            onChange={(e) => {
              setDesignation(e.target.value);
              setDesignationErr(false);
            }}
          />

          <TextField
            error={addressErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="address"
            label="Address"
            variant="outlined"
            size="small"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setAddressErr(false);
            }}
          />

          <TextField
            error={dojErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="outlined-basic"
            label="doj"
            variant="outlined"
            type="date"
            size="small"
            value={doj}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              placeholder: "",
            }}
            onChange={(e) => {
              setDoj(e.target.value);
              setDojErr(false);
            }}
          />

          {role === "Director" ? (
            <FormControl
              style={{ width: "45%", margin: 12 }}
              error={institueErr}
              id="instName"
            >
              <InputLabel id="demo-simple-select-helper-label" size="small">
                Institute
              </InputLabel>
              <Select
                variant="outlined"
                style={{ height: "45px" }}
                value={institue}
                onChange={(e) => {
                  setinstitue(e.target.value);
                  setinstitueErr(false);
                }}
              >
                {dir?.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.id}>
                      {option.instituteName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              style={{ margin: "10px", width: "46%" }}
              variant="outlined"
              size="small"
              disabled
              value={institudeName}
            />
          )}

          <FormControl
            style={{ width: "45%", margin: 12 }}
            error={directorNameErr}
            id="directorName"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Department Name
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              style={{ height: "45px" }}
              value={directorName}
              onChange={(e) => {
                setdirectorName(e.target.value);
                setdirectorNameErr(false);
              }}
            >
              {di?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={emailErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="email"
            label="Email Id"
            variant="outlined"
            size="small"
            value={email}
            disabled={id > 0 ? true : false}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailErr(false);
            }}
          />

          <TextField
            error={mobilenoErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="mobileno"
            label="Mobile No"
            variant="outlined"
            size="small"
            value={mobileno}
            onChange={(e) => {
              setMobileNo(e.target.value);
              setMobileNoErr(false);
            }}
          />

          <TextField
            error={specializationErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="specialization"
            label="Specialization"
            variant="outlined"
            size="small"
            value={specialization}
            onChange={(e) => {
              setspecialization(e.target.value);
              setspecializationErr(false);
            }}
          />

          <TextField
            error={upfcpFNoErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="upfcpFNo"
            label="UPFCPF NO"
            variant="outlined"
            size="small"
            value={upfcpFNo}
            onChange={(e) => {
              setUPFCPFNo(e.target.value);
              setUPFCPFNoErr(false);
            }}
          />

          <TextField
            error={googleScholarIDErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="googleScholarID"
            label="Google Scholar ID"
            variant="outlined"
            size="small"
            value={googleScholarID}
            onChange={(e) => {
              setGoogleScholarID(e.target.value);
              setGoogleScholarIDErr(false);
            }}
          />

          <TextField
            error={irinsidErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="irinsid"
            label="IRINS ID"
            variant="outlined"
            size="small"
            value={irinsid}
            onChange={(e) => {
              setIRINSID(e.target.value);
              setIRINSIDErr(false);
            }}
          />

          <TextField
            error={positionErr}
            fullWidth
            style={{ margin: "10px", width: "46%" }}
            id="position"
            label="Ordered By (Only Numbers)"
            type="number"
            variant="outlined"
            size="small"
            value={position}
            onChange={(e) => {
              setPosition(e.target.value);
              SetPositionErr(false);
            }}
          />
          <FormControl
            style={{ width: "45%", margin: 12 }}
            error={facultyTypeErr}
            id="facultyType"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              FacultyType
            </InputLabel>
            <Select
              variant="outlined"
              label="Faculty Type"
              style={{ height: "45px" }}
              value={facultyType}
              onChange={(e) => {
                setFacultyType(e.target.value);
                setFacultyTypeErr(false);
              }}
            >
              <MenuItem value="Teaching Staff"> Teaching Staff</MenuItem>
              <MenuItem value="Non Teaching Staff">
                {" "}
                Non Teaching Staff
              </MenuItem>
            </Select>
          </FormControl>

          {id > 0 || facultyType === "Non Teaching Staff" ? null : (
            <TextField
              error={passwordErr}
              fullWidth
              style={{ margin: "10px", width: "46%" }}
              id="password"
              label="Password(Minimum 6 Charecter)"
              // type="password"
              InputProps={{
                inputProps: {
                  // maxLength: minLength,
                },
              }}
              variant="outlined"
              size="small"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
                setpasswordErr(false);
              }}
            />
          )}

          <div
            className="dialogue_action_button"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <button
                style={{
                  backgroundColor: "#0abb87",
                  height: "20px",
                  padding: "15px",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                onClick={handleBack}
              >
                Back
              </button>
            </div>

            {showSubmitButton === true ? (
              <>
                <div>
                  <button
                    style={{
                      backgroundColor: "#0abb87",
                      height: "20px",
                      padding: "15px",
                      borderRadius: "5px",
                      color: "#fff",
                    }}
                    onClick={handelTransfer}
                  >
                    Transfer
                  </button>
                </div>

                <div>
                  <button
                    style={{
                      backgroundColor: "#0abb87",
                      height: "20px",
                      padding: "15px",
                      borderRadius: "5px",
                      color: "#fff",
                    }}
                    onClick={handleBlock}
                  >
                    Block
                  </button>
                </div>
              </>
            ) : null}

            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {showSubmitButton === true ? (
                facultyType === "Non Teaching Staff" ? null : (
                  <button
                    type="submit"
                    onClick={handleResetOpen}
                    style={{ width: "auto" }}
                    className="sbmit-buttons"
                  >
                    Reset Password
                  </button>
                )
              ) : null}

              <button
                type="submit"
                className="sbmit-buttons"
                onClick={formValidateFun}
              >
                Submit
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestigatorInsdie;
