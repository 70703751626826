import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { B15VALIDATION } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";

function B15Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [Lovbooktype, setLovbooktype] = useState([]);

  const [Authors, setAuthors] = useState("");
  const [Year, setYear] = useState("");
  const [Booktype, setBooktype] = useState("");
  const [BookChapter, setBookChapter] = useState("");
  const [Nameofbook, setNameofbook] = useState("");
  const [Publisher, setPublisher] = useState("");
  const [Pageno, setPageno] = useState("");
  const [ISBN, setISBN] = useState("");
  const [loader,setLoader] =useState(true);

  const [AuthorsErr, setAuthorsErr] = useState(false);
  const [YearErr, setYearErr] = useState(false);
  const [BooktypeErr, setBooktypeErr] = useState(false);
  const [BookChapterErr, setBookChapterErr] = useState(false);
  const [NameofbookErr, setNameofbookErr] = useState(false);
  const [PublisherErr, setPublisherErr] = useState(false);
  const [PagenoErr, setPagenoErr] = useState(false);
  const [ISBNErr, setISBNErr] = useState(false);


  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [BookTypes, setBookTypes] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B15VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      handleBookType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Book Chapters");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setAuthors(item.authors);
    setYear(item.year);
    setBooktype(item.bookType);
    setNameofbook(item.bookName);
    setBookChapter(item.titleOfBookChapter)
    setPublisher(item.publisher);
    setPageno(item.pageNo);
    setISBN(item.isbn);
    setId(item.id);
  };

  const handleBack = () => {
    navigate("/B15");
  };

  const BtnSubmit = () => {
    alert("Successfully Submitted");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov
  const handleLov = async (token, BookType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"BookType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovbooktype(data);
    } catch (error) {
      console.log(error);
    }
  };

  // Lov Type //
  const handleBookType = async (token, ProductType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"BookType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setBookTypes(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      Authors: Authors,
      Year: Year,
      BookType: Booktype,
      TitleOfBookChapter: BookChapter,
      BookName:Nameofbook,
      Publisher: Publisher,
      PageNo: Pageno,
      ISBN: ISBN,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B15SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B15");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B15EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/B15");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!Authors){
      setAuthorsErr(true)
      document.getElementById("Authors").focus()
    }else if(!Year){
      setYearErr(true)
      document.getElementById("Year").focus()
    }else if(!Booktype){
      setBooktypeErr(true)
      document.getElementById("Booktype").focus()
    }else if(!Nameofbook){
      setNameofbookErr(true)
      document.getElementById("Nameofbook").focus()
    }else if(!BookChapter){
      setBookChapterErr(true)
      document.getElementById("BookChapter").focus()
    }else if(!Publisher){
      setPublisherErr(true)
      document.getElementById("Publisher").focus()
    }else if(!Pageno){
      setPagenoErr(true)
      document.getElementById("Pageno").focus()
    }else if(!ISBN){
      setISBNErr(true)
      document.getElementById("ISBN").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div style={{ marginTop: 70 }}> <ComponentLoader isLoading={loader} />

      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Books Chapters (B15)</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>B15. Books/Book Chapters</h5>

          <TextField
            error={AuthorsErr}
            id="Authors"
            label="Authors"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Authors}
            onChange={(e) => {
              setAuthors(e.target.value)
              setAuthorsErr(false)
            }}
          />

          <TextField
            error={YearErr}
            id="Year"
            label="Year"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Year}
            onChange={(e) => {
              setYear(e.target.value)
              setYearErr(false)
            }}
          />

          {/* <TextField
            id="outlined-basic"
            label="Type"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("BookType")}
            error={errors.BookType ? true : false}
            helperText={errors.BookType?.message}
          /> */}
          <FormControl style={{ width: "48%", margin: 12 }} error={BooktypeErr} id="Booktype">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Book Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="MeetingName"
              value={Booktype}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setBooktype(e.target.value)
                setBooktypeErr(false)
              }}
            >
              {Lovbooktype?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={NameofbookErr}
            id="Nameofbook"
            label="Name Of Book"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Nameofbook}
            onChange={(e) => {
              setNameofbook(e.target.value)
              setNameofbookErr(false)
            }}
          />

          <TextField
            error={BookChapterErr}
            id="BookChapter"
            label="Book Chapter (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={BookChapter}
            onChange={(e) => {
              setBookChapter(e.target.value)
              setBookChapterErr(false)
            }}
          />

          <TextField
            error={PublisherErr}
            id="Publisher"
            label=" Publisher"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Publisher}
            onChange={(e) => {
              setPublisher(e.target.value)
              setPublisherErr(false)
            }}
          />

          <TextField
            error={PagenoErr}
            id="Pageno"
            label="Page No"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Pageno}
            onChange={(e) => {
              setPageno(e.target.value)
              setPagenoErr(false)
            }}
          />

          <TextField
            error={ISBNErr}
            id="ISBN"
            label="ISBN"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={ISBN}
            onChange={(e) => {
              setISBN(e.target.value)
              setISBNErr(false)
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack} style={{paddingTop:"5px"}}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={()=>formValidateFun()} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B15Inside;
