import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  FormControl,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function ArB1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [Natureofcoaching, setNatureofcoaching] = useState("");
  const [Noofclassesoffered, setNoofclassesoffered] = useState("");
  const [FellowshipName, setFellowshipName] = useState("");
  const [NoOfStudent, setNoOfStudent] = useState("");
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInstitute] = useState();
  const [loader,setLoader] =useState(true)

  const [lov, setLov] = useState([]);
  const [nameofDiplomo, setNameofdiplomo] = useState("");
  const [maleStudent, setMaleStudent] = useState("");
  const [femaleStudent, setFeMaleStudent] = useState("");
  const [totalnoStudent, setTotalnoStudent] = useState("");
  const [stateGovtFellowship, setStateGovtFellowship] = useState("");
  const [centralGovtFellowship, setCentralGovtFellowship] = useState("");
  const [govtCompetiveFellowship, setGovtCompetiveFellowship] = useState("");
  const [privateFellowship, setPrivateFellowship] = useState("");
  const [total, setTotal] = useState("");

  const [nameofDiplomoErr, setNameofdiplomoErr] = useState(false);
  const [maleStudentErr, setMaleStudentErr] = useState(false);
  const [femaleStudentErr, setFeMaleStudentErr] = useState(false);
  const [totalnoStudentErr, setTotalnoStudentErr] = useState(false);
  const [stateGovtFellowshipErr, setStateGovtFellowshipErr] = useState(false);
  const [centralGovtFellowshipErr, setCentralGovtFellowshipErr] =
    useState(false);
  const [govtCompetiveFellowshipErr, setGovtCompetiveFellowshipErr] =
    useState(false);
  const [privateFellowshipErr, setPrivateFellowshipErr] = useState(false);
  const [totalErr, setTotalErr] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [dropnature, setDropnature] = useState([]);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A8VALI),
  });

  const backs = () => {
    navigate("/ArB1");
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLovType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student facilitation");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const instituteId = localStorage.getItem("loginId");
    setInstitute(instituteId);
  }, []);

  const edit = (item) => {
    console.log(item);

    setNameofdiplomo(item.courseType);
    setMaleStudent(item.noOfMaleStudents);
    setFeMaleStudent(item.noOfFemaleStudents);
    setTotalnoStudent(item.totalNoStudents);
    setStateGovtFellowship(item.stateGovtFellowship);
    setCentralGovtFellowship(item.centralGovtFellowship);
    setGovtCompetiveFellowship(item.govtCompetitiveFellowship);
    setPrivateFellowship(item.privateFellowship);
    setTotal(item.totalFellowship);
    setId(item.id)
  };

  //   Count
  const handleMaleCount = (e) => {
    setMaleStudent();
  };
  const handleFemaleCount = (e) => {
    setFeMaleStudent();
  };
  const handleTotalCount = (e) => {
    setTotalnoStudent();
  };

  const handleStateGovt = (e) => {
    setStateGovtFellowship();
  };
  const handleCentralGovt = (e) => {
    setCentralGovtFellowship();
  };
  const handleGovtCompetive = (e) => {
    setGovtCompetiveFellowship();
  };
  const handlePrivate = (e) => {
    setPrivateFellowship();
  };
  const handleTotal = (e) => {
    setTotal();
  };

  // Lov
  const handleLovType = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLov(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleDegreeProgram = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      CourseType: nameofDiplomo,
      NoOfMaleStudents: maleStudent,
      NoOfFemaleStudents: femaleStudent,
      TotalNoStudents: totalnoStudent,
      StateGovtFellowship: stateGovtFellowship,
      CentralGovtFellowship: centralGovtFellowship,
      GovtCompetitiveFellowship: govtCompetiveFellowship,
      PrivateFellowship: privateFellowship,
      TotalFellowship: total,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB1SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArB1");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
          // toastError(data.errorMsgs)
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.ARB1EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Updated");
          navigate("/ArB1");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!nameofDiplomo) {
      setNameofdiplomoErr(true);
      document.getElementById("nameofDiplomo").focus();
    } else if (!maleStudent) {
      setMaleStudentErr(true);
      document.getElementById("maleStudent").focus();
    } else if (!femaleStudent) {
      setFeMaleStudentErr(true);
      document.getElementById("femaleStudent").focus();
    } else if (!totalnoStudent) {
      setTotalnoStudentErr(true);
      document.getElementById("totalnoStudent").focus();
    } else if (!stateGovtFellowship) {
      setStateGovtFellowshipErr(true);
      document.getElementById("stateGovtFellowship").focus();
    } else if (!centralGovtFellowship) {
      setCentralGovtFellowshipErr(true);
      document.getElementById("centralGovtFellowship").focus();
    } else if (!govtCompetiveFellowship) {
      setGovtCompetiveFellowshipErr(true);
      document.getElementById("govtCompetiveFellowship").focus();
    } else if (!privateFellowship) {
      setPrivateFellowshipErr(true);
      document.getElementById("privateFellowship").focus();
    } else if (!total) {
      setTotalErr(true);
      document.getElementById("total").focus();
    } else {
      handleDegreeProgram();
    }
  };

  useEffect(() => {
    let first = maleStudent ? parseInt(maleStudent) : 0;
    let second = femaleStudent ? parseInt(femaleStudent) : 0;
    let total = first + second;

    let StateG = stateGovtFellowship ? parseInt(stateGovtFellowship) : 0;
    let CentralG = centralGovtFellowship ? parseInt(centralGovtFellowship) : 0;
    let GovtCompet = govtCompetiveFellowship
      ? parseInt(govtCompetiveFellowship)
      : 0;
    let PrivateG = privateFellowship ? parseInt(privateFellowship) : 0;
    let SecondTotal = StateG + CentralG + GovtCompet + PrivateG;

    setTotalnoStudent(total);
    setTotal(SecondTotal);
  }, [
    maleStudent,
    femaleStudent,
    totalnoStudent,
    stateGovtFellowship,
    centralGovtFellowship,
    govtCompetiveFellowship,
    privateFellowship,
  ]);

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>B1. Student strength
 </span>
        </div>
      </div>

      {/* <form className="from-mains" onSubmit={handleSubmit(handleDegreeProgram)}> */}
      <div className="form-control">
        <h5 style={{ width: 350, margin: 20 }}>
        B1. Student strength

        </h5>

        <FormControl
          style={{ width: "48%", margin: 12 }}
          error={nameofDiplomoErr}
          id="nameofDiplomo"
        >
          <InputLabel id="demo-simple-select-helper-label" size="small">
            Name of the Diploma / UG / PG / Ph.D. Program
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label=" Position Scheme "
            size="small"
            sx={{ width: "100%" }}
            value={nameofDiplomo}
            onChange={(e) => {
              setNameofdiplomo(e.target.value);
              setNameofdiplomoErr(false);
            }}
          >
            {lov?.map((row) => {
              return (
                <MenuItem key={row.lovName} value={row.lovName}>
                  {row.lovName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          error={maleStudentErr}
          id="maleStudent"
          label="Male students"
          variant="outlined"
          value={maleStudent}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handleMaleCount();
            setMaleStudent(e.target.value);
            setMaleStudentErr(false);
          }}
        />

        <TextField
          error={femaleStudentErr}
          id="femaleStudent"
          label="Female students"
          variant="outlined"
          value={femaleStudent}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handleFemaleCount();
            setFeMaleStudent(e.target.value);
            setFeMaleStudentErr(false);
          }}
        />

        <TextField
          error={totalnoStudentErr}
          id="totalnoStudent"
          label="Total number of students"
          variant="outlined"
          value={totalnoStudent}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handleTotalCount();
            setTotalnoStudent(e.target.value);
            setTotalnoStudentErr(false);
          }}
        />

        <TextField
          error={stateGovtFellowshipErr}
          id="stateGovtFellowship"
          label="State Govt. sponsored fellowship(s)"
          variant="outlined"
          value={stateGovtFellowship}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handleStateGovt();
            setStateGovtFellowship(e.target.value);
            setStateGovtFellowshipErr(false);
          }}
        />

        <TextField
          error={centralGovtFellowshipErr}
          id="centralGovtFellowship"
          label="Central Govt. sponsored fellowship(s)"
          variant="outlined"
          value={centralGovtFellowship}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handleCentralGovt();
            setCentralGovtFellowship(e.target.value);
            setCentralGovtFellowshipErr(false);
          }}
        />

        <TextField
          error={govtCompetiveFellowshipErr}
          id="govtCompetiveFellowship"
          label="Govt. competitive fellowship(s)"
          variant="outlined"
          value={govtCompetiveFellowship}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handleGovtCompetive();
            setGovtCompetiveFellowship(e.target.value);
            setGovtCompetiveFellowshipErr(false);
          }}
        />

        <TextField
          error={privateFellowshipErr}
          id="privateFellowship"
          label="Private fellow- ship(s)"
          variant="outlined"
          value={privateFellowship}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handlePrivate();
            setPrivateFellowship(e.target.value);
            setPrivateFellowshipErr(false);
          }}
        />

        <TextField
          error={totalErr}
          id="total"
          label="Total"
          variant="outlined"
          value={total}
          type="number"
          style={{ margin: "10px", width: "48%" }}
          onChange={(e) => {
            handleTotal();
            setTotal(e.target.value);
            setTotalErr(false);
          }}
        />

        <div className="from-last">
        <button
              type="submit"
              style={{ backgroundColor: "#008CBA",padding:"5px",borderRadius:"3px",width:"80px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
          <button
            type="submit"
            className="sbmit-buttons"
            onClick={formValidateFun}
          >
            Save
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
}

export default ArB1Inside;
