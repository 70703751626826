import React from "react";
import { useState } from "react";
import * as yup from "yup";

const errosName = {
  required: "Field is Required",
  phone: "Phone number is not valid",
  wesite: "Website is not valid",
  min: "Min 5 charcter",
  letter: "Invalid",
};

const phoneRegExp = /^\d{10}$/;
const websiteRegExp =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
const letter = /^[a-zA-Z]+$/;
const regno = /^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/;
const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;

export const manufacturer = yup.object().shape({
  manufactureName: yup.string().required(errosName.required),
  shortName: yup.string().required(errosName.required),
  legalName: yup.string().required(errosName.required),
  email: yup.string().email().required(),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  phoneNumber2: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  webSite: yup.string().required(errosName.required),
  address: yup.string().required(errosName.required).min(5, errosName.min),
  state: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  city: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  zipCode: yup.string().required(errosName.required),
  country: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  regno: yup.string().required(errosName.required),

  // gstno:yup.string().required("Field is Required"),
});
export const manufacturerLogin = yup.object().shape({
  manufactureName: yup.string().required(errosName.required),
  shortName: yup.string().required(errosName.required),
  legalName: yup.string().required(errosName.required),
  email: yup.string().email().required(),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  phoneNumber2: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  webSite: yup.string().required(errosName.required),
  address: yup.string().required(errosName.required).min(5, errosName.min),
  state: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  city: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  zipCode: yup.string().required(errosName.required),
  country: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  regno: yup.string().required(errosName.required),
  password: yup
    .string()
    .required(errosName.required)
    .matches(passwordPattern, errosName.letter),
  cpassword: yup
    .string()
    .required(errosName.required)
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  // gstno:yup.string().required("Field is Required"),
});

export const distributor = yup.object().shape({
  distributorName: yup.string().required(errosName.required),
  distributorCode: yup.string().required(errosName.required),
  email: yup.string().email().required(),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  phoneNumber2: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  webSite: yup.string().required(errosName.required),
  address: yup.string().required(errosName.required).min(5, errosName.min),
  state: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  city: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  country: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  regno: yup.string().required(errosName.required),
  gstno: yup.string().required(errosName.required),
});
export const distributorLogin = yup.object().shape({
  distributorName: yup.string().required(errosName.required),
  distributorCode: yup.string().required(errosName.required),
  email: yup.string().email().required(),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  phoneNumber2: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  webSite: yup.string().required(errosName.required),
  address: yup.string().required(errosName.required).min(5, errosName.min),
  state: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  city: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  country: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  regno: yup.string().required(errosName.required),
  gstno: yup.string().required(errosName.required),
  password: yup
    .string()
    .required(errosName.required)
    .matches(passwordPattern, errosName.letter),
  cpassword: yup
    .string()
    .required(errosName.required)
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
export const distributorLocationDate = yup.object().shape({
  location: yup.string().required(errosName.required),
  sDate: yup.string().required(errosName.required),
  eDate: yup.string().required(errosName.required),
});

export const distributorHierarchy = yup.object().shape({
  distributorHierarchyName: yup.string().required(errosName.required),
  distributorHierarchyCode: yup.string().required(errosName.required),
  type: yup.string().required(errosName.required),
});

export const distributorHierarchyaddress = yup.object().shape({
  address: yup.string().required(errosName.required),
  addressType: yup.string().required(errosName.required),
  state: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  city: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  zipCode: yup.string().required(errosName.required),
  country: yup
    .string()
    .required(errosName.required)
    .matches(letter, errosName.letter),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  street: yup.string().required(errosName.required),
  area: yup.string().required(errosName.required),
});

export const locationHierarchy = yup.object().shape({
  locationlevelname: yup.string().required(errosName.required),
  locationlevelcode: yup.string().required(errosName.required),
  // type:yup.string().required(errosName.required)
});

export const positionHierarchy = yup.object().shape({
  positioncode: yup.string().required(errosName.required),
  positionname: yup.string().required(errosName.required),
});

export const userLevel = yup.object().shape({
  userlevelcode: yup.string().required(errosName.required),
  userlevelname: yup.string().required(errosName.required),
});

export const users = yup.object().shape({
  firstName: yup.string().required(errosName.required),
  lastName: yup.string().required(errosName.required),
  middleName: yup.string().required(errosName.required),
  email: yup.string().email().required(),
  userId: yup.string().required(errosName.required),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  loginId: yup.string().required(errosName.required),
});
export const usersLogin = yup.object().shape({
  firstName: yup.string().required(errosName.required),
  lastName: yup.string().required(errosName.required),
  middleName: yup.string().required(errosName.required),
  email: yup.string().email().required(),
  userId: yup.string().required(errosName.required),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  loginId: yup.string().required(errosName.required),
  password: yup
    .string()
    .required(errosName.required)
    .matches(passwordPattern, errosName.letter),
  cpassword: yup
    .string()
    .required(errosName.required)
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const storeAccount = yup.object().shape({
  StoreAccountname: yup.string().required(errosName.required),
  StoreAccountcode: yup.string().required(errosName.required),
});

export const storeChannel = yup.object().shape({
  StoreChannelname: yup.string().required(errosName.required),
  StoreChannelcode: yup.string().required(errosName.required),
});
export const storeLocalChannel = yup.object().shape({
  global: yup.string().required(errosName.required),
  localchannelcode: yup.string().required(errosName.required),
  localchannelname: yup.string().required(errosName.required),
});

export const storeSubChannel = yup.object().shape({
  StoreSubChannelname: yup.string().required(errosName.required),
  StoreSubChannelcode: yup.string().required(errosName.required),
});

export const stores = yup.object().shape({
  storeName: yup.string().required(errosName.required),
  shortName: yup.string().required(errosName.required),
  legalName: yup.string().required(errosName.required),

  email: yup.string().email().required(),
  phoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  bankname: yup.string().required(errosName.required),
  bankacnumber: yup.string().required(errosName.required),
  bankifscnumber: yup.string().required(errosName.required),
  ownername: yup.string().required(errosName.required),
  locationtype: yup.string().required(errosName.required),
  storesubchanneltype: yup.string().required(errosName.required),
  storechanneltype: yup.string().required(errosName.required),
  storeaccounttype: yup.string().required(errosName.required),
  storetype: yup.string().required(errosName.required),
  // locationtype:yup.string().required(errosName.required),
  regno: yup.string().required(errosName.required),
  gstno: yup.string().required(errosName.required),
});

export const storesAddress = yup.object().shape({
  address1: yup.string().required(errosName.required),
  address2: yup.string().required(errosName.required),
  addressType: yup.string().required(errosName.required),
  state: yup.string().required(errosName.required),
  city: yup.string().required(errosName.required),
  country: yup.string().required(errosName.required),
  addemail: yup.string().email().required(),
  personNumber: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  addphoneNumber1: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
  phoneNumber2: yup
    .string()
    .required(errosName.required)
    .matches(phoneRegExp, errosName.phone),
});

export const locationLevel = yup.object().shape({
  locationlevelname: yup.string().required(errosName.required),
  locationlevelcode: yup.string().required(errosName.required),
  type: yup.string().required(errosName.required),
});

export const UOM = yup.object().shape({
  uomname: yup.string().required(errosName.required),
  uomcode: yup.string().required(errosName.required),
});

export const tax = yup.object().shape({
  taxname: yup.string().required(errosName.required),
  ledgername: yup.string().required(errosName.required),
  taxvalue: yup.string().required(errosName.required),
  taxpercent: yup.string().required(errosName.required),
  taxgroup: yup.string().required(errosName.required),
});
export const taxGroup = yup.object().shape({
  taxgroup: yup.string().required(errosName.required),
});

export const lov = yup.object().shape({
  lovname: yup.string().required(errosName.required),
  lovcode: yup.string().required(errosName.required),
  type: yup.string().required(errosName.required),
});

export const Route = yup.object().shape({
  routename: yup.string().required(errosName.required),
  routecode: yup.string().required(errosName.required),
});

export const scheme = yup.object().shape({
  schemename: yup.string().required(errosName.required),
  schemedetails: yup.string().required(errosName.required),
  sdate: yup.string().required(errosName.required),
  edate: yup.string().required(errosName.required),
});

export const Banks = yup.object().shape({
  bankname: yup.string().required(errosName.required),
  bankcode: yup.string().required(errosName.required),
  bankbranch: yup.string().required(errosName.required),
  ifsccode: yup.string().required(errosName.required),
});

export const Reason = yup.object().shape({
  reasonname: yup.string().required(errosName.required),
  reasoncode: yup.string().required(errosName.required),
});

export const product = yup.object().shape({
  productname: yup.string().required(errosName.required),
  productcode: yup.string().required(errosName.required),
  // short:yup.string().required(errosName.required),
  // type:yup.string().required(errosName.required),

  // caseprice:yup.string().required(errosName.required),
  // pieceprice:yup.string().required(errosName.required),
  // piecepercase:yup.string().required(errosName.required),
  // mrp:yup.string().required(errosName.required),
  // baseuom:yup.string().required(errosName.required),
});
export const productAllField = yup.object().shape({
  productname: yup.string().required(errosName.required),
  productcode: yup.string().required(errosName.required),
  short: yup.string().required(errosName.required),
  type: yup.string().required(errosName.required),
  caseprice: yup.string().required(errosName.required),
  pieceprice: yup.string().required(errosName.required),
  piecepercase: yup.string().required(errosName.required),
  mrp: yup.string().required(errosName.required),
  baseuom: yup.string().required(errosName.required),
});

export const productLevel = yup.object().shape({
  productlevelname: yup.string().required(errosName.required),
  // mrp:yup.string().required(errosName.required),
});

export const positionLocationMapping = yup.object().shape({
  locationid: yup.string().required(errosName.required),
  positionid: yup.string().required(errosName.required),
  sdate: yup.string().required(errosName.required),
  edate: yup.string().required(errosName.required),
});
export const MargetSegment = yup.object().shape({
  segmentname: yup.string().required(errosName.required),
  segmentcode: yup.string().required(errosName.required),
});

//directorater
export const Dddddd = yup.object().shape({
  d: yup.string().required("Please Enter Code"),
  Dname: yup.string().required(" Please Enter Name"),
  Dparmentname: yup.string().required(" Please Enter Department Name"),
  Dparmentemail: yup
    .string()
    .required(" Please Enter Direct Email")
    .email("Please Enter Valid Email"),
  Dparmentmobileno: yup.string().required(" Please Enter Mobileno"),

  Dpassword: yup.string().required(" Please Enter Password"),
});

export const Dddddds = yup.object().shape({
  d: yup.string().required(" Please Enter Code"),
  Dname: yup.string().required("Please Enter Name"),
  Dparmentname: yup.string().required(" Please Enter Department Name"),
  Dparmentemail: yup
    .string()
    .required("Please Enter Direct Email")
    .email("Invalid"),
  Dparmentmobileno: yup.string().required(" Please Enter Mobileno"),
});

// intitute error
export const Instituteex = yup.object().shape({
  Icode: yup.string().required(" Please Enter Code"),
  Insname: yup.string().required(" Please Enter Institute Name"),
  Prinname: yup.string().required(" Please Enter Principal Name"),
  Prinemail: yup
    .string()
    .required(" Please Enter Principal Email")
    .email("Please Enter valid Email"),
  Prinemobileno: yup.string().required(" Please Enter Mobile No"),
  Prineepassword: yup.string().required(" Please Enter Password"),
  Directordepartname: yup
    .string()
    .required(" Please Enter Directordepartmentname"),
});

export const Instituteexss = yup.object().shape({
  Icode: yup.string().required(" Please Enter Code"),
  Insname: yup.string().required(" Please Enter Institute Name"),
  Prinname: yup.string().required(" Please Enter Principal Name"),
  Prinemail: yup.string().required("Please Enter Principal Email"),
  Prinemobileno: yup.string().required("Please Enter Mobile No"),
});

export const Investigatoree = yup.object().shape({
  Investigatorname: yup.string().required("  Please Enter Investigator Name"),
  Qualification: yup.string().required(" Please Enter Qualification"),
  Discipline: yup.string().required(" Please Enter Discipline Email"),
  Designation: yup.string().required(" Please Enter Designation"),
  Address: yup.string().required(" Please Enter Address"),
  DOJ: yup.string().required(" Please Enter DOJ"),
  DepartmentId: yup.string().required(" Please Enter DepartmentId"),
  InstituteId: yup.string().required(" Please Enter InstituteId"),
  EmailId: yup.string().required(" Please Enter EmailId"),
  MobileNo: yup.string().required(" Please Enter MobileNo"),
  UPFCPFNo: yup.string().required(" Please Enter UPFCPFNo"),
  GoogleScholarID: yup.string().required(" Please Enter GoogleScholarID"),
  IRINSID: yup.string().required(" Please Enter IRINSID"),
});

//LEAVE PAGE
export const Leaveess = yup.object().shape({
  Leavecode: yup.string().required(" Please Enter Code"),
  Leavenotes: yup.string().required(" Please Enter Name"),
});

export const masonduty = yup.object().shape({
  ondutycode: yup.string().required(" Please Enter Code"),
  ondutynotes: yup.string().required(" Please Enter Name"),
});

export const Transactionleave = yup.object().shape({
  leavedatev: yup.string().required("Please Enter Date"),
  leavenamev: yup.string().required("Please Enter Name"),
  notesv: yup.string().required(" Please Enter Notes"),
});

//TRANSATIONONDUTY

export const Transactionondutyedity = yup.object().shape({
  ondutydatev: yup.string().required("Please Enter StartDate"),
  ondutyenddatev: yup.string().required("Please Enter EndDate"),
  ondutyleavenamev: yup.string().required(" Please Enter Name"),
  ondutyplacev: yup.string().required(" Please Enter Place"),
  // ondutyleave: yup.string().required(" Please Enter leave"),
  // ondutynotesv: yup.string().required(" Please Enter Notes"),
  ondutypuposev: yup.string().required(" Please Enter Purpose"),
});

////tsms
export const Tsmsvali = yup.object().shape({
  datev: yup.string().required("Please Enter Date"),
  Resonsv: yup.string().required(" Please Enter Reason"),
});

export const Activetiesvalidation = yup.object().shape({
  dateActi: yup.string().required("Please Enter Date"),
  CourseActi: yup.string().required("Please Enter Course"),

  HourseActi: yup.string().required("Please Enter Hourse"),
  CoursetypeidActi: yup.string().required(" Please Enter Course TypeId"),
  CourseTeacherActiveties: yup
    .string()
    .required(" Please Enter Course Teacher"),
  classeActi: yup.string().required(" Please Enter Class Tp"),

  SlowlearnActi: yup.string().required("Please Enter Slow Learners"),
  weeknesActi: yup.string().required(" Please Enter Weakness"),
  AddtionActiveties: yup.string().required(" Please Enter Additional Efforts"),
  PassActi: yup.string().required(" Please Enter Pass"),

  GueastActi: yup.string().required("Please Enter Slow Gueast Speaker"),
  AffilitionActi: yup.string().required(" Please Enter  Affilition"),
  LeactureActiveties: yup.string().required(" Please Enter  Leacture"),

  InsituActi: yup.string().required("Please Enter Institute"),
  StudentActi: yup.string().required(" Please Enter  Student"),
});

// DEPARTMRNT
export const DEPARTMENTVALI = yup.object().shape({
  CodeV: yup.string().required("Please Enter  Code"),
  NameV: yup.string().required(" Please Enter  Name"),
});

//station Type
export const STATION = yup.object().shape({
  CodeV: yup.string().required("Please Enter  Code"),
  NameV: yup.string().required(" Please Enter  Name"),
});
// FUNDING
export const FUNDINGVALI = yup.object().shape({
  Codev: yup.string().required("Please Enter  Code"),
  FundingAgencyNamev: yup
    .string()
    .required(" Please Enter  Funding Agency Name"),
});

// crop
export const CROP = yup.object().shape({
  CodeV: yup.string().required("Please Enter  Code"),
  NameV: yup.string().required(" Please Enter  Name"),
});

////B1
export const B1VALIDATION = yup.object().shape({
  ResearchAreaCropv: yup.string().required("ENTER ResearchAreaCrop"),
});

// B3
// export const B3VALIDATION = yup.object().shape({
//   DepatId: yup.string().required(""),
//   TeamMember: yup.string().required(),
//   ProductType: yup.string().required(" "),
//   ProductName: yup.string().required(" "),
//   ProdcutSpecialFeature: yup.string().required(" "),
// });

// B4
// export const B4VALIDATION = yup.object().shape({
//   ProductType: yup.string().required(""),
//   ProductName: yup.string().required(),
//   Currentstatus: yup.string().required(" "),
//   ProgressTentativetimeline: yup.string().required(" "),
//   Progress: yup.string().required(" "),
// });

// B5
export const NewVariety = yup.object().shape({
  DepatIdv: yup.string().required("Please Enter  Depat Id"),
  Producttypev: yup.string().required(" Please Enter Product type"),
  ProductNamev: yup.string().required(" Please Enter Product Name"),
  RealeaseTypev: yup.string().required(" Please Enter   Realease Type"),
  ProdcutSpecialFeaturev: yup
    .string()
    .required(" Please Enter ProdcutSpecial Feature"),
});

// B6
export const B6VALI = yup.object().shape({
  CropIdv: yup.string().required("Please Enter CropId"),
  DuringMonthv: yup.string().required(" Please Enter DuringMonth"),
  // CodeV: yup.string().required(""),
  // NameV: yup.string().required(""),
  TotalGermplasmcollectedv: yup
    .string()
    .required(" Please Enter TotalGermplasmcollected"),
  ProdcutSpecialFeaturev: yup
    .string()
    .required(" Please Enter ProdcutSpecialFeature"),
  // ondutynotes:yup.string().required(" Please Enter Notes"),
});

export const B7VALIDATION = yup.object().shape({
  DepatIdV: yup.string().required(""),
  DescriptionoftheProductV: yup.string().required(""),
  PatentTypeV: yup.string().required(""),
  RemarksV: yup.string().required(""),
});

// B8
export const B8VALIDATION = yup.object().shape({
  DepatId: yup.string().required(" "),
  Organization: yup.string().required(""),
  FPOlinkageType: yup.string().required(),
  Purpose: yup.string().required(" "),
});

// B9
export const B9VALIDATION = yup.object().shape({
  DepatId: yup.string().required(""),
  Organization: yup.string().required(),
  Purpose: yup.string().required(" "),
  MOUDate: yup.string().required(" "),
  Progress: yup.string().required(" "),
});

// B10
export const B10VALIDATION = yup.object().shape({
  TitleoftheScheme: yup.string().required(""),
  ProjectType: yup.string().required(),
  InvestigatorType: yup.string().required(" "),
  Budget: yup.string().required(" "),
  ProjectStartTime: yup.string().required(" "),
  ProjectEndTime: yup.string().required(" "),
  Progress: yup.string().required(" "),
});

// B11
export const B11VALIDATION = yup.object().shape({
  TitleoftheActionPlan: yup.string().required(),
  CentresInvolved: yup.string().required(),
  CategoryV: yup.string().required(),
  Progress: yup.string().required(),
});

// B12
export const B12VALIDATION = yup.object().shape({
  TitleoftheProject: yup.string().required(""),
  InvestigatorType: yup.string().required(),
  FundingAgencyId: yup.string().required(" "),
  Budget: yup.string().required(" "),
  DateofSubmission: yup.string().required(" "),
  Status: yup.string().required(" "),
});

// B13
export const B13VALIDATION = yup.object().shape({
  TitleoftheProject: yup.string().required(""),
  FundingAgencyId: yup.string().required(""),
  Dateofclosure: yup.string().required(" "),
  CompletionReport: yup.string().required(" "),
  // Settlementofaccounts: yup.string().required(" "),
});

// B14
export const B14VALIDATION = yup.object().shape({
  Authors: yup.string().required(" "),
  PublicationsYear: yup.string().required(" "),
  TitleOfPublications: yup.string().required(" "),
  JournalIssue: yup.string().required(" "),
  Volume: yup.string().required(" "),
  Issue: yup.string().required(" "),
  RatingType: yup.string().required(" "),
  PageNo: yup.string().required(" "),
  ISSNNAASID: yup.string().required(" "),
  NAASRating: yup.string().required(" "),
  SCIIndex: yup.string().required(" "),
});

// B15
export const B15VALIDATION = yup.object().shape({
  Authors: yup.string().required(""),
  Year: yup.string().required(),
  BookType: yup.string().required(" "),
  TitleOfBookChapter: yup.string().required(" "),
  Publisher: yup.string().required(" "),
  PageNo: yup.string().required(" "),
  ISBN: yup.string().required(" "),
});

// B16
export const B16VALIDATION = yup.object().shape({
  Authors: yup.string().required(""),
  Year: yup.string().required(),
  ArticleTitle: yup.string().required(" "),
  ConferenceTitle: yup.string().required(" "),
  ConferenceDate: yup.string().required(" "),
  Place: yup.string().required(" "),
  Organizer: yup.string().required(" "),
});

// Vasanth //

// A1
export const A1VALI = yup.object().shape({
  CourseNumberV: yup.string().required("Please Enter  Course Number"),
  // CourseIdV: yup.string().required(" Please Enter Course Id"),
  CourseTypev: yup.string().required(" Please Enter  Course Type"),
  CourseTeacherV: yup.string().required(" Please Enter Course Teacher "),
  // HoursV: yup.string().required(" Please Enter Hours"),
  NoOfClassTPV: yup.string().required(" Please Enter No Of Class TP"),
});

// A2
export const A2VALI = yup.object().shape({
  // CourseNumberV: yup.string().required("Please Enter  Course Number"),
  CourseIdV: yup.string().required(" Please Enter Course Id"),
  // hoursv: yup.string().required(" Please Enter  Hours"),
  Noofslowlearnersv: yup
    .string()
    .required(" Please Enter No of slow Learners  "),
  Noofweaknessv: yup.string().required(" Please Enter No of weakness "),
  Additionaleffortsv: yup
    .string()
    .required(" Please Enter Additional Efforts "),
  Passv: yup.string().required(" Please Enter Pass "),
});

// A3
export const A3VALI = yup.object().shape({
  CourseNumberv: yup.string().required("Please Enter  Course Number"),
  CourseIdv: yup.string().required(" Please Enter Course Id"),
  // hoursv: yup.string().required(" Please Enter  Hours"),
  GuestSpeakerv: yup.string().required(" Please Enter Guest Speaker  "),
  Affiliationv: yup.string().required(" Please Enter Affiliation "),
  Titleofthelecturev: yup
    .string()
    .required(" Please Enter Title of the Lecture "),
});

// A4
export const A4VALI = yup.object().shape({
  CourseNumberv: yup.string().required("Please Enter  Course Number"),
  CourseIdv: yup.string().required(" Please Enter Course Id"),
  // hoursv: yup.string().required(" Please Enter  Hours"),
  Institutev: yup.string().required(" Please Enter Institute "),
  NoOfStudentsv: yup.string().required(" Please Enter No Of Students "),
});

// A5
export const A5VALI = yup.object().shape({
  StudentIdv: yup.string().required("Please Enter  Student Id"),
  StudentNamev: yup.string().required(" Please Enter Student Name"),
  CourseTypev: yup.string().required(" Please Enter  Course Type"),
  MemberNamev: yup.string().required(" Please Enter Member Name "),
  Thesistitlev: yup.string().required(" Please Enter Thesis Title "),
  Fellowshipdetailsv: yup
    .string()
    .required(" Please Enter Fellow ship Details "),
  Thesissubmissionv: yup.string().required(" Please Enter Thesis Submission"),
});

// A6
export const A6VALI = yup.object().shape({
  StudentNamev: yup.string().required("Please Enter  Student Name"),
  Noofmeetingsv: yup.string().required(" Please Enter No of Meetings"),
  InstituteIdv: yup.string().required(" Please Enter  InstituteId"),
  InternshipPlanv: yup.string().required(" Please Enter Internship Plan"),
  Progressv: yup.string().required(" Please Enter Progress"),
});

// A8
export const A8VALI = yup.object().shape({
  Natureofcoaching: yup.string().required(""),
  Noofclassesoffered: yup.string().required(" "),
  FellowshipName: yup.string().required(""),
  NoOfStudent: yup.string().required(" "),
});

// A7
export const A7VALI = yup.object().shape({
  Natureofthejob: yup.string().required(""),
  CourseId: yup.string().required(" "),
  CourseNo: yup.string().required(""),
});

// B17
export const B17VALI = yup.object().shape({
  AuthorsV: yup.string().required(""),
  YearV: yup.string().required(" "),
  ArticleTitleV: yup.string().required(" "),
  MagazineNewspaperV: yup.string().required(""),
  ArticleDateV: yup.string().required(" "),
  PagenoV: yup.string().required(" Please Enter Page No"),
  // OrganizerV: yup.string().required(" Please Enter Organizer"),
});

// B18
export const B18VALI = yup.object().shape({
  EventNameV: yup.string().required("Please Enter  Event Name"),
  NoOfdaysV: yup.string().required(" Please Enter No Of Days"),
  EventdateV: yup.string().required(" Please Enter  Event Date"),
  OrganizedbyV: yup.string().required(" Please Enter Organized By"),
  SponsorV: yup.string().required(" Please Enter Sponsor"),
});

// B19
export const B19VALI = yup.object().shape({
  EventNameV: yup.string().required("Please Enter  Event Name"),
  NumberofparticipantsV: yup
    .string()
    .required(" Please Enter Number of Participants"),
  EventdateV: yup.string().required(" Please Enter  Event Date"),
  TargetGroupV: yup.string().required(" Please Enter Target Group"),
  FundingAgencyIdV: yup.string().required(""),
  Budget: yup.string().required(" Please Enter Budget"),
});

// B20
export const B20VALI = yup.object().shape({
  EventNameV: yup.string().required("Please Enter  Event Name"),
  TitleofthetalkV: yup.string().required(" Please Enter Title of the Talk"),
  EventdateV: yup.string().required(" Please Enter  Event Date"),
  NumberofparticipantsV: yup
    .string()
    .required(" Please Enter Number of Participants"),
  SponsorV: yup.string().required(" Please Enter Sponsor"),
});

// C1
export const C1VALI = yup.object().shape({
  MeetingNamev: yup.string().required("Please Enter  Meeting Name"),
  DOMv: yup.string().required(" Please Enter DOM"),
  Numberofparticipantsv: yup
    .string()
    .required(" Please Enter Number of Participants"),
  // FundingAgencyIdv: yup.string().required(""),
  TargetGroupv: yup.string().required(" Please Enter Target Group"),
  Budget: yup.string().required(" Please Enter Budget"),
});

// C2
export const C2VALI = yup.object().shape({
  EventNamev: yup.string().required("Please Enter  Event Name"),
  NoOfdaysv: yup.string().required(" Please Enter  No Of Days"),
  Eventdatev: yup.string().required(" Please Enter Event Date"),
  Organizedbyv: yup.string().required(" Please Enter Organizedby"),
  Sponsor: yup.string().required(" Please Enter Sponsor"),
});

// C3
export const C3VALI = yup.object().shape({
  Technologyv: yup.string().required("Please Enter  Technology"),
  Teamleaderv: yup.string().required(" Please Enter  Team Leader"),
  Placev: yup.string().required(" Please Enter Place"),
  Nooftrialsv: yup.string().required(" Please Enter No of Trials"),
});

// C4
export const C4VALI = yup.object().shape({
  FieldVisitDatev: yup.string().required("Please Enter  Field Visit Date"),
  Particularsv: yup.string().required(" Please Enter  Particulars"),
  Solutionsv: yup.string().required(" Please Enter Solutions"),
  Numberofbeneficiariesv: yup
    .string()
    .required(" Please Enter Number of Beneficiaries"),
});

// C5
export const C5VALI = yup.object().shape({
  Activitiesv: yup.string().required("Please Enter  Activities"),
  TitleoftheProgrammev: yup
    .string()
    .required(" Please Enter Title of the Programme"),
  ProgrammeDateTimev: yup
    .string()
    .required(" Please Enter Programme Date Time "),
});

// C6
export const C6VALI = yup.object().shape({
  Organizationv: yup.string().required("Please Enter Name of the program"),
  EditorialBoardReviewerv: yup.string().required(" Please Enter Activities"),
  Nofpapersprocessedreviewedv: yup
    .string()
    .required(" Please Enter Village Name"),
});

// C7
export const C7VALI = yup.object().shape({
  Nameoftheprogramv: yup.string().required("Please Enter Name of the program"),
  Activitiesv: yup.string().required(" Please Enter Activities"),
  VillageNamev: yup.string().required(" Please Enter Village Name"),
  NumberofBeneficiaryv: yup
    .string()
    .required(" Please Enter Number of Beneficiary"),
  datev: yup.string().required(" Please Enter Date"),
});

// C8
export const C8VALI = yup.object().shape({
  NameoftheFPOv: yup.string().required("Please Enter NameoftheFPO"),
  Activitiesv: yup.string().required(" Please Enter Activities"),
  Noofmembersv: yup.string().required(" Please Enter Noofmembers"),
});

// C9

export const C9VALI = yup.object().shape({
  DocumentDescriptionV: yup
    .string()
    .required("Please Enter Document Description"),
  RealeaseTypeV: yup.string().required(" Please Enter Realease Type"),
  LeadAuthorV: yup.string().required(" Please Enter Lead Author"),
});

///D1

export const D1VALI = yup.object().shape({
  CropIdV: yup.string().required("Please Enter NameOfItems"),
  CropVarietyV: yup.string().required(" Please Enter Crop Variety"),
  // TypeofSeedsV: yup.string().required(" Please Enter Type of Seeds"),
  InputsProducedV: yup.string().required(" Please Enter Quantity Produced"),
  // InputsDistributedV: yup.string().required(" Please Enter Inputs Distributed"),
  IncomeGeneratedV: yup
    .string()
    .required(" Please Enter Inputs Income Generated"),
});

// D2

export const D2VALI = yup.object().shape({
  Activityv: yup.string().required("Please Enter Activity"),
  Responsibility: yup.string().required(" Please Enter Responsibility"),
});

//D3
export const D3VALI = yup.object().shape({
  Activityv: yup.string().required("Please Enter Activity"),
  Responsibility: yup.string().required(" Please Enter Responsibility"),
});

//D4
export const D4VALI = yup.object().shape({
  VisitorNamev: yup.string().required("Please Enter Visitor Name"),
  VisitingDatev: yup.string().required(" Please Enter Visiting Date"),
  Purposev: yup.string().required(" Please Enter Purpose"),
  Activityv: yup.string().required(" Please Enter Activity"),
});

//D5

export const D5VALI = yup.object().shape({
  DepartNameV: yup.string().required("Please Enter Depart Name"),
  EquipmentNameV: yup.string().required(" Please Enter Equipment Name"),
  SourceofFundingV: yup.string().required(" Please Enter Source of Funding"),
  BudgetV: yup.string().required(" Please Enter Budget"),
  ActivityV: yup.string().required(" Please Enter Activity"),
});

//D6

export const D6VALI = yup.object().shape({
  NameoftheAwardv: yup.string().required("Please Enter Name of the Award"),
  Awardedbyv: yup.string().required(" Please Enter Awarded By"),
  AwardDatev: yup.string().required(" Please Enter Award Date"),
});

export const error = {};

export const clickEvent = () => {};

export const cHange = (value, name) => {};

export const onClickResult = (n1, n2, n3, reg, num1, num2) => {};

export const clear = () => {};
