import React from "react";
import "./B3Inside.css";
import {
  TextField,
  Grid,
  // InputLabel,
  // Select,
  // MenuItem,
  DialogActions,
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { B3VALIDATION } from "../../../Validation";
//  import { Targetproduct } from '../../../Pages/Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";

function B3Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [Studentbtn, setStudentbtn] = useState([]);
  const [teamMember, setTeamMember] = useState(null);
  const [depatId, setDepatId] = useState(null);
  const [depatIdProductType, setDepatIdProductType] = useState(null);
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [prodcutSpecialFeature, setProdcutSpecialFeature] = useState("");
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [dropdown, setDropdown] = useState([]);
  const [PType, setPType] = useState([]);
  const [loader,setLoader] =useState(true);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);


// For Error States Declare here
const [depatIdErr,setDepatIdErr] = useState(false)
const [teamMemberErr, setTeamMemberErr] = useState(false);
const [depatIdProductTypeErr,setProductTypeErr] = useState(false)
const [productNameErr, setProductNameErr] = useState(false);
const [prodcutSpecialFeatureErr, setProdcutSpecialFeatureErr] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B3VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      handleDropdown(tok);
      handleLovType(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Target product");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setDepatId(item.depatId)
    setProductType(item.productType)
    setProductName(item.productName)
    setProdcutSpecialFeature(item.prodcutSpecialFeature)
    setTeamMember(item.teamMember)
    setId(item.id);
  };

  // const SubmitBtn = () => {
  //   alert("Succesfully Submitted");
  //   // window.location.reload();
  // };

  const handleBack = () => {
    navigate("/B3");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // DeptId Dropdown //
  const handleDropdown = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.DeptId}`, {
        headers: h,
      });
      console.log(data);
      if (data) {
        setDropdown(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Lov Type //
  const handleLovType = async (token, ProductType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"ProductType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setPType(data);setLoader(false);
      }
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      TeamMember: teamMember,
      DepatId: parseInt(depatId),
      ProductName: productName,
      ProductType: productType,
      ProdcutSpecialFeature: prodcutSpecialFeature,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    console.log(d);
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B3SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B3");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.B3EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/B3");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!depatId){
      setDepatIdErr(true)
      document.getElementById("depatId").focus()
    }else if(!teamMember){
      setTeamMemberErr(true)
      document.getElementById("teamMember").focus()
    }else if(!productType){
      setProductTypeErr(true)
      document.getElementById("productType").focus()
    }else if(!productName){
      setProductNameErr(true)
      document.getElementById("productName").focus()
    }else if(!prodcutSpecialFeature){
      setProdcutSpecialFeatureErr(true)
      document.getElementById("prodcutSpecialFeature").focus()
    }
    else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Target product (B3)</span>
        </div>
      </div>
      <div className="from-mains">
        {/* <div className=" col-lg-6 col-md-6 col-sm-12 Activies-main"></div> */}
        <div className="form-control">
          <h5>B3. Target product</h5>
          
          <FormControl style={{ width: "48%", margin: 12 }} error={depatIdErr} id="depatId">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Station Name
            </InputLabel>
            <Select
              variant="outlined"
              style={{ height: "44px" }}
              value={depatId}
              onChange={(e) => {
                setDepatId(e.target.value)
                setDepatIdErr(false)
              }}
            >
              {dropdown?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.instituteName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={teamMemberErr}
            id="TeamMember"
            label="TeamMember"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={teamMember}
            onChange={(e) => {
              setTeamMember(e.target.value)
              setTeamMemberErr(false)
            }}
            // {...register("TeamMember")}
            // error={errors.TeamMember ? true : false}
            // helperText={errors.TeamMember?.message}
          />

          <FormControl style={{ width: "48%", margin: 12 }} error={depatIdProductTypeErr} id="productType">
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Product Type
            </InputLabel>
            <Select
              variant="outlined"
              style={{ height: "45px" }}
              value={productType}
              onChange={(e) => {
                setProductType(e.target.value)
                setProductTypeErr(false)
              }}
            >
              {PType?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.lovName}>
                    {option.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={productNameErr}
            id="productName"
            label="ProductName"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value)
              setProductNameErr(false)
            }}
            // {...register("ProductName")}
            // error={errors.ProductName ? true : false}
            // helperText={errors.ProductName?.message}
          />

          <TextField
            error={prodcutSpecialFeatureErr}
            id="prodcutSpecialFeature"
            label="Product Special Feature (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={prodcutSpecialFeature}
            onChange={(e) => {
              setProdcutSpecialFeature(e.target.value)
              setProdcutSpecialFeatureErr(false)
            }}
            // {...register("ProdcutSpecialFeature")}
            // error={errors.ProdcutSpecialFeature ? true : false}
            // helperText={errors.ProdcutSpecialFeature?.message}
          />

          <div className="from-last">
            <button
              // type="submit"
              className="deactivate-buttons"
              onClick={handleBack}
              style={{paddingTop:"5px"}}
            >
              Back
            </button>
            <button
             
              className="sbmit-buttons"
              style={{paddingTop:"5px"}}
              onClick={()=>formValidateFun()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B3Inside;
