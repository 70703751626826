import React from 'react'
import html2xlsx from "html2xlsx";

const FooterExcel = ({Footer}) => {
    const handleExportToExcel = () => {
        console.log("haiiiiiiiiiiiiiiiiiiii")
        const sheet = html2xlsx(Footer);
        sheet.export('YourFileName.xlsx');
      };
  return (
    <div>FooterExcel
      
          <button onClick={handleExportToExcel}>Export to Excel</button>
    </div>
  )
}

export default FooterExcel