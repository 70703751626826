import React from "react";
import h1 from "../Images/header/1.jpg";



function Projectteam() {
  return (
    <>
      <div>
        <div class="utility-page">

          <div class="carousel-item-img2">
            <div class="content-page">
              <h4> INSTITUTIONAL PARTNERS</h4>
              <ul class="team-list">
                <li><a href="https://tnau.ac.in/site/rrs-aduthurai/" target="_blank" >TamilNadu Rice Research Institute - Aduthurai </a></li>
                <li><a href="https://tnau.ac.in/site/rrs-tirur/" target="_blank">Rice Research Station - Tirur</a></li>
                <li><a href="https://tnau.ac.in/site/research-vridhachalam/" target="_blank"> Regional Research Station - Vridhachalam </a></li>
                <li><a href="https://tnau.ac.in/site/prs-vamban/" target="_blank">National Pulses Research Centre - Vamban</a></li>
                <li><a href="https://tnau.ac.in/site/srs-sirugamani/" target="_blank">Sugarcane Research Station - Sirugamani</a></li>
                <li><a href="https://tnau.ac.in/site/srs-cuddalore/" target="_blank">Sugarcane Research Station - Cuddalore </a></li>
                <li><a href="https://tnau.ac.in/site/srs-melalathur/" target="_blank" > Sugarcane Research Station - Melalathur</a></li>
                <li><a href="https://tnau.ac.in/site/ars-virinjipuram/" target="_blank" > Agricultural Research Station - Virinjipuram </a></li>
                <li><a href="https://tnau.ac.in/site/ars-pattukotai/" target="_blank"> Agricultural Research Station - Pattukottai</a></li>
                <li><a href="https://tnau.ac.in/site/ors-tindivanam/" target="_blank"> Oilseeds Research Station - Tindivanam</a></li>
                <li><a href=" https://tnau.ac.in/site/crs-veppanthattai/" target="_blank"> Cotton Research Station - Veppanthattai</a></li>
                <li><a href="https://agritech.tnau.ac.in/about_us/abt_us_cemtvm.html" target="_blank"> Centre Of Excellence For Millets - Athiyendal</a></li>
                <li><a href="https://tnau.ac.in/site/swmri-thanjavur/" target="_blank"> Agricultural Research Station - Kattuthottam - Thanjavur</a></li>
              </ul>

              <h4>TECHNICAL SUPPORT </h4>
              <p class="team-list-p">
                <a href="https://thepikture.com/" target="_blank" style={{ color: "black" }}>Pikture Associates Pvt Ltd - Chennai</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Projectteam;
