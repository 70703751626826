import React, { useEffect, useState } from "react";
import MasterData from "../MasterDataMangement/MasterData";
import MasterDataFaculty from "../MasterDataMangement/MasterDataFaculty/MasterDataFaculty";

function Dashboard() {
  const [role, setRole] = useState("");
  const isTrue = role === "Investigator";
  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role);
  }, []);
  return <>{isTrue ? <MasterDataFaculty /> : <MasterData />}</>;
}

export default Dashboard;
