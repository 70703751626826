import * as React from "react";
// import './Leave.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import ComponentLoader from "../../ComponentLoader";

// import { Leaveess } from '../Validation';
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useForm } from 'react-hook-form';

// import './App.css'

function MasterCourse({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/MasterData");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");
  const [loader,setLoader] =useState(true)
  const [courseNo, setCourseNo] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [dropnature, setDropnature] = useState([]);
  console.log(dropnature);
  const [dropcoursetype, setDropcoursetype] = useState([]);


  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  // const {
  //     register,
  //     handleSubmit,
  //     formState: { errors, isValid },
  //     setValue,
  //     reset,
  //     watch,
  // } = useForm({
  //     resolver: yupResolver(Leaveess),
  // });

  // const handleClickSubmit =()=>{

  // // }

  const reload = () => {
    window.location.reload();
  };
  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setId(item.id);
    // setCode(item.levCode)
    // setLeaveName(item.levName)
    setCourseNo(item.courseNo);
    setCourseName(item.courseName);
    setCourseType(item.hours);
  };


  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A1VALIMa),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
      handleNaturetypes(tok);
    }
    head("Course");
  }, []);

  //    ***************************************************************

  function createData(code, Lname, option) {
    return { code, Lname, option };
  }

  const rows = [
    //     createData('CL', 'Casual Leave', 'Edit'),
    //     createData('SL', 'Sick Leave', 'Edit'),
  ];
  
  const handleNaturetypes = async (token, JOB) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"JOB"}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      setDropnature(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      CourseNo: courseNo,
      CourseName: CourseName,
      Hours: courseType,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COURSESAVEBUT}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data.result == "Success") {
        alert("Successfully Submitted");
        reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcoursetype(data);setLoader(false)
      setDirect(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleClickSubmit = () => {
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  return (
    <div style={{ marginTop: 70 }}>
      <ToastContainer /> <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Course</span>
        </div>
      </div>
      <div className="table-container-grid table-container-grid1">
        <div className="table-head">
          {/* <div>
                    <button className='top-butto'  onClick={backButton}>

                        <ArrowBackIcon style={{ marginBottom: -5 }} className="sidebar-icon" />
                        Back</button>
                </div> */}
          <div>
            <button className="top-button" onClick={handleClickOpen}>
              + Add
            </button>
          </div>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

          // PaperProps={{
          //     component: "form",
          //     onSubmit: handleSubmit(handleLeavepage),
          // }}
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>Course</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon className="dialogue_close_icon" />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course No"
                  value={courseNo}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setCourseNo(e.target.value)}
                  //   {...register("Leavecode")}
                  //             error={errors.Leavecode ? true : false}
                  //             helperText={errors.Leavecode?.message}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course Name"
                  variant="outlined"
                  size="small"
                  value={CourseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </div>

              <div className="box width-50">
              <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Hours"
                  variant="outlined"
                  size="small"
                  value={courseType}
                  onChange={(e) => setCourseType(e.target.value)}
                />
                {/* <FormControl style={{ width: "100%"  }}>
                  <InputLabel id="demo-simple-select-helper-label" size="small">
                    Course Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Course Type"
                    // option={courseType}
                    size="small"
                    sx={{ width: "100%" }}
                    {...register("CourseTypev")}
                    error={errors.CourseTypev? true : false}
                    helperText={errors.CourseTypev?.message}
                  >
                    {dropcoursetype?.map((row) => {
                          return <MenuItem value={row.courseName}>{row.courseName}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}
              </div>
              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handleLeavepage}>
              Submit
            </button>
          </DialogActions>
        </Dialog>

        {/* ************************************************************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell align="right">Course No</TableCell>
              <TableCell align="right">Course Name</TableCell>
              <TableCell align="right">Hours</TableCell>
              <TableCell align="right">Option</TableCell>
            </TableHead>

            <TableBody>
              {direct.map((row) => (
                <TableRow
                  key={row.Leave}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right"> {row.courseNo}</TableCell>
                  <TableCell align="right">{row.courseName}</TableCell>
                  <TableCell align="right">{row.hours}</TableCell>

                  <TableCell align="right">
                    <button
                      className="btns"
                      variant="outlined"
                      onClick={() => edit(row)}
                    >
                      {<Edit className="sidebar-icon" />}
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={direct.length}
               rowsPerPage={masterManufacturer.rowsPerPage}
               page={masterManufacturer.page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
      </div>
    </div>
  );
}
export default MasterCourse;
