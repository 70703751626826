import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import { B16VALIDATION } from "../../../Validation";
import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import moment from "moment";
import ComponentLoader from "../../../../ComponentLoader";

function B16Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Authors, setAuthors] = useState("");
  const [Year, setYear] = useState("");
  const [Articletitle, setArticletitle] = useState("");
  const [Conferencetitle, setConferencetitle] = useState("");
  const [Conferencedate, setConferencedate] = useState("");
  const [Place, setPlace] = useState("");
  const [Organizer, setOrganizer] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader,setLoader] =useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [state,setState] = useState();
  const [assM,setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(B16VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Seminar Papers");
  }, []);

  useEffect(()=>{
    const objectString = localStorage.getItem('MReport');
    const myObject = JSON.parse(objectString);
    if(myObject !==null){
      setState(myObject)
    }
    const Month = localStorage.getItem('Month');
    if(Month !==null){
      setAssMonth(Month)
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  },[])

  const edit = (item) => {
    console.log(item);
    setValue("Authors",item.authors);
    setValue("Year",item.year);
    setValue("ArticleTitle",item.articleTitle);
    setValue("ConferenceTitle",item.conferenceTitle);
    setValue("ConferenceDate",moment(item.conferenceDate).format("YYYY-MM-DD"));
    setValue("Place",item.place);
    setValue("Organizer",item.organizer);
    setId(item.id);
  };

  const handleBack = () => {
    navigate("/B16")
  }
  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
    id,
    Authors: data.Authors,
    Year: data.Year,
    ArticleTitle: data.ArticleTitle,
    ConferenceTitle: data.ConferenceTitle,
    ConferenceDate: data.ConferenceDate,
    Place: data.Place,
    Organizer: data.Organizer,
    MRSId:state.id,
    investigatorId:parseInt(invis),
    rptMonth:parseInt(assM)
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B16SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Successfully Submitted")
          navigate("/B16")
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.B16EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted")
          navigate("/B16")
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDirectorate = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.STUDENTFACILITATIONTABLEALL}`,
        { headers: h }
      );setLoader(false);
      console.log(data);
      // setStudentbtn(data);
    } catch (error) {
      console.log(error);setLoader(false);
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>Seminar Papers & Articles (B16) </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>
            B16. Conference / Seminar Papers / Articles
          </h5>

          <TextField
            id="outlined-basic"
            label="Authors"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Natureofcoaching}
            // onChange={(e) => setAuthors(e.target.value)}
            {...register("Authors")}
            error={errors.Authors ? true : false}
            helperText={errors.Authors?.message}
          />

          <TextField
            id="outlined-basic"
            label="Year"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={Noofclassesoffered}
            // onChange={(e) => setYear(e.target.value)}
            {...register("Year")}
            error={errors.Year ? true : false}
            helperText={errors.Year?.message}
          />

          <TextField
            id="outlined-basic"
            label="Article Title (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={FellowshipName}
            // onChange={(e) => setArticletitle(e.target.value)}
            {...register("ArticleTitle")}
            error={errors.ArticleTitle ? true : false}
            helperText={errors.ArticleTitle?.message}
          />

          <TextField
            id="outlined-basic"
            label=" Conference Title (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={NoOfStudent}
            // onChange={(e) => setConferencetitle(e.target.value)}
            {...register("ConferenceTitle")}
            error={errors.ConferenceTitle ? true : false}
            helperText={errors.ConferenceTitle?.message}
          />

          <TextField
            id="outlined-basic"
            label="Date"
            // type="date"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // InputProps={{
            //   placeholder: "",
            // }}
            {...register("ConferenceDate")}
            error={errors.ConferenceDate ? true : false}
            helperText={errors.ConferenceDate?.message}
          />

          <TextField
            id="outlined-basic"
            label="Place"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={NoOfStudent}
            // onChange={(e) => setPlace(e.target.value)}
            {...register("Place")}
            error={errors.Place ? true : false}
            helperText={errors.Place?.message}
          />

          <TextField
            id="outlined-basic"
            label="Organizer"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            // value={NoOfStudent}
            // onChange={(e) => setOrganizer(e.target.value)}
            {...register("Organizer")}
            error={errors.Organizer ? true : false}
            helperText={errors.Organizer?.message}
          />

          <div className="from-last">
            <button type="submit" className='deactivate-buttons' onClick={handleBack}>Back</button>
            <button
              type="submit"
              className="sbmit-buttons"
            //   onClick={handleLeavepage}
            >
              Save
            </button>
          </div>
          {/* <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody> */}
        </div>
      </form>
    </div>
  );
}

export default B16Inside;
