import React from 'react';
import h1 from "../Images/header/1.jpg";

function About() {
    return (
        <>
            <div>
                <div class="about-page">
                    <div class="carousel-item-img4">
                        <div class=" content-page3">
                            <h5>ABOUT THE PROJECT</h5>
                            <p>Honourable Vice-Chancellor of Tamil Nadu Agricultural University, Coimbatore emphasized
                                the need for improving the existing education and research management system to improve
                                the efficiency of scientists.  Honourable Vice-Chancellor also tinted the need for improved
                                monitoring mechanism for all the University Officers. This vital gap highlighted by the Honourable
                                Vice-Chancellor of Tamil Nadu Agricultural University, Coimbatore was the root cause
                                for the evolution of Digital Reporting System for Region II Research Station. </p>

                            <p> This thought provoking discussion by the Honourable Vice-Chancellor of Tamil Nadu
                                Agricultural University, Coimbatore kindled Dr.K. Subrahmaniyan, Director, Tamil
                                Nadu Rice Research Station, Aduthurai and inspired to bridge this existing gap.
                                Director understood that ICT tools have immense potential to solve this issue and
                                also emphasized the need for one single platform for monitoring research work
                                flow, generating reports and permanent information depository for the region II
                                directorate. </p>

                            <p>Based on the scrutiny of scientists available at TRRI, Aduthurai, Director assigned this splendid task
                                to Dr.K.Manikandan, Assistant Professor (SS&AC) considering his previous expertise
                                on use of ICT tools in developing decision support system for mushroom choice for entire Indian continent. </p>


                            <p>Initially, the formal nod was obtained from the Honourable Vice-Chancellor of Tamil Nadu Agricultural
                                University, Coimbatore to initiate this noble work. Dr.K. Subrahmaniyan, Director, TRRI, Aduthurai
                                has kind enough to provide a separate web server for hosting this web
                                application.
                            </p>
                            <p>
                                In order to evolve a user friendly, acceptable web application module, a committee has been constituted
                                on August, 2022, by involving Heads of Research Stations, Faculties and staff from all 12 Region
                                II Research Stations under the chairmanship of Director, TRRI, Aduthurai. Wants and needs of individual
                                users viz., Scientists, Staffs, Heads of Departments and University officers was discussed
                                and elaborate information collection insisted by the committee to meet the objective of this process.
                            </p>
                            <p>
                                Web designing and programming components evolved in an accepted manner by the Pikture Associates Ltd., Chennai.
                                The prototype web application module was floated in the web on March, 2023 and comments from individual
                                users from all 12 research stations were obtained. The corrections were carried out accordingly and web
                                application module was refined technically. The aesthetic view of the front end of the application also
                                improved.
                            </p>
                            <p>
                                The trial run of web application was initiated in the month of August, 2023. Regular feedbacks from individual users were obtained and errors rectified now and then. 
                                The improved web application module was launched officially by  the Honourable Vice-Chancellor of Tamil Nadu Agricultural University on 15-July-2024.
                            </p>
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default About;

