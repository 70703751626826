import React from "react";
import "./Contactus.css";
import bannerImage from "../../Images/contact-us-banner3.jpg";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

function Contactus() {
  return (
    <>
    
    

      <div className="table-container-grid">
        <div className="table-head" style={{position:"fixed",width:"95%"}}>
          <Link to="/MasterData"><HomeIcon style={{ color: "white",marginLeft:20 }}/></Link>
        </div>

        <div>
        <section className="contact-us" style={{marginTop:"30px"}}>
          <div className="banner">
            <img src={bannerImage} alt="Banner"/>
          </div>
          <div className="contact-grid">
            {/* Contact Box 1 */}
            <div className="contact-box">
              <h2>Iyyankannu</h2>
              {/* <p>Address: 123 Main St</p> */}
              <p>Email: iyyan@thepikture.com</p>
              <p>Phone: 98401 45598</p>
            </div>

            {/* Contact Box 2 */}
            {/* <div className="contact-box">
              <h2>Azhagesan</h2>
             
              <p>Email: azhagesan@thepikture.com</p>
              <p>Phone: 96294 47215</p>
            </div> */}

            {/* Contact Box 3 */}
            <div className="contact-box">
              <h2>Pikture Associate Pvt Ltd</h2>
              <p>Address: 5057, 21st Main Road, G Block, Anna Nagar, Chennai - 600040</p>
              {/* <p>Email: contact3@example.com</p> */}
              <p>Phone: +91 89259 14524</p>
            </div>
          </div>
        </section>
      </div>

      </div>

      
    </>
  );
}

export default Contactus;
