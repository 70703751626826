import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { B15VALIDATION } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArC2Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true)
  const [Studentbtn, setStudentbtn] = useState([]);
  const [Lovbooktype, setLovbooktype] = useState([]);

  const [Authors, setAuthors] = useState("");
  const [Year, setYear] = useState("");
  const [Booktype, setBooktype] = useState("");
  const [BookChapter, setBookChapter] = useState("");
  const [Nameofbook, setNameofbook] = useState("");
  const [Publisher, setPublisher] = useState("");
  const [Pageno, setPageno] = useState("");
  const [ISBN, setISBN] = useState("");

  const [AuthorsErr, setAuthorsErr] = useState(false);
  const [YearErr, setYearErr] = useState(false);
  const [BooktypeErr, setBooktypeErr] = useState(false);
  const [BookChapterErr, setBookChapterErr] = useState(false);
  const [NameofbookErr, setNameofbookErr] = useState(false);
  const [PublisherErr, setPublisherErr] = useState(false);
  const [PagenoErr, setPagenoErr] = useState(false);
  const [ISBNErr, setISBNErr] = useState(false);


  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti,setInsti] = useState();
  const [inves, setInves] = useState([]);
  const [BookTypes, setBookTypes] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const[value,setValue]=useState({
    InvestigatorId:"",
    TechnologyName:"",
    SpecialFuture:"",
    Status:"",
    TentativeTimeLine:"",
   });

   const[errValue,setErrValue]=useState({
    InvestigatorId:false,
    TechnologyName:false,
    SpecialFuture:false,
    Status:false,
    TentativeTimeLine:false,
   });

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(B15VALIDATION),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok)
      handleBookType(tok);
      

      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Book Chapters");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
    handleInvestigators(tok,insti);
  }, []);

  const edit = (item) => {
    console.log(item);

setValue({
  InvestigatorId:item.investigatorId,
  TechnologyName:item.technologyName,
  SpecialFuture:item.specialFuture,
  Status:item.status,
  TentativeTimeLine:item.tentativeTimeLine
})
setId(item.id)
  };

  const handleData = ((event)=>{
    const {name,value}=event.target
    setValue(prev =>({
      ...prev,
      [name] : value
    }))
    

  })
  console.log("value",value);

  const handleBack = () => {
    navigate("/ArC2");
  };

  const BtnSubmit = () => {
    alert("Successfully Submitted");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  // Lov
  const handleLov = async (token, BookType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"BookType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovbooktype(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  // Lov Type //
  const handleBookType = async (token, ProductType) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"BookType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data) {
        setBookTypes(data);setLoader(false)
      }
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };
  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId:parseInt(value.InvestigatorId),
      TechnologyName:value.TechnologyName,
      SpecialFuture:value.SpecialFuture,
      Status:value.Status,
      TentativeTimeLine:value.TentativeTimeLine,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARC2SAVE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArC2");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARC2EDIT}`,
          d,
          { headers: h }
        );
        console.log(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArC2");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{

    
    if(!value.InvestigatorId){
      setErrValue((prev) => ({ ...prev, InvestigatorId: true }))
      document.getElementById("InvestigatorId").focus()
    }else if(!value.TechnologyName){
      setErrValue((prev) => ({ ...prev, TechnologyName: true }))
      document.getElementById("TechnologyName").focus()
    }else if(!value.SpecialFuture){
      setErrValue((prev) => ({ ...prev, SpecialFuture: true }))
      document.getElementById("SpecialFuture").focus()
    }else if(!value.Status){
      setErrValue((prev) => ({ ...prev, Status: true }))
      document.getElementById("Status").focus()
    }else if(!value.TentativeTimeLine){
      setErrValue((prev) => ({ ...prev, TentativeTimeLine: true }))
      document.getElementById("TentativeTimeLine").focus()
    }
    else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C2. Technologies
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ width: 450, margin: 20 }}>  C2. Technologies
 </h5>

          {/* <TextField
            error={AuthorsErr}
            id="Authors"
            label="S. No."
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Authors}
            onChange={(e) => {
              setAuthors(e.target.value)
              setAuthorsErr(false)
            }}
          /> */}

         

          {/* <TextField
            id="outlined-basic"
            label="Type"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            {...register("BookType")}
            error={errors.BookType ? true : false}
            helperText={errors.BookType?.message}
          /> */}
          {/* InvestigatorId:"",
    TechnologyName:"",
    SpecialFuture:"",
    Status:"",
    TentativeTimeLine:"", */}
          
         <FormControl
            style={{ width: "45%", margin: 12 }}
            // error={facultyErr}
            id="InvestigatorId"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
           Scientist Involved
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="InvestigatorId"
              error={errValue.InvestigatorId}
              style={{ height: "45px" }}
              value={value.InvestigatorId}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
              }}
            >
              {inves?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.investigatorName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* InvestigatorId:"",
    TechnologyName:"",
    SpecialFuture:"",
    Status:"",
    TentativeTimeLine:"", */}
          <TextField
            error={errValue.TechnologyName}
            id="TechnologyName"
            label="Name of the technology (Maximum 500 Characters)"
            variant="outlined"
            name="TechnologyName"
            style={{ margin: "10px", width: "48%" }}
            value={value.TechnologyName}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, TechnologyName: false }));
            }}
          />
          
          <TextField
            error={errValue.SpecialFuture}
            id="SpecialFuture"
            label="Special features of the technology (Maximum 500 Characters)"
            variant="outlined"
            name="SpecialFuture"
            style={{ margin: "10px", width: "48%" }}
            value={value.SpecialFuture}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, SpecialFuture: false }));
            }}
          />

          <TextField
            error={errValue.Status}
            id="Status"
            label="Status (Maximum 500 Characters)"
            variant="outlined"
            name="Status"
            style={{ margin: "10px", width: "48%" }}
            value={value.Status}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, Status: false }));
            }}
          />

          <TextField
            error={errValue.TentativeTimeLine}
            id="TentativeTimeLine"
            label=" Tentative time line for release (Maximum 500 Characters)"
            variant="outlined"
            name="TentativeTimeLine"
            style={{ margin: "10px", width: "48%" }}
            value={value.TentativeTimeLine}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, TentativeTimeLine: false }));
            }}
          />

          


          <div className="from-last">
            <button
              type="submit"
              className="deactivate-buttons"
              onClick={handleBack} style={{paddingTop:"5px"}}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={()=>formValidateFun()} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArC2Inside;