import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
  Autocomplete,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";

// import { Teachingrelated } from '../../../Pages/Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import { A7VALI } from "../../../Validation";
import ComponentLoader from "../../../../ComponentLoader";

function A7Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [dropcourse, setDropcourse] = useState([]);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();

  const [dropnature, setDropnature] = useState([]);
  const [dropnatcoching, setDropnatcoching] = useState([]);
  const [CourseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [dropcoursetype, setDropcoursetype] = useState([]);
  const [courseNo, setCourseNo] = useState("");
  const [hourss, sethourss] = useState("");
  const [loader,setLoader] =useState(true);

  const [options, setOptions] = useState([]);
  // Comman Id Send to set value
  const [courseDetailsId, setCourseDetailsId] = useState("");

  const [courseDetails, setCourseDetails] = useState(null);
  const [NatureCoch, setNatureCoch] = useState("");
  const [Number, setNumber] = useState("");
  const [DetailsOfWork, setDetailsOfWork] = useState("");

  const [courseDetailsErr, setCourseDetailsErr] = useState(false);
  const [NatureCochErr, setNatureCochErr] = useState(false);
  const [NumberErr, setNumberErr] = useState(false);
  const [DetailsOfWorkErr, setDetailsOfWorkErr] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(A7VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleCochingNaturetypes(tok);
      handleCoursetypes(tok);
      handleNaturetypes(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Teaching related");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const backs = () => {
    navigate("/A7");
  };

  const edit = (item) => {
    console.log(item);
    setCourseDetailsId(item.courseId);
    setNatureCoch(item.natureofthejob);
    setNumber(item.courseNo);
    setDetailsOfWork(item.detailsOfWork);
    setId(item.id);
  };

  useEffect(() => {
    console.log("========== ", options);
    options.forEach((item) => {
      console.log(item);
    });
    const getValue = options.find((data) => data.id === courseDetailsId);
    setCourseDetails(getValue);
  }, [dropcourse, courseDetailsId]);

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  //dropdown
  const handleNaturetypes = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnature(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  // Lov
  const handleCochingNaturetypes = async (token, JOB) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"JOB"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnatcoching(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  //dropdown
  const handleCoursetypes = async (token) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setDropcoursetype(data);
      setDropcourse(data);
      setOptions(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    const d = {
      id,
      Natureofthejob: NatureCoch,
      // CourseId: parseInt(courseDetails.id),
      CourseNo: Number,
      DetailsOfWork:DetailsOfWork,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A7SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/A7");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }

        //   setDirect(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.A7EDIT}`, d, {
          headers: h,
        });
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/A7");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const reload = () => {
    window.location.reload();
  };

  const handleDialog = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      CourseNo: courseNo,
      CourseName: CourseName,
      // CourseType: courseType,
      Hours: hourss,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COURSESAVEBUT}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data.result == "Success") {
        alert("Successfully Submitted");
        reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formValidateFun = () => {
    // if (!courseDetails || Object.keys(courseDetails).length === 0) {
    //   setCourseDetailsErr(true);
    //   document.getElementById("dropcourse").focus();
    // }
      if (!NatureCoch) {
      setNatureCochErr(true);
      document.getElementById("NatureCoch").focus();
    } 
    // else if (!Number) {
    //   setNumberErr(true);
    //   document.getElementById("Number").focus();
    // }
    else if (!DetailsOfWork) {
      setDetailsOfWorkErr(true);
      document.getElementById("DetailsOfWork").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>Course</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon
                  className="dialogue_close_icon"
                  style={{ backgroundColor: "black" }}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course No"
                  value={courseNo}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setCourseNo(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Course Name"
                  variant="outlined"
                  size="small"
                  value={CourseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Hours"
                  variant="outlined"
                  size="small"
                  value={hourss}
                  onChange={(e) => sethourss(e.target.value)}
                />
              </div>

              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={handleDialog}>
              Submit
            </button>
          </DialogActions>
        </Dialog>

        <div className="master-main-span">
          <span>Teaching related Activities (A7)</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        <div className="form-control">
          <h5 style={{ width: 350, margin: 20 }}>
            A7. Teaching related Activities
          </h5>

          {/* <div className="d-flex" style={{marginBottom:"15px"}}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              onChange={(e, val) => {
                setCourseDetails(val);
                setCourseDetailsErr(false);
              }}
              getOptionLabel={(option) =>
                `${option.courseNo} - ${option.courseName} - ${option.hours}`
              }
              value={courseDetails}
              // fullWidth
              style={{width:"97%",marginLeft:"12px"}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Course"
                  error={courseDetailsErr}
                />
              )}
            />
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "6px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div> */}

         

          <FormControl
            style={{ width: "48%", marginLeft: "15px" }}
            error={NatureCochErr}
            id="NatureCoch"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Nature of coaching
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Course Type"
              size="small"
              value={NatureCoch}
              onChange={(e) => {
                setNatureCoch(e.target.value);
                setNatureCochErr(false);
              }}
              sx={{ width: "100%", height: "43px" }}
            >
              {dropnatcoching?.map((row) => (
                <MenuItem key={row.lovName} value={row.lovName}>
                  {row.lovName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            error={DetailsOfWorkErr}
            id="DetailsOfWork"
            label=" Details Of Work (Max 500 Character)"
            variant="outlined"
            style={{ marginLeft: "10px", width: "48%" }}
            value={DetailsOfWork}
            onChange={(e) => {
              setDetailsOfWork(e.target.value);
              setDetailsOfWorkErr(false);
            }}
          />

          {/* <TextField
            error={NumberErr}
            id="Number"
            label=" Number"
            variant="outlined"
            style={{ marginLeft: "10px", width: "48%" }}
            value={Number}
            onChange={(e) => {
              setNumber(e.target.value);
              setNumberErr(false);
            }}
          /> */}

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={() => formValidateFun()}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default A7Inside;
