import { makeStyles } from "@material-ui/styles"
// import useTheme from "@material-ui/styles"
// import { useTheme } from "styled-components"
import {useTheme} from "@material-ui/styles"
export const tableContainerStyle = {
    maxHeight : 480,
    mr:"auto",
    width:"100%"
}

export const tableStyle = {
    minWidth: 750,
}

export const boxStyle = {
    width: "90%",
    bgcolor: "background.paper",
    margin: "0 auto 0px auto",
}
export const boxStyle2 = {
    width: "90%",
    bgcolor: "background.paper",
    margin: "auto",
    paddingTop: "10px",
}

export const tabIndicatorProps = {
    backgroundColor: "#ec764b",
    height: "2.5px",
    borderRadius: "10px",
    transition: "0.6s",
}
export const inputStyle = {
    width:"100%"
}
export const heirarchyInput = {
    width: "90%", margin: "20px auto 0 auto"
}


export const DialogueSt = () => {
    const theme = useTheme()
    const useStyle = makeStyles({
        dialogue:{
            height:"600px",
            // width:"1000px",
            maxWidth:"100%",
            // [theme.breakpoints.down("md")]:{
            //     width:"100vw",
            //     height:"100vh",
            //     margin:0,
            //     maxHeight:"none",
            //     maxWidth:"none",
            //     borderRadius:0
            // }
        }
    })

    const useDialogue = useStyle();
    return useDialogue;
}


