import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Autocomplete,
  IconButton,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { A8VALI } from "../../../Validation";
// import { Studentfacilitation } from '../../../Pages/Validation';
// import { FormControl } from "react-bootstrap";
import { Box } from "react-bootstrap-icons";
import HomeIcon from "@mui/icons-material/Home";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import ComponentLoader from "../../../ComponentLoader";

function ArB1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);
  const [inves, setInves] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [courseType, setCourseType] = useState([]);
  const [courseRole, setCourseRole] = useState([]);
  const [options, setOptions] = useState([]);

  const [Natureofcoaching, setNatureofcoaching] = useState("");
  const [Noofclassesoffered, setNoofclassesoffered] = useState("");
  const [FellowshipName, setFellowshipName] = useState("");
  const [NoOfStudent, setNoOfStudent] = useState("");

  const [courseDetails, setCourseDetails] = useState(null);
  const [courseDetailsErr, setCourseDetailsErr] = useState(false);
  const [courseDetailsId, setCourseDetailsId] = useState("");
  const [dropcourse, setDropcourse] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader,setLoader] =useState(true)

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [insti, setInsti] = useState();

  const [value, setValue] = useState({
    InvestigatorId: "",
    CourseNumber: "",
    CourseName: "",
    CourseId: "",
    CourseType: "",
    CourseRole: "",
    Hours: "",
    NoOfClassTP: "",
    StudyMaterialsProvided: "",
  });

  const [errValue, setErrValue] = useState({
    InvestigatorId: false,
    CourseNumber: false,
    CourseName: false,
    CourseId: false,
    CourseType: false,
    CourseRole: false,
    Hours: false,
    NoOfClassTP: false,
    StudyMaterialsProvided: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [dropnature, setDropnature] = useState([]);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    // setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(A8VALI),
  });

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      
      handleCourse(tok);
      handleCourseType(tok);
      handleCourseRole(tok);
      // handleDirectorate(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head(" Student facilitation");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const insti = localStorage.getItem("loginId");
    setInsti(insti);
    handleInvestigators(tok,insti)
  }, []);

  useEffect(() => {
    console.log("========== ", options);
    options.forEach((item) => {
      console.log(item);
    });
    const getValue = options.find((data) => data.id === courseDetailsId);
    setCourseDetails(getValue);
  }, [dropcourse, courseDetailsId]);

  const edit = (item) => {
    console.log(item);

    // InvestigatorId:"",
    // CourseNumber:"",
    // CourseId:"",
    // CourseType:"",
    // CourseRole:"",
    // Hours:"",
    // NoOfClassTP:"",
    // StudyMaterialsProvided:"",

    setValue({
      InvestigatorId: item.investigatorId,

      CourseType: item.courseType,
      CourseRole: item.courseRole,

      NoOfClassTP: item.noOfClassTP,
      StudyMaterialsProvided: item.studyMaterialsProvided,
    });
    setCourseDetailsId(item.courseId);
    setId(item.id);
  };

  const handleData = (event) => {
    const { name, value } = event.target;
    setValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log("value", value);

  const backs = () => {
    navigate("/ArB2");
  };

  const handleClickOpen = () => {
    setOpen(true);
    //  navigate("/Activities")
  };

  const handleClose = () => {
    setOpen(false);
    // setId(0)
  };

  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);
      setInves(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleCourse = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.COURSEALL}`, {
        headers: h,
      });
      console.log(data);
      setCourseList(data);
      setOptions(data);
      setDropcourse(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleCourseType = async (token, CourseType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setCourseType(data); setLoader(false)
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleCourseRole = async (token, CourseRole) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"CourseRole"}`,
        {
          headers: h,
        }
      );
      console.log(data); setLoader(false)
      setCourseRole(data);
    } catch (error) {
      console.log(error); setLoader(false)
    }
  };

  const handleDialog = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      CourseNo: value.CourseNumber,
      CourseName: value.CourseName,
      // CourseType: courseType,
      Hours: value.Hours,
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COURSESAVEBUT}`,
        d,
        { headers: h }
      );
      console.log(data);
      handleCourse(token);
      if (data.result == "Success") {
        alert("Successfully Submitted");
        // reload();
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLeavepage = async (data) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: value.InvestigatorId,
      CourseNumber: courseDetails.courseNo,
      CourseId: parseInt(courseDetails.id),
      CourseType: value.CourseType,
      CourseRole: value.CourseRole,
      Hours: courseDetails.hours,
      NoOfClassTP: value.NoOfClassTP,
      StudyMaterialsProvided: value.StudyMaterialsProvided,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARB2SAVE}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Successfully Submitted");
          navigate("/ArB2");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ARB2EDIT}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully updated");
          navigate("/ArB2");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = (e) => {
    e.preventDefault();

    if (!value.InvestigatorId) {
      setErrValue((prev) => ({ ...prev, InvestigatorId: true }));
      document.getElementById("InvestigatorId").focus();
    }
    // else if (!value.CourseNumber) {
    //   setErrValue((prev) => ({ ...prev, CourseNumber: true }))
    //   document.getElementById("CourseNumber").focus();
    // }
    // else if (!value.CourseId) {
    //   setErrValue((prev) => ({ ...prev, CourseId: true }))
    //   document.getElementById("CourseId").focus();
    // }
    else if (!value.CourseType) {
      setErrValue((prev) => ({ ...prev, CourseType: true }));
      document.getElementById("CourseType").focus();
    } else if (!value.CourseRole) {
      setErrValue((prev) => ({ ...prev, CourseRole: true }));
      document.getElementById("CourseRole").focus();
    }
    // else if (!value.Hours) {
    //   setErrValue((prev) => ({ ...prev, Hours: true }))
    //   document.getElementById("Hours").focus();
    // }
    else if (!value.NoOfClassTP) {
      setErrValue((prev) => ({ ...prev, NoOfClassTP: true }));
      document.getElementById("NoOfClassTP").focus();
    } else if (!value.StudyMaterialsProvided) {
      setErrValue((prev) => ({ ...prev, StudyMaterialsProvided: true }));
      document.getElementById("StudyMaterialsProvided").focus();
    } else {
      handleLeavepage();
    }
  };

  const formValidateDialog = (e) => {
    e.preventDefault();

    if (!value.CourseNumber) {
      setErrValue((prev) => ({ ...prev, CourseNumber: true }));
      document.getElementById("CourseNumber").focus();
    } else if (!value.CourseName) {
      setErrValue((prev) => ({ ...prev, CourseName: true }));
      document.getElementById("CourseName").focus();
    } else if (!value.Hours) {
      setErrValue((prev) => ({ ...prev, Hours: true }));
      document.getElementById("Hours").focus();
    } else {
      handleDialog();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>Course</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon
                  className="dialogue_close_icon"
                  style={{ backgroundColor: "black" }}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <TextField
                id="CourseNumber"
                label="Course Number (Max 250 Character)"
                name="CourseNumber"
                variant="outlined"
                style={{ margin: "10px", width: "40%" }}
                // value={value.CourseNumber}
                onChange={(e) => {
                  handleData(e);
                  setErrValue((prev) => ({ ...prev, CourseNumber: false }));
                }}
                error={errValue.CourseNumber}
                helperText={errors.Noofclassesoffered?.message}
              />

              <TextField
                id="CourseName"
                label="Course Name"
                name="CourseName"
                variant="outlined"
                style={{ margin: "10px", width: "40%" }}
                // value={value.CourseName}
                onChange={(e) => {
                  handleData(e);
                  setErrValue((prev) => ({ ...prev, CourseName: false }));
                }}
                error={errValue.CourseName}
                helperText={errors.Noofclassesoffered?.message}
              />

              <TextField
                id="Hours"
                label="Credit hours (Max 50 Character)"
                name="Hours"
                variant="outlined"
                style={{ margin: "10px", width: "40%" }}
                // value={value.Hours}
                onChange={(e) => {
                  handleData(e);
                  setErrValue((prev) => ({ ...prev, Hours: false }));
                }}
                error={errValue.Hours}
                helperText={errors.Noofclassesoffered?.message}
              />

              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="dialogue_action_button">
            <button type="submit" onClick={formValidateDialog}>
              Submit
            </button>
          </DialogActions>
        </Dialog>
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>B2. Diploma / UG / PG / Ph.D. Courses handled</span>
        </div>
      </div>

      <form className="from-mains" onSubmit={formValidateFun}>
        <div className="form-control">
          <h5 style={{ width: 350, margin: 20 }}>
            B2. Diploma / UG / PG / Ph.D. Courses handled
          </h5>

          <div className="d-flex">
            <FormControl
              style={{ width: "48%", margin: 12 }}
              // error={facultyErr}
              id="InvestigatorId"
              size="small"
            >
              <InputLabel id="demo-simple-select-helper-label" size="small">
                Name of the faculty{" "}
              </InputLabel>
              <Select
                variant="outlined"
                size="small"
                name="InvestigatorId"
                error={errValue.InvestigatorId}
                style={{ height: "45px" }}
                value={value.InvestigatorId}
                onChange={(e) => {
                  handleData(e);
                  setErrValue((prev) => ({ ...prev, InvestigatorId: false }));
                }}
              >
                {inves?.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.id}>
                      {option.investigatorName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ margin: "10px", width: "48%" }}
              options={options}
              size="small"
              onChange={(e, val) => {
                setCourseDetails(val);
                setCourseDetailsErr(false);
              }}
              getOptionLabel={(option) =>
                `${option.courseNo} - ${option.courseName} - ${option.hours}`
              }
              value={courseDetails}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Course"
                  error={courseDetailsErr}
                />
              )}
            />
            <div>
              <IconButton
                onClick={handleClickOpen}
                className="addbtn"
                style={{ fontSize: "14px", marginTop: "12px" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            // error={facultyErr}
            id="CourseType"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Dip / UG /PG / Ph.D.{" "}
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="CourseType"
              error={errValue.CourseType}
              style={{ height: "45px" }}
              value={value.CourseType}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, CourseType: false }));
              }}
            >
              {courseType?.map((row, index) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            // error={facultyErr}
            id="CourseRole"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Course Teacher / Associate{" "}
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="CourseRole"
              error={errValue.CourseRole}
              style={{ height: "45px" }}
              value={value.CourseRole}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({ ...prev, CourseRole: false }));
              }}
            >
              {courseRole?.map((row, index) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            id="NoOfClassTP"
            label="No. of classes conducted (Theory + Practical)(Max 500 Character)"
            variant="outlined"
            name="NoOfClassTP"
            style={{ margin: "10px", width: "48%" }}
            value={value.NoOfClassTP}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, NoOfClassTP: false }));
            }}
            error={errValue.NoOfClassTP}
            helperText={errors.NoOfStudent?.message}
          />

          {/* <TextField
            id="StudyMaterialsProvided"
            label="Study materials provided to the students (Yes /No)"
            variant="outlined"
            name="StudyMaterialsProvided"
       
            style={{ margin: "10px", width: "48%" }}
            value={value.StudyMaterialsProvided}
            onChange={(e) => {
              handleData(e);
              setErrValue((prev) => ({ ...prev, StudyMaterialsProvided: false }));
            }}
            error={errValue.StudyMaterialsProvided}
            helperText={errors.NoOfStudent?.message}
          /> */}

          <FormControl
            style={{ width: "48%", margin: 12 }}
            // error={facultyErr}
            id="StudyMaterialsProvided"
            size="small"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Study materials provided to the students (Yes /No) (Max 500
              Character){" "}
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              name="StudyMaterialsProvided"
              error={errValue.StudyMaterialsProvided}
              style={{ height: "45px" }}
              value={value.StudyMaterialsProvided}
              onChange={(e) => {
                handleData(e);
                setErrValue((prev) => ({
                  ...prev,
                  StudyMaterialsProvided: false,
                }));
              }}
            >
              <MenuItem value="Yes"> Yes </MenuItem>
              <MenuItem value="No"> No </MenuItem>
            </Select>
          </FormControl>

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"              
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArB1Inside;
