import * as React from "react";
// import './Leave.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../utils/style";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormControl";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  InputLabel,
  MenuItem,   
  Select,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import { Link, Navigate, useNavigate } from "react-router-dom";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { ToastContainer } from "react-toastify";
import ComponentLoader from "../../ComponentLoader";

function ListValues({ head }) {
  const Navigate = useNavigate("");

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [selectedvalue, setselectedvalue] = useState();
  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loader,setLoader] =useState(true)

  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");

  const [courseNo, setCourseNo] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [lovList,SetLovList] =useState("");

  const [courseNoErr,SetCourseNoErr] =useState(false);
  const [courseNameErr,setCourseNameErr] =useState(false);

  const [courseType, setCourseType] = useState("");


    const [lov,setLov] =useState([]);
  const [dropcourse, setDropcourse] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  
  const navigate = useNavigate();
  // const {
  //     register,
  //     handleSubmit,
  //     formState: { errors, isValid },
  //     setValue,
  //     reset,
  //     watch,
  // } = useForm({
  //     resolver: yupResolver(Leaveess),
  // });

  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setId(item.id);
    setCourseNo(item.lovCode);
    setCourseName(item.lovName);
    // setCourseType(item.courseType);
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);

    console.log(tok);
    if (tok != null && tok != undefined) {
      // handleDirectorate(tok);
      handleLoveData(tok)
      handleAddLov(tok)
    }
    head("Course");
  }, []);

  //    *********************

  const handleCoursetypes = async (value) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVEGETALL}${value}`,
        { headers: h }
      );
      console.log(data);

      setDropcourse(data);setLoader(false)
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };


  const handleLoveData = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    try {
      const { data } = await axios.get(`${window.BASE}${window.LOVLIST}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setLov(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  // const handleData =()=>{
  //   const h = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };
  //   console.log(token);
  //   try {
  //     const { data } = await axios.get(`${window.BASE}${}`, {
  //       headers: h,
  //     });
  //     console.log(data);
  //     setLov(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  

  function createData(code, Lname, option) {
    return { code, Lname, option };
  }

  const rows = [
    //     createData('CL', 'Casual Leave', 'Edit'),
    //     createData('SL', 'Sick Leave', 'Edit'),
  ];

  const handleClickSubmit = () => {
    setOpen(true);
  };
  const handleClickOpen = () => {
  if(!lovList){
    alert("Lov Type IS Required")
  }else{
    setOpen(true);
  }
   
  };

  const handleClose = () => {
    setOpen(false);
    setCourseNo("");
 setCourseName("");
    setId(0);
  };

  const handleAddLov = async () => {

    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      LovType : lovList,
      LovCode : courseNo,
      LovName : CourseName
    }
    if(id===0){
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ADDLOVTYPE}`,d,
          { headers: h }
        );
        console.log(data);setLoader(false)
  
        if (data.result === "Success") {
          alert("Succesfly Submited");
          // navigate("/ListValues");
          setCourseNo("");
          setCourseName("");
          setId(0);
          setOpen(false);
          handleCoursetypes(lovList)
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);setLoader(false)
      }
    }
    else{
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.EDITLOVTYPE}`,d,
          { headers: h }
        );
        console.log(data);
        setLoader(false)
        if (data.result === "Success") {
          alert("Succesfly Submited");
          // navigate("/ListValues");
          setCourseNo("");
          setCourseName("");
          setId(0);
          setOpen(false);
          handleCoursetypes(lovList)
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);setLoader(false)
      }
    }
    
  };

  const formValidateFun =()=>{

    if(!courseNo){
      SetCourseNoErr(true);
      document.getElementById("courseNo").focus()
    }else if(!CourseName){
      setCourseNameErr(true);
      document.getElementById("CourseName").focus()
    }else{
      handleAddLov()
    }

  }
  

  return (
    <div style={{ marginTop: 130 }}>
      <ToastContainer /><ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/MasterData">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>List of Values</span>
        </div>
      </div>
      <div style={{ width: "100%" }} className="type-sty">


        <FormGroup style={{ width: "30%" }}>
          <FormControl style={{ width: "100%", margin: 5 }}>
            <InputLabel id="demo-simple-select-helper-label" size="small">
              LovType
            </InputLabel>

            <Select
              style={{ margin: 5, background: "white" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Course Id"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                handleCoursetypes(e.target.value)
                SetLovList(e.target.value)
                setLoader(true)
              }}
            >

              {lov.map((val,index)=>{

                 return (
                  <MenuItem key={index} value={val.lovType}>
                    {val.lovType}
                  </MenuItem>
                );    }

              )}
              
            </Select>
          </FormControl>
        </FormGroup>


        
      </div>
      <div className="table-container-grid margin-minus">
        <div className="table-head">
          <div>
          <button
                id="adds"
                className="top-button"
                onClick={handleClickOpen}
                style={{fontSize:"13px"}}
              >
                + Add
              </button>
          </div>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div>
                <h5>List of Values</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon className="dialogue_close_icon" />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Code"
                  value={courseNo}
                  variant="outlined"
                  error={courseNoErr}
                  size="small"
                  onChange={(e) =>{
                    setCourseNo(e.target.value)
                    SetCourseNoErr(false)}
                                      }
                />
              </div>

              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=" Name"
                  variant="outlined"
                  size="small"
                  value={CourseName}
                  error={courseNameErr}
                  onChange={(e) => {
                    setCourseName(e.target.value)
                    setCourseNameErr(false)}
                                    }
                />
              </div>

              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="button">
            {/* <button variant="outlined" onClick={handleClose} className='btn2' >Deactivate</button> */}
            <button variant="outlined" className="btn1" onClick={formValidateFun}>
              Submit
            </button>
            .{/* onClick={handleLeavepage} */}
          </DialogActions>
        </Dialog>

        {/* ************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell align="right">Code</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">option</TableCell>
            </TableHead>

            <TableBody>
              {dropcourse?.map((row) => (
                <TableRow
                  key={row.Leave}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right"> {row.lovCode}</TableCell>
                  <TableCell align="right">{row.lovName}</TableCell>

                  <TableCell align="right">
                    <button
                      className="btns"
                      variant="outlined"
                      onClick={() => edit(row)}
                    >
                      {<Edit className="sidebar-icon" />}
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
export default ListValues;
