import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import { D1VALI } from "../../../Validation";

// import { Activetiesvalidation } from '../Validation';
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../../ComponentLoader";

function D1Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [Nameofitem, setNameofitem] = useState("");
  const [Nameofcrop, setNameofcrop] = useState("");
  const [Specifycity, setSpecifycity] = useState("");
  // --------------------------------------------------------
  const [Previosebalance, setPreviosebalance] = useState("");
  const [QProduced, setQProduced] = useState("");
  const [Quantitysold, setQuantitysold] = useState("");
  const [Balance, setBalance] = useState("");

  const [total,setTotal] = useState(0)
  // --------------------------------------------------------
  const [IncomeGenerated, setIncomeGenerated] = useState("");

  const [NameofitemErr, setNameofitemErr] = useState(false);
  const [NameofcropErr, setNameofcropErr] = useState(false);
  const [SpecifycityErr, setSpecifycityErr] = useState(false);
  const [PreviosebalanceErr, setPreviosebalanceErr] = useState(false);
  const [QProducedErr, setQProducedErr] = useState(false);
  const [QuantitysoldErr, setQuantitysoldErr] = useState(false);
  const [BalanceErr, setBalanceErr] = useState(false);
  const [IncomeGeneratedErr, setIncomeGeneratedErr] = useState(false);
  const [loader,setLoader] =useState(true);

  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [dropcourse, setDropcourse] = useState([]);
  const [dropnatcoching, setDropnatcoching] = useState([]);
  const [Lovspecifycity, setLovspecifycity] = useState([]);

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();


  
  const handleFirstChange = (e) => {
    setPreviosebalance();
  };

  const handleSecondChange = (e) => {
    setQProduced();
  };

  const handleThirdChange = (e) => {
    setQuantitysold();
  };

  const calculateResult = () => {
    const additionResult = parseFloat(Previosebalance) + parseFloat(QProduced);
    const subtractionResult = additionResult - parseFloat(Quantitysold);
    setBalance(subtractionResult);
  };


  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(D1VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLovSpecifycity(tok)
      handleLov(tok);
      handleCoursetypes(tok);
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("  Production and distribution of seeds / seedlings / Bio-inputs");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  const edit = (item) => {
    console.log(item);
    setNameofitem(item.nameOfItems);
    setNameofcrop(item.cropVariety);
    setQProduced(item.quantityProduced);
    setIncomeGenerated(item.incomeGenerated);
    setSpecifycity(item.specificityType);
    setPreviosebalance(item.previousBalance);
    setQuantitysold(item.quantitySold);
    setBalance(item.balance);

    setId(item.id);
  };

  // Lov Specifycity
  const handleLovSpecifycity = async (token, SpecificityType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"SpecificityType"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setLovspecifycity(data);
    } catch (error) {
      console.log(error);
    }
  };


  // Lov
  const handleLov = async (token, NameOfItems) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"NameOfItems"}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setDropnatcoching(data);
    } catch (error) {
      console.log(error);
    }
  };

  // dropdown
  const handleCoursetypes = async (token) => {
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.CROPGETALL}`, {
        headers: h,
      });
      console.log(data);setLoader(false)

      setDropcourse(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const backs = () => {
    navigate("/D1");
  };

  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      NameOfItems: Nameofitem,
      CropVariety: Nameofcrop,
      QuantityProduced:QProduced === "" ? "0" : parseInt(QProduced),
      IncomeGenerated:IncomeGenerated === "" ? "0" :  parseInt(IncomeGenerated),
      SpecificityType:Specifycity,
      PreviousBalance:Previosebalance === "" ? "0" : parseInt(Previosebalance),
      QuantitySold:Quantitysold === "" ? "0" : parseInt(Quantitysold),
      Balance:Balance === "" ? "0" : parseInt(Balance),
      // CropId: parseInt(data.CropIdV),
      // CropVariety: data.CropVarietyV,
      // TypeofSeeds: data.TypeofSeedsV,
      // InputsProduced: data.InputsProducedV,
      // InputsDistributed: data.InputsDistributedV,
      // IncomeGenerated: parseInt(data.IncomeGeneratedV),
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };

    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.D1SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);
        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/D1");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          // toastError(data.errorMsgs)
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.D1EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/D1");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun=()=>{
    if(!Nameofitem){
      setNameofitemErr(true)
      document.getElementById("Nameofitem").focus()
    }else if(!Nameofcrop){
      setNameofcropErr(true)
      document.getElementById("Nameofcrop").focus()
    }else if(!Specifycity){
      setSpecifycityErr(true)
      document.getElementById("Specifycity").focus()
    }
    // else if(!Previosebalance){
    //   setPreviosebalanceErr(true)
    //   document.getElementById("Previosebalance").focus()
    // }else if(!QProduced){
    //   setQProducedErr(true)
    //   document.getElementById("QProduced").focus()
    // }else if(!Quantitysold){
    //   setQuantitysoldErr(true)
    //   document.getElementById("Quantitysold").focus()
    // }else if(!Balance){
    //   setBalanceErr(true)
    //   document.getElementById("Balance").focus()
    // }else if(!IncomeGenerated){
    //   setIncomeGeneratedErr(true)
    //   document.getElementById("IncomeGenerated").focus()
    // }
    else{
      handleLeavepage()
    }
  }

//calculation All Onchange

useEffect(() => {
  console.log("Previosebalance ======= ",Previosebalance)
  let prebal = Previosebalance ? parseInt(Previosebalance) : 0;
  let proceed = QProduced ? parseInt(QProduced) : 0;
  let minus = Quantitysold ? parseInt(Quantitysold) : 0;
  let total = ( prebal + proceed) - minus

  setBalance(total)
}, [Previosebalance,QProduced,Quantitysold]);

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(D1). Production and distribution of seeds </span>
        </div>
      </div>

      <form className="from-mains" onSubmit={handleSubmit(handleLeavepage)}>
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
            D1. Production and distribution of seeds / seedlings / Bio-inputs
          </h5>

          <FormControl style={{ width: "48%", margin: 12 }} error={NameofitemErr} id="Nameofitem">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name Of Items
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Nameofitem}
              label="Name Of Items"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setNameofitem(e.target.value)
                setNameofitemErr(false)
              }}
            >
              {dropnatcoching?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            error={NameofcropErr}
            id="Nameofcrop"
            label="Name Of Crop / BioInput / Produce / Product"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Nameofcrop}
            onChange={(e) => {
                setNameofcrop(e.target.value)
                setNameofcropErr(false)
              }}
          />

          {/* <FormControl style={{ width: "48%", margin: 12 }} error={SpecifycityErr} id="Specifycity">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Specifycity of Produce
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Specifycity}
              label="Name Of Items"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setSpecifycity(e.target.value)
                setSpecifycityErr(false)
              }}
            >
              {Lovspecifycity?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <TextField
            error={SpecifycityErr}
            id="Specifycity"
            label="Mention Variety / hybrid / Vegetative"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Specifycity}
            onChange={(e) => {
              setSpecifycity(e.target.value)
              setSpecifycityErr(false)
              }}
          />


    {/* --------------------------------------------------------------------------------------------------- */}


          <TextField
            error={PreviosebalanceErr}
            name="Current Month Stock in Hand"
            id="Previosebalance"
            label="Current Month Stock in Hand (kg/No.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Previosebalance}
            type="number"
            onChange={(e) => {
              handleFirstChange()
              setPreviosebalance(e.target.value)
              setPreviosebalanceErr(false)
            }}
             
          />

          <TextField
          error={QProducedErr}
            id="QProduced"
            name="Current Month Production"
            label="Current Month Production (kg/No.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={QProduced}
            type="number"
            onChange={(e) => {
                handleSecondChange()
                setQProduced(e.target.value)
                setQProducedErr(false)
              }}
          />

          <TextField
            error={QuantitysoldErr}
            id="Quantitysold"
            name="Current Month Sold"
            label="Current Month Sold (kg/No.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Quantitysold}
            type="number"
            onChange={(e) => {
              // calculateResult()
                handleThirdChange()
                setQuantitysold(e.target.value)
                setQuantitysoldErr(false)
              }}
          />

          <TextField
            error={BalanceErr}
            id="Balance"
            label="Closing Stock (kg/No.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={Balance}
            onChange={(e) => {
                setBalance()
                // setBalanceErr(false)
              }}
            disabled  
          />

    {/* --------------------------------------------------------------------------------------------------- */}     

          <TextField
            error={IncomeGeneratedErr}
            id="IncomeGenerated"
            label="Income Generated (Rs.)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={IncomeGenerated}
            onChange={(e) => {
                setIncomeGenerated(e.target.value)
                setIncomeGeneratedErr(false)
              }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={() => { formValidateFun() }}>
            Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </form>
    </div>
  );
}

export default D1Inside;
