import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

// import { C5VALI } from "../../../Validation";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import moment from "moment";
import ComponentLoader from "../../../ComponentLoader";

function ArC12Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [dropcourse, setDropcourse] = useState([]);

  const [investigatorName,setInvestigatorName] = useState("")
  const [organizationName,setOrganizationName] = useState("")
  const [linkagesType,setLinkagesType] = useState("")
  const [dateOfMOU,setDateOfMOU] = useState("")
  const [purpose,setPurpose] = useState("")
  const [followup,setFollowup] = useState("")

  const [investigatorNameErr,setInvestigatorNameErr] = useState(false)
  const [organizationNameErr,setOrganizationNameErr] = useState(false)
  const [linkagesTypeErr,setLinkagesTypeErr] = useState(false)
  const [dateOfMOUErr,setDateOfMOUErr] = useState(false)
  const [purposeErr,setPurposeErr] = useState(false)
  const [followupErr,setFollowupErr] = useState(false)

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [loader,setLoader] =useState(true)
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();
  const [inves, setInves] = useState([]);
  const [lov, setLov] = useState();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C5VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
      handleLov(tok);
      
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("Extension Activities");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const tok = localStorage.getItem("token");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    handleInvestigators(tok,institute)
  }, []);

  const backs = () => {
    navigate("/ArC12");
  };
  
  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId);
    setOrganizationName(item.organizationName);
    setLinkagesType(item.linkagesType);
    setDateOfMOU(moment(item.dateOfMOU).format("YYYY-MM-DD"))
    setPurpose(item.purpose);
    setFollowup(item.followup);
    setId(item.id);
  };

  // Lov
  const handleLov = async (token, LinkagesType) => {
    console.log(token);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.LOVTYPE}${"LinkagesType"}`,
        {
          headers: h,
        }
      );
      console.log(data);setLoader(false)
      setLov(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  // Investigator 
  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };


  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);

    const d = {
      id,
      InvestigatorId: investigatorName,
      OrganizationName: organizationName,
      LinkagesType: linkagesType,
      DateOfMOU: dateOfMOU,
      Purpose: purpose,
      Followup: followup,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr12SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC12");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr12EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArC12");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
 
  const formValidateFun=()=>{
    if(!investigatorName){
      setInvestigatorNameErr(true)
      document.getElementById("investigatorName").focus()
    }else if(!organizationName){
      setOrganizationNameErr(true)
      document.getElementById("organizationName").focus()
    }else if(!linkagesType){
      setLinkagesTypeErr(true)
      document.getElementById("linkagesType").focus()
    }else if(!dateOfMOU){
      setDateOfMOUErr(true)
      document.getElementById("dateOfMOU").focus()
    }else if(!purpose){
      setPurposeErr(true)
      document.getElementById("purpose").focus()
    }else if(!followup){
      setFollowupErr(true)
      document.getElementById("followup").focus()
    }else{
      handleLeavepage()
    }
  }

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C12. Linkages established
 </span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>
          C12. Linkages established

          </h5>

          <FormControl style={{ width: "48%", margin: 12 }} error={investigatorNameErr}  id="investigatorName">
            <InputLabel id="demo-simple-select-helper-label" size="small">
            Scientist In Charge
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="investigatorName"
              value={investigatorName}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setInvestigatorName(e.target.value)
                setInvestigatorNameErr(false)
              }}
            >
            {inves?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>

          <TextField
            error={organizationNameErr}
            id="organizationName"
            label="Organization Name (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={organizationName}
            onChange={(e) => {
                setOrganizationName(e.target.value)
                setOrganizationNameErr(false)
              }}
          />

          <FormControl style={{ width: "48%", margin: 12 }} error={linkagesTypeErr}  id="linkagesType">
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Linkages Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="linkagesType"
              value={linkagesType}
              label="Programme Type"
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setLinkagesType(e.target.value)
                setLinkagesTypeErr(false)
              }}
            >
            {lov?.map((row) => {
                return (
                  <MenuItem key={row.lovName} value={row.lovName}>
                    {row.lovName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>


          <TextField
            error={dateOfMOUErr}
            id="dateOfMOU"
            type="date"
            label="Date Of MOU"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={dateOfMOU}
            onChange={(e) => {
                setDateOfMOU(e.target.value)
                setDateOfMOUErr(false)
            }}
            InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                placeholder: "",
              }}
          />

          <TextField
            error={purposeErr}
            id="purpose"
            label="Purpose (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={purpose}
            onChange={(e) => {
                setPurpose(e.target.value)
                setPurposeErr(false)
              }}
          />

          <TextField
            error={followupErr}
            id="followup"
            label="Follow Up (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={followup}
            onChange={(e) => {
                setFollowup(e.target.value)
                setFollowupErr(false)
              }}
          />


          <div className="from-last">
            <button
              type="submit"
              style={{ backgroundColor: "#008CBA", height: "20px",padding:"5px 30px 28px 26px",borderRadius:"3px",color:"#fff" }}
              onClick={backs}
            >
              Back
            </button>
            <button type="submit" className="sbmit-buttons" onClick={formValidateFun} style={{paddingTop:"5px"}}>
            Save
            </button>
          </div>

          <TableBody>
            {Array.isArray(Studentbtn) &&
              Studentbtn.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </div>
      </div>
    </div>
  );
}

export default ArC12Inside;
