import React from "react";

import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { Dialog, DialogContent, Icon, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
// import { C3VALI } from "../../../Validation";
// import { FormControl } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { Box } from "react-bootstrap-icons";
import ComponentLoader from "../../../ComponentLoader";

function ArC10Inside({ head }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);

  const [Studentbtn, setStudentbtn] = useState([]);

  const [inves,setInves] = useState([])
  const [cropN,setCropN] = useState([])

  const [investigatorName, setInvestigatorName] = useState("");
  const [cropName, setCropName] = useState("");
  const [duringMonth, setDuringMonth] = useState("");
  const [germplasmCollected, setGermplasmCollected] = useState("");
  const [specialFeature, setSpecialFeature] = useState("");

  const [investigatorNameErr, setInvestigatorNameErr] = useState(false);
  const [cropNameErr, setCropNameErr] = useState(false);
  const [duringMonthErr, setDuringMonthErr] = useState(false);
  const [germplasmCollectedErr, setGermplasmCollectedErr] = useState(false);
  const [specialFeatureErr, setSpecialFeatureErr] = useState(false);
  const [loader,setLoader] =useState(true)
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [insti, setInsti] = useState();

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    // resolver: yupResolver(C3VALI),
  });

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    if (tok != null && tok != undefined) {
   
      handleCrop(tok)
      console.log(location.state);
      if (location.state) {
        edit(location.state);
      }
    }
    head("FFS Programs organized");
  }, []);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    if (myObject !== null) {
      setState(myObject);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const institute = localStorage.getItem("loginId");
    setInsti(institute);
    const tok = localStorage.getItem("token");
    handleInvestigators(tok,institute)
  }, []);

  const backs = () => {
    navigate("/ArC10");
  };

  const edit = (item) => {
    console.log(item);
    setInvestigatorName(item.investigatorId);
    setCropName(item.cropId);
    setDuringMonth(item.duringMonth);
    setGermplasmCollected(item.totalGermplasmcollected);
    setSpecialFeature(item.prodcutSpecialFeature);
    setId(item.id);
  };


  const handleInvestigators = async (token,id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.INVESTIGATORIDMAPNAME}${id}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setInves(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const handleCrop = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.CROPGETALL}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setCropN(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };


  const handleLeavepage = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
 
    const d = {
      id,
      InvestigatorId: investigatorName,
      CropId: cropName,
      DuringMonth: duringMonth,
      TotalGermplasmcollected: germplasmCollected,
      ProdcutSpecialFeature: specialFeature,
      IAMRSId: state.id,
      InstituteId: parseInt(insti),
      RptMonth: parseInt(assM),
    };
    if (id === 0) {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr10SAVE}`, d, {
          headers: h,
        });
        console.log(data);
        setStudentbtn(data);

        if (data.result === "Success") {
          alert("Succesfully Submitted");
          navigate("/ArC10");
          toastSuccess("Successfully Submitted");
          setId(0);
          setOpen(false);
        } else {
          alert(data.errorMsgs[0])
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${window.BASE}${window.Cr10EDIT}`, d, {
          headers: h,
        });
        console.log(data);

        if (data.result === "Success") {
          alert("Succesfully updated");
          navigate("/ArC10");
          toastSuccess("Successfully updated");
          setId(0);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formValidateFun = () => {
    if (!investigatorName) {
      setInvestigatorNameErr(true);
      document.getElementById("investigatorName").focus();
    } else if (!cropName) {
      setCropNameErr(true);
      document.getElementById("cropName").focus();
    } else if (!duringMonth) {
      setDuringMonthErr(true);
      document.getElementById("duringMonth").focus();
    } else if (!germplasmCollected) {
      setGermplasmCollectedErr(true);
      document.getElementById("germplasmCollected").focus();
    } else if (!specialFeature) {
      setSpecialFeatureErr(true);
      document.getElementById("specialFeature").focus();
    } else {
      handleLeavepage();
    }
  };

  return (
    <div className="main-container-margin">
       <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/GeneralAR">
            <HomeIcon style={{ color: "white", marginLeft: 70 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>C10.Germplasm collection
</span>
        </div>
      </div>

      <div className="from-mains">
        <div className="form-control">
          <h5 style={{ margin: 20 }}>C10.Germplasm collection
</h5>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={investigatorNameErr}
            id="investigatorName"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Name of the Faculty
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=" Position Scheme "
              size="small"
              sx={{ width: "100%" }}
              value={investigatorName}
              onChange={(e) => {
                setInvestigatorName(e.target.value);
                setInvestigatorNameErr(false);
              }}
            >
              {inves?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.investigatorName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>

          <FormControl
            style={{ width: "48%", margin: 12 }}
            error={cropNameErr}
            id="cropName"
          >
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Crop
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=" Position Scheme "
              size="small"
              sx={{ width: "100%" }}
              value={cropName}
              onChange={(e) => {
                setCropName(e.target.value);
                setCropNameErr(false);
              }}
            >
              {cropN?.map((row,index) => {
              return (
                <MenuItem key={row.index} value={row.id}>
                  {row.cropName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>

          <TextField
            error={duringMonthErr}
            id="duringMonth"
            label="Germplasm collected during this
            month (Max 500 Character)
            "
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={duringMonth}
            onChange={(e) => {
              setDuringMonth(e.target.value);
              setDuringMonthErr(false);
            }}
          />
          <TextField
            error={germplasmCollectedErr}
            id="germplasmCollected"
            label="Total Germplasm collected so far (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={germplasmCollected}
            onChange={(e) => {
              setGermplasmCollected(e.target.value);
              setGermplasmCollectedErr(false);
            }}
          />
          <TextField
            error={specialFeatureErr}
            id="specialFeature"
            label="Special features (Max 500 Character)"
            variant="outlined"
            style={{ margin: "10px", width: "48%" }}
            value={specialFeature}
            onChange={(e) => {
              setSpecialFeature(e.target.value);
              setSpecialFeatureErr(false);
            }}
          />

          <div className="from-last">
            <button
              type="submit"
              style={{
                backgroundColor: "#008CBA",
                height: "20px",
                padding: "5px 30px 28px 26px",
                borderRadius: "3px",
                color: "#fff",
              }}
              onClick={backs}
            >
              Back
            </button>
            <button
              type="submit"
              className="sbmit-buttons"
              onClick={() => formValidateFun()}
              style={{ paddingTop: "5px" }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArC10Inside;
