import { Link } from "@mui/material";
import React, { useRef } from "react";
import HomeIcon from "@mui/icons-material/Home";
import ReportTables from "../ReportTable/ReportTable";
import { Navigate, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import "./ReportDownloadPage.css";

function ReportDownloadPage() {
  const Navigate = useNavigate();
  const monthlyReport = () => {
    Navigate("/monthyReport");
  };

  return (
    <div>
      <div style={{ marginTop: 60 }}>
        {/* <div className="master-main d-flex" style={{ position: "fixed" }}>
          <div className="master-main-span">
            <span> Report Download </span>
          </div>
        </div> */}
        <div className="table-container-gri">
          <div className="tables-mains">
            <div
              className="table-head"
              style={{ position: "fixed", width: "100%" }}
            >
              <div>
                <div className="head-icon">
                  <Link onClick={monthlyReport}>
                    <HomeIcon style={{ color: "white", marginLeft: 38 }} />
                  </Link>
                </div>
                <div className="report-head" style={{marginLeft:"10px",paddingTop:"8px"}}>
                {/* <h5 style={{fontSize:"18px"}}>Report Download</h5> */}
                </div>
              </div>
            </div>
            <div>
              <ReportTables />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDownloadPage;
